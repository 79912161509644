import firebase, { auth, db } from "../main";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  setDoc,
  getDocs,
  getDoc,
  deleteDoc,
  collectionGroup,
  query,
  arrayUnion,
  arrayRemove,
  where,
  limit,
} from "firebase/firestore";
import { getUser } from "./user";
import Classes from "../../../common/models/classes";
import { preSaveOrUpdateAClass } from "../../../common/validations/PreSave";
import { getCollectionRef } from "../../../common/Helpers/GetCollectionRef";
const coll_classes = "classes";

export const createID = () => {
  return new Promise(async (resolve, reject) => {
    addDoc(getCollectionRef(coll_classes), {})
      .then((data) => {
        const classesObj = Object.assign(new Classes(), { id: data.id });
        resolve({ id: classesObj.id });
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

export const addClass = async (data) => {
  try {
    const classObj = {
      ...data,
    };
    const docRef = await addDoc(
      getCollectionRef(coll_classes),
      preSaveOrUpdateAClass(classObj)
    );
    return docRef.id;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateClass = (id, content) => {
  return new Promise(async (resolve, reject) => {
    setDoc(
      doc(getCollectionRef(coll_classes), id),
      preSaveOrUpdateAClass(content),
      { merge: true }
    )
      .then((data) => {
        resolve({ success: true });
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const removeClass = () => {
  //   return new Promise(async (resolve, reject) => {
  //   });
};
export const getAllClasses = () => {
  //   return new Promise(async (resolve, reject) => {
  //   });
};
export const getClassesByGym = async (id) => {
  try {
    const temp = [];
    const querySnapshot = await getDocs(
      query(getCollectionRef(coll_classes), where("gym", "==", id))
    );

    querySnapshot.forEach((doc) => {
      const classesData = doc.data();
      const classesObj = {
        ...classesData,
        id: doc.id,
      };
      temp.push(classesObj);
    });

    return temp;
  } catch (error) {
    console.error("Error fetching classes:", error);
    return []; // Return an empty array or handle the error as per your application's requirements
  }
};

export const getAllFreelanceClasses = async () => {
  try {
    const temp = [];
    const temp02 = [];
    const docsF = await getDocs(
      query(getCollectionRef(coll_classes)),
      where("gym", "==", "")
    );
    docsF.forEach((doc) => {
      const classesObj = {
        ...doc.data(),
        id: doc.id,
      };
      if (classesObj.gym === "" && classesObj.owner !== "") {
        temp.push(classesObj);
      }
    });
    if (temp.length > 0) {
      for (const data of temp) {
        const _user = await getUser(data.owner);
        temp02.push({ ...data, ownerEmail: _user.username });
      }
      return temp02;
    } else {
      return temp;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// export const getAllFreelanceClasses = () => {
//   return new Promise(async (resolve, reject) => {
//     let temp = [];
//     let temp02 = [];
//     let docsF = await getDocs(
//       query(getCollectionRef(coll_classes)),
//       where("gym", "==", "")
//     );
//     if (docsF.size) {
//       docsF.forEach((doc) => {
//         const classesObj = Object.assign(new Classes(), {
//           ...doc.data(),
//           id: doc.id,
//         });
//         if (classesObj.gym == "" && classesObj.owner !== "")
//           return temp.push(classesObj);
//       });
//     }
//     if (temp && temp.length > 0) {
//       for (let data of temp) {
//         const _user = await getUser(data.owner);
//         temp02.push({ ...data, ownerEmail: _user.username });
//       }
//       resolve(temp02);
//     } else {
//       resolve(temp);
//     }
//   });
// };

export const getClassesByTrainer = (id) => {
  return new Promise(async (resolve, reject) => {
    let temp = [];
    let docsF = await getDocs(
      query(getCollectionRef(coll_classes)),
      where("owner", "==", id)
    );
    if (docsF.size) {
      docsF.forEach((doc) => {
        if (doc.data().owner == id) temp.push({ ...doc.data(), id: doc.id });
      });
    }
    resolve(temp);
  });
};

export const getClassesByBranch = () => {
  //   return new Promise(async (resolve, reject) => {
  //   });
};
export const getClassById = () => {
  //   return new Promise(async (resolve, reject) => {
  //   });
};
export const getClassesListByBranch = () => {
  //   return new Promise(async (resolve, reject) => {
  //   });
};
