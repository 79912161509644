import React from "react";
import "./Panel.scss";

export default function Panel(props) {
  return (
    <>
      <div
        style={{ minHeight: props.minheight || "95%" }}
        {...props}
        className={`panel-container ${props.className}`}
      >
        <div className="pane-body">{props.children}</div>
      </div>
    </>
  );
}
