import React, { Component } from "react";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";

class TosterCom extends Component {
  constructor(props) {
    super(props);
    window.tostercom = this;
  }
  static show(message, isError = false) {
    const { t } = window.tostercom.props;
    if (!isError) {
      toast.success(t(message), {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
      });
    } else {
    }
    if (isError) {
      toast.error(t(message), {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
  }
  render() {
    return <></>;
  }
}

const Toster = withTranslation()(TosterCom);
Toster.show = TosterCom.show;

export default Toster;
