import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BsPlusCircleFill } from "react-icons/bs";
import { RiSearchEyeLine, RiPencilFill } from "react-icons/ri";
import { IoClose, IoCheckmark } from "react-icons/io5";
import AddUser from "./addUser";
// import { getMembershipNames } from "../../../../repository/web/js/membership";
import { getMembershipNamesController } from "../../../../../../controllers/web/memberShipController";
import { useLocation } from "react-router-dom";
// import { getUsersByRole } from "../../../../repository/web/js/user";
import { getUsersByRoleController } from "../../../../../../controllers/web/userController";
// import { changeUserActivateState } from "../../../../repository/web/js/user";
import { changeUserActivateStateController } from "../../../../../../controllers/web/userController";
// import { getMainGymIDByUser } from "../../../../repository/web/js/gym_main";
import { getMainGymIDByUserController } from "../../../../../../controllers/web/gymMainController";
import { useNavigate } from "react-router-dom";
import CustomLoading from "../../../items/loadingBar";
import UserTypes from "../../../../../../common/data/UserTypes";
import { useSelector } from "react-redux";
import ConfirmDialog from "../../../custom/ConfirmDialog/ConfirmDialog";
import { Msgs } from "../../../../../../common/data/msgs";
import { Form } from "react-bootstrap";
import Switch from "@mui/material/Switch";
import { FormControlLabel, FormGroup, ThemeProvider } from "@mui/material";
import { outerTheme } from "../../../global";
import InputBox from "../../../custom/InputBox/InputBox";

const TableRow = ({ content, memberships, openEdit, onActivate }) => {
  const { t } = useTranslation();
  const [memberShipPlan, setMemberShipPlan] = useState("");
  const [description, setDescription] = useState("");
  const { user: userInfo } = useSelector((state) => state.user);
  const [activate, setActivate] = useState(true);
  useEffect(() => {
    setActivate(content.activated);
  }, [content]);

  useEffect(() => {
    for (let i = 0; i < memberships?.length; i++) {
      if (memberships[i].value === content.membership) {
        setMemberShipPlan(memberships[i].label);
        setDescription(memberships[i].description);
        break;
      }
    }
  }, [content]);

  const onClickActivate = () => {
    onActivate(content.id, !content.activated);
  };
  return (
    <div className="table-row">
      <span className="surename">{content?.sure_name}</span>
      <span className="username">{content?.username}</span>
      <span className="mobile">{content?.phone}</span>

      <span className="type">
        {content?.role.map((role) =>
          UserTypes.map((type, index) => {
            if (type.value === role)
              return (
                <React.Fragment key={index}>{type.label + ", "}</React.Fragment>
              );
          })
        )}
      </span>
      <span className="facility_name">
        {content?.facility_name ? content.facility_name : ""}
      </span>

      {/* <span className="facility_name">
        {content?.facility_name && content?.facility_name?.length > 0
          ? content?.facility_name?.map((facility_name, key) => {
              return (
                <React.Fragment key={key}>
                  <span key={key}>{facility_name?.label}</span>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </React.Fragment>
              );
            })
          : ""}
      </span> */}
      {/* temoporary comment */}
      <div className="controls">
        <span
          className="edit"
          onClick={() => {
            openEdit(content?.id, memberShipPlan, description);
          }}
        >
          <RiPencilFill />
        </span>
        {userInfo.role.includes(4) && (
          <div className="d-flex align-items-center">
            <ThemeProvider theme={outerTheme}>
              <Switch
                checked={activate}
                onChange={(e, c) => {
                  onActivate(content?.id, c);
                }}
                name="checkedA"
              />
              <div className={`${activate ? "text-light" : "text-secondary"}`}>
                Active
              </div>
            </ThemeProvider>
          </div>
        )}
      </div>
      <div className="controls-blank">&nbsp;</div>
      <div className="controls-mobile">
        <span
          className="edit"
          onClick={() => {
            openEdit(content?.id, memberShipPlan, description);
          }}
        >
          <RiPencilFill />
        </span>
        {userInfo.role.includes(4) && (
          <>
            {content.activated ? (
              <span className="activate" onClick={onClickActivate}>
                <IoCheckmark />
              </span>
            ) : (
              <span className="activate disable" onClick={onClickActivate}>
                <IoClose />
              </span>
            )}
          </>
        )}
      </div>
      {/* temoporary comment */}
    </div>
  );
};

const ManageUser = () => {
  const { t } = useTranslation();
  var { user: userInfo } = useSelector((state) => state.user);
  const [isAdd, setIsAdd] = useState(false);
  const { path } = useLocation();
  const [memberships, setMemberships] = useState([]);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [content, setContent] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState([]);
  const [mainGymID, setMainGymID] = useState("");

  useEffect(() => {
    setRole(userInfo.role);
    getMainGymIDByUserController(userInfo.id).then((id) => {
      setMainGymID(id);
    });
    getMembershipNamesController().then((data) => {
      setMemberships(data.data);
    });
    getUsers();
  }, [path]);

  const getUsers = () => {
    setLoading(true);

    getUsersByRoleController(userInfo)
      .then((data) => {
        setUsers(data.data);
        setContent(data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const openAddModal = () => {
    if (userInfo?.activated) {
      setIsAdd(true);
    } else {
      ConfirmDialog.show({
        message: Msgs.NotActiveMsg,
        showFooter: false,
      });
    }
  };

  const closeAddModal = () => {
    setIsAdd(false);
  };

  const openEdit = (userid, memberShipPlan, description) => {
    if (userInfo?.activated) {
      navigate("/edituser", {
        state: {
          userid,
          memberShipPlan: memberShipPlan,
          description: description,
          dataReceivedFrom: "custom",
        },
      });
    } else {
      ConfirmDialog.show({
        message: Msgs.NotActiveMsg,
        showFooter: false,
      });
    }
  };

  const onSearch = (e) => {
    setSearch(e);
    let temp = [];
    users.forEach((item) => {
      if (
        (item.username &&
          item.username.toLowerCase().includes(e.toLowerCase())) ||
        (item.sure_name &&
          item.sure_name.toLowerCase().includes(e.toLowerCase()))
      ) {
        temp.push(item);
      }
    });
    setContent(temp);
  };

  const onActivate = (id, state) => {
    if (userInfo?.activated) {
      ConfirmDialog.show({
        title: "Confirmation!",
        message: `Are Sure to change user status?`,
        okCallback: async () => {
          ConfirmDialog.hide();
          await changeUserActivateStateController(id, state).then((data) => {
            if (data.success === "success") {
              getUsers();
            }
          });
        },
      });
    } else {
      ConfirmDialog.show({
        message: Msgs.NotActiveMsg,
        showFooter: false,
      });
    }
  };
  return (
    <Container>
      <div className="header">
        <div className="title-layout">
          <span className="title">{t("Users")}</span>
          <span
            className="button"
            onClick={openAddModal}
            style={{ cursor: "pointer" }}
          >
            <BsPlusCircleFill className="icon" />
            {t("Add User")}
          </span>
        </div>
        {/* <div className="search">
          <RiSearchEyeLine className="icon" />
          <input
            className="input"
            placeholder={t("Search Users")}
            value={search}
            onChange={(e) => onSearch(e.target.value)}
          />
        </div> */}
        <div className="d-flex ms-auto mt-3">
          <InputBox
            icon={<RiSearchEyeLine />}
            placeholder="Search..."
            value={search}
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="content-layout">
        <div className="control-group" />
        <CustomLoading isStart={loading} isFull={true} />
        <div className="content">
          <div className="table-header">
            <span className="surename">{t("Sure Name")}</span>
            <span className="username">{t("Username")}</span>
            <span className="mobile">{t("Mobile Number")}</span>
            <span className="type">{t("Roles")}</span>
            <span className="facility_name">{t("Facility Name")}</span>
            <span className="controls">&nbsp;</span>
            <span className="controls-blank">&nbsp;</span>
            <span className="controls-mobile">&nbsp;</span>
          </div>
          <div className="table-content">
            
            {content?.map((item, index) => (
              // item.id !== userInfo.id && (
                <TableRow
                  content={item}
                  memberships={memberships}
                  openEdit={openEdit}
                  onActivate={onActivate}
                  key={index}
                />
              // )
            ))}
          </div>
        </div>
      </div>
      <AddUser
        isOpen={isAdd}
        role={role}
        gymID={mainGymID}
        close={closeAddModal}
        memberships={memberships}
        refresh={getUsers}
      />
    </Container>
  );
};

const Container = styled("div")`
  display: flex;
  width: 100%;

  flex-direction: column;
  background: #1c1a1d;
  @media (max-width: 1180px) {
    margin-top: 86px;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    height: 30px;
    background-color: black;
    z-index: 100;

    .title-layout {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .title {
      font-size: 24px;
      line-height: 120%;
      text-transform: uppercase;
      font-weight: bold;
      color: #f2f2f2;
      margin-right: 25px;
    }

    .button {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #feca00;

      .icon {
        font-size: 16px;
        margin-right: 14px;
      }
    }

    .search {
      width: 260px;
      height: 50px;
      background: #1c1a1d;
      border: 1px solid #888888;
      box-sizing: border-box;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 20px;
      font-size: 18px;
      line-height: 100%;
      color: #3e3e3e;

      .icon {
        font-size: 22px;
        margin-right: 12px;
      }

      .input {
        width: 100%;
        background: #1c1a1d;
        border: none;
        color: #888888;

        &:focus-visible {
          outline: none;
        }
      }
    }
  }
  @media (max-width: 1180px) {
  }
  .content {
    padding: 45px 40px;
    display: flex;
    flex-direction: column;

    .table-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.01em;
      color: #888888;
      padding-bottom: 10px;
      border-bottom: 1px solid #4f4f4f;
    }

    .surename {
      width: 100%;
      max-width: 250px;
      min-width: 150px;
    }

    .username {
      width: 100%;
      max-width: 300px;
      min-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;
    }

    .mobile {
      width: 100%;
      max-width: 150px;
      min-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;
    }

    .membership {
      width: 100%;
      min-width: 200px;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;
    }

    .facility_name {
      width: 100%;
      min-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;
    }

    .branches-select {
      max-width: 200px;
    }

    .type {
      width: 100%;
      max-width: 200px;
      min-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;
    }

    .controls {
      width: 100%;
      padding-right: 10px;
      max-width: 120px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 20px;
      align-items: center;

      .edit {
        cursor: pointer;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        background-color: #333333;
        color: #159cff;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:active,
        &:hover {
          cursor: pointer;
          color: #fff;
        }
      }

      .activate {
        cursor: pointer;
        width: 73px;
        height: 32px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 160%;
        background: #1e382a;
        color: #27ae60;
      }

      .activate.disable {
        cursor: pointer;
        background: #462224;
        color: #f03f3f;
      }
    }

    .table-content {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 160%;
      color: #d6d6d6;
      max-height: calc(100vh - 270px);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: #888888;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-color: #ffcc00;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
      }

      .table-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 0px;
        border-bottom: 1px solid #4f4f4f;
      }
    }
  }

  .control-group {
    display: none;
  }

  .controls-mobile {
    display: none;
  }

  .controls-blank {
    display: none;
    min-width: 110px;
  }

  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      height: unset;
      background: #1c1a1d;
      padding: 15px 20px;

      .title-layout {
        justify-content: space-between;
      }

      .search {
        margin-top: 15px;
        width: 100%;
      }
    }

    .content-layout {
      position: relative;
    }

    .content {
      border: 1px solid #4f4f4f;
      border-radius: 15px;
      margin-left: 10px;
      margin-right: 10px;
      padding: 20px 10px;
      overflow: auto;
      flex: 0 1 auto;

      .controls {
        display: none;
      }

      .controls-blank {
        display: unset;
      }

      .table-header {
        padding-bottom: 0px;
        border-bottom: none;

        & > * {
          padding-bottom: 10px;
          border-bottom: 1px solid #4f4f4f;
        }

        .controls-mobile {
          display: unset;
          // position: absolute;
          min-width: 100px;
          right: 11px;
        }
      }

      .table-content {
        max-height: unset;
        overflow: visible;

        .table-row {
          padding-bottom: 0px;
          border-bottom: none;

          & > * {
            padding-bottom: 10px;
            border-bottom: 1px solid #4f4f4f;
          }

          .controls-mobile {
            display: unset;
            // position: absolute;
            right: 11px;
            width: 100px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-bottom: 4px;

            .edit {
              font-size: 24px;
              color: #2f80ed;
              margin-right: 16px;
            }

            .activate {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              line-height: 160%;
              background: #1e382a;
              color: #27ae60;
            }

            .activate.disable {
              background: #462224;
              color: #f03f3f;
            }
          }
        }
      }
    }

    .control-group {
      width: 100px;
      height: 100%;
      display: unset;
      // background: #000000;
      position: absolute;
      right: 0px;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      margin-right: 10px;
      border: 1px solid #4f4f4f;
      border-left: none;
      display: none;
    }
  }
`;

export default ManageUser;
