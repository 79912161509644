import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import React, { createContext, useContext, useEffect } from "react";
import { useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsSave } from "react-icons/bs";
import { MdAddCircle, MdClose, MdPerson } from "react-icons/md";
import ReactImageUploading from "react-images-uploading";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
// import {
//   addAttachment,
//   createGymId,
//   getGymInfo,
//   getMainGymByOwnerID,
//   removeAttachment,
//   updateGymInfo,
//   updateMembershipcardFile,
//   updateMembershipcardFileByAdmin,
//   updateTradeLicenseFile,
//   updateTradeLicenseFileByAdmin,
// } from "../../../../../common/repository/js/gym_main";
import {
  addAttachmentController,
  createGymIdController,
  getGymInfoController,
  getMainGymByOwnerIDController,
  getAllGymByOwnerIDController,
  removeAttachmentController,
  updateGymInfoController,
  updateMembershipcardFileByAdminController,
  updateTradeLicenseFileByAdminController,
  saveGymInfoNewController,
} from "../../../../../../../controllers/web/gymMainController";
import { getUser } from "../../../../../../../repository/web/js/user";
// import {
//   getMembershipDataList,
//   getMembershipNames,
// } from "../../../../../repository/web/js/membership";
import { getMembershipDataListController } from "../../../../../../../controllers/web/memberShipController";
import AccessManager from "../../../../custom/AccessManager/AccessManager";
import ConfirmDialog from "../../../../custom/ConfirmDialog/ConfirmDialog";
import Divider from "../../../../custom/Divider/Divider";
import FileUploader from "../../../../custom/FileUploader/FileUploader";
import InputBox from "../../../../custom/InputBox/InputBox";
import Padding from "../../../../custom/Padding/Padding";
import PageHeader from "../../../../custom/PageHeader/PageHeader";
import PageSection, {
  PageSectionBody,
} from "../../../../custom/PageSection/PageSection";
import Panel from "../../../../custom/Panel/Panel";
import Section from "../../../../custom/Section/Section";
import Toster from "../../../../custom/Toaster/Toster";
import CustomLoading from "../../../../items/loadingBar";
import CustomSelect from "../../item/CustomSelect";
import gym_main from "../../../../../../../common/models/gym_main";
import { useNavigate } from "react-router";
import "./adminGymDetailView.scss";

const PageContext = createContext();

export default function GymDetailAdminView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user: userProfile } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [membershipList, setmembershipList] = useState([]);
  const [selectedMembershipPlan, setselectedMembershipPlan] = useState([]);
  const [tradeLicenseImage, setTradeLicenseImage] = useState([]);
  const [membershipCardImage, setmembershipCardImage] = useState([]);
  const [tradeLicenseImageError, setTradeLicenseImageError] = useState(false);
  const [membershipCardImageError, setmembershipCardImageError] =
    useState(false);
  const [atachmentsNewCompany, setAttachmentsNewCompany] = useState([]);
  const [gymOwnerDetails, setGymOwnerDetails] = useState();
  const [newACompanyAttachmentFiles, setNewCompanyAttachmentFiles] = useState(
    []
  );
  const [companyInfo, setcompanyInfo] = useState(
    location?.state?.content || null
  );
  const [gymIdNew, setGymIdNew] = useState(null);
  const [selectedOwnerId, setSelectedOwnerId] = useState(null);
  const [selectedOwnerSureName, setSelectedOwnerSureName] = useState(null);
  const [selecttedOwnerData, setSelectedOwner] = useState({});

  const defaultData = {
    name: "",
    nameError: false,
    tradeLicenseNumber: "",
    licenseError: false,
    tradeLicenseExpiryDate: "",
    expiryDateError: false,
    iban: "",
    ibanError: false,
    bankname: "",
    banknameError: false,
    memberShipPlan: "",
    membershipError: false,
    attendanceCost: "0",
    attendanceError: false,
    membershipCardPath: "",
    tradeLicenseFilePath: "",
  };
  const [formData, setFormData] = useState(defaultData);
  useEffect(() => {
    setselectedMembershipPlan(companyInfo?.memberShipPlan);
    if (companyInfo) {
      setFormData({
        ...formData,
        name: companyInfo?.name,
        tradeLicenseNumber: companyInfo?.tradeLicenseNumber,
        tradeLicenseExpiryDate: companyInfo?.tradeLicenseExpiryDate,
        iban: companyInfo?.iban,
        bankname: companyInfo?.bankname,
        memberShipPlan: companyInfo?.memberShipPlan,
        attendanceCost: companyInfo?.memberShipPlan?.attendancePrice || "0",
      });
    }
    loadMembershipPlans();
    loadownerDetails();
  }, [companyInfo]);

  const loadMembershipPlans = async () => {
    const membershipData = await getMembershipDataListController();
    if (membershipData.success) {
      setmembershipList(membershipData.data);
    }
  };
  const loadownerDetails = async () => {
    const ownerDetails = await getAllGymByOwnerIDController(companyInfo?.qrCode);
    if (ownerDetails) {
      setGymOwnerDetails(ownerDetails);
    }
  };

  const handleMembershipPlanChange = (plan) => {
    setselectedMembershipPlan(plan);
    delete plan["value"];
    setFormData({
      ...formData,
      memberShipPlan: plan,
      attendanceCost: plan.attendancePrice,
    });
  };

  const resetStates = () => {
    setFormData(defaultData);
    setselectedMembershipPlan([]);
    setTradeLicenseImage([]);
    setmembershipCardImage([]);
    setTradeLicenseImageError(false);
    setmembershipCardImageError(false);
    setAttachmentsNewCompany([]);
    setNewCompanyAttachmentFiles([]);
  };

  const resetErrors = () => {
    setFormData({
      ...formData,
      nameError: formData.name.trim().length === 0,
      licenseError: formData.tradeLicenseNumber.trim().length === 0,
      expiryDateError: formData.tradeLicenseExpiryDate.trim().length != 10,
      ibanError: formData.iban.trim().length === 0,
      banknameError: formData.bankname.trim().length === 0,
      membershipError: formData.memberShipPlan === "",
      membershipError: formData.memberShipPlan === undefined,
      attendanceError: formData.attendanceCost == 0,
      attendanceError: isNaN(formData.attendanceCost),
    });
    setTradeLicenseImageError(tradeLicenseImage.length == 0);
    setmembershipCardImageError(membershipCardImage.length == 0);
  };

  const uploadMembershipCard = async (companyId) => {
    const imageFile = membershipCardImage[0].file;
    const storage = getStorage();
    const userRef = ref(storage, `/membershipcards/${companyId}`);
    const uploadTask = uploadBytesResumable(userRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // setLoading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {},
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref)
          .then(async (downloadURL) => {
            // Toster.show("Membership card updated.");

            await updateMembershipcardFileByAdminController(
              companyId,
              downloadURL
            );
            setFormData({ ...formData, membershipCardPath: downloadURL });
          })
          .then(() => {
            // setLoading(false);
          });
        setmembershipCardImage([]);
      }
    );
  };

  const uploadTradeLicense = async (gymId) => {
    const imageFile = tradeLicenseImage[0].file;
    const storage = getStorage();
    const userRef = ref(storage, `/companytradelicense/${gymId}`);
    const uploadTask = uploadBytesResumable(userRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // setLoading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {},
      () => {
        // Toster.show("Trade license updated.");
        setFormData({
          ...formData,
          tradeLicenseFilePath: tradeLicenseImage[0],
        });
        getDownloadURL(uploadTask.snapshot.ref)
          .then(async (downloadURL) => {
            await updateTradeLicenseFileByAdminController(gymId, downloadURL);
            setFormData({ ...formData, tradeLicenseFilePath: downloadURL });
          })
          .then(() => {
            // setLoading(false);
          });
        setTradeLicenseImage([]);
      }
    );
  };

  const uploadAttachments = async (data, gymId) => {
    const imageFile = data.images[0];
    const storage = getStorage();
    const userRef = ref(storage, `/attachments/${gymId}/${imageFile.name}`);
    const uploadTask = uploadBytesResumable(userRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setLoading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload attachment is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload attachment is paused");
            break;
          case "running":
            console.log("Upload attachment is running");
            break;
        }
      },
      (error) => {},
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then(
          async (downloadURL) => {
            let result = await addAttachmentController(gymId, {
              title: data.title,
              url: downloadURL,
              filename: "attachments/" + gymId + "/" + imageFile.name,
            });
            // if (result.success) {
            //   // Toster.show("Attachment added.");
            //   // setLoading(false);
            // } else {
            //   //remove the added document
            //   // setLoading(false);
            // }
          }
        );
      }
    );
  };

  const uploadAttachmentFiles = async (gymId) => {
    for (var i = 0; i < newACompanyAttachmentFiles.length; i++) {
      await uploadAttachments(newACompanyAttachmentFiles[i], gymId);
    }
  };
  const saveData = async () => {
    resetErrors();
    let gymId = companyInfo?.id || null;

    const gymMainProps = Object.getOwnPropertyNames(new gym_main());
    const filteredProperties = {};

    for (const prop in formData) {
      if (gymMainProps.includes(prop)) {
        filteredProperties[prop] = formData[prop];
      }
    }
    

    setLoading(true);
    setIsSaving(true);
    if (
      formData.name.trim().length === 0 ||
      formData.tradeLicenseNumber.trim().length === 0 ||
      formData.tradeLicenseExpiryDate.trim().length != 10 ||
      formData.iban.trim().length === 0 ||
      formData.bankname.trim().length === 0 ||
      formData.memberShipPlan === undefined ||
      formData.memberShipPlan === "" ||
      formData.attendanceCost == 0 ||
      tradeLicenseImage.length == 0 ||
      membershipCardImage.length == 0 ||
      isNaN(formData.attendanceCost)
    ) {
      setLoading(false);
      setIsSaving(false);
      resetErrors();
      // return;
    }
    if (!gymIdNew) {
      const newGymId = await createGymIdController();
      gymId = newGymId.id;
      setGymIdNew(newGymId.id);
    }
    const result = await saveGymInfoNewController(gymId, filteredProperties);
    // console.log("Results",result)
    if (result && result.success === "success") {
      await Promise.all([
        await uploadAttachmentFiles(gymId),
        await uploadTradeLicense(gymId),
        await uploadMembershipCard(gymId),
      ]).then(() => {
        resetStates();
        Toster.show("Company information saved.");
      });
    } else {
      Toster.show("Failed to save information.", true);
      setLoading(false);
      setIsSaving(false);
      resetStates();
    }
    setTimeout(() => {
      setIsSaving(false);
      setLoading(false);
      navigate(-1);
    }, 7000);
  };
  const updateData = async () => {
    resetErrors();
    if (
      formData.name.trim().length === 0 ||
      formData.tradeLicenseNumber.trim().length === 0 ||
      formData.tradeLicenseExpiryDate.trim().length != 10 ||
      formData.iban.trim().length === 0 ||
      formData.bankname.trim().length === 0 ||
      formData.memberShipPlan === undefined ||
      formData.memberShipPlan === "" ||
      formData.attendanceCost == 0 ||
      isNaN(formData.attendanceCost)
    ) {
      resetErrors();
      return;
    }
    setLoading(true);
    setIsSaving(true);
    const result = await updateGymInfoController(companyInfo?.qrCode, formData);
    if (result && result.success === "success") {
      Toster.show("Company information updated.");
      setLoading(false);
      setIsSaving(false);
    } else {
      Toster.show("Failed to update information.", true);
      setLoading(false);
      setIsSaving(false);
    }
  };

  const CompanyInformationSection = (
    <div className="company-information-section-container">
      <Divider marginTop="10px">Company Information</Divider>
      <div className="gym-form-container">
        <Padding>
          <InputBox
            error={formData.nameError}
            label="Name"
            required
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <div className="form-group mb-2">
            <div className="form-row">
              <InputBox
                error={formData.licenseError}
                label="Trade License No"
                required
                value={formData.tradeLicenseNumber}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    tradeLicenseNumber: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-row">
              <InputBox
                error={formData.expiryDateError}
                label="License Expiry Date"
                required
                type="date"
                value={formData.tradeLicenseExpiryDate}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    tradeLicenseExpiryDate: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-row">
              <InputBox
                error={formData.ibanError}
                label="IBAN"
                required
                value={formData.iban}
                onChange={(e) =>
                  setFormData({ ...formData, iban: e.target.value })
                }
              />
            </div>
            <div className="form-row">
              <InputBox
                error={formData.banknameError}
                label="Bank Name"
                required
                value={formData.bankname}
                onChange={(e) =>
                  setFormData({ ...formData, bankname: e.target.value })
                }
              />
            </div>
          </div>
        </Padding>
      </div>
      <Divider marginTop="10px">Membership Information</Divider>
      <div className="gym-form-container">
        <Padding>
          <div className="form-group mb-2">
            <div className="form-row">
              <span className="form-label">Membership Plan *</span>
              <CustomSelect
                className={formData.membershipError ? "select-error" : ""}
                options={membershipList}
                value={selectedMembershipPlan}
                onChange={handleMembershipPlanChange}
              />
            </div>
            <div className="form-row">
              <InputBox
                error={formData.attendanceError}
                label="Attendance Cost"
                className={`disabled`}
                value={formData.attendanceCost}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-row"></div>
            <div className="form-row">
              <span style={{ marginTop: "-5px", paddingLeft: "8px" }}>
                <input type="checkbox" />
                <span
                  className="form-label"
                  style={{ display: "inline-flex", paddingLeft: "8px" }}
                >
                  Modify the attendance cost.
                </span>
              </span>
            </div>
          </div>
          <div className="w-100 d-flex mt-2 mb-2">
            <Button
              variant="warning"
              className="w-100"
              onClick={location?.state?.content ? updateData : saveData}
            >
              {location?.state?.content ? "UPDATE" : "SAVE"}
            </Button>
          </div>
        </Padding>
      </div>
    </div>
  );

  const CompanyAdmininformationSection = (
    <div className="company-information-section-container">
      <Divider marginTop="10px">Company Owner Information</Divider>
      <div className="gym-form-container">
        <Padding>
          <InputBox
            label="Name"
            required
            value={selecttedOwnerData?.sure_name}
            disabled={true}
          />
          <div className="form-group mb-2">
            <div className="form-row">
              <InputBox
                label="E-mail"
                required
                value={selecttedOwnerData?.username}
                
                disabled={true}
              />
            </div>
            <div className="form-row">
              <InputBox
                label="Phone"
                required
                type="Phone"
                value={selecttedOwnerData?.phone}
                disabled={true}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-row mt-2">
              <label className="custom-select-label">Company Owner *</label>
              <CustomSelect
                options={gymOwnerDetails?.data?.map((user) => ({
                  value: user.id,
                  label: user.sure_name,
                  user: user
                }))}
                placeholder={selecttedOwnerData.sure_name}
                onChange={(selectedOption) => {
                  // Extract the necessary data from the selected option
                  const { sure_name, phone, username } = selectedOption.user;

                  // Update the state with the extracted data
                  setSelectedOwner({
                    sure_name: sure_name,
                    phone: phone,
                    username: username
                  });
                }}

                isSearchable={true}
              />
            </div>
            
          </div>
        </Padding>
      </div>
    </div>
  );

  // const CompanyAdmininformationSection = (
  //   <div className="company-information-section-container">
  //     <Divider marginTop="10px">Company Owner Information</Divider>
  //     <div className="gym-form-container">
  //       <Padding>
  //         <InputBox
  //           label="Name"
  //           required
  //           value={gymOwnerDetails?.sure_name}
  //           // onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            
  //         />
  //         <div className="form-group mb-2">
  //           <div className="form-row">
  //             <InputBox
  //               label="E-mail"
  //               required
  //               value={gymOwnerDetails?.username}
  //               onChange={(e) =>
  //                 setFormData({
  //                   ...formData,
  //                   tradeLicenseNumber: e.target.value,
  //                 })
  //               }
  //             />
  //           </div>
  //           <div className="form-row">
  //             <InputBox
  //               label="Phone"
  //               required
  //               type="Phone"
  //               value={gymOwnerDetails?.phone}
  //               onChange={(e) =>
  //                 setFormData({
  //                   ...formData,
  //                   tradeLicenseExpiryDate: e.target.value,
  //                 })
  //               }
  //             />
  //           </div>
  //         </div>
  //         <div className="form-group">
  //           <div className="form-row mt-2">
  //             <InputBox
  //               error={formData.ibanError}
  //               label="Company"
  //               required
  //               className="t-2"
  //               value={gymOwnerDetails?.company_id}
  //               onChange={(e) =>
  //                 setFormData({ ...formData, iban: e.target.value })
  //               }
  //             />
  //           </div>
  //           <div className="form-row">
  //             <label className="custom-select-label">Branches *</label>
  //             <CustomSelect
  //               // options={roleOptions}
  //               isSearchable={true}
  //               value={gymOwnerDetails?.branches}
  //               // onChange={(e) => {
  //               //   let temp = [];
  //               //   e.reduce((pv, cv) => {
  //               //     pv = cv;
  //               //     temp.push(Number.parseInt(pv.value));
  //               //     return pv;
  //               //   }, []);
  //               //   setuserProfileToUpdate({
  //               //     ...userProfileToUpdate,
  //               //     role: temp,
  //               //   });
  //               //   setselectedRole(e);
  //               // }}
  //               isMulti
  //             />
  //           </div>
  //         </div>
  //       </Padding>
  //     </div>
  //   </div>
  // );
  return (
    <AccessManager allowedRoles={[4]}>
      <PageSection>
        <CustomLoading isStart={loading} isFull={true} />
        <PageHeader>
          <div className="page-header-title">
            {location?.state?.addNewComapny ||
              companyInfo?.name ||
              "COMPANY DETAILS"}
          </div>
        </PageHeader>
        <PageSectionBody className="company-info-body">
          <Row className="w-100 gx-0 gy-0">
            <Col lg={7}>
              <Panel>{CompanyInformationSection}</Panel>
            </Col>
            <Col lg={5}>
              <Panel>
                <div className="d-flex flex-column w-100 justify-content-center align-items-center">
                  <Divider marginTop="10px">Company Trade License</Divider>
                  <TradeLicenceSection
                    tradeLicenseImageError={tradeLicenseImageError}
                    companyData={companyInfo}
                    onLicensePicked={(image) => {
                      setTradeLicenseImage(image);
                    }}
                  />
                </div>
              </Panel>
            </Col>
            <Col lg={7}>
              <Panel>{CompanyAdmininformationSection}</Panel>
            </Col>
            <Col lg={5}>
              <Panel>
                <div className="d-flex flex-column w-100 justify-content-center align-items-center">
                  <Divider marginTop="10px">Membership Card</Divider>
                  <MembershipcardSection
                    companyData={companyInfo}
                    membershipCardImageError={membershipCardImageError}
                    onMembershipCardPicked={(image) => {
                      setmembershipCardImage(image);
                    }}
                  />
                </div>
              </Panel>
            </Col>
            <Col lg={12}>
              <Panel>
                <div className="d-flex flex-column w-100">
                  <Divider marginTop="10px">Attached Documents</Divider>
                  <AttachmentsSection
                    companyData={companyInfo}
                    newCompanyAttachments={atachmentsNewCompany}
                    onAddFile={(image) => {
                      setNewCompanyAttachmentFiles((prev) => [...prev, image]);
                    }}
                    onAttachmentStateChange={(image) =>
                      setAttachmentsNewCompany(image)
                    }
                  />
                </div>
              </Panel>
            </Col>
          </Row>
        </PageSectionBody>
      </PageSection>
    </AccessManager>
  );
}

const AddAttachmentModal = (props) => {
  const [formData, setFormData] = useState({
    title: "",
    titleError: false,
    images: [],
  });

  const onChange = (imageList) => {
    setFormData({ ...formData, images: imageList });
  };

  const handleAddFile = () => {
    setFormData({
      ...formData,
      titleError: formData.title.trim().length === 0,
    });
    if (formData.images.length <= 0 || formData.title.trim().length === 0) {
      formData.images.length <= 0 && alert("Please select a file to add.");
      return;
    }
    props.okCallback(formData);
  };
  return (
    <div className="add-attachment-container">
      <InputBox
        label="Title"
        error={formData.titleError}
        value={formData.title}
        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
      />
      <FileUploader onChange={onChange} acceptTypes={["png", "jpg", "pdf"]}>
        {({ onSelect, onRemove, error }) => {
          return (
            <div className="attachment-file-uploader">
              {error && <div className="error-msg">{error}</div>}
              {!error && (
                <div className="file-name">
                  {formData.images.length > 0
                    ? formData.images[0].name
                    : "No file selected"}
                </div>
              )}
              {formData.images.length === 0 && (
                <Button size="sm" variant="success" onClick={onSelect}>
                  Select File
                </Button>
              )}
              {formData.images.length > 0 && (
                <div className="d-inline-flex w-100">
                  <Button size="sm" variant="danger" onClick={onRemove}>
                    <MdClose /> Change
                  </Button>
                </div>
              )}
            </div>
          );
        }}
      </FileUploader>
      <div
        className="d-flex justify-content-center w-100 mt-3 mb-3 border-top pt-3"
        style={{ gap: "10px" }}
      >
        <Button
          className="w-100"
          variant="secondary"
          onClick={(e) => ConfirmDialog.hide()}
        >
          Cancel
        </Button>
        <Button className="w-100" variant="warning" onClick={handleAddFile}>
          Add File
        </Button>
      </div>
    </div>
  );
};

export const AttachmentsSection = ({
  companyData,
  onAttachmentStateChange,
  newCompanyAttachments,
  onAddFile,
}) => {
  const { user: userProfile } = useSelector((state) => state.user);
  const [attachmentData, setattachmentData] = useState([]);
  const [loading, setloading] = useState(null);

  useEffect(() => {
    loadAttachments();
  }, []);

  const uploadFile = async (data) => {
    const imageFile = data.images[0];
    const storage = getStorage();
    const userRef = ref(
      storage,
      `/attachments/${companyData.id}/${imageFile.name}`
    );
    const uploadTask = uploadBytesResumable(userRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setloading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {},
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then(
          async (downloadURL) => {
            let result = await addAttachmentController(
              userProfile.company_id,
              {
                title: data.title,
                url: downloadURL,
                filename:
                  "attachments/" +
                  userProfile.company_id +
                  "/" +
                  imageFile.name,
              }
            );
            if (result.success) {
              Toster.show("Attachment added.");
              setloading(false);
            } else {
              //remove the added document
              setloading(false);
            }
          }
        );
      }
    );
  };

  const AddNewFile = (data) => {
    if (attachmentData.length < 5 && newCompanyAttachments.length < 5) {
      let temp = companyData ? [...attachmentData] : [...newCompanyAttachments];
      temp.push({ title: data.title, link: "", actionLink: "" });
      companyData ? setattachmentData(temp) : onAttachmentStateChange(temp);
      companyData ? uploadFile(data) : onAddFile(data);
      ConfirmDialog.hide();
    } else {
      ConfirmDialog.show({
        message: "You have reached the max attachment limit.",
        showFooter: false,
      });
    }
  };

  const handleRemoveItem = (index, url) => {
    ConfirmDialog.show({
      message: "Sure to remove the attachment?",
      okCallback: async () => {
        const storage = getStorage();
        const desertRef = ref(storage, url);
        if (companyData) {
          return deleteObject(desertRef)
            .then(async () => {
              return await removeAttachmentController(companyData.id, {
                filename: url,
                title: attachmentData[index].title,
                url: attachmentData[index].link,
              }).then((result) => {
                if (result.success) {
                  Toster.show("Attachment removed.");
                  let temp = [...attachmentData];
                  temp.splice(index, 1);
                  setattachmentData(temp);
                  return true;
                } else {
                  throw new Error("file not found");
                }
              });
            })
            .catch((error) => {
              ConfirmDialog.show({
                message: "An error accoured to remove this file.",
                showFooter: false,
              });
            });
        } else {
          let temp = [...newCompanyAttachments];
          temp.splice(index, 1);
          onAttachmentStateChange(temp);
          Toster.show("Attachment removed.");
          ConfirmDialog.hide();
        }
      },
    });
  };

  const loadAttachments = () => {
    try {
      if (companyData) {
        let temp = [];
        companyData.attachments?.forEach((e, index) => {
          temp.push({ title: e.title, link: e.url, filename: e.filename });
        });
        setattachmentData(temp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Section className="p-0">
      {loading && (
        <div className="loadSpinner black">
          <Spinner size="sm" animation="border" variant="warning" />
        </div>
      )}
      <div className="attachment-section-header">
        <h5>
          Other Attachments{" "}
          <span style={{ fontSize: "0.65em" }} className="text-warning">
            (Max 05 files)
          </span>
        </h5>
        <Button
          onClick={(e) => {
            if (!loading) {
              ConfirmDialog.show({
                message: <AddAttachmentModal okCallback={AddNewFile} />,
                title: "Add Attachment",
                okText: "Add File",
                cancelText: "Cancel",
                showFooter: false,
              });
            }
          }}
        >
          <MdAddCircle /> Add Attachment
        </Button>
      </div>
      {!loading && (
        <div className="gym-attachment-table">
          <div className="table-headings">
            <span>SN</span>
            <span>Document Title</span>
            <span>Link</span>
            <span>Action</span>
          </div>
          <div className="table-body">
            {attachmentData && attachmentData.length > 0
              ? attachmentData?.map((element, index) => {
                  return (
                    <div className="table-row" key={index + 1}>
                      <span>{index + 1}</span>
                      <span>{element.title}</span>
                      <span>
                        {/* <a
                        onClick={(e) => {
                          console.log(element.link);
                          var a = document.createElement("a");
                          a.href = element.link;
                          a.target = "_blank";
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                        }}
                      >
                        Download
                      </a> */}
                        <a href={`${element.link}`} target="_blank">
                          Download
                        </a>
                      </span>
                      <span>
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={(e) =>
                            handleRemoveItem(index, element.filename)
                          }
                        >
                          <MdClose />
                        </Button>
                      </span>
                    </div>
                  );
                })
              : newCompanyAttachments?.map((element, index) => (
                  <div className="table-row" key={index + 1}>
                    <span>{index + 1}</span>
                    <span>{element.title}</span>
                    {/* <span> */}
                    {/* <a
                        onClick={(e) => {
                          console.log(element.link);
                          var a = document.createElement("a");
                          a.href = element.link;
                          a.target = "_blank";
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                        }}
                      >
                        Download
                      </a> */}
                    {/* <a href={`${element.link}`} target="_blank">
                        Download
                      </a>
                    </span> */}
                    <span>
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={(e) =>
                          handleRemoveItem(index, element.filename)
                        }
                      >
                        <MdClose />
                      </Button>
                    </span>
                  </div>
                ))}
          </div>
        </div>
      )}
    </Section>
  );
};

export const TradeLicenceSection = ({
  companyData,
  onLicensePicked,
  tradeLicenseImageError,
}) => {
  const { user: userProfile } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [companyProfile, setcompanyProfile] = useState(companyData);
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    name: companyProfile?.name || null,
    tradeLicenseFilePath: companyProfile?.tradeLicenseFilePath || "",
  });

  useEffect(() => {
    setLoading(true);
    loadProfileInfo();
  }, []);

  const loadProfileInfo = async () => {
    if (!companyData?.qrCode) {
      setLoading(false);
      setFormData({
        ...formData,
        name: null,
        tradeLicenseFilePath: "",
      });
      return;
    }
    await getGymInfoController(companyData?.qrCode).then((res) => {
      if (res) {
        setcompanyProfile(res);
        setFormData({
          ...formData,
          name: res.name || null,
          tradeLicenseFilePath: res.tradeLicenseFilePath || "",
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const onChange = (imageList, addUpdateIndex) => {
    onLicensePicked(imageList);
    setImages(imageList);
  };

  const handleImageUpdate = async () => {
    const imageFile = images[0].file;
    const storage = getStorage();
    let gymId = companyProfile?.id || null;
    // if (!gymId) {
    //   const newGymId = await createGymIdController();
    //   gymId = newGymId.id;
    // }
    const userRef = ref(storage, `/companytradelicense/${gymId}`);
    const uploadTask = uploadBytesResumable(userRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setLoading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {},
      () => {
        Toster.show("Trade license updated.");
        setLoading(false);
        setFormData({ ...formData, tradeLicenseFilePath: images[0] });
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          updateTradeLicenseFileByAdminController(gymId, downloadURL);
          setFormData({ ...formData, tradeLicenseFilePath: downloadURL });
        });
        setImages([]);
      }
    );
  };
  return (
    <div className="tradelicense-container">
      {loading && (
        <div className="loadSpinner">
          <Spinner animation="border" variant="warning" />
        </div>
      )}
      <img
        style={{
          cursor: images[0]?.data_url ? "not-allowed" : "pointer",
          border:
            tradeLicenseImageError && !companyData ? "1px solid red" : "0px",
        }}
        alt=""
        src={images[0]?.data_url || formData.tradeLicenseFilePath || null}
        onClick={(e) => {
          if (!images[0]?.data_url) {
            window.open(
              formData.tradeLicenseFilePath
                ? formData.tradeLicenseFilePath
                : "",
              "blank"
            );
          }
        }}
      />
      {
        <ReactImageUploading
          value={images}
          onChange={onChange}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <>
              {images?.length <= 0 ? (
                <>
                  <Button
                    variant="warning"
                    className="mb-4"
                    onClick={onImageUpload}
                  >
                    Select Trade License
                  </Button>
                </>
              ) : (
                <>
                  <div className="d-inline-flex ">
                    {companyData?.qrCode ? (
                      <Button
                        variant="warning"
                        className="mb-4"
                        onClick={handleImageUpdate}
                      >
                        <BsSave /> {" Update"}
                      </Button>
                    ) : null}
                    <Button
                      variant="danger"
                      className="delete-btn mb-4"
                      onClick={() => onImageRemove(0)}
                    >
                      <MdClose />
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </ReactImageUploading>
      }
    </div>
  );
};

const MembershipcardSection = ({
  companyData,
  onMembershipCardPicked,
  membershipCardImageError,
}) => {
  const [loading, setLoading] = useState(false);
  const [companyProfile, setcompanyProfile] = useState(companyData);
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    name: companyData?.name || null,
    membershipCardPath: companyData?.membershipCardPath || "",
  });

  useEffect(() => {
    setLoading(true);
    loadProfileInfo();
  }, []);

  const loadProfileInfo = async () => {
    await getGymInfoController(companyData?.qrCode).then((res) => {
      if (res) {
        setcompanyProfile(res);
        setFormData({
          ...formData,
          name: res.name || null,
          membershipCardPath: res.membershipCardPath || "",
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const onChange = (imageList, addUpdateIndex) => {
    onMembershipCardPicked(imageList);
    setImages(imageList);
  };

  const handleImageUpdate = async () => {
    const imageFile = images[0].file;
    const storage = getStorage();
    let companyId = companyProfile?.id || null;
    // if (!companyId) {
    //   const newGymId = await createGymIdController();
    //   companyId = newGymId.id;
    // }
    const userRef = ref(storage, `/membershipcards/${companyId}`);
    const uploadTask = uploadBytesResumable(userRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setLoading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {},
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          Toster.show("Membership card updated.");
          setLoading(false);
          updateMembershipcardFileByAdminController(companyId, downloadURL);
          setFormData({ ...formData, membershipCardPath: downloadURL });
        });
        setImages([]);
      }
    );
  };

  return (
    <div
      className="d-flex w-100 justify-content-center position-relative"
      style={{ maxHeight: "450px" }}
    >
      <div className="membershipcard-container">
        {loading && (
          <div className="loadSpinner">
            <Spinner animation="border" variant="warning" />
          </div>
        )}
        {formData.membershipCardPath && formData.membershipCardPath !== "" && (
          <div className="user-image-thumb">
            <MdPerson />
          </div>
        )}
        <img
          style={{
            cursor: images[0]?.data_url ? "not-allowed" : "pointer",
            border:
              membershipCardImageError && !companyData
                ? "1px solid red"
                : "0px",
          }}
          alt=""
          src={images[0]?.data_url || formData.membershipCardPath || null}
          onClick={(e) => {
            if (!images[0]?.data_url) {
              window.open(
                formData.membershipCardPath ? formData.membershipCardPath : "",
                "blank"
              );
            }
          }}
        />
        {companyProfile && (
          <ReactImageUploading
            value={images}
            onChange={onChange}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <>
                {images?.length <= 0 ? (
                  <>
                    <Button
                      variant="warning"
                      className="mb-4"
                      onClick={onImageUpload}
                    >
                      Select Membership Card
                    </Button>
                  </>
                ) : (
                  <>
                    <div className="d-inline-flex ">
                      {companyData?.qrCode ? (
                        <Button
                          variant="warning"
                          className="mb-4"
                          onClick={handleImageUpdate}
                        >
                          <BsSave /> {" Update"}
                        </Button>
                      ) : null}
                      <Button
                        variant="danger"
                        className={`delete-btn mb-4 ${
                          companyData?.qrCode ? "" : "mx-auto"
                        }`}
                        onClick={() => onImageRemove(0)}
                      >
                        <MdClose />
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </ReactImageUploading>
        )}
      </div>
    </div>
  );
};
