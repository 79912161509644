import {
  getGyms,
  addAttachment,
  changeGymActivation,
  createGymId,
  getActiveCompanyList,
  getActiveMembershipGymsList,
  getAllGyms,
  getGymInfo,
  getGymInfo02,
  getGymsList,
  getGymsOption,
  getMainGymByOwnerID,
  getAllMainGymByOwnerID,
  getMainGymIDByUser,
  removeAttachment,
  saveGymInfo,
  saveGymInfoNew,
  updateCompany,
  updateGymInfo,
  updateGymInfoNew,
  updateMemberShipCard,
  updateMembershipcardFile,
  updateMembershipcardFileByAdmin,
  updateTradeLicenseFile,
  updateTradeLicenseFileByAdmin,
} from "../../repository/web/js/gym_main";

export const getGymsController = async () => {
  const gyms = await getGyms();
  return gyms;
};

export const getAllGymsController = async () => {
  const allGyms = await getAllGyms();
  return allGyms;
};

export const getGymsListController = async () => {
  const gymsList = await getGymsList();
  return gymsList;
};

export const getActiveMembershipGymsListController = async () => {
  const activeMembershipsGymsList = await getActiveMembershipGymsList();
  return activeMembershipsGymsList;
};

export const getActiveCompanyListController = async () => {
  const activeCompanyList = await getActiveCompanyList();
  return activeCompanyList;
};

export const getGymInfoController = async (id) => {
  const gymInfo = await getGymInfo(id);
  return gymInfo;
};

export const getGymInfo02Controller = async (id) => {
  const gymInfo02 = await getGymInfo02(id);
  return gymInfo02;
};

export const createGymIdController = async () => {
  const createGym = await createGymId();
  return createGym;
};

export const getMainGymByOwnerIDController = async (ownerID) => {
  const gymOwner = await getMainGymByOwnerID(ownerID);
  return gymOwner;
};

export const getAllGymByOwnerIDController = async (gymId) =>{
  const gymOwner = await getAllMainGymByOwnerID(gymId);
  return gymOwner;
}

export const getMainGymIDByUserController = async (owner) => {
  const gymId = await getMainGymIDByUser(owner);
  return gymId;
};

export const updateMemberShipCardController = async (id, path) => {
  const { error, success } = await updateMemberShipCard(id, path);
  if (!success) {
    return { success: "", error: "error" };
  }
  return { success: "success", error: "" };
};

export const updateCompanyController = async (id, data) => {
  await updateCompany(id, data);
};

export const updateGymInfoController = async (id, data) => {
  const { error, success } = await updateGymInfo(id, data);
  if (!success) {
    return { success: "", error: "error" };
  }
  return { success: "success", error: "" };
};

export const saveGymInfoController = async (id, name, number, path) => {
  const { error, success } = await saveGymInfo(id, name, number, path);
  if (!success) {
    return { success: "", error: error.message };
  }
  return { success: "success", error: "" };
};

export const saveGymInfoNewController = async (id, data) => {
  const { error, success } = await saveGymInfoNew(id, data);
  if (!success) {
    return { success: "", error: error.message };
  }
  return { success: "success", error: "" };
};

export const updateTradeLicenseFileByAdminController = async (
  id,
  tradeLicenseFilePath
) => {
  const { error, success } = await updateTradeLicenseFileByAdmin(
    id,
    tradeLicenseFilePath
  );
  if (!success) {
    return { success: false, error: "gym id is not valid." };
  }
  return { success: true, error: "" };
};

export const updateMembershipcardFileByAdminController = async (
  id,
  membershipCardPath
) => {
  const { error, success } = await updateMembershipcardFileByAdmin(
    id,
    membershipCardPath
  );
  if (!success) {
    return { success: false, error: "" };
  }
  return { success: true, error: "gym id is not valid." };
};

export const getGymsOptionController = async () => {
  const gymOptions = await getGymsOption();
  return gymOptions;
};

export const changeGymActivationController = async (id, state) => {
  const { error, success } = await changeGymActivation(id, state);
  if (!success) {
    return { success: "", error: error.message };
  }
  return { success: "success", error: "" };
};

export const addAttachmentController = async (id, data) => {
  const { error, success } = await addAttachment(id, data);
  if (!success) {
    return { success: "", error: error.message };
  }
  return { success: "success", error: "" };
};

export const updateTradeLicenseFileController = async (
  id,
  tradeLicenseFilePath,
  companyName
) => {
  try {
    const result = await updateTradeLicenseFile(
      id,
      tradeLicenseFilePath,
      companyName
    );
    return result;
  } catch (error) {
    console.error(error);
    return { error: error.message, success: false };
  }
};

export const removeAttachmentController = async (id, data) => {
  const { error, success } = await removeAttachment(id, data);
  if (!success) {
    return { success: "", error: error.message };
  }
  return { success: "success", error: "" };
};

export const updateGymInfoNewController = async (id, data) => {
  try {
    const result = await updateGymInfoNew(id, data);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateMembershipcardFileController = async (
  id,
  membershipCardPath,
  companyName
) => {
  const result = await updateMembershipcardFile(
    id,
    membershipCardPath,
    companyName
  );
  return result;
};
