import { useDispatch, useSelector } from "react-redux";
import { signUpWithEmailAndPassword } from "../../../../repository/web/js/firebaseAuth";
import { setCreateError } from "../../redux/slices/AddUser.slice";
const useManageUserController = () => {
  const dispatch = useDispatch();
  const {} = useSelector((state) => state.addUser);
  const SignupWithEmailAndPassword = async (user, password) => {
    const data = await signUpWithEmailAndPassword(user, password).catch(
      (err) => {
        dispatch(setCreateError(true));
      }
    );
    return data;
  };
  return {
    SignupWithEmailAndPassword,
  };
};

export default useManageUserController;
