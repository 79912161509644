import {
  createID,
  addClass,
  getAllClasses,
  getAllFreelanceClasses,
  getClassById,
  getClassesByBranch,
  getClassesByGym,
  getClassesByTrainer,
  getClassesListByBranch,
  removeClass,
  updateClass,
} from "../../repository/web/js/classes.service";

export const createIDController = async () => {
  const result = await createID();
  return result;
};

export const addClassController = async (data) => {
  const classId = await addClass(data);
  return classId;
};

export const updateClassController = async (id, content) => {
  const result = await updateClass(id, content);
  return result;
};

export const getClassesByGymController = async (id) => {
  try {
    const classes = await getClassesByGym(id);
    return classes;
  } catch (error) {
    return { success: null, error: error.message };
  }
};

export const getAllFreelanceClassesController = async () => {
  try {
    const classes = await getAllFreelanceClasses();
    return classes;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getClassesByTrainerController = async (trainerId) => {
  const classes = await getClassesByTrainer(trainerId);
  return classes;
};
