import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { BsSave } from "react-icons/bs";
import {
  MdAddBox,
  MdAddCircle,
  MdClose,
  MdDelete,
  MdPerson,
} from "react-icons/md";
import ReactImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
// import {
//   addAttachment,
//   createGymId,
//   getMainGymByOwnerID,
//   removeAttachment,
//   saveGymInfoNew,
//   updateGymInfoNew,
//   updateMembershipcardFile,
//   updateTradeLicenseFile,
// } from "../../../../../common/repository/js/gym_main";
import {
  addAttachmentController,
  createGymIdController,
  getMainGymByOwnerIDController,
  removeAttachmentController,
  updateGymInfoController,
  updateGymInfoNewController,
  updateMembershipcardFileController,
  updateTradeLicenseFileController,
} from "../../../../../../../controllers/web/gymMainController";
// import { getUser } from "../../../../../repository/web/js/user";
import { getUserController } from "../../../../../../../controllers/web/userController";
// import {actionUserLoggedin} from "../../../../../redux/states/user/user.action";
import { setUserLoggedIn } from "../../../../../redux/slices/Auth.slice";
import AccessManager from "../../../../custom/AccessManager/AccessManager";
import ConfirmDialog from "../../../../custom/ConfirmDialog/ConfirmDialog";
import Divider from "../../../../custom/Divider/Divider";
import FileUploader from "../../../../custom/FileUploader/FileUploader";
import InputBox from "../../../../custom/InputBox/InputBox";
import Padding from "../../../../custom/Padding/Padding";
import PageHeader from "../../../../custom/PageHeader/PageHeader";
import PageSection, {
  PageSectionBody,
} from "../../../../custom/PageSection/PageSection";
import Panel from "../../../../custom/Panel/Panel";
import Section from "../../../../custom/Section/Section";
import Toster from "../../../../custom/Toaster/Toster";
import "./gymowner_detailview.scss";

const PageContext = createContext();

export default function GymownerDetailView() {

  const { user: userProfile } = useSelector((state) => state.user);
  const [reloadAll, setreloadAll] = useState(false);
  const [loading, setloading] = useState(false);
  const [gymProfileData, setgymProfileData] = useState(null);
  useEffect(() => {
    setloading(true);
    loadGymProfileData();
  }, []);

  useEffect(() => {
    setloading(true);
    loadGymProfileData();
  }, [reloadAll]);

  const loadGymProfileData = async () => {
    await getMainGymByOwnerIDController(userProfile?.id).then((res) => {
      if (res) {
        setgymProfileData({ ...res, id: res.id });
        setloading(false);
      } else {
        setloading(false);
      }
    });
  };
  return (
    <AccessManager allowedRoles={[3]}>
      <PageContext.Provider value={[reloadAll, setreloadAll, gymProfileData]}>
        <PageSection className="autoHeight">
          <PageHeader>
            <div className="page-header-title">Gym Info</div>
          </PageHeader>
          <PageSectionBody>
            {loading && (
              <div className="w-100 h-100 d-flex justify-content-center align-items-center position-relative">
                <div className="loadSpinner">
                  <Spinner animation="border" variant="warning" />
                </div>
              </div>
            )}
            {!loading && (
              <div className="gymowner-gymdetail-container">
                <div className="content">
                  <Row className="gx-0 gy-0 p-0 m-0 d-flex w-100">
                    <Col lg={12}>
                      <Panel>
                        <Padding>
                          <GymInformationForm />
                        </Padding>
                      </Panel>
                    </Col>
                    <Col lg={12}>
                      <Panel>
                        <AttachmentsSection />
                      </Panel>
                    </Col>
                  </Row>
                </div>
                <div className="docs">
                  <Padding padding="15px 8px">
                    <div className="d-flex w-100 flex-column align-items-center position-relative">
                      <h4 className="text-light">Required Documents*</h4>
                      <br />
                      <h5 className="text-left">Trade License *</h5>
                      <TradeLicenceSection />
                      <h5 className="text-left mb-5">Membership Card</h5>
                      <MembershipcardSection />
                    </div>
                  </Padding>
                </div>
              </div>
            )}
          </PageSectionBody>
        </PageSection>
      </PageContext.Provider>
    </AccessManager>
  );
}

const AddAttachmentModal = (props) => {
  const [formData, setFormData] = useState({
    title: "",
    titleError: false,
    images: [],
  });

  const onChange = (imageList) => {
    setFormData({ ...formData, images: imageList });
  };

  const handleAddFile = () => {
    setFormData({
      ...formData,
      titleError: formData.title.trim().length === 0,
    });
    if (formData.images.length <= 0 || formData.title.trim().length === 0) {
      formData.images.length <= 0 && alert("Please select a file to add.");
      return;
    }
    props.okCallback(formData);
  };
  return (
    <div className="add-attachment-container">
      <InputBox
        label="Title"
        error={formData.titleError}
        value={formData.title}
        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
      />
      <FileUploader onChange={onChange} acceptTypes={["png", "jpg", "pdf"]}>
        {({ onSelect, onRemove, error }) => {
          return (
            <div className="attachment-file-uploader">
              {error && <div className="error-msg">{error}</div>}
              {!error && (
                <div className="file-name">
                  {formData.images.length > 0
                    ? formData.images[0].name
                    : "No file selected"}
                </div>
              )}
              {formData.images.length === 0 && (
                <Button size="sm" variant="success" onClick={onSelect}>
                  Select File
                </Button>
              )}
              {formData.images.length > 0 && (
                <div className="d-inline-flex w-100">
                  <Button size="sm" variant="danger" onClick={onRemove}>
                    <MdClose /> Change
                  </Button>
                </div>
              )}
            </div>
          );
        }}
      </FileUploader>
      <div
        className="d-flex justify-content-center w-100 mt-3 mb-3 border-top pt-3"
        style={{ gap: "10px" }}
      >
        <Button
          className="w-100"
          variant="secondary"
          onClick={(e) => ConfirmDialog.hide()}
        >
          Cancel
        </Button>
        <Button className="w-100" variant="warning" onClick={handleAddFile}>
          Add File
        </Button>
      </div>
    </div>
  );
};

const AttachmentsSection = () => {
  const { user: userProfile } = useSelector((state) => state.user);
  const [reloadAll, setreloadAll, gymProfileData] = useContext(PageContext);
  const [attachmentData, setattachmentData] = useState([]);
  const [loading, setloading] = useState(null);

  useEffect(() => {
    loadAttachments();
  }, []);

  const uploadFile = async (data) => {
    const imageFile = data.images[0];
    const storage = getStorage();
    const userRef = ref(
      storage,
      `/attachments/${gymProfileData.id}/${imageFile.name}`
    );
    const uploadTask = uploadBytesResumable(userRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setloading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => { },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then(
          async (downloadURL) => {
            let result = await addAttachmentController(
              userProfile.id,
              {
                title: data.title,
                url: downloadURL,
                filename:
                  "attachments/" +
                  userProfile.id +
                  "/" +
                  imageFile.name,
              }
            );
            if (result.success) {
              Toster.show("Attachment added.");
              setloading(false);
              setreloadAll(!reloadAll);
            } else {
              //remove the added document
              setloading(false);
            }
          }
        );
      }
    );
  };

  const AddNewFile = (data) => {
    if (attachmentData.length < 5) {
      let temp = [...attachmentData];
      temp.push({ title: data.title, link: "", actionLink: "" });
      setattachmentData(temp);
      uploadFile(data);
      ConfirmDialog.hide();
    } else {
      ConfirmDialog.show({
        message: "You have reached the max attachment limit.",
        showFooter: false,
      });
    }
  };

  const handleRemoveItem = (index, url) => {
    ConfirmDialog.show({
      message: "Sure to remove the attachment?",
      okCallback: async () => {
        const storage = getStorage();
        const desertRef = ref(storage, url);
        return deleteObject(desertRef)
          .then(async () => {
            return await removeAttachmentController(gymProfileData.id, {
              filename: url,
              title: attachmentData[index].title,
              url: attachmentData[index].link,
            }).then((result) => {
              if (result.success) {
                Toster.show("Attachment removed.");
                let temp = [...attachmentData];
                temp.splice(index, 1);
                setattachmentData(temp);
                setreloadAll(!reloadAll);
                return true;
              } else {
                throw new Error("file not found");
              }
            });
          })
          .catch((error) => {
            ConfirmDialog.show({
              message: "An error accoured to remove this file.",
              showFooter: false,
            });
          });
      },
    });
  };

  const loadAttachments = () => {
    try {
      if (gymProfileData) {
        let temp = [];
        gymProfileData.attachments?.forEach((e, index) => {
          temp.push({ title: e.title, link: e.url, filename: e.filename });
        });
        setattachmentData(temp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Section>
      {loading && (
        <div className="loadSpinner black">
          <Spinner size="sm" animation="border" variant="warning" />
        </div>
      )}
      <div className="attachment-section-header">
        <h5>
          Other Attachments{" "}
          <span style={{ fontSize: "0.65em" }} className="text-warning">
            (Max 05 files)
          </span>
        </h5>
        <Button
          onClick={(e) => {
            if (!loading && gymProfileData) {
              ConfirmDialog.show({
                message: <AddAttachmentModal okCallback={AddNewFile} />,
                title: "Add Attachment",
                okText: "Add File",
                cancelText: "Cancel",
                showFooter: false,
              });
            }
          }}
        >
          <MdAddCircle /> Add Attachment
        </Button>
      </div>
      {!loading && (
        <div className="gym-attachment-table">
          <div className="table-headings">
            <span>SN</span>
            <span>Document Title</span>
            <span>Link</span>
            <span>Action</span>
          </div>
          <div className="table-body">
            {attachmentData &&
              attachmentData.length > 0 &&
              attachmentData.map((element, index) => {
                return (
                  <div className="table-row" key={index + 1}>
                    <span>{index + 1}</span>
                    <span>{element.title}</span>
                    <span>
                      <a
                        onClick={(e) => {
                          var a = document.createElement("a");
                          a.href = element.link;
                          a.target = "_blank";
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                        }}
                      >
                        Download
                      </a>
                    </span>
                    <span>
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={(e) =>
                          handleRemoveItem(index, element.filename)
                        }
                      >
                        <MdClose />
                      </Button>
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </Section>
  );
};

const TradeLicenceSection = () => {
  const [reloadAll, setreloadAll] = useContext(PageContext);
  const { user: userProfile } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [companyProfile, setcompanyProfile] = useState(null);
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState({
    name: companyProfile?.name || null,
    tradeLicenseFilePath: companyProfile?.tradeLicenseFilePath || "",
  });

  useEffect(() => {
    setLoading(true);
    loadProfileInfo();
  }, []);

  useEffect(() => {
    setLoading(true);
    let tileouto1 = setTimeout(() => {
      loadProfileInfo();
    }, 2000);
    return () => {
      clearTimeout(tileouto1);
    };
  }, [reloadAll]);

  const loadProfileInfo = async () => {
    await getMainGymByOwnerIDController(userProfile?.id).then((res) => {
      if (res) {
        const data = res;
        setcompanyProfile({ ...res, id: res.id });
        setFormData({
          ...formData,
          name: data.name || null,
          tradeLicenseFilePath: data.tradeLicenseFilePath || "",
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const handleImageUpdate = async () => {
    const imageFile = images[0].file;
    const storage = getStorage();
    let gymId = companyProfile?.id || null;
    if (!gymId) {
      const newGymId = await createGymIdController();
      gymId = newGymId.id;
    }
    const userRef = ref(storage, `/companytradelicense/${gymId}`);
    const uploadTask = uploadBytesResumable(userRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setLoading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => { },
      () => {
        Toster.show("Trade license updated.");
        setreloadAll(!reloadAll);
        setLoading(false);
        setFormData({ ...formData, tradeLicenseFilePath: images[0] });
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          updateTradeLicenseFileController(gymId, downloadURL, formData.name);
          setFormData({ ...formData, tradeLicenseFilePath: downloadURL });
        });
        setImages([]);
      }
    );
  };

  return (
    <div className="tradelicense-container">

      {loading && (
        <div className="loadSpinner">
          <Spinner animation="border" variant="warning" />
        </div>
      )}
      <img
        style={{ cursor: images[0]?.data_url ? "not-allowed" : "pointer" }}
        alt=""
        src={images[0]?.data_url || formData.tradeLicenseFilePath || null}
        onClick={(e) => {
          if (!images[0]?.data_url) {
            window.open(
              formData.tradeLicenseFilePath
                ? formData.tradeLicenseFilePath
                : "",
              "blank"
            );
          }
        }}
      />

      {companyProfile?.tradeLicenseFilePath && (
        <ReactImageUploading
          value={images}
          onChange={onChange}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <>
              {images?.length <= 0 ? (
                <>
                  <Button
                    variant="warning"
                    className="mb-4"
                    onClick={onImageUpload}
                  >
                    Select Trade License
                  </Button>
                </>
              ) : (
                <>
                  <div className="d-inline-flex ">
                    <Button
                      variant="warning"
                      className="mb-4"
                      onClick={handleImageUpdate}
                    >
                      <BsSave /> {" Update"}
                    </Button>
                    <Button
                      variant="danger"
                      className="delete-btn mb-4"
                      onClick={() => onImageRemove(0)}
                    >
                      <MdClose />
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </ReactImageUploading>
      )}
      {/* <Button variant="warning" onClick={(e) => setreloadAll(!reloadAll)}>
        Select Trade License
      </Button> */}
    </div>
  );
};

const MembershipcardSection = () => {
  const [reloadAll, setreloadAll, gymProfileData] = useContext(PageContext);
  const { user: userProfile } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [companyProfile, setcompanyProfile] = useState(gymProfileData);
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState({
    name: gymProfileData?.name || null,
    membershipCardPath: gymProfileData?.membershipCardPath || "",
  });

  useEffect(() => {
    setLoading(true);
    loadProfileInfo();
  }, []);

  useEffect(() => {
    setLoading(true);
    let tileouto1 = setTimeout(() => {
      loadProfileInfo();
    }, 2000);
    return () => {
      clearTimeout(tileouto1);
    };
  }, [reloadAll]);

  const loadProfileInfo = async () => {
    await getMainGymByOwnerIDController(userProfile?.id).then((res) => {
      if (res) {
        const data = res;
        setcompanyProfile({ ...res, id: res.id });
        setFormData({
          ...formData,
          name: data.name || null,
          tradeLicenseFilePath: data.tradeLicenseFilePath || "",
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const handleImageUpdate = async () => {
    const imageFile = images[0].file;
    const storage = getStorage();
    let gymId = companyProfile?.id || null;
    if (!gymId) {
      const newGymId = await createGymIdController();
      gymId = newGymId.id;
    }
    const userRef = ref(storage, `/membershipcards/${gymId}`);
    const uploadTask = uploadBytesResumable(userRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setLoading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => { },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          Toster.show("Membership card updated.");
          setreloadAll(!reloadAll);
          setLoading(false);
          updateMembershipcardFileController(gymId, downloadURL, formData.name);
          setFormData({ ...formData, membershipCardPath: downloadURL });
        });
        setImages([]);
      }
    );
  };
  return (
    <div className="membershipcard-container">
      {loading && (
        <div className="loadSpinner">
          <Spinner animation="border" variant="warning" />
        </div>
      )}
      {formData.membershipCardPath && formData.membershipCardPath !== "" && (
        <div className="user-image-thumb">
          <MdPerson />
        </div>
      )}
      <img
        style={{ cursor: images[0]?.data_url ? "not-allowed" : "pointer" }}
        alt=""
        src={images[0]?.data_url || formData.membershipCardPath || null}
        onClick={(e) => {
          if (!images[0]?.data_url) {
            window.open(
              formData.membershipCardPath ? formData.membershipCardPath : "",
              "blank"
            );
          }
        }}
      />
      {companyProfile && (
        <ReactImageUploading
          value={images}
          onChange={onChange}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <>
              {images?.length <= 0 ? (
                <>
                  <Button
                    variant="warning"
                    className="mb-4"
                    onClick={onImageUpload}
                  >
                    Select Membership Card
                  </Button>
                </>
              ) : (
                <>
                  <div className="d-inline-flex ">
                    <Button
                      variant="warning"
                      className="mb-4"
                      onClick={handleImageUpdate}
                    >
                      <BsSave /> {" Update"}
                    </Button>
                    <Button
                      variant="danger"
                      className="delete-btn mb-4"
                      onClick={() => onImageRemove(0)}
                    >
                      <MdClose />
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </ReactImageUploading>
      )}
    </div>
  );
};

const GymInformationForm = () => {
  const dispatch = useDispatch();
  const { user: userProfile } = useSelector((state) => state.user);
  const [reloadAll, setReloadAll] = useContext(PageContext); // Assuming PageContext is defined elsewhere
  const [loading, setLoading] = useState(false);
  const [companyProfile, setCompanyProfile] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    tradeLicenseNumber: "",
    tradeLicenseExpiryDate: "",
    tradeLicenseFilePath: "",
    iban: "",
    bankname: "",
  });
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    setLoading(true);
    loadProfileInfo();
  }, [reloadAll]); // Include reloadAll in the dependency array

  const loadProfileInfo = async () => {
    try {
      const res = await getMainGymByOwnerIDController(userProfile?.id);
      if (res) {
        setCompanyProfile({ ...res, id: res.id });
        setFormData({
          ...formData,
          name: res.name || "",
          tradeLicenseNumber: res.tradeLicenseNumber || "",
          tradeLicenseExpiryDate: res.tradeLicenseExpiryDate || "",
          tradeLicenseFilePath: res.tradeLicenseFilePath || "",
          iban: res.iban || "",
          bankname: res.bankname || "",
        });
      }
    } catch (error) {
      console.error("Error loading profile information:", error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateProfile = async () => {
    try {
      // Validate form fields
      if (Object.values(formData).some(value => value.trim().length === 0)) {
        setErrors({
          nameError: formData.name.trim().length === 0,
          licenseNoError: formData.tradeLicenseNumber.trim().length === 0,
          licenseExpiryError: formData.tradeLicenseExpiryDate.trim().length === 0,
          ibanError: formData.iban.trim().length === 0,
          banknameError: formData.bankname.trim().length === 0,
        });
      } else {
        setErrors(null);
        const data = await updateGymInfoNewController(companyProfile.id, { ...formData });
        if (data.success === "success") {
          setReloadAll(!reloadAll);
          Toster.show("Updated successfully");
        } else {
          Toster.show("Something wrong.", true);
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      Toster.show("An error occurred while updating profile.", true);
    }
  };

  return (
    <Section className="position-relative">
      {/*{!formData.tradeLicenseFilePath &&*/}
      {/*<div className="trade-license-message">*/}
      {/*  <h5 className="text-warning">*/}
      {/*    Trade licence must required to create company profile.*/}
      {/*  </h5>*/}
      {/*  <h6 className="text-secondary">Please upload your trade license</h6>*/}
      {/*</div>*/}
      {/*}*/}
      <div>
        <h5>Gym Information</h5>
      </div>
      {loading && (
        <div className="loadSpinner black">
          <Spinner animation="border" variant="warning" />
        </div>
      )}

      {
        <form className="mx-auto d-flex  flex-column w-100">
          <>
            <InputBox
              required
              className={
                companyProfile?.name?.trim().length > 0 ? "disabled" : ""
              }
              label="Company name"
              error={errors?.nameError}
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
            <div className="form-group">
              <div className="form-row">
                <InputBox
                  className={
                    companyProfile?.tradeLicenseNumber?.trim().length > 0
                      ? "disabled"
                      : ""
                  }
                  label="Trade license number"
                  error={errors?.licenseNoError}
                  value={formData.tradeLicenseNumber}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      tradeLicenseNumber: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-row">
                <InputBox
                  className={
                    companyProfile?.tradeLicenseExpiryDate?.trim().length > 0
                      ? "disabled"
                      : ""
                  }
                  type="date"
                  label="License expiry date"
                  error={errors?.licenseExpiryError}
                  value={formData.tradeLicenseExpiryDate}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      tradeLicenseExpiryDate: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <Divider color="#bfbfbf" marginTop="10px">
              Banking Details
            </Divider>
            <div className="form-group">
              <div className="form-row">
                <InputBox
                  className={
                    companyProfile?.iban?.trim().length > 0 ? "disabled" : ""
                  }
                  type="text"
                  label="IBAN"
                  required
                  error={errors?.ibanError}
                  value={formData.iban}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      iban: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-row">
                <InputBox
                  className={
                    companyProfile?.bankname?.trim().length > 0
                      ? "disabled"
                      : ""
                  }
                  type="text"
                  label="Bank Name"
                  required
                  error={errors?.banknameError}
                  value={formData.bankname}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      bankname: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <br />
            {!companyProfile?.bankname?.trim().length > 0 && (
              <div className="d-flex ps-5 pe-5 ">
                <Button
                  className="ps-5 pe-5 w-100"
                  variant="warning"
                  onClick={(e) => {
                    if (!loading && formData?.tradeLicenseFilePath !== "")
                      onUpdateProfile();
                  }}
                >
                  Update Details
                </Button>
              </div>
            )}
          </>
        </form>
      }
    </Section>
  );
};

