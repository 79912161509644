import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BsPlusCircleFill } from "react-icons/bs";
import { RiEyeFill, RiPencilFill, RiSearchEyeLine } from "react-icons/ri";
import { IoClose, IoCheckmark } from "react-icons/io5";
// import { getGym } from "../../../../../common/repository/js/gym";
import AddClass from "./modal/addClass";
import EditClass from "./modal/editClass_FreelanceTrainer";
import CustomLoading from "../../../../items/loadingBar";
// import {
//   getAllFreelanceClasses,
//   getClassesByGym,
//   getClassesByTrainer,
//   updateClass,
// } from "../../../../../common/repository/js/classes.service";
import {
  updateClassController,
  getAllFreelanceClassesController,
  getClassesByGymController,
  getClassesByTrainerController,
} from "../../../../../../../controllers/web/classesController";
import { useSelector } from "react-redux";
import ConfirmDialog from "../../../../custom/ConfirmDialog/ConfirmDialog";
import { Msgs } from "../../../../../../../common/data/msgs";
import AddClassFreelance from "./modal/addClass_FreelaceTrainer";
import { Switch, ThemeProvider } from "@mui/material";
import { outerTheme } from "../../../../global";
import ViewClassModal from "./modal/viewClass";
import AccessManager from "../../../../custom/AccessManager/AccessManager";
import InputBox from "../../../../custom/InputBox/InputBox";

const TableRow = ({ content, onActivate, edit }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(true);
  const { user: userProfile } = useSelector((state) => state.user);

  useEffect(() => {
    setActivate(content.activated);
  }, [content]);

  const onClickActivate = () => {
    onActivate(content.id, !content.activated);
  };

  const onViewClick = () => {
    ConfirmDialog.show({
      message: <ViewClassModal data={content} />,
      showFooter: false,
      title: "Class Details",
    });
  };

  return (
    <div className="table-row">
      <span className="name">{content.ownerEmail}</span>
      <span className="name">{content.name}</span>
      {/* <span className="capacity">{content.capacity}</span> */}
      <span className="start-date">{content.starts_date}</span>
      <span className="end-date">{content.ends_date}</span>
      <span className="duration">{content.duration} Days</span>
      {/* <span className="start-date">{content.start_time}</span> */}
      {/* <span className="end-date">{content.end_time}</span> */}
      <span className="duration">
        {content.daily_time} {content.time_unit}
      </span>

      {/* <span className="trainers">
        {content.trainers?.length > 0 &&
          content.trainers.reduce(
            (accum, current, index) => {
              return accum + current.label + ", ";
            },
            [""]
          )}
      </span> */}
      <div className="controls">
        <span className="edit me-1" onClick={onViewClick}>
          <RiEyeFill />
        </span>
        <span className="edit" onClick={() => edit(content)}>
          <RiPencilFill />
        </span>
        {userProfile.role.includes(4) && (
          <div className="d-flex align-items-center">
            <ThemeProvider theme={outerTheme}>
              <Switch
                checked={activate}
                onChange={(e, c) => {
                  ConfirmDialog.show({
                    title: "Confirmation!",
                    message: `Are Sure to change class status?`,
                    okCallback:  async () => {
                      await onActivate(content.id, c);
                    },
                  });
                }}
                name="checkedA"
              />
              <div className={`${activate ? "text-light" : "text-secondary"}`}>
                Active
              </div>
            </ThemeProvider>
          </div>
        )}
      </div>
      <div className="controls-blank">&nbsp;</div>
      <div className="controls-mobile">
        <span className="edit" onClick={() => edit(content)}>
          <RiPencilFill />
        </span>
        {userProfile.role.includes(4) && (
          <>
            {userProfile.role.includes(4) && content.activated ? (
              <span className="activate" onClick={() => onClickActivate()}>
                <IoCheckmark />
              </span>
            ) : (
              <span
                className="activate disable"
                onClick={() => onClickActivate()}
              >
                <IoClose />
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const ManageFreelanceClasses = () => {
  // User from login, current branch coming from GymBranchDetail.js
  const { user: userProfile } = useSelector((state) => state.user);
  const [currnetBranch, setcurrentBranch] = useState(
    JSON.parse(localStorage.getItem("branchDoc"))
  );
  const { t } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [filteredContent, setfilteredContent] = useState([]);
  const [searchText, setSearchtext] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editContent, setEditContent] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    if (userProfile.role.includes(4)) {
      getClasses();
    }
  }, []);

  const getClasses = async () => {
    await getAllFreelanceClassesController()
      .then((data) => {
        setfilteredContent(data);
        setLoading(false);
      })
      .catch((err) => {
        setClasses([]);
        setfilteredContent([]);
        setLoading(false);
      });
  };

  const refreshData = () => {
    if (userProfile.role.includes(4)) {
      getClasses();
    }
  };

  const closeAddModal = () => {
    setIsAdd(false);
  };

  const openAddModal = () => {
    if (userProfile.activated) {
      setIsAdd(true);
    } else {
      ConfirmDialog.show({
        message: Msgs.NotActiveMsg,
        showFooter: false,
      });
    }
  };

  const openEditModal = (item) => {
    if (userProfile.activated) {
      setEditContent(item);
      setIsEdit(true);
    } else {
      ConfirmDialog.show({
        message: Msgs.NotActiveMsg,
        showFooter: false,
      });
    }
  };

  const closeEditModal = () => {
    setIsEdit(false);
  };

  const onActivate = async (gymId, activation) => {
    setLoading(true);
    if (userProfile.activated) {
      await updateClassController(gymId, {
        activated: activation,
      }).then((data) => {
        if (data.success) {
          if (userProfile.role.includes(4)) {
            getClasses();
          }
          ConfirmDialog.hide();
          setLoading(false);
          return true;
        } else {
          ConfirmDialog.hide();
          setLoading(false);
          return false;
        }
      });
    } else {
      ConfirmDialog.show({
        message: Msgs.NotActiveMsg,
        showFooter: false,
      });
    }
    setLoading(false);
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchtext(val);
    const _filteredData = classes.filter(
      (data) =>
        data.ownerEmail.toString().toLowerCase().includes(val.toLowerCase()) ||
        data.name.toString().toLowerCase().includes(val.toLowerCase())
    );
    setfilteredContent(_filteredData);
  };

  return (
    <AccessManager allowedRoles={[4]} fallback={"/dashboard"}>
      <Container>
        <CustomLoading isStart={loading} />
        <div className="header">
          <div className="title-layout">
            <span className="title">
              {currnetBranch?.name || "Freelance Classes"}
            </span>
            <span
              className="button"
              onClick={() => openAddModal()}
              style={{ cursor: "pointer" }}
            >
              <BsPlusCircleFill className="icon" />
              {t("Add Class")}
            </span>
          </div>
          <span className="d-flex ms-auto mt-3">
            <InputBox
              icon={<RiSearchEyeLine />}
              placeholder="Search..."
              value={searchText}
              onChange={handleSearch}
            />
          </span>
        </div>
        <div className="content-layout">
          <div className="control-group" />
          <div className="content">
            <div className="table-header">
              <span className="name">{t("Owner")}</span>
              <span className="name">{t("Name")}</span>
              {/* <span className="capacity">{t("Capacity")}</span> */}
              <span className="start-date">{t("Start Date")}</span>
              <span className="end-date">{t("End Date")}</span>
              <span className="duration">{t("Duration")}</span>
              {/* <span className="start-date">{t("Start Time")}</span> */}
              {/* <span className="end-date">{t("End Time")}</span> */}
              <span className="duration">{t("Daily")}</span>
              {/* <span className="trainers">{t("Trainers")}</span> */}
              <span className="controls">&nbsp;</span>
              <span className="controls-blank">&nbsp;</span>
              <span className="controls-mobile">&nbsp;</span>
            </div>
            <div className="table-content">
              {filteredContent.map((item, index) => (
                <TableRow
                  key={index}
                  content={item}
                  onActivate={onActivate}
                  edit={openEditModal}
                />
              ))}
            </div>
          </div>
        </div>
        <>
          {userProfile.role.includes(2) && (
            <AddClassFreelance
              isOpen={isAdd}
              close={closeAddModal}
              refresh={refreshData}
            />
          )}
          {!userProfile.role.includes(2) && (
            <AddClass
              isOpen={isAdd}
              close={closeAddModal}
              refresh={refreshData}
            />
          )}
          <EditClass
            isOpen={isEdit}
            close={closeEditModal}
            refresh={refreshData}
            content={editContent}
          />
        </>
      </Container>
    </AccessManager>
  );
};

const Container = styled("div")`
  display: flex;
  width: 100%;

  flex-direction: column;
  background: #1c1a1d;
  @media (max-width: 1180px) {
    margin-top: 86px;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    height: 30px;
    background-color: black;
    .title-layout {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .title {
      font-size: 24px;
      line-height: 120%;
      text-transform: uppercase;
      font-weight: bold;
      color: #f2f2f2;
      margin-right: 25px;
    }
    .button {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #feca00;
      .icon {
        font-size: 16px;
        margin-right: 14px;
      }
    }
    .location {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #888888;
      margin-left: 20px;
    }
    .search {
      width: 260px;
      height: 50px;
      background: #1c1a1d;
      border: 1px solid #888888;
      box-sizing: border-box;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 20px;
      font-size: 18px;
      line-height: 100%;
      color: #3e3e3e;
      .icon {
        font-size: 22px;
        margin-right: 12px;
      }
      .input {
        width: 100%;
        background: #1c1a1d;
        border: none;
        color: #888888;

        &:focus-visible {
          outline: none;
        }
      }
    }
  }
  .content {
    padding: 45px 40px;
    display: flex;
    flex-direction: column;
    .table-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.01em;
      color: #888888;
      padding-bottom: 10px;
      border-bottom: 1px solid #4f4f4f;
    }
    .name {
      width: 100%;
      max-width: 300px;
      min-width: 150px;
      overflow-x: hidden;
    }
    .start-date {
      width: 100%;
      max-width: 200px;
      min-width: 110px;
    }
    .end-date {
      width: 100%;
      max-width: 200px;
      min-width: 110px;
    }
    .duration {
      width: 100%;
      max-width: 200px;
      min-width: 120px;
    }
    .capacity {
      width: 100%;
      max-width: 200px;
      min-width: 120px;
    }
    .trainers {
      width: 100%;
      min-width: 150px;
    }
    .type {
      width: 100%;
      max-width: 400px;
      min-width: 100px;
    }
    .controls {
      width: 100%;
      padding-right: 10px;
      max-width: 120px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 20px;
      align-items: center;
      .edit {
        cursor: pointer;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        background-color: #333333;
        color: #159cff;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:active,
        &:hover {
          cursor: pointer;
          color: #fff;
        }
      }
      .activate {
        width: 73px;
        height: 32px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 160%;
        background: #1e382a;
        color: #27ae60;
      }
      .activate.disable {
        background: #462224;
        color: #f03f3f;
      }
    }
    .table-content {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 160%;
      color: #d6d6d6;
      max-height: calc(100vh - 200px);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: #888888;
        border-radius: 100px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-color: #ffcc00;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
      }
      .table-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 0px;
        border-bottom: 1px solid #4f4f4f;
      }
    }
  }
  .control-group {
    display: none;
  }
  .controls-mobile {
    display: none;
  }
  .controls-blank {
    display: none;
    min-width: 110px;
  }
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      height: unset;
      background: #1c1a1d;
      padding: 15px 20px;
      .title-layout {
        justify-content: space-between;
      }
      .search {
        margin-top: 15px;
        width: 100%;
      }
    }
    .content-layout {
      position: relative;
    }
    .content {
      border: 1px solid #4f4f4f;
      border-radius: 15px;
      margin-left: 10px;
      margin-right: 10px;
      padding: 20px 10px;
      overflow: auto;
      flex: 0 1 auto;
      .controls {
        display: none;
      }
      .controls-blank {
        display: unset;
      }
      .table-header {
        padding-bottom: 0px;
        border-bottom: none;
        & > * {
          padding-bottom: 10px;
          border-bottom: 1px solid #4f4f4f;
        }
        .controls-mobile {
          display: unset;
          position: relative;
          min-width: 100px;
          right: 11px;
        }
      }
      .table-content {
        max-height: unset;
        overflow: visible;
        .table-row {
          padding-bottom: 0px;
          border-bottom: none;
          & > * {
            padding-bottom: 10px;
            border-bottom: 1px solid #4f4f4f;
          }
          .controls-mobile {
            display: unset;
            position: relative;
            right: 11px;
            width: 100px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-bottom: 4px;
            .edit {
              font-size: 24px;
              color: #2f80ed;
              margin-right: 16px;
            }
            .activate {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              line-height: 160%;
              background: #1e382a;
              color: #27ae60;
            }
            .activate.disable {
              background: #462224;
              color: #f03f3f;
            }
          }
        }
      }
    }
    .control-group {
      width: 100px;
      height: 100%;
      display: unset;
      background: #000000;
      position: absolute;
      right: 0px;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      margin-right: 10px;
      border: 1px solid #4f4f4f;
      border-left: none;
      display: none;
    }
  }
`;

export default ManageFreelanceClasses;
