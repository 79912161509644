import firebase, { auth, db } from "../main";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  setDoc,
  getDocs,
  getDoc,
  deleteDoc,
  collectionGroup,
  query,
  arrayUnion,
  arrayRemove,
  where,
  limit,
} from "firebase/firestore";
import gym_main from "../../../common/models/gym_main";
import { updateUserNew } from "./user";
import { preSaveOrUpdateAClass } from "../../../common/validations/PreSave";
import { getCollectionRef } from "../../../common/Helpers/GetCollectionRef";
const main_gymCollection = "gym_main";

export const getGyms = () => {
  return getDocs(getCollectionRef(main_gymCollection));
};

// export const getAllGyms = () => {
//   return getDocs(collection(db, main_gymCollection)).then((datas) => {
//     let gyms = [];
//     datas.forEach((data) => {
//       gyms.push({ ...data.data(), id: data.id });
//     });
//     return gyms;
//   });
// };
export const getAllGyms = async () => {
  try {
    const querySnapshot = await getDocs(getCollectionRef(main_gymCollection));
    const gyms = [];

    querySnapshot.forEach((doc) => {
      const gym = { id: doc.id, ...doc.data() };
      gyms.push(gym);
    });

    return gyms;
  } catch (error) {
    console.error("Error fetching gyms:", error);
    return []; // Return an empty array in case of error
  }
};


// export const getGymsList = async () => {
//   let result = [];
//   await getDocs(
//     query(collection(db, main_gymCollection), where("approved", "==", true))
//   ).then((res) => {
//     if (res.size > 0) {
//       let temp = [];
//       res.docs.forEach((doc) => {
//         temp.push({ label: doc.data().name, id: doc.id });
//       });
//       result = temp;
//     } else {
//       result = [];
//     }
//   });
//   return result;
// };

export const getGymsList = async () => {
  let result = [];
  try {
    const querySnapshot = await getDocs(
      query(getCollectionRef(main_gymCollection), where("approved", "==", true))
    );
    if (querySnapshot.size > 0) {
      let temp = [];
      querySnapshot.forEach((doc) => {
        const gym = Object.assign(new gym_main(), {
          ...doc.data(),
          id: doc.id,
        });
        temp.push({ label: gym.name, id: gym.id });
      });
      result = temp;
    }
  } catch (error) {
    console.error(error);
  }
  return result;
};

// export const getActiveMembershipGymsList = async () => {
//   let result = [];
//   await getDocs(
//     query(
//       collection(db, main_gymCollection),
//       where("approved", "==", true),
//       where("memberShipPlan", "!=", undefined || "")
//     )
//   ).then((res) => {
//     if (res.size > 0) {
//       let temp = [];
//       res.docs.forEach((doc) => {
//         const gymMainObj = Object.assign(new gym_main(), {
//           ...doc.data(),
//           id: doc.id,
//         });
//         // if (
//         //   gymMainObj.memberShipPlan
//         //   gymMainObj.memberShipPlan !== undefined &&
//         //   gymMainObj.memberShipPlan !== ""
//         // ) {
//         temp.push(gymMainObj);
//         // }
//       });
//       result = temp;
//     } else {
//       result = [];
//     }
//   });
//   return result;
// };

export const getActiveMembershipGymsList = async () => {
  let result = [];
  await getDocs(
    query(
      getCollectionRef(main_gymCollection),
      where("approved", "==", true),
      where("memberShipPlan", "!=", "")
    )
  ).then((res) => {
    if (res.size > 0) {
      let temp = [];
      res.docs.forEach((doc) => {
        const _doc = Object.assign(new gym_main(), {
          ...doc.data(),
          id: doc.id,
        });
        temp.push(_doc);
      });
      result = temp;
    } else {
      result = [];
    }
  });
  return result;
};

// export const getActiveCompanyList = async () => {
//   let result = [];
//   await getDocs(
//     query(collection(db, main_gymCollection), where("approved", "==", true))
//   ).then((res) => {
//     if (res.size > 0) {
//       let temp = [];
//       res.docs.forEach((doc) => {
//         temp.push({ label: doc.data().name, value: doc.id });
//       });
//       result = temp;
//     } else {
//       result = [];
//     }
//   });
//   return result;
// };
export const getActiveCompanyList = async () => {
  let result = [];
  await getDocs(
    query(getCollectionRef(main_gymCollection), where("approved", "==", true))
  ).then((res) => {
    if (res.size > 0) {
      let temp = [];
      res.docs.forEach((doc) => {
        const gymObj = Object.assign(new gym_main(), {
          ...doc.data(),
          id: doc.id,
        });
        temp.push({ label: gymObj.name, value: gymObj.id });
      });
      result = temp;
    } else {
      result = [];
    }
  });
  return result;
};

// export const getGymInfo = (id) => {
//   return getDoc(doc(db, main_gymCollection, id))
//     .then((data) => {
//       return { ...data.data(), id: data.id };
//     })
//     .catch((err) => {
//       return { err: "error" };
//     });
// };
export const getGymInfo = async (id) => {
  try {
    const docRef = getCollectionRef(main_gymCollection, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const gymData = docSnap.data();
      const gymInfo = Object.assign(new gym_main(), gymData);
      gymInfo.id = docSnap.id;
      return gymInfo;
    } else {
      return { error: "Gym does not exist" };
    }
  } catch (error) {
    console.error(error);
    return { error: "Error getting gym info" };
  }
};

// export const getGymInfo02 = async (id) => {
//   let result = "";
//   let res = await getDoc(doc(db, main_gymCollection, id));
//   if (res.exists()) {
//     result = { name: res.data().name, id: res.id };
//   }
//   return result;
// };
export const getGymInfo02 = async (id) => {
  let result = "";
  let res = await getDoc(getCollectionRef(main_gymCollection, id));
  if (res.exists()) {
    result = Object.assign(new gym_main(), { ...res.data(), id: res.id });
  }
  return result;
};

// export const createGymId = () => {
//   return addDoc(collection(db, main_gymCollection), {})
//     .then((data) => {
//       return { id: data.id };
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };
export const createGymId = async () => {
  try {
    const docRef = await addDoc(getCollectionRef(main_gymCollection), {});
    const gym = new gym_main();
    gym.id = docRef.id;
    return gym;
  } catch (error) {
    console.log(error);
  }
};


export const getAllMainGymByOwnerID = async (gymId) => {
  try {
    const q = query(
      getCollectionRef("users"),
      where("company_id", "==", gymId),
      where("role", "array-contains", 3)
    );

    const querySnapshot = await getDocs(q);
    const users = [];

    querySnapshot.forEach((doc) => {
      users.push({ id: doc.id, ...doc.data() });
    });

    return { success: true, data: users };
  } catch (error) {
    console.error("Error fetching users:", error);
    return { success: false, error: error.message };
  }
};


// export const getAllMainGymByOwnerID = async (doc) => {

//   const docRef = firestore.collection().doc(doc);
//   docRef.get().then((doc) => {
//     if (doc.exists) {
//       const docId = doc.id;
//       // Use the document ID as needed
//     } else {
//       console.log('No such document!');
//     }
//   }).catch((error) => {
//     console.log('Error getting document:', error);
//   });


//   try {
//     console.log("getting all gym owner")
//     const q = query(
//       getCollectionRef('users'),
//       where("gym_id", "==", gymId),
//       where("role", "in", [3]) // Assuming role is an array
//     );

//     const querySnapshot = await getDocs(q);
//     const users = [];

//     querySnapshot.forEach((doc) => {
//       users.push({ id: doc.id, ...doc.data() });
//     });

//     return { success: true, data: users };
//   } catch (error) {
//     console.error("Error fetching users:", error);
//     return { success: false, error: error.message };
//   }
// };

export const getMainGymByOwnerID = async (ownerID) => {
  const q = query(
    getCollectionRef(main_gymCollection),
    where("owner", "==", ownerID),
    limit(1)
  );

  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const gymData = { ...doc.data(), id: doc.id };
      return gymData;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching gym data:", error);
    throw error;
  }
};


/// This function must be deleted it is bad and poor quality and check where it is used
// export const getMainGymIDByUser = (owner) => {
//   return getDocs(collection(db, main_gymCollection)).then((datas) => {
//     let id = "";
//     datas.forEach((data) => {
//       if (data.data().owner === owner) {
//         id = data.id;
//       }
//     });
//     return id;
//   });
// };

export const getMainGymIDByUser = async (owner) => {
  const q = query(
    getCollectionRef(main_gymCollection),
    where("owner", "==", owner)
  );
  return await getDocs(q).then((docs) => {
    let id = "";
    docs.forEach((doc) => {
      const gym = Object.assign(new gym_main(), { ...doc.data(), id: doc.id });
      if (gym.owner === owner) {
        id = gym.id;
      }
    });
    // console.log("owner",owner, id);
    return id;
  });
};

// export const updateMemberShipCard = (id, path) => {
//   return updateDoc(doc(db, main_gymCollection, id), {
//     membershipCardPath: path,
//   })
//     .then((data) => {
//       return { success: "success", error: "" };
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

export const updateMemberShipCard = async (id, path) => {
  try {
    await updateDoc(getCollectionRef(main_gymCollection, id), {
      membershipCardPath: path,
    });
    return { success: "success", error: "" };
  } catch (error) {
    console.log(error);
    return { success: "", error: "error" };
  }
};

// export const updateCompany = async (id, data) => {
//   await updateDoc(doc(db, main_gymCollection, id), data);
// };
export const updateCompany = async (id, data) => {
  try {
    const gymRef = getCollectionRef(main_gymCollection, id);
    await updateDoc(gymRef, data);
  } catch (error) {
    console.log(error);
  }
};

// export const updateGymInfo = (id, data) => {
//   return updateDoc(doc(db, main_gymCollection, id), {
//     name: data.name,
//     tradeLicenseNumber: data.tradeLicenseNumber,
//     tradeLicenseExpiryDate: data.tradeLicenseExpiryDate,
//     memberShipPlan: data.memberShipPlan,
//     attendanceCost: data.attendanceCost,
//     iban: data.iban,
//     bankname: data.bankname,
//   })
//     .then((data) => {
//       return { success: "success", error: "" };
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

export const updateGymInfo = async (id, data) => {
  const gymMainObj = new gym_main();
  gymMainObj.name = data.name;
  gymMainObj.tradeLicenseNumber = data.tradeLicenseNumber;
  gymMainObj.tradeLicenseExpiryDate = data.tradeLicenseExpiryDate;
  gymMainObj.memberShipPlan = data.memberShipPlan;
  gymMainObj.attendanceCost = data.attendanceCost;
  gymMainObj.iban = data.iban;
  gymMainObj.bankname = data.bankname;
  return updateDoc(getCollectionRef(main_gymCollection, id), {
    name: gymMainObj.name,
    tradeLicenseNumber: gymMainObj.tradeLicenseNumber,
    tradeLicenseExpiryDate: gymMainObj.tradeLicenseExpiryDate,
    memberShipPlan: gymMainObj.memberShipPlan,
    attendanceCost: gymMainObj.attendanceCost,
    iban: gymMainObj.iban,
    bankname: gymMainObj.bankname,
  })
    .then((data) => {
      return { success: "success", error: "" };
    })
    .catch((error) => {
      console.log(error);
      return { success: "", error: "error" };
    });
};

// export const updateGymInfoNew = (id, data) => {
//   const userId = JSON.parse(localStorage.getItem("accesstoken"));
//   return updateDoc(doc(db, main_gymCollection, id), data)
//     .then(async () => {
//       await updateUserNew(userId, {
//         company: { value: id, label: data.name },
//       });
//       return { success: "success", error: "" };
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };
export const updateGymInfoNew = async (id, data) => {
  const gymMainObj = Object.assign(new gym_main(), { ...data });
  const userId = JSON.parse(localStorage.getItem("accesstoken"));
  return updateDoc(
    getCollectionRef(main_gymCollection, id),
    preSaveOrUpdateAClass(gymMainObj)
  )
    .then(async () => {
      await updateUserNew(userId, {
        company_id: id,
      });
      return { success: "success", error: "" };
    })
    .catch((error) => {
      console.log(error);
    });
};

// export const saveGymInfo = async (id, name, number, path) => {
//   const userId = JSON.parse(localStorage.getItem("accesstoken"));

//   return setDoc(doc(db, main_gymCollection, id), {
//     name: name,
//     owner: userId,
//     tradeLicenseNumber: number,
//     tradeLicenseFilePath: path,
//     approved: false,
//   })
//     .then(async () => {
//       await updateUserNew(userId, { company: { value: id, label: name } });
//       return { success: "success", error: "" };
//     })
//     .catch((error) => {
//       return { error: error.message, success: "" };
//     });
// };

export const saveGymInfo = async (id, name, number, path) => {
  // console.log
  const userId = JSON.parse(localStorage.getItem("accesstoken"));

  const gym = new gym_main();
  gym.id = id;
  gym.name = name;
  gym.owner = "";
  gym.tradeLicenseNumber = number;
  gym.tradeLicenseFilePath = path;
  gym.approved = false;

  return setDoc(getCollectionRef(main_gymCollection, id), gym.toData())
    .then(async () => {
      await updateUserNew(userId, { company_id:id });
      return { success: "success", error: "" };
    })
    .catch((error) => {
      return { error: error.message, success: "" };
    });
};

// export const saveGymInfoNew = async (id, data) => {
//   const userId = JSON.parse(localStorage.getItem("accesstoken"));
//   let gymId = id;
//   if (id === null || id === undefined || !id) {
//     const newGymId = await createGymId();
//     gymId = newGymId.id;
//   }
//   return setDoc(
//     doc(db, main_gymCollection, gymId),
//     {
//       owner: userId,
//       approved: false,
//       ...data,
//     },
//     { merge: true }
//   )
//     .then(async () => {
//       await updateUserNew(userId, {
//         company: { value: id, label: data.name },
//       });
//       return { success: "success", error: "" };
//     })
//     .catch((error) => {
//       return { error: error.message, success: "" };
//     });
// };
export const saveGymInfoNew = async (id, data) => {
  let gymId = id;
  if (!id) {
    const newGymId = await createGymId();
    gymId = newGymId.id;
  }
  const gym = Object.assign(new gym_main(), { ...data });
  gym.owner = "";
  return setDoc(
    getCollectionRef(main_gymCollection, gymId),
    preSaveOrUpdateAClass(gym),
    {
      merge: true,
    }
  )
    .then(() => {
      return { success: "success", error: "" };
    })
    .catch((error) => {
      return { error: error.message, success: "" };
    });
};


// export const updateTradeLicenseFileByAdmin = async (
//   id,
//   tradeLicenseFilePath
// ) => {
//   const userId = JSON.parse(localStorage.getItem("accesstoken"));
//   let gymId = id;
//   if (gymId) {
//     let dataToUpdate = {
//       tradeLicenseFilePath: tradeLicenseFilePath,
//     };
//     return setDoc(doc(db, main_gymCollection, gymId), dataToUpdate, {
//       merge: true,
//     })
//       .then(async () => {
//         return { success: true, error: "" };
//       })
//       .catch((error) => {
//         return { error: error.message, success: false };
//       });
//   } else {
//     return { error: "gym id is not valid.", success: false };
//   }
// };
export const updateTradeLicenseFileByAdmin = async (
  id,
  tradeLicenseFilePath
) => {
  const userId = JSON.parse(localStorage.getItem("accesstoken"));
  let gymId = id;

  if (gymId) {
    const gym = new gym_main();
    gym.tradeLicenseFilePath = tradeLicenseFilePath;

    return setDoc(
      getCollectionRef(main_gymCollection, gymId),
      { tradeLicenseFilePath: gym.tradeLicenseFilePath },
      {
        merge: true,
      }
    )
      .then(async () => {
        return { success: true, error: "" };
      })
      .catch((error) => {
        return { error: error.message, success: false };
      });
  } else {
    return { error: "gym id is not valid.", success: false };
  }
};

export const updateTradeLicenseFile = async (
  id,
  tradeLicenseFilePath,
  companyName = null
) => {
  const userId = JSON.parse(localStorage.getItem("accesstoken"));
  let gymId = id;
  if (id === null || id === undefined || !id) {
    const newGymId = await createGymId();
    gymId = newGymId.id;
  }
  if (gymId) {
    const gymMainObj = new gym_main();
    gymMainObj.owner = userId;
    gymMainObj.tradeLicenseFilePath = tradeLicenseFilePath;
    gymMainObj.approved = false;
    let dataToUpdate = {
      owner: gymMainObj.owner,
      tradeLicenseFilePath: gymMainObj.tradeLicenseFilePath,
      approved: gymMainObj.approved,
    };
    return setDoc(getCollectionRef(main_gymCollection, gymId), dataToUpdate, {
      merge: true,
    })
      .then(async () => {
        await updateUserNew(userId, {
          company_id: id ,
        });
        return { success: true, error: "" };
      })
      .catch((error) => {
        return { error: error.message, success: false };
      });
  } else {
    return { error: "gym id is not valid.", success: false };
  }
};
// export const updateTradeLicenseFile = async (
//   id,
//   tradeLicenseFilePath,
//   companyName = null
// ) => {
//   const userId = JSON.parse(localStorage.getItem("accesstoken"));
//   let gym = new gym_main(id);

//   if (!gym.data.id) {
//     const newGymId = await createGymId();
//     gym.setId(newGymId.id);
//   }

//   gym.setOwner(userId);
//   gym.setTradeLicenseFilePath(tradeLicenseFilePath);
//   gym.setApproved(false);

//   return setDoc(doc(db, main_gymCollection, gym.data.id), gym.toData(), {
//     merge: true,
//   })
//     .then(async () => {
//       await updateUserNew(userId, {
//         company: { value: gym.data.id, label: companyName || gym.data.name },
//       });
//       return { success: true, error: "" };
//     })
//     .catch((error) => {
//       return { error: error.message, success: false };
//     });
// };

export const updateMembershipcardFileByAdmin = async (
  id,
  membershipCardPath
) => {
  let gymId = id;
  if (gymId) {
    const gymMainObj = new gym_main();
    gymMainObj.membershipCardPath = membershipCardPath;
    let dataToUpdate = {
      membershipCardPath: gymMainObj.membershipCardPath,
    };
    return setDoc(getCollectionRef(main_gymCollection, gymId), dataToUpdate, {
      merge: true,
    })
      .then(async () => {
        return { success: true, error: "" };
      })
      .catch((error) => {
        return { error: error.message, success: false };
      });
  } else {
    return { error: "gym id is not valid.", success: false };
  }
};

export const updateMembershipcardFile = async (
  id,
  membershipCardPath,
  companyName = null
) => {
  const userId = JSON.parse(localStorage.getItem("accesstoken"));
  let gymId = id;
  if (id === null || id === undefined || !id) {
    const newGymId = await createGymId();
    gymId = newGymId.id;
  }
  if (gymId) {
    const gymMainObj = new gym_main();
    gymMainObj.owner = userId;
    gymMainObj.membershipCardPath = membershipCardPath;
    gymMainObj.approved = false;
    let dataToUpdate = {
      owner: gymMainObj.owner,
      membershipCardPath: gymMainObj.membershipCardPath,
      approved: gymMainObj.approved,
    };
    return setDoc(getCollectionRef(main_gymCollection, gymId), dataToUpdate, {
      merge: true,
    })
      .then(async () => {
        await updateUserNew(userId, {
          company_id: id,
        });
        return { success: true, error: "" };
      })
      .catch((error) => {
        return { error: error.message, success: false };
      });
  } else {
    return { error: "gym id is not valid.", success: false };
  }
};

// export const getGymsOption = async () => {
//   const gymOptions = [];
//   return getDocs(collection(db, main_gymCollection)).then((gyms) => {
//     gyms.forEach((gym) => {
//       if (gym.data().name === undefined) {
//         return;
//       }
//       gymOptions.push({
//         label: gym.data().name,
//         value: gym.id,
//       });
//     });
//     return gymOptions;
//   });
// };

export const getGymsOption = async () => {
  const gymOptions = [];
  return getDocs(query(getCollectionRef(main_gymCollection))).then((gyms) => {
    gyms.forEach((gym) => {
      const gymObj = Object.assign(new gym_main(), {
        ...gym.data(),
        id: gym.id,
      });
      gymOptions.push({
        label: gymObj.name,
        value: gymObj.id,
      });
    });
    return gymOptions;
  });
};

// export const changeGymActivation = (id, state) => {
// return updateDoc(doc(db, main_gymCollection, id), {
//   approved: state,
// })
//   .then(() => {
//     return { success: "success", error: "" };
//   })
//   .catch((error) => {
//     return { success: "", error: error.message };
//   });
// };
export const changeGymActivation = async (id, state) => {
  const gym = new gym_main();
  gym.approved = state;
  return updateDoc(getCollectionRef(main_gymCollection, id), {
    approved: gym.approved,
  })
    .then(() => {
      return { success: "success", error: "" };
    })
    .catch((error) => {
      return { success: "", error: error.message };
    });
};

export const addAttachment = async (id, data) => {
  const userId = JSON.parse(localStorage.getItem("accesstoken"));
  try {
    return updateDoc(getCollectionRef(main_gymCollection, id), {
      attachments: arrayUnion(data),
    })
      .then(async () => {
        return { success: true, error: "" };
      })
      .catch((error) => {
        return { error: error.message, success: false };
      });
  } catch (err) {
    return { error: err, success: false };
  }
};

export const removeAttachment = async (id, data) => {
  const userId = JSON.parse(localStorage.getItem("accesstoken"));
  try {
    return updateDoc(getCollectionRef(main_gymCollection, id), {
      attachments: arrayRemove(data),
    })
      .then(async () => {
        return { success: true, error: "" };
      })
      .catch((error) => {
        return { error: error.message, success: false };
      });
  } catch (err) {
    return { error: err, success: false };
  }
};
