import {
  getGymOwners,
  addUserAttachment,
  changeUserActivateState,
  getAllFreelanceTrainer,
  getAllUsersNew,
  getTrainers,
  getTrainersforBranch,
  getUser,
  getUsers,
  getUsersByRole,
  removeUserAttachment,
  resetDeviceID,
  saveUser,
  updateUser,
  updateUserBranches,
  updateUserImage,
  updateUserNew,
  createNewUser
} from "../../repository/web/js/user";

export const getGymOwnersController = async () => {
  try {
    const { success, data } = await getGymOwners();

    if (!success) {
      return { success: false, message: "Failed to retrieve gym owners." };
    }

    return { success: true, data };
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to retrieve gym owners." };
  }
};

export const getTrainersController = async (gymId) => {
  try {
    const { data, success } = await getTrainers(gymId);
    if (!success) {
      return { success: false, message: "Failed to retrieve trainers." };
    }
    return { success: true, data };
  } catch (error) {
    console.log(error);
    return { success: false, message: "Failed to retrieve trainers." };
  }
};

export const getAllFreelanceTrainerController = async (userProfile) => {
  try {
    const { data, success } = await getAllFreelanceTrainer(userProfile);
    if (!success) {
      return {
        success: false,
        message: "Failed to retrieve freelance trainers.",
      };
    }
    return { success: true, data };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: "Failed to retrieve freelance trainers.",
    };
  }
};

export const getTrainersforBranchController = async (branchId) => {
  try {
    const { data, success } = await getTrainersforBranch(branchId);
    if (!success) {
      return {
        success: false,
        message: "Failed to retrieve trainers for branch.",
      };
    }
    return { success: true, data };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: "Failed to retrieve trainers for branch.",
    };
  }
};

export const getUsersController = async () => {
  try {
    const { data, success } = await getUsers();
    if (!success) {
      return { success: false, message: "Failed to retrieve users." };
    }
    return { success: true, data };
  } catch (error) {
    console.log(error);
    return { success: false, message: "Failed to retrieve users." };
  }
};

export const getUsersByRoleController = async (userProfileInfo) => {
  try {
    if (userProfileInfo.role.includes(4)) {
      // return all users 
      const { data, success } = await getUsersByRole(userProfileInfo);
      if (!success) {
        return { success: false, message: "Failed to retrieve users." };
      }
      return { success: true, data };
    } else if (userProfileInfo.role.includes(3) && userProfileInfo.company_id !== "") {
      // return only with same Company  ID 
      const { data, success } = await getUsersByRole(userProfileInfo);
      if (!success) {
        return { success: false, message: "Failed to retrieve users." };
      }
      return { success: true, data };
    } else if (userProfileInfo.role.includes(6) && userProfileInfo.company_id !== "") {
      // return only with same facility ID 
      const { data, success } = await getUsersByRole(userProfileInfo);
      if (!success) {
        return { success: false, message: "Failed to retrieve users." };
      }
      return { success: true, data };
    } else {
      return { success: false, message: "No Gym id found." };
    }}
     catch (error) {

      return { success: false, message: "Failed to retrieve users." };
    }

  //   if(!userProfileInfo.company_id == ""){
  //     const { data, success } = await getUsersByRole(userProfileInfo);
  //     if (!success) {
  //       return { success: false, message: "Failed to retrieve users." };
  //     }
  //     return { success: true, data };
  //   }else{
  //     return { success: false, message: "No Gym id found." };
  //   }
  // } catch (error) {
   
  //   return { success: false, message: "Failed to retrieve users." };
  // }
};

export const getAllUsersNewController = async (userProfileInfo) => {
  try {
    const { data, success } = await getAllUsersNew(userProfileInfo);
    if (!success) {
      return { success: false, message: "Failed to retrieve new users." };
    }
    return { success: true, data };
  } catch (error) {
    console.log(error);
    return { success: false, message: "Failed to retrieve new users." };
  }
};

export const updateUserController = async (id, updatedUser) => {
  await updateUser(id, updatedUser);
};

export const updateUserNewController = async (id, updatedUser) => {
  const { success, error } = await updateUserNew(id, updatedUser);
  if (!success) {
    return { success: false, error: error.message };
  }
  return { success: true, error: "" };
};

export const changeUserActivateStateController = async (id, state) => {
  const { error, success } = await changeUserActivateState(id, state);
  if (!success) {
    return { success: "", error: error.message };
  }
  return { success: "success", error: "" };
};

export const updateUserBranchesController = async (id, branches) => {
  const { error, success } = await updateUserBranches(id, branches);
  if (!success) {
    return { success: "", error: error.message };
  }
  return { success: "success", error: "" };
};

export const addUserAttachmentController = async (id, data) => {
  const { error, success } = await addUserAttachment(id, data);
  if (!success) {
    return { success: false, error: error.message };
  }
  return { success: true, error: "" };
};

export const removeUserAttachmentController = async (id, data) => {
  const { error, success } = await removeUserAttachment(id, data);
  if (!success) {
    return { success: false, error: error.message };
  }
  return { success: true, error: "" };
};

export const resetDeviceIDController = async (id) => {
  const { error, success } = await resetDeviceID(id);
  if (!success) {
    return { success: false, error: error.message };
  }
  return { success: true, error: "" };
};

export const updateUserImageController = async (uid, url) => {
  await updateUserImage(uid, url);
};

export const saveUserController = async (uid, user) => {
  await saveUser(uid, user);
};

export const createNewUserController=async(user)=>{
  await createNewUser(user)
}

export const getUserController = async (id) => {
  const user = await getUser(id);
  return user;
};
