import { db } from "../../repository/web/main";
import { doc, collection } from "firebase/firestore";

const collection_user = "users";
const collection_membership = "memberships";
const collection_gym = "gyms";
const main_gymCollection = "gym_main";
const coll_classes = "classes";
const coll_attendance = "attendance";
const coll_subscribers = "subscribers";
const userCollectionRef = collection(db, collection_user);
const membershipCollectionRef = collection(db, collection_membership);
const gymCollectionRef = collection(db, collection_gym);
const mainGymCollectionRef = collection(db, main_gymCollection);
const classesRef = collection(db, coll_classes);
const attendanceRef = collection(db, coll_attendance);
const subscriberRef = collection(db, coll_subscribers);

export const getCollectionRef = (collectionName, id) => {
  switch (collectionName) {
    case collection_user:
      return id ? doc(db, collection_user, id) : userCollectionRef;
    case collection_membership:
      return id ? doc(db, collection_membership, id) : membershipCollectionRef;
    case collection_gym:
      return id ? doc(db, collection_gym, id) : gymCollectionRef;
    case main_gymCollection:
      return id ? doc(db, main_gymCollection, id) : mainGymCollectionRef;
    case coll_classes:
      return id ? doc(db, coll_classes, id) : classesRef;
    case coll_attendance:
      return id ? doc(db, coll_attendance, id) : attendanceRef;
    case coll_subscribers:
      return id ? doc(db, coll_subscribers, id) : subscriberRef;
    default:
      throw new Error("Invalid collection name");
  }
};
