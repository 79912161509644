import { createSlice } from "@reduxjs/toolkit";

const ManageGym = createSlice({
  name: "ManageGym",
  initialState: {
    isAdd: false,
    filteredContent: [],
    searchText: "",
    gymOwners: [],
    loading: false,
    gyms: [],
    BranchDetailPage: {
      branchData: {},
      loading: false,
      showQrModal: false,
      formData: {
        name: "",
        nameError: false,
        gender: "",
        genderError: false,
        company: "-",
        address: "",
        images: [],
      },
    },
  },
  reducers: {
    setIsAdd: (state, action) => {
      state.isAdd = action.payload;
    },
    setFilteredContent: (state, action) => {
      state.filteredContent = action.payload;
    },
    setGyms: (state, action) => {
      state.gyms = action.payload;
    },
    setsearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setGymOwners: (state, action) => {
      state.gymOwners = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setBranchDetailPage: (state, action) => {},
  },
});

export const {
  setFilteredContent,
  setGymOwners,
  setGyms,
  setIsAdd,
  setLoading,
  setsearchText,
  setBranchDetailPage,
} = ManageGym.actions;

export default ManageGym.reducer;
