import React from "react";
import "./Section.scss";

export default function Section(props) {
  return (
    <div {...props} className={`section-container-com ${props.className}`}>
      {props.children}
    </div>
  );
}
