import React, { useEffect } from "react";
import "./Dragtrial.scss";

export default function Dragtrial() {
  var dragSrcEl = null;
  function dragStart(e) {
    this.style.opacity = 0.2;
    dragSrcEl = this;
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", this.innerHTML);
  }

  function dragEnd(e) {
    this.style.opacity = 1;
  }

  function dragEnter(e) {
    this.classList.add("over");
  }

  function dragLeave(e) {
    e.stopPropagation();
    this.classList.remove("over");
  }

  function dragOver(e) {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    return false;
  }

  function dragDrop(e) {
    if (dragSrcEl != this) {
      dragSrcEl.innerHTML = e.target.innerHTML;
      e.target.innerHTML = e.dataTransfer.getData("text/html");
    }
    this.classList.remove("over");
    return false;
  }
  useEffect(() => {
    var itemList = document.querySelectorAll(".draggable-items");
    [].forEach.call(itemList, (item) => {
      item.classList.add("items");
      item.addEventListener("dragstart", dragStart, false);
      item.addEventListener("dragend", dragEnd, false);
      item.addEventListener("dragenter", dragEnter, false);
      item.addEventListener("dragleave", dragLeave, false);
      item.addEventListener("drop", dragDrop, false);
      item.addEventListener("dragover", dragOver, false);
    });
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center w-100 h-100 text-primary"
      style={{ padding: "50px" }}
    >
      <ul>
        <li className="draggable-items" draggable>
          Ravi
        </li>
        <li className="draggable-items" draggable>
          Verma
        </li>
        <li className="draggable-items" draggable>
          Bike
        </li>
        <li className="draggable-items" draggable>
          car
        </li>
      </ul>
    </div>
  );
}
