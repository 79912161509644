import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import Slider from "@farbenmeer/react-spring-slider";
import StyledButton from "./../../items/StyledButton";
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";
import img_work from "../../../assets/images/home/gym-work.png";
import {FaSearchLocation} from "react-icons/fa";
import {IoMdDocument, IoMdDoneAll} from "react-icons/io";

const WorkPage = () => {
    const {t} = useTranslation();
    const [active, setActive] = useState("");

    const onSubscribe = () => {
        setActive("subscribe");
    };

    const onGymWhere = () => {
        setActive("gym_where");
    };

    const BulletComponent = ({onClick, isActive}) => (
        <li
            className="bullet"
            style={{
                opacity: !isActive && "0.5",
                backgroundColor: isActive ? "#000" : "transparent",
                transition: "all 0.3s ease-out",
                transform: isActive ? "scale(1.05)" : "",
            }}
            onClick={onClick}
        />
    );

    const ArrowComponent = ({onClick, direction}) => {
        return (
            <>
                {direction === "left" && (
                    <div className="arrow-bg" onClick={onClick}>
                        <AiOutlineArrowLeft className="arrow"/>
                    </div>
                )}
                {direction === "right" && (
                    <div className="arrow-bg" onClick={onClick}>
                        <AiOutlineArrowRight className="arrow"/>
                    </div>
                )}
            </>
        );
    };

    return (
        <Container id="work">
            <div className="content">
                <span className="content-header">{t("How vigory works")}</span>
                <span className="content-description">
                Vigory is an all-in-one application that provides unlimited access to multiple gym halls all over the United Arab Emirates. With only one subscription, get access to many gyms and fitness clubs around you, schedule a class with a personal trainer, and sign up for a fitness class.
              </span>
            </div>

        </Container>
    );
};

const Container = styled("div")`
  padding: 4em 7em;
  font-family: "CabinRegular";
  font-style: normal;
  /* min-height: 100vh; */

  .pricing {
    text-align: left;
    font-weight: bold;
    font-size: 1.1em;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #ffcc00;
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }


  .bullet {
    width: 0.6em;
    height: 0.6em;
    margin: -4.5em 0.4em;
  }
}

.describe-item {
  display: flex;
  flex-direction: column;
}

.image-pad {
  width: calc(100% - 376px - 120px);
  display: flex;
  flex-direction: column;
}

.content-header {

  font-weight: bold;
  font-size: 3.5em;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #ffcc00;
}

.content-description {
  font-size: 3vh;
  line-height: 160%;
  color: #ffffff;
  font-weight: normal;
  margin-top: 3em;
}

.content-list {
  font-size: 0.8em;
  line-height: 160%;
  color: #f2f2f2;
  font-weight: normal;
  padding-left: 1.3em;
  height: 11.5em;
  overflow: auto;

  li {
    margin-top: 1em;
  }

  &::-webkit-scrollbar {
    width: 0.4em;
  }

  &::-webkit-scrollbar-track {
    background-color: #888888;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #ffcc00;
    box-shadow: inset 0 0 0.4em rgba(0, 0, 0, 0.8);
  }
}

.arrow {
  width: 1.3em;
  height: 1em;
  color: #f2f2f2;
}

.arrow-bg {
  width: 2em;
  height: 2em;
  border: 2px solid #feca00;
  border-radius: 50%;
  margin-top: 13em;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: white;
    font-weight: 900;
    font-size: 1.3em;
  }
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.image {
  margin-top: 1.5em;
  width: 100%;
  max-height: 30em;
  border-radius: 2em;
}

.image-panel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  margin-top: 1em;
}

.content-phone {
  .content-items {
    height: 720px;
  }

  .describe-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header {
    font-size: 36px;
    line-height: 100%;
    text-align: center;
    color: #f2f2f2;
    max-width: 200px;
  }

  .image {
    max-width: 100%;
    max-height: 222px;
    margin-top: 0px;
  }

  .description {
    font-size: 14px;
    line-height: 160%;
    color: #888888;
    margin-top: 30px;
    min-height: 110px;
  }

  .content-list {
    font-size: 14px;
    line-height: 160%;
    color: #f2f2f2;
    font-weight: normal;
    padding-left: 20px;
    height: 180px;
    overflow: auto;

    li {
      margin-top: 16px;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: #888888;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: #ffcc00;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
    }
  }

  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .image-panel {
    margin-top: 30px;
  }

  .button {
    margin-right: 0px;
  }
}

.content-phone {
  display: none;
}

.bullet {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  margin: -90px 5px;
  border: 2px solid white;
}

@media (max-width: 1280px) {
  padding: 13px 80px;
  .button-group {
    flex-direction: column;
  }

  .image-pad {
    justify-content: space-evenly;
  }

  .describe {
    margin-right: 60px;
  }

  .image-pad {
    width: calc(100% - 376px - 60px);
  }
}

@media (max-width: 768px) {
  padding: 13px 60px;
  .pricing {
    text-align: center;
  }

  .content {
    display: none;
  }

  .content-phone {
    display: unset;
  }

  .arrow-bg {
    margin-top: 320px;
  }

  .bullet {
    margin: -10px 5px;
  }
}

@media (max-width: 576px) {
  padding: 13px 25px;
}
`;

export default WorkPage;
