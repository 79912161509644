import { createSlice } from "@reduxjs/toolkit";

const AddUserSlice = createSlice({
  name: "AddUserSlice",
  initialState: {
    isOpen: false,
    name: "",
    email: "",
    mobile: "",
    type: [],
    memberShipPlan: "",
    nameError: false,
    emailError: false,
    mobileError: false,
    typeError: false,
    membershipError: false,
    companyList: [],
    selectedCompany: "",
    companyError: false,
    branchList: [],
    selectedBranch: "",
    branchError: false,
    createSuccess: false,
    createError: false,
    errorMessage: "",
    disable: false,
    loading: false,
  },
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setMobile: (state, action) => {
      state.mobile = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setMembership: (state, action) => {
      state.memberShipPlan = action.payload;
    },
    setNameError: (state, action) => {
      state.nameError = action.payload;
    },
    setEmailError: (state, action) => {
      state.emailError = action.payload;
    },
    setMobileError: (state, action) => {
      state.mobileError = action.payload;
    },
    setTypeError: (state, action) => {
      state.typeError = action.payload;
    },
    setMembershipError: (state, action) => {
      state.membershipError = action.payload;
    },
    setCompanyList: (state, action) => {
      state.companyList = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    setCompanyError: (state, action) => {
      state.companyError = action.payload;
    },
    setBranchList: (state, action) => {
      state.branchList = action.payload;
    },
    setSelectedBranch: (state, action) => {
      state.selectedBranch = action.payload;
    },
    setBranchError: (state, action) => {
      state.branchError = action.payload;
    },
    setCreateSuccess: (state, action) => {
      state.createSuccess = action.payload;
    },
    setCreateError: (state, action) => {
      state.createError = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setDisable: (state, action) => {
      state.disable = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setBranchError,
  setBranchList,
  setCompanyError,
  setCompanyList,
  setCreateError,
  setCreateSuccess,
  setDisable,
  setEmail,
  setEmailError,
  setErrorMessage,
  setIsOpen,
  setLoading,
  setMembership,
  setMembershipError,
  setMobile,
  setMobileError,
  setName,
  setNameError,
  setSelectedBranch,
  setSelectedCompany,
  setType,
  setTypeError,
} = AddUserSlice.actions;

export default AddUserSlice.reducer;
