import {
  autoLogin,
  emailVerification,
  logInWithEmailAndPassword,
  logOut,
  loginWithApple,
  loginwithGoogleAccount,
  resetPassword,
  signUpWithEmailAndPassword,
  getUserById,
  addUserWithEmailAndName,
  getUsersByEmail,
} from "../../repository/web/js/firebaseAuth";

export const autoLoginController = async () => {
  const result = await autoLogin();
  return result;
};

export const getUserByIdController = async (id) => {
  try {
    const user = await getUserById(id);
    return user;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getUsersByEmailController = async (email) => {
  const result = await getUsersByEmail(email);
  return result;
};

export const addUserWithEmailAndNameController = async (
  username,
  displayName
) => {
  const result = await addUserWithEmailAndName(username, displayName);
  return result;
};

export const logInWithEmailAndPasswordController = async (email, password) => {
  try {
    const result = await logInWithEmailAndPassword(email, password);
    return result;
  } catch (error) {
    console.log(error);
    return { profile: "", error: error.code, token: "" };
  }
};

export const signUpWithEmailAndPasswordController = async (user, password) => {
  const signUpResult = await signUpWithEmailAndPassword(user, password);

  return signUpResult;
};

export const loginwithGoogleAccountController = async () => {
  const result = await loginwithGoogleAccount();
  return result;
};

export const loginWithAppleController = async () => {
  const result = await loginWithApple();
  return result;
};

export const resetPasswordController = async (userEmail) => {
  try {
    const result = await resetPassword(userEmail);
    return result;
  } catch (error) {
    console.log(error);
    return error.message;
  }
};
