import { setLoading } from "../../redux/slices/ManageMembership.slice";
import {
  setCreateError,
  setLoading as setEditMembershipLoading,
} from "../../redux/slices/EditMembership.slice";
import {
  addMembership,
  changeMembership,
} from "../../../../repository/web/js/membership";
import { useSelector, useDispatch } from "react-redux";

const useManageMembershipController = () => {
  const dispatch = useDispatch();
  const { description, name, priceMonth, attendancePrice, priceAnnual, color } =
    useSelector((state) => state.addMembership);
  const { isId, formData } = useSelector((state) => state.editMembership);
  const AddaMemberShip = async () => {
    const data = await addMembership(
      name,
      description,
      priceMonth,
      priceAnnual,
      attendancePrice,
      color
    );
    return data;
  };

  const EditaMembership = async () => {
    const data = await changeMembership(
      isId,
      formData.name,
      formData.description,
      formData.monthly_amount,
      formData.annually_amount,
      formData.attendancePrice,
      formData.color
    ).catch((e) => {
      dispatch(setEditMembershipLoading(true));
      dispatch(setLoading(false));
    });
    return data;
  };

  return {
    AddaMemberShip,
    EditaMembership,
  };
};

export default useManageMembershipController;
