import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BsPlusCircleFill } from "react-icons/bs";
import { IoMapSharp } from "react-icons/io5";
import { RiPencilFill, RiSearchEyeLine } from "react-icons/ri";
// import {
//   changeGymActivation,
//   getGyms,
// } from "../../../../common/repository/js/gym_main";
import { useLocation, useNavigate } from "react-router-dom";
// import { getGymOwners } from "../../../../common/repository/js/user";
import { getGymOwnersController } from "../../../../../../controllers/web/userController";
import CustomLoading from "../../../items/loadingBar";
import formatDate from "../../function/function";
import { useSelector } from "react-redux";
import AccessManager from "../../../custom/AccessManager/AccessManager";
import { Switch, ThemeProvider } from "@mui/material";
import { outerTheme } from "../../../global";
import ConfirmDialog from "../../../custom/ConfirmDialog/ConfirmDialog";
import InputBox from "../../../custom/InputBox/InputBox";
import {
  getGymsController,
  changeGymActivationController,
} from "../../../../../../controllers/web/gymMainController";
import { Msgs } from "../../../../../../common/data/msgs";

const TableRow = ({ content, openEdit, refresh }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(true);
  useEffect(() => {
    setActivate(content.approved);
  }, [content]);
  return (
    <React.Fragment>
      <div className="table-row">
        <div className="gymImage">
          <img
            src={
              content.tradeLicenseFilePath
                ? content.tradeLicenseFilePath
                : undefined
            }
            className="image-gym"
            alt="trade license"
          />
        </div>
        <span className="gymname">{content.name}</span>
        <span className="tradeLicenseNumber">{content.tradeLicenseNumber}</span>
        <span className="location">
          {content.tradeLicenseExpiryDate ? content.tradeLicenseExpiryDate : ""}
        </span>
        <div className="controls">
          <span
            className="edit"
            onClick={() => {
              openEdit(content);
            }}
          >
            <RiPencilFill className="icon" />
          </span>
          <div className="d-flex align-items-center">
            <ThemeProvider theme={outerTheme}>
              <Switch
                checked={activate}
                onChange={(e, c) => {
                  ConfirmDialog.show({
                    title: "Confirmation!",
                    message: `Are Sure to change company status?`,
                    okCallback: async () => {
                      ConfirmDialog.hide();
                      await changeGymActivationController(content.qrCode, c).then(
                          (res) => {
                            if (res?.success === "success") {
                              refresh();
                            }
                          }
                      );
                    },
                  });
                }}
                name="checkedA"
              />
              <div className={`${activate ? "text-light" : "text-secondary"}`}>
                Active
              </div>
            </ThemeProvider>
          </div>
        </div>
      </div>
      <div className="table-row-mobile">
        <div className="info">
          <div>
            <img
              src={content.tradeLicenseFilePath}
              className="image-gym"
              alt="tradeImage"
            />
          </div>
          <div className="text">
            <span className="gymname">{content.name}</span>
            <span className="tradeLicenseNumber">
              {content.tradeLicenseNumber}
            </span>
            <span className="">
              {content.tradeLicenseExpiryDate
                ? content.tradeLicenseExpiryDate
                : ""}
            </span>
          </div>
        </div>
        <div className="button-group">
          {/* <span className="map-view">
            <IoMapSharp className="icon" />
            {t("View on map")}
          </span> */}
          {/* <div className="controls">
            <span
              className="edit"
              onClick={() => {
                openEdit(content);
              }}
            >
              <RiPencilFill className="icon" />
              {t("Edit")}
            </span>
            <span
              className={content.approved ? "activate" : "activate disable"}
              onClick={() => {
                changeGymActivation(content.qrCode, !content.approved).then(
                  (res) => {
                    if (res?.success === "success") {
                      refresh();
                    }
                  }
                );
              }}
            >
              {content.approved ? t("Activate") : t("Deactive")}
            </span>
          </div> */}
          <div className="controls">
            <span
              className="edit"
              onClick={() => {
                openEdit(content);
              }}
            >
              <RiPencilFill className="icon" />
            </span>
            <div className="d-flex align-items-center">
              <ThemeProvider theme={outerTheme}>
                <Switch
                  checked={activate}
                  onChange={(e, c) => {
                    ConfirmDialog.show({
                      title: "Confirmation!",
                      message: `Are Sure to change company status?`,
                      okCallback: () => {
                        changeGymActivationController(content.qrCode, c).then(
                          (res) => {
                            if (res?.success === "success") {
                              refresh();
                              ConfirmDialog.hide();
                            }
                          }
                        );
                      },
                    });
                  }}
                  name="checkedA"
                />
                <div
                  className={`${activate ? "text-light" : "text-secondary"}`}
                >
                  Active
                </div>
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const ManageGymBranch = () => {
  const { t } = useTranslation();
  const { user: loggedinUser } = useSelector((state) => state.user);
  const [isAdd, setIsAdd] = useState(false);
  const [gyms, setGyms] = useState([]);
  const [content, setContent] = useState([]);
  const { path } = useLocation();
  const [gymOwners, setGymOwners] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const openGymMainDetailComponent = (content) => {
    // console.log("CONTENT IN OPEN EDIT : ", JSON.stringify(content));
    navigate("/maingymdetail", {
      state: { content: content, owners: gymOwners },
    });
  };

  const addGymMain = () => {
    if (loggedinUser?.activated) {
      navigate("/maingymdetail", {
        state: { owners: gymOwners, addNewComapny: "ADD COMPANY" },
      });
    } else {
      ConfirmDialog.show({ message: Msgs.NotActiveMsg, showFooter: false });
    }
  };

  const openAddModal = () => {
    setIsAdd(true);
  };

  const closeAddModal = () => {
    setIsAdd(false);
  };

  const getAllGyms = () => {
    setLoading(true);
    getGymsController().then((datas) => {
      let temp_gyms = [];
      datas.forEach((data) => {
        let temp = { ...data.data(), qrCode: data.id };
        temp_gyms.push(temp);
      });
      if (loggedinUser.role.includes(4)) {
        setGyms(temp_gyms);
        setContent(temp_gyms);
        //console.log(gyms);
      } else {
        let temp = [];
        temp_gyms.forEach((item) => {
          if (item.owner === loggedinUser.id) {
            temp.push(item);
          }
        });
        setGyms(temp);
        setContent(temp);
      }
      setLoading(false);
    });
  };

  const onSearch = (e) => {
    setSearch(e);
    let temp = [];
    gyms.forEach((item) => {
      if (item.name && item.name.toLowerCase().includes(e.toLowerCase())) {
        temp.push(item);
      }
    });
    setContent(temp);
  };

  useEffect(() => {
    getAllGyms();
    getGymOwnersController().then((response) => {
      setGymOwners(response.data);
    });
  }, [path]);

  return (
    <AccessManager fallback="dashboard" allowedRoles={[4]}>
      <Container>
        <div className="header">
          <div className="title-layout">
            <span className="title">{t("Companies list")}</span>
            {(loggedinUser.role.includes(3) ||
              loggedinUser.role.includes(4)) && (
              <span
                className="button"
                // onClick={openAddModal}
                onClick={addGymMain}
                style={{ cursor: "pointer" }}
                // close={closeAddModal}
              >
                <BsPlusCircleFill className="icon" />
                {t("Add Company")}
              </span>
            )}
          </div>
          <div className="d-flex ms-auto mt-3">
            <InputBox
              icon={<RiSearchEyeLine />}
              placeholder="Search..."
              value={search}
              onChange={(e) => onSearch(e.target.value)}
            />
          </div>
        </div>

        <CustomLoading isStart={loading} isFull={true} />

        <div className="content">
          <div className="table-header">
            <span className="gymImage">{t("Trade Licenses")}</span>
            <span className="gymname">{t("Company name")}</span>
            <span className="gymname">{t("License Number")}</span>
            <span className="location">{t("License expiry date")}</span>
            <span className="controls">{""}</span>
          </div>
          <div className="table-content">
            {content.map((item, index) => (
              <TableRow
                key={index}
                content={item}
                openEdit={openGymMainDetailComponent}
                refresh={getAllGyms}
              />
            ))}
          </div>
        </div>
        {/* <Addgym isOpen={isAdd} close={closeAddModal} refresh={getAllGyms} /> */}
        {/* <Addgymbranch
          isOpen={isAdd}
          close={closeAddModal}
          refresh={getAllGyms}
        /> */}
      </Container>
    </AccessManager>
  );
};

const Container = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #1c1a1d;
  @media (max-width: 1180px) {
    margin-top: 86px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    height: 30px;
    background-color: black;
    z-index: 100;
    .title {
      font-size: 24px;
      line-height: 120%;
      text-transform: uppercase;
      font-weight: bold;
      color: #f2f2f2;
      margin-right: 25px;
    }
    .button {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #feca00;
      .icon {
        font-size: 16px;
        margin-right: 14px;
      }
    }
    .title-layout {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .search {
      width: 260px;
      height: 50px;
      background: #1c1a1d;
      border: 1px solid #888888;
      box-sizing: border-box;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 20px;
      font-size: 18px;
      line-height: 100%;
      color: #3e3e3e;

      .icon {
        font-size: 22px;
        margin-right: 12px;
      }

      .input {
        width: 100%;
        background: #1c1a1d;
        border: none;
        color: #888888;

        &:focus-visible {
          outline: none;
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 18px 40px;

    .table-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.01em;
      color: #888888;
      padding-bottom: 10px;
      border-bottom: 1px solid #4f4f4f;
    }
    .gymImage {
      width: 100%;
      max-width: 180px;
      .image-gym {
        width: 50px;
        height: 50px;
        border-radius: 6px;
      }
    }
    .qrcode {
      width: 100%;
      max-width: 180px;
      .image {
        width: 90px;
        height: 90px;
      }
    }
    .gymname {
      width: 100%;
      max-width: 300px;
    }
    .tradeLicenseNumber {
      width: 100%;
      max-width: 300px;
    }
    .location {
      width: 100%;
      .map-icon {
        width: 44px;
        min-width: 44px;
        height: 44px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: white;
        margin-right: 10px;
        &:hover,
        &:active {
          background: #333333;
        }
      }
    }
    .controls {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 20px;
      align-items: center;
      .edit {
        cursor: pointer;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        background-color: #333333;
        color: #159cff;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:active,
        &:hover {
          cursor: pointer;
          color: #fff;
        }
      }
      .activate {
        cursor: pointer;
        width: 93px;
        height: 42px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 160%;
        background: #1e382a;
        color: #27ae60;
      }
      .activate.disable {
        cursor: pointer;
        background: #462224;
        color: #f03f3f;
      }
    }
    .table-content {
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 200px);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: #888888;
        border-radius: 100px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-color: #ffcc00;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
      }
    }
    .table-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 0px;
      border-bottom: 1px solid #4f4f4f;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.01em;
      color: #bfbfbf;
    }
    .table-row-mobile {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      height: unset;
      background: #1c1a1d;
      padding: 15px 20px;

      .title-layout {
        justify-content: space-between;
      }

      .search {
        margin-top: 15px;
        width: 100%;
      }
    }
    .content {
      padding: 10px;
      .table-header {
        display: none;
      }
      .table-row {
        display: none;
      }
      .table-content {
        max-height: unset;
        overflow: none;
      }
      .table-row-mobile {
        display: flex;
        border: 1px solid #4f4f4f;
        border-radius: 15px;
        margin-bottom: 10px;
        padding: 18px;
        flex-direction: column;

        .image-gym {
          width: 80px;
          height: 80px;
          border-radius: 15px;
          margin-right: 10px;
        }
        .image {
          width: 70px;
          height: 70px;
        }
        .info {
          display: flex;
          flex-direction: row;
          align-items: center;

          .text {
            display: flex;
            flex-direction: column;
            margin-left: 24px;
            .gymname {
              font-size: 18px;
              line-height: 22px;
              color: #d6d6d6;
            }
            span {
              color: #ddd;
            }
            .location {
              font-size: 14px;
              line-height: 160%;
              letter-spacing: 0.01em;
              color: #888888;
            }
          }
        }
        .button-group {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .map-view {
            font-size: 14px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #f6f6f6;
            .icon {
              font-size: 20px;
              margin-right: 6px;
            }
          }
          .controls {
            display: flex;
            flex-direction: row;
            .edit {
              width: 74px;
              height: 34px;
              background-color: #333333;
              color: #2f80ed;
              border-radius: 10px;
              font-size: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 10px;
              .icon {
                margin-right: 5px;
                font-size: 18px;
              }
              &:active,
              &:hover {
                background-color: #1f2f46;
              }
            }
            .activate {
              width: 83px;
              height: 34px;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              line-height: 160%;
              background: #1e382a;
              color: #27ae60;
            }
            .activate.disable {
              background: #462224;
              color: #f03f3f;
            }
          }
        }
      }
    }
  }
`;

export default ManageGymBranch;
