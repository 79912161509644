import { createSlice } from "@reduxjs/toolkit";

const ManageMembershipSlice = createSlice({
  name: "ManageMembershipSlice",
  initialState: {
    activate: true,
    isEdit: false,
    content: [],
    selectedContentToEdit: [],
    draggedContent: [],
    loading: false,
    isId: "",
    errorObject: {},
  },
  reducers: {
    setActivate: (state, action) => {
      state.activate = action.payload;
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    setContent: (state, action) => {
      state.content = action.payload;
    },
    setSelectedContentToEdit: (state, action) => {
      state.selectedContentToEdit = action.payload;
    },
    setdraggedContent: (state, action) => {
      state.draggedContent = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsId: (state, action) => {
      state.isId = action.payload;
    },
    setErrorObject: (state, action) => {
      state.errorObject = action.payload;
    },
  },
});

export const {
  setActivate,
  setContent,
  setErrorObject,
  setIsEdit,
  setIsId,
  setLoading,
  setSelectedContentToEdit,
  setdraggedContent,
} = ManageMembershipSlice.actions;
export default ManageMembershipSlice.reducer;
