import React, { useContext, useState, useEffect } from "react";
import Header from "../item/Header";
import GymDetailComponent from "../ManageGymMain/GymDetailComponent";
import ReactImageUploading from "react-images-uploading";
import UserContext from "../../../../context/UserContext";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { MdDelete, MdChangeCircle } from "react-icons/md";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { UserTypeArray } from "../../../../../../common/data/UserTypes";


// import { updateUserImage } from "../../../../common/repository/js/user";
// import { getMembershipNames } from "../../../../common/repository/js/membership";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PageSection, {
  PageSectionBody,
} from "../../../custom/PageSection/PageSection";
import PageHeader from "../../../custom/PageHeader/PageHeader";
import UserDetailPageNew from "../UserDetailPage/UserDetailPageNew";
import GymownerDetailView from "../ManageGymMain/detailGymOwner/gymowner.detailview";
import AccessManager, {
  AccessContext,
} from "../../../custom/AccessManager/AccessManager";

const Setting = () => {
  const { t } = useTranslation();
  const { user: userProfile } = useSelector((state) => state.user);

  return (
    <AccessManager allowedRoles={[1, 2, 3, 4, 5, 6]}>
      <PageSection>
        <PageSectionBody>
          <UserDetailPageNew />
          {userProfile?.role?.includes(3) && <GymownerDetailView />}
        </PageSectionBody>
      </PageSection>
    </AccessManager>
  );
};

export default Setting;
