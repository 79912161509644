import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import "react-time-picker-input/dist/components/TimeInput.css";
import CustomSelect from "../../../item/CustomSelect";
// import {
//   getAllFreelanceTrainer,
//   getTrainersforBranch,
// } from "../../../../../../common/repository/js/user";
import {
  getAllFreelanceTrainerController,
  getTrainersforBranchController,
} from "../../../../../../../../controllers/web/userController";
// import { updateClass } from "../../../../../../repository/web/js/classes.service";
import { updateClassController } from "../../../../../../../../controllers/web/classesController";
import { useSelector } from "react-redux";
import InputBox from "../../../../../custom/InputBox/InputBox";
import {
  formatDateHTML5,
  getDaysBetween,
  getHoursBetween,
} from "../../../../../global";
import Divider from "../../../../../custom/Divider/Divider";
import { useLocation } from "react-router";

const EditClass = (props) => {
  const { t } = useTranslation();
  const { user: userProfile } = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const user = JSON.parse(
    localStorage.getItem("state") || localStorage.getItem("branchDoc")
  );
  const [isOpen, setIsOpen] = useState(false);
  const [createError, setCreateError] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [trainersList, settrainersList] = useState([]);
  const [trainer, settrainer] = useState("");

  const [formData, setformData] = useState({
    name: "",
    nameError: false,
    capacity: "1",
    capacityError: false,
    description: "",
    descriptionError: false,
    startDate: props.content.starts_date,
    startDateError: false,
    endDate: props.content.ends_date,
    endDateError: false,
    duration: "1",
    durationUnit: "DAY",
    startTime: "10:00",
    startTimeError: false,
    endTime: "11:00",
    endTimeError: false,
    scheduleTime: "1",
    timeUnit: "HOURS",
    trainersError: false,
    address: "",
    selectedTrainer: ""
  });

  const initStates = () => {
    //console.log(formatDateHTML5(props.content.starts_date));
    setformData({
      name: props.content.name || "",
      nameError: false,
      capacity: props.content.capacity || "",
      capacityError: false,
      description: props.content.description,
      descriptionError: false,
      address: props.content.address || "",
      startDate: formatDateHTML5(props.content.starts_date),
      startDateError: false,
      endDate: formatDateHTML5(props.content.ends_date),
      endDateError: false,
      duration: props.content.duration || "0",
      durationUnit: "DAY",
      startTime: props.content.start_time || "",
      startTimeError: false,
      endTime: props.content.end_time || "",
      endTimeError: false,
      scheduleTime: props.content.daily_time || "0",
      timeUnit: props.content.time_unit || "HOURS",
      trainersError: false,
      address: props.content.address,
      selectedTrainer: "",
    });
  };

  useEffect(async () => {
    if (userProfile.role.includes(2)) {
      settrainersList([
        { value: userProfile.id, label: userProfile.sure_name },
      ]);
    } else if (
      userProfile.role.includes(4) &&
      pathname.includes("freelanceclasses")
    ) {
      const freelance_trainer = await getAllFreelanceTrainerController(userProfile.company_id);
      if (freelance_trainer.success) {
        settrainersList(freelance_trainer.data);
      }
    } else {
      getTrainersforBranchController(userProfile.company_id).then((res) => {
        settrainersList(res.data);
      });
    }
  }, []);

  useEffect(() => {
    setIsOpen(props.isOpen);
    initStates();
  }, [props]);

  const resetStates = () => {
    setIsSaving(false);
    setIsCreated(false);
  };
  const hideModal = () => {
    resetStates();
    emptyMessage();
    setIsOpen(false);
    if (isCreated) {
      props.refresh();
    }
    props.close();
  };
  const emptyMessage = () => {
    setCreateError(false);
    setCreateSuccess(false);
  };
  const onSave = () => {
    if (
      formData.name.trim().length === 0 ||
      formData.capacity.trim().length === 0 ||
      formData.description.trim().length === 0 ||
      formData.startDate.trim().length !== 10 ||
      formData.endDate.trim().length !== 10 ||
      formData.startTime.trim().length !== 5 ||
      formData.endTime.trim().length !== 5 ||
      formData.selectedTrainer.length <= 0 ||
      formData.duration === "0" ||
      formData.scheduleTime == 0
    ) {
      setformData({
        ...formData,
        nameError: formData.name.trim().length === 0,
        capacityError: formData.capacity.trim().length === 0,
        descriptionError: formData.description.trim().length === 0,
        startDateError: formData.startDate.trim().length !== 10,
        endDateError: formData.endDate.trim().length !== 10,
        startTimeError: formData.startTime.trim().length !== 5,
        endTimeError: formData.endTime.trim().length !== 5,
        trainersError:
          formData.selectedTrainer && formData.selectedTrainer?.length <= 0,
      });
      if (formData.duration === "0") {
        setformData({
          ...formData,
          startDateError: true,
          endDateError: true,
        });
      }
      if (formData.scheduleTime == 0) {
        setformData({
          ...formData,
          startTimeError: true,
          endTimeError: true,
        });
      }
      return;
    }
    if (isSaving) return;
    setIsSaving(true);
    updateClassController(props.content.id, {
      name: formData.name,
      capacity: formData.capacity,
      description: formData.description,
      starts_date: formData.startDate,
      ends_date: formData.endDate,
      duration: formData.duration,
      start_time: formData.startTime,
      end_time: formData.endTime,
      daily_time: formData.scheduleTime,
      time_unit: formData.timeUnit,
      trainer: formData.selectedTrainer,
      // status: "open",
      activated: props.content.activated ? props.content.activated : false,
    }).then((data) => {
      setIsSaving(false);
      hideModal();
      props.refresh();
    });
  };

  useEffect(() => {
    const _duration = getDaysBetween(
      new Date(formData.startDate),
      new Date(formData.endDate)
    );
    setformData({ ...formData, duration: _duration || "0" });
  }, [formData.startDate, formData.endDate]);

  useEffect(() => {
    const { time, unit } = getHoursBetween(
      formData.startTime,
      formData.endTime
    );
    setformData({
      ...formData,
      scheduleTime: time || 0,
      timeUnit: unit || "HOURS",
    });
  }, [formData.startTime, formData.endTime]);

  const onDateChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const onTimeChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <Modal
      show={isOpen}
      onHide={hideModal}
      centered
      dialogClassName="login-modal"
    >
      <Container>
        <div className="content-row">
          <div className="content-1">
            <div className="header">{t("Edit Class")}</div>
            <div className="content">
              <InputBox
                error={formData.nameError}
                label="Class Title"
                placeholder="name/title of class"
                value={formData.name}
                maxLength={50}
                onChange={(e) =>
                  setformData({ ...formData, name: e.target.value })
                }
              />
              <InputBox
                error={formData.capacityError}
                type="number"
                label="Capacity"
                maxLength={4}
                placeholder="No. of candidates"
                value={formData.capacity}
                onChange={(e) =>
                  setformData({ ...formData, capacity: e.target.value })
                }
              />
              <InputBox
                error={formData.descriptionError}
                type="textarea"
                label="Description"
                maxLength={300}
                placeholder="Describe your class"
                value={formData.description}
                onChange={(e) =>
                  setformData({ ...formData, description: e.target.value })
                }
              />
              <Divider>
                Class duration
                <span>
                  <span className="text-warning h4">{formData.duration}</span>{" "}
                  {formData.durationUnit}
                </span>
              </Divider>
              <div className="form-row">
                <div className="field-1">
                  <InputBox
                    error={formData.startDateError}
                    label="Start Date"
                    type="date"
                    name="startDate"
                    value={formData.startDate}
                    onChange={onDateChange}
                  />
                </div>
                <div className="field-1">
                  <InputBox
                    error={formData.endDateError}
                    label="End Date"
                    type="date"
                    name="endDate"
                    value={formData.endDate}
                    onChange={onDateChange}
                  />
                </div>
              </div>
              <Divider>
                Daily Time Schedule
                <span>
                  <span className="text-warning h4">
                    {formData.scheduleTime}
                  </span>{" "}
                  {formData.timeUnit}
                </span>
              </Divider>
              <div className="form-row">
                <div className="field-1">
                  <InputBox
                    error={formData.startTimeError}
                    label="Start Time"
                    type="time"
                    name="startTime"
                    value={formData.startTime}
                    onChange={onTimeChange}
                  />
                </div>
                <div className="field-1">
                  <InputBox
                    error={formData.endTimeError}
                    label="End Time"
                    type="time"
                    name="endTime"
                    value={formData.endTime}
                    onChange={onTimeChange}
                  />
                </div>
              </div>
              <Divider marginTop="10px">More Info</Divider>
              {/* <InputBox className="disabled" label="Address" value={formData.address} /> */}
              <span className="label">{t("Trainer")}</span>
              <CustomSelect
                className={
                  formData.trainersError ? "input-new error" : "input-new"
                }
                options={trainersList}
                value={trainer}
                onChange={(e) => {
                  // setformData({ ...formData, selectedTrainers: e });
                  settrainer(e);
                  setformData((prevData) => ({
                    ...prevData,
                    selectedTrainer: e,
                  }));
                }}
                // isMulti={pathname.includes("freelanceclasses") ? false : true}
                isSearchable={true}
              />
            </div>
            <div className="message">
              {createError && (
                <span className="error-msg">
                  {t("Cannot create a new membership. Try again.")}
                </span>
              )}
              {createSuccess && (
                <span className="success-msg">
                  {t("New Membership is created successfully.")}
                </span>
              )}
            </div>
            <div className="button-group">
              <span className="button" onClick={hideModal}>
                {t("Cancel")}
              </span>
              <span
                className={isSaving ? "button" : "button active"}
                onClick={onSave}
              >
                {t("Save")}
              </span>
            </div>
          </div>
        </div>
      </Container>
    </Modal>
  );
};

const Container = styled("div")`
  width: 528px;
  height: 100%;
  border-radius: 20px;
  background: black;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border: 1px solid #88888840;
  box-shadow: 0px 0px 45px 0px #88888820;

  .content-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .content-1 {
    width: 100%;
    padding: 35px;
  }

  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-equal;
    width: 100%;
    gap: 10px 8px;
  }

  .field-1 {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .react-datetime-picker,
  .react-datetime-picker *,
  .react-datetime-picker *:before,
  .react-datetime-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-datetime-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-datetime-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 1px solid #888888;
    border-radius: 8px;
    height: 42px;
    color: #888888;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .react-datetime-picker__inputGroup {
    min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
  }
  .react-datetime-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-datetime-picker__inputGroup__input {
    min-width: 0.54em;
    height: calc(100% - 2px);
    color: #888888;
    position: relative;
    padding: 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
  .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-datetime-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-datetime-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-datetime-picker__inputGroup__amPm {
    font: inherit;
    -moz-appearance: menulist;
  }
  .react-datetime-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
    color: #888888;
  }
  .react-datetime-picker__button:enabled {
    cursor: pointer;
  }
  .react-datetime-picker__button:enabled:hover
    .react-datetime-picker__button__icon,
  .react-datetime-picker__button:enabled:focus
    .react-datetime-picker__button__icon {
    stroke: #888888;
  }
  .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
    stroke: #888888;
  }
  .react-datetime-picker__button svg {
    display: inherit;
    stroke: #888888;
  }
  .react-datetime-picker__calendar,
  .react-datetime-picker__clock {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }
  .react-datetime-picker__calendar--closed,
  .react-datetime-picker__clock--closed {
    display: none;
  }
  .react-datetime-picker__calendar {
    width: 350px;
    max-width: 100vw;
  }
  .react-datetime-picker__calendar .react-calendar {
    border-width: thin;
  }
  .react-datetime-picker__clock {
    width: 200px;
    height: 200px;
    max-width: 100vw;
    padding: 25px;
    background-color: white;
    border: thin solid #a0a096;
  }
  .error {
    border-color: red !important;
  }
  .header {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: #f2f2f2;
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  .label {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #888888;
    margin-bottom: 8px;
  }
  .input {
    color: #888888;
    background: #1c1a1d;
    border-radius: 8px;
    border: 1px solid #888888;
    height: 38px;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  .button {
    cursor: pointer;
    width: 110px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f2f2f2;
    font-size: 18px;
    line-height: 22px;
    border-radius: 8px;
    border: 1px solid #888888;
    margin-left: 16px;
  }
  .button.active {
    text-transform: uppercase;
    color: #000000;
    background: #feca00;
    border: none;
  }
  .button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 40px;
  }
  .description {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 140px;
    padding: 10px 0px;

    li {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #f2f2f2;
      margin-bottom: 6px;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: #888888;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: #ffcc00;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
    }
  }
  .description-layout {
    display: flex;
    flex-direction: column;
    height: 250px;
    margin-bottom: 20px;
  }
  .create-description {
    display: flex;
    flex-direction: column;
  }
  .disable {
    display: none;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 160%;
    color: #888888;
    margin-top: 10px;
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .create-container {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
    .add {
      color: #feca00;
    }
    .icon {
      margin-right: 8px;
      font-size: 18px;
    }
  }

  .time-layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  .message {
    display: flex;
    align-items: center;
    margin-top: 10px;
    height: 20px;
  }
  .error-msg {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: red;
  }
  .success-msg {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #f2f2f2;
  }
  .react-time-input-picker-wrapper {
    border-radius: 12px;
    height: 50px;
    width: 40%;
    border: 1px solid #888888;
    color: #888888;
  }
  .react-time-input-picker {
    color: #888888 !important;
  }
  .react-time-input-picker input {
    color: #888888;
  }
  .arrow {
    color: #888888;
    font-size: 18px;
  }
  @media (max-width: 768px) {
    width: 90%;
    height: unset;
    .header {
      margin-bottom: 20px;
    }

    .content-row {
      flex-direction: column;
    }
    .content-1 {
      width: 90%;
      padding: 20px;
    }
    .content-2 {
      width: 100%;
      min-height: 400px;
    }

    .button {
      margin-right: 0px;
    }
    .button-group {
      justify-content: space-between;
      margin-top: 30px;
    }
  }
`;

export default EditClass;
