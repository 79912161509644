import firebase, { auth, db } from "../main";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  setDoc,
  getDocs,
  getDoc,
  deleteDoc,
  collectionGroup,
  query,
  arrayUnion,
  arrayRemove,
  where,
  limit,
} from "firebase/firestore";
import { getCollectionRef } from "../../../common/Helpers/GetCollectionRef";

const coll_attendance = "attendance";
const coll_maingym = "gym_main";
const coll_branches = "gyms";
const coll_memberships = "memberships";
const coll_users = "users";
const coll_classes = "classes";

export const getAnalyticsData = (role, mainGymId) => {
  return new Promise(async (resolve, reject) => {
    let temp = {
      maingym: 0,
      branches: 0,
      customers: 0,
      membershipplans: 0,
      attendance: 0,
      classes: 0,
      trainers: 0,
    };
    if (role && role === 4) {
      // Admin analytics
      let mainGymData = await getDocs(getCollectionRef(coll_maingym));
      if (mainGymData.size > 0) {
        temp = { ...temp, maingym: mainGymData.size };
      }
      let branchesData = await getDocs(getCollectionRef(coll_branches));
      if (branchesData.size > 0) {
        temp = { ...temp, branches: branchesData.size };
      }
      let customersData = await getDocs(
        query(getCollectionRef(coll_users), where("role", "array-contains", 5))
      );
      if (customersData.size > 0) {
        temp = { ...temp, customers: customersData.size };
      }
      let membershipplanData = await getDocs(
        getCollectionRef(coll_memberships)
      );
      if (membershipplanData.size > 0) {
        temp = { ...temp, membershipplans: membershipplanData.size };
      }
      let classesData = await getDocs(getCollectionRef(coll_classes));
      if (classesData.size > 0) {
        temp = { ...temp, classes: classesData.size };
      }
      let attendanceData = await getDocs(getCollectionRef(coll_attendance));
      if (attendanceData.size > 0) {
        temp = { ...temp, attendance: attendanceData.size };
      }
      let trainersData = await getDocs(
        query(collection(db, coll_users), where("role", "array-contains", 1))
      );
      if (trainersData.size > 0) {
        temp = { ...temp, trainers: trainersData.size };
      }
    } else if ((role && role === 3) || role === 6) {
      // Gym Owner || Gym Manager Analytics
      let mainGymData = await getDoc(getCollectionRef(coll_maingym, mainGymId));
      if (mainGymData.exists()) {
        temp = { ...temp, maingym: 1 };
      }
      let branchesData = await getDocs(
        query(
          getCollectionRef(coll_branches),
          where("mainBranchID", "==", mainGymId)
        )
      );
      if (branchesData.size > 0) {
        temp = { ...temp, branches: branchesData.size };
      }
      let customersData = await getDocs(
        query(getCollectionRef(coll_users), where("role", "array-contains", 5))
      );
      if (customersData.size > 0) {
        temp = { ...temp, customers: customersData.size };
      }
      let trainersData = await getDocs(
        query(
          getCollectionRef(coll_users),
          where("role", "array-contains", 1),
          where("gym_id", "==", mainGymId)
        )
      );
      if (trainersData.size > 0) {
        temp = { ...temp, trainers: trainersData.size };
      }
    }
    resolve(temp);
  });
};
