import React, { useRef, useState } from "react";
import imagesExtentions from "../../../../../common/data/imagesExtentions";

var FileUploader = (_a) => {
  const [selectedFiles, setselectedFiles] = useState([]);
  const [err, setErr] = useState(null);
  const inputRef = useRef();
  const gacpT = (_types) => {
    let _r = "image/*";
    if (_types && _types.length > 0) {
      _r = "";
      let _tp = [];
      _types.forEach((_t) => {
        _tp.push("." + _t);
      });
      _acptA = _tp;
      _r = _tp.join(", ");
    }
    return _r;
  };
  var C = typeof _a.children === "function" ? _a.children : void 0,
    oc = _a.onChange || void 0,
    acpT = gacpT(_a.acceptTypes),
    _acptA = [],
    _ers = null,
    _invlidFmg = "";

  const ihOc = (e) => {
      const files = e.target.files;
      if (files && files.length > 0) {
        _invlidFmg = "Invalid file type.";
        let _vlidFtps = imagesExtentions;
        let _vlid = false;
        setErr(null);
        let regexM = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;
        const _ex = files[0].name.match(regexM);
        _acptA = acpT.split(", ");
        if (_acptA.length > 0) {
          if (_acptA.includes(_ex[0])) {
            _vlid = true;
          }
        } else {
          if (_vlidFtps.includes(_ex[0])) {
            _vlid = true;
          }
        }
        _vlid
          ? (() => {
              oc(files);
              setselectedFiles(files);
            })()
          : (() => {
              setErr(_invlidFmg);
            })();
      }
    },
    ihFo = (e) => {
      inputRef.current.value = "";
      inputRef.current.click();
    },
    ihOr = () => {
      setselectedFiles([]);
      oc([]);
    };

  return (
    <>
      <input
        ref={inputRef}
        type={"file"}
        style={{ display: "none" }}
        onChange={ihOc}
        accept={acpT}
      />
      {(() => {
        if (typeof C === "function") {
          return <C onSelect={ihFo} onRemove={ihOr} error={err} />;
        } else {
          console.log("FileUploader children must be a function.");
        }
      })()}
    </>
  );
};
export default FileUploader;
