import styled from "styled-components";

const Divider = styled("div")`
  width: 100%;
  height: max-content;
  background-color: transparent;
  border-bottom: 1px solid #00000050;
  box-shadow: 0px 1px 0px #ffffff50;
  display: flex;
  margin: 0px 0px 10px 0px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 5px;
  color: ${(props) => (props.color ? props.color : "#FFF")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  justify-content: space-between;
  align-items: center;
`;
export default Divider;
