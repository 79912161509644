import React, { useState, useEffect } from "react";
import ReactImageUploading from "react-images-uploading";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdDelete, MdChangeCircle } from "react-icons/md";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import memberShipImage from "../../../../assets/images/dasbhboard/memberShipImage.jpg";
import CustomLoading from "../../../items/loadingBar";
// import {
//   saveGymInfo,
//   createGymId,
//   updateMemberShipCard,
//   getMainGymByOwnerID,
//   updateGymInfo,
// } from "../../../../common/repository/js/gym_main";
import {
  saveGymInfoController,
  createGymIdController,
  updateMemberShipCardController,
  getMainGymByOwnerIDController,
  updateGymInfoController,
} from "../../../../../../controllers/web/gymMainController";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { Col, Row, Table } from "react-bootstrap";
import InputBox from "../../../custom/InputBox/InputBox";
import CustomSelect from "../item/CustomSelect";
// import {
//   getMembership,
//   getMembershipNames,
// } from "../../../../repository/web/js/membership";
import {
  getMembershipController,
  getMembershipNamesController,
} from "../../../../../../controllers/web/memberShipController";
import { useSelector } from "react-redux";
import AccessManager from "../../../custom/AccessManager/AccessManager";
import Divider from "../../../custom/Divider/Divider";

toast.configure();

const GymDetailComponent = () => {
  // Utils
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [gymInfo, setGymInfo] = useState(null);
  const [saveTradeLicenseImage, setGymSaveImage] = useState("");
  const { user: userProfile } = useSelector((state) => state.user);
  const gym = location?.state?.content || "";
  // const ownersOptions = location.state.owners;

  // Form Inputs
  const [tradeLicenseNumber, setTradeLicenseNumber] = useState(
    gymInfo?.tradeLicenseNumber
  );
  const [companyName, setCompanyName] = useState(gymInfo?.name);
  const [tradeLicenseImage, setTradeLicenseImages] = useState([]);
  const [memberShipCardImage, setMemberShipCardImages] = useState([]);

  const [formDataMain, setFormDataMain] = useState({
    companyName: "",
    tradeLicenseNo: "",
    tradeLicenseExpiry: "",
    tradeLicenseImage: "",
    membershipInfo: {},
    visitCost: "0",
    attendanceCost: "0",
    iban: "",
    bankname: "",
  });

  // Error Messages
  const [tradeLicenseNumberError, setTradeLicenseNumberError] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);

  useEffect(() => {
    getGymInfoAndSetData();
  }, []);

  useEffect(() => {
    if (gym == "") {
      return;
    }
    if (gymInfo) {
      setFormDataMain({
        companyName: gymInfo.name,
        tradeLicenseNo: gymInfo.tradeLicenseNumber,
        tradeLicenseExpiry: gymInfo?.tradeLicenseExpiryDate,
        tradeLicenseImage: gymInfo.tradeLicenseFilePath,
        membershipInfo: gymInfo?.memberShipPlan,
        visitCost: gymInfo?.visitCost,
        attendanceCost: gymInfo?.attendanceCost,
        iban: gymInfo?.iban || "",
        bankname: gymInfo?.bankname || "",
      });
    }
  }, [gymInfo]);

  const updateMainData = (data) => {
    setFormDataMain(data);
  };

  const onChange = (setTempImage, imageList, addUpdateIndex) => {
    setTempImage(imageList);
  };
  const uploadGymImage = () => {
    const imageFile = tradeLicenseImage[0].file;
    const storage = getStorage();
    const userRef = ref(storage, `/gym/${tradeLicenseNumber}`);
    const uploadTask = uploadBytesResumable(userRef, imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setLoading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        setTradeLicenseImages([]);
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setGymSaveImage(downloadURL);
          getGymInfoAndSetData();
        });
      }
    );
  };
  const uploadMemberImage = () => {
    const imageFile = memberShipCardImage[0].file;
    const storage = getStorage();
    // const userRef = ref(storage, `/users/${new Date().valueOf().toString()}`);
    const userRef = ref(
      storage,
      `/membershipcards/${gymInfo?.owner || new Date().valueOf().toString()}`
    );
    const uploadTask = uploadBytesResumable(userRef, imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setLoading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        setLoading(false);

        setMemberShipCardImages([]);
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          updateMemberShipCardController(gymInfo.id, downloadURL);
          getGymInfoAndSetData();
        });
      }
    );
  };
  const onSubmits = async () => {
    // alert('I dont know')
    if (tradeLicenseNumber === "" || companyName === "") {
      setCompanyNameError(companyName === "");
      setTradeLicenseNumberError(tradeLicenseNumber === "");
      return;
    }
    if (saveTradeLicenseImage === "") {
      toast.info("First, please upload image", "Infomation", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    setLoading(true);
    const id = await createGymIdController();
    saveGymInfoController(
      id.id,
      companyName,
      tradeLicenseNumber,
      saveTradeLicenseImage
    ).then((data) => {
      if (data.success === "success") {
        toast.success("Submit Success", "Success", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        getGymInfoAndSetData();
      } else {
        toast.error("Something Wrong", "Error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setLoading(false);
      }
    });
  };

  const getGymInfoAndSetData = () => {
    setLoading(true);
    if (userProfile.role.includes(4)) {
      getMainGymByOwnerIDController(gym.owner).then((gym) => {
        setGymInfo({ id: gym.id, ...gym.data() });
        if (gym.data().tradeLicenseFilePath)
          setGymSaveImage(gym.data().tradeLicenseFilePath);
        setLoading(false);
      });
    } else {
      // console.log("Getting Gym INfo owner's logic");
      getMainGymByOwnerIDController(userProfile.id)
        .then((gym) => {
          // console.log(userProfile.id, gym);
          if (gym) {
            setGymInfo(gym?.data());
            if (gym?.data().tradeLicenseFilePath)
              setGymSaveImage(gym?.data().tradeLicenseFilePath);
          }
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };
  const AdminFormFragment = (props) => {
    const [formData, setFormData] = useState({
      companyName: props.data?.companyName || "",
      tradeLicenseNo: props.data?.tradeLicenseNo || "",
      tradeLicenseExpiry: props.data?.tradeLicenseExpiry || "",
      iban: props.data?.iban || "",
      bankname: props.data?.bankname || "",
      membershipInfo: props.data?.membershipInfo || {},
      visitCost: props.data?.visitCost || "0",
      attendanceCost: props.data?.attendanceCost || "0",
    });
    const [options, setOptions] = useState([]);
    const [itsModifiable, setitsModifiable] = useState(false);

    const handleMembershipChange = (e) => {
      if (e) {
        const id = e.value || null;
        if (!id) return;
        getMembershipController(id).then((res) => {
          //console.log("Selected membership : ", res);

          setFormData({
            ...formData,
            visitCost: res.data.oneDayPrice,
            attendanceCost: res.data.attendancePrice,
            membershipInfo: e,
          });
        });
      }
    };

    const onUpdate = async () => {
      // alert('I dont know')
      if (formData.companyName.trim().length <= 0) {
      }
      if (saveTradeLicenseImage === "") {
        toast.info("First, please upload image", "Infomation", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        return;
      }
      setLoading(true);
      const id = gymInfo?.id;
      updateGymInfoController(id, formData).then((data) => {
        if (data.success === "success") {
          toast.success("Updated Success", "Success", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          getGymInfoAndSetData();
        } else {
          toast.error("Something Wrong", "Error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setLoading(false);
        }
      });
    };

    const handleOnSubmit = () => {
      props.onUpdate(formData);
      onSubmits();
    };
    const handleOnUpdate = () => {
      props.onUpdate(formData);
      onUpdate();
    };
    useEffect(() => {
      if (gym == "") {
        return;
      }
      getMembershipNamesController().then((res) => {
        // console.log(res);
        setOptions(res.data);
      });
    }, []);
    return (
      <Row>
        <Divider>Company Details</Divider>
        <Col lg={12}>
          <InputBox
            error={formData.companyName.trim().length <= 2}
            label={t("Company Name")}
            required
            minLength={3}
            maxLength={75}
            value={formData.companyName}
            onChange={(e) =>
              setFormData({ ...formData, companyName: e.target.value })
            }
          />
        </Col>
        <Col lg={12}>
          <InputBox
            error={formData.tradeLicenseNo.trim().length <= 2}
            label={t("Trade License No.")}
            required
            minLength={3}
            maxLength={75}
            value={formData.tradeLicenseNo}
            onChange={(e) =>
              setFormData({ ...formData, tradeLicenseNo: e.target.value })
            }
          />
        </Col>
        <Col lg={12}>
          <InputBox
            error={formData.tradeLicenseExpiry.trim().length <= 9}
            label={t("License expiry date")}
            value={formData.tradeLicenseExpiry}
            onChange={(e) =>
              setFormData({ ...formData, tradeLicenseExpiry: e.target.value })
            }
            type="date"
          />
        </Col>
        <Col lg={12}>
          <InputBox
            className="disabled"
            error={formData.iban.trim().length <= 0}
            label={t("IBAN")}
            value={formData.iban}
            // onChange={(e) => setFormData({ ...formData, iban: e.target.value })}
            type="text"
          />
        </Col>
        <Col lg={12}>
          <InputBox
            className="disabled"
            error={formData.bankname.trim().length <= 0}
            label={t("Bank Name")}
            value={formData.bankname}
            // onChange={(e) =>
            //   setFormData({ ...formData, bankname: e.target.value })
            // }
            type="text"
          />
        </Col>
        <Divider>Membership Details</Divider>
        <Col lg={12}>
          <label className="formgroup-label">Membership</label>
          <CustomSelect
            className="input-select"
            options={options}
            onChange={handleMembershipChange}
            placeholder="Select Membership"
            value={formData.membershipInfo}
          />
          <br />
        </Col>
        <Col lg={12}>
          <InputBox
            className={`${itsModifiable ? "plain" : "disabled"}`}
            label={t("Visit Cost")}
            value={formData.visitCost}
            readOnly={!itsModifiable}
            type="number"
            onChange={(e) =>
              setFormData({ ...formData, visitCost: e.target.value })
            }
          />
        </Col>
        <Col lg={12}>
          <InputBox
            className={`${itsModifiable ? "plain" : "disabled"}`}
            label={t("Attendance Cost")}
            value={formData.attendanceCost}
            readOnly={!itsModifiable}
            type="number"
            onChange={(e) =>
              setFormData({ ...formData, attendanceCost: e.target.value })
            }
          />
        </Col>
        <Col lg={12}>
          <input
            type="checkbox"
            id="modify_vist_attendance"
            value="modify_v_a"
            checked={itsModifiable}
            onChange={(e) => setitsModifiable(e.target.checked)}
          />
          <label
            for="modify_vist_attendance"
            className="text-secondary ms-2 mb-3"
          >
            Modify Visit & Attendance Cost
          </label>
        </Col>
        {userProfile.role.includes(4) ? (
          <Col lg={12}>
            <button className="btn active" onClick={handleOnUpdate}>
              Update
            </button>
          </Col>
        ) : (
          <Col lg={12}>
            <button className="btn active" onClick={handleOnSubmit}>
              Submits
            </button>
          </Col>
        )}
      </Row>
    );
  };
  const TradeLisnceImageComponent = () => {
    return (
      <>
        <ReactImageUploading
          value={tradeLicenseImage}
          onChange={(imageList, addUpdateIndex) =>
            onChange(setTradeLicenseImages, imageList, addUpdateIndex)
          }
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => {
            return (
              <div
                className="image-content"
                style={{ padding: 10, flex: "1", margin: 0 }}
                {...dragProps}
              >
                <div style={{ fontSize: "20px", color: "white" }}>
                  Your Trade License
                </div>
                {tradeLicenseImage?.length === 0 &&
                  (saveTradeLicenseImage ? (
                    <img
                      src={saveTradeLicenseImage}
                      alt=""
                      className="gym-image"
                    />
                  ) : (
                    <div className="gym-image"></div>
                  ))}
                {tradeLicenseImage?.map((image, index) => (
                  <React.Fragment key={index}>
                    <img src={image.data_url} alt="" className="gym-image" />
                    <div className="image-item__btn-wrapper">
                      <div
                        className="update-btn"
                        onClick={() => onImageUpdate(index)}
                      >
                        <MdChangeCircle />
                      </div>
                      <div
                        className="delete-btn"
                        onClick={() => onImageRemove(index)}
                      >
                        <MdDelete />
                      </div>
                    </div>
                  </React.Fragment>
                ))}
                {tradeLicenseImage?.length === 0 && !gymInfo?.name && (
                  <button className="button active" onClick={onImageUpload}>
                    {t("Select")}
                  </button>
                )}
                {tradeLicenseImage.length !== 0 && (
                  <button
                    className="button active"
                    onClick={() => uploadGymImage()}
                  >
                    {t("Upload")}
                  </button>
                )}
              </div>
            );
          }}
        </ReactImageUploading>
      </>
    );
  };
  const GymFormFragment = (props) => {
    return (
      <React.Fragment>
        <Table className="table-borderless information-table">
          <Row className="mt-2">
            <Col className="col-4 form-cols">
              <span className="label"> {t("Company Name")}</span>
            </Col>
            <Col>
              <input
                className={companyNameError ? "input error" : "input"}
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4 form-cols">
              <span className="label"> {t("Trade license number")}&nbsp;</span>
            </Col>
            <Col>
              <input
                className={tradeLicenseNumberError ? "input error" : "input"}
                value={tradeLicenseNumber}
                onChange={(e) => {
                  setTradeLicenseNumber(e.target.value);
                }}
              />
            </Col>
          </Row>
        </Table>
        <button
          className="btn active"
          onClick={() => {
            onSubmits();
          }}
          style={{ marginTop: "270px" }}
        >
          Submits
        </button>
      </React.Fragment>
    );
  };
  const GynInformationFragment = () => {
    return (
      <React.Fragment>
        <Table className="table-borderless information-table">
          <Row className="mt-1">
            <Col className="col-4">
              <span className="label"> {t("Trade license number")}</span>
            </Col>
            <Col>
              <span className="text">{gymInfo.tradeLicenseNumber}</span>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <span className="label"> {t("Company Name")}</span>
            </Col>
            <Col>
              <span className="text">{gymInfo.name}</span>
            </Col>
          </Row>
        </Table>
      </React.Fragment>
    );
  };
  return (
    <AccessManager fallback="gyms" allowedRoles={[4]}>
      <div className="d-flex flex-column w-100">
        {/* <GymDetailsView /> */}
        <Container>
          <CustomLoading isStart={loading} isFull={true} />
          <div className="header">
            <span className="gym-name">{gymInfo?.name}</span>
          </div>
          <div className="content">
            <div className="information w-60">
              <div className="componenets-header">{t("Gyms Information")}</div>
              <div className="details">
                <div className="container">
                  <div className="row">
                    <div className="col col-6">
                      {userProfile.role.includes(4) ? (
                        <AdminFormFragment
                          data={formDataMain}
                          onUpdate={updateMainData}
                        />
                      ) : !gymInfo?.name ? (
                        <GymFormFragment />
                      ) : (
                        <GynInformationFragment />
                      )}
                    </div>
                    <div className="col">
                      <TradeLisnceImageComponent />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="membershipCardImage">
              <div className="componenets-header">{t("MemberShip Card")}</div>
              <div className="details">
                <ReactImageUploading
                  value={memberShipCardImage}
                  onChange={(imageList, addUpdateIndex) =>
                    onChange(setMemberShipCardImages, imageList, addUpdateIndex)
                  }
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => {
                    return (
                      <div
                        className="image-content"
                        style={{ padding: 10, flex: "1", margin: 0 }}
                        {...dragProps}
                      >
                        {memberShipCardImage.length === 0 && (
                          <div
                            style={{
                              height: !gymInfo?.membershipCardPath && "300px",
                            }}
                            className="membershipImage"
                          >
                            <img
                              className="membership-image"
                              src={gymInfo?.membershipCardPath}
                              width={"100%"}
                            />
                            <div className="userCard">
                              <img
                                src={memberShipImage}
                                alt=""
                                className="userImage"
                              />
                            </div>
                            <div className="userInfo">
                              <span>
                                Customer Name {/*: </b>{state.sure_name}*/}
                              </span>
                              <span>
                                Customer UserName {/*: </b>{state.username}*/}
                              </span>
                            </div>
                          </div>
                        )}
                        {memberShipCardImage.map((image, index) => (
                          <React.Fragment key={index}>
                            <div
                              style={{ height: !gymInfo && "300px" }}
                              className="membershipImage"
                            >
                              <img src={image.data_url} width={"100%"} />
                              <div className="userCard">
                                <img
                                  src={memberShipImage}
                                  alt=""
                                  className="userImage"
                                />
                              </div>
                              <div className="userInfo">
                                <span>
                                  Customer Name {/*: </b>{state.sure_name}*/}
                                </span>
                                <span>
                                  Customer UserName {/*: </b>{state.username}*/}
                                </span>
                              </div>
                            </div>
                            <div className="image-item__btn-wrapper">
                              <div
                                className="update-btn"
                                onClick={() => onImageUpdate(index)}
                              >
                                <MdChangeCircle />
                              </div>
                              <div
                                className="delete-btn"
                                onClick={() => onImageRemove(index)}
                              >
                                <MdDelete />
                              </div>
                            </div>
                          </React.Fragment>
                        ))}

                        {memberShipCardImage.length === 0 && (
                          <button
                            className="button active"
                            onClick={onImageUpload}
                          >
                            {t("Select")}
                          </button>
                        )}

                        {memberShipCardImage.length !== 0 && (
                          <button
                            className="button active"
                            onClick={() => uploadMemberImage()}
                          >
                            {t("Upload")}
                          </button>
                        )}
                      </div>
                    );
                  }}
                </ReactImageUploading>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </AccessManager>
  );
};

const Container = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;

  .header {
    background: black;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #f2f2f2;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;

    .gym-name {
      color: #888888;
      text-transform: uppercase;
      margin-left: 18px;
    }
  }

  .content {
    padding: 20px;
    display: flex;
    flex-direction: row;
  }

  .btn {
    width: 150px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f2f2f2;
    font-size: 18px;
    line-height: 22px;
    border-radius: 10px;
    border: 1px solid #888888;
    margin: auto;
  }

  .btn.active {
    text-transform: uppercase;
    color: #000000;
    background: #feca00;
    border: none;
  }

  .image-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 30px;
    gap: 30px 0px;
    background: black;
    border-radius: 25px;
    margin-right: 20px;

    .image {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      border: 1px solid #f2f2f2;
      object-fit: cover;
    }

    .button {
      width: 100px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f2f2f1;
      font-size: 18px;
      line-height: 22px;
      border-radius: 10px;
      border: 1px solid #888888;
    }

    .button.active {
      text-transform: uppercase;
      color: #000000;
      background: #feca00;
      border: none;
    }

    .image-item__btn-wrapper {
      width: 150px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0px 20px;
      font-size: 24px;

      .update-btn {
        color: white;
      }

      .delete-btn {
        color: red;
      }
    }
  }

  .information {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 25px;
    padding: 20px;
    background: black;

    .componenets-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: #f2f2f2;
      font-size: 24px;
    }

    .details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px 0px;
    }

    .detail {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0px 10px;
    }

    .membership-content {
      display: flex;
      flex-direction: column;
      color: #f2f2f2;
      font-size: 14px;
      padding-left: 16px;
    }

    .label {
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: #888888;
    }

    .text {
      font-size: 18px;
      color: #f2f2f2;
      line-height: 100%;
    }

    .input {
      background: #1c1a1d;
      border: 1px solid #888888;
      border-radius: 12px;
      height: 25px;
      color: #888888;
      min-width: 240px;
      flex-grow: 1;
    }
  }

  .membershipCardImage {
    width: 40%;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    padding: 20px;
    background: black;
    margin-left: 10px;

    .componenets-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: #f2f2f2;
      font-size: 24px;
    }

    .imageContent {
      width: 100%;
      height: 200px;
      border-radius: 10px;
      border: 2px solid #f2f2f2;
      object-fit: cover;
    }
  }

  .w-60 {
    width: 60% !important;
  }

  .gym-image {
    width: 260px !important;
    height: 350px !important;
    border-radius: 10px !important;
    border: 2px solid #f2f2f2 !important;
  }

  .bar-container {
    background-color: #888888;
    width: 150px;
    height: 15px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .bar-completed {
    background-color: lightblue;
    width: 150px;
    height: 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .bar-label {
    line-height: 100%;
    font-size: 12px;
    color: #f2f2f2;
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #f2f2f2;
  }

  .error {
    border-color: red !important;
  }

  .membershipImage {
    background-size: 100% !important;
    width: 100%;
    border-radius: 15px;
    height: auto;
    border: solid;

    .userCard {
      float: right;
      margin: 15px;
      border-radius: 15px;
      border: solid white 4px;
      padding: 2px;
      position: absolute;
      top: 10px;
      right: 10px;

      .userImage {
        width: 150px;
        height: 150px;
        border-radius: 10px;
        border: solid white 2px;
      }
    }
  }
  .membership-image {
    height: 300px;
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    color: white !important;
    position: absolute;
    top: 50px;
    left: 25px;
  }

  .information-table {
    margin-top: 40px;
  }

  .form-cols {
    margin-top: auto;
    margin-bottom: auto;
  }

  .formgroup-label {
    margin-bottom: 3px;
    color: #9c9c9c;
    font-size: 0.9rem;
  }
`;

export default GymDetailComponent;

export const Icons = {
  Search: (
    <svg
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 24 24"
      class="icon"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15zm-3.847-8.699a2 2 0 1 0 2.646 2.646 4 4 0 1 1-2.646-2.646z"></path>
      </g>
    </svg>
  ),
};
