import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
} from "recharts";
import { BiDumbbell } from "react-icons/bi";
import { IoPerson } from "react-icons/io5";
import { FaUserTie } from "react-icons/fa";
import { Container } from "./Dashboard.config";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import AccessManager from "../../../custom/AccessManager/AccessManager";
import CustomSelect from "../item/CustomSelect";
import useDashboardController from "../../../componentsController/DashboardController";
import {
  setYearOptions,
  setYearFilter,
} from "../../../../redux/slices/Dashboard.slice";

const CustomTooltip = ({ payload, label, active }) => {
  if (active) {
    return (
      <div style={{ width: "100px", display: "flex", flexDirection: "column" }}>
        <span className="label">
          {`${label} : `}
          {payload !== null && (
            <span style={{ fontWeight: "900" }} className="text-warning h5">
              {payload[0].value}
            </span>
          )}
        </span>
        <span style={{ fontSize: "0.9em" }} className="text-light">
          Customers
        </span>
      </div>
    );
  }
  return null;
};

const TableItem = ({ name = "-", customers = 0, price = 0, ...props }) => {
  const { t } = useTranslation();
  return (
    <div className="table-item">
      <div className="user">
        <div className="user-image"></div>
        <span className="user-name desktop">{name}</span>
        <div className="user-info">
          <span className="user-name">{name}</span>
          <div className="customers">
            <span>{customers}</span>
            <span className="label">{t("customers")}</span>
          </div>
        </div>
      </div>
      <div className="customers desktop">
        <span className="text-warning">{customers}</span>
        <span className="label">{t("customers")}</span>
      </div>
      <span style={{ minWidth: "150px" }} className="text-end">
        <span style={{ fontSize: "14px" }} className="text-secondary">
          {"AED "}
        </span>
        {(customers * price).toFixed(2).toLocaleString("en-US")}
      </span>
    </div>
  );
};

const Dashboard = () => {
  const { loadAnalyticsData, loadGraphAndTopGymsData } =
    useDashboardController();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: profile } = useSelector((state) => state.user);
  const { analytics, graphData, topGymData, yearOptions, yearFilter } =
    useSelector((state) => state.dashbaord);
  useEffect(() => {
    if (
      (profile?.role?.includes(1) || profile?.role?.includes(5)) &&
      profile?.role?.length === 1
    ) {
      navigate("/settings");
    }
    if (profile?.role?.includes(6)) {
      navigate("/branches");
    }
    if (profile?.role?.includes(2)) {
      navigate("/gymeditclass");
    }
    localStorage.removeItem("branchDoc");
    dispatch(setYearOptions(loadYears(2021)));
    loadAnalyticsData();
  }, []);

  useEffect(() => {
    loadGraphAndTopGymsData();
  }, [yearFilter]);

  const renderCustomAxisYTick = ({ x, y, payload }) => {
    // let text =
    //   "" +
    //   (payload.value % 1
    //     ? (payload.value / 1).toFixed(1)
    //     : payload.value / 1) +
    //   " k";
    let text = payload.value;
    return (
      <text
        orientation="left"
        type="number"
        x={x}
        y={y}
        stroke="none"
        fill="#F2F2F2"
        className="recharts-text recharts-cartesian-axis-tick-value"
        textAnchor="end"
      >
        <tspan x={x} dy="0.355em">
          {text}
        </tspan>
      </text>
    );
  };

  const renderCustomAxisXTick = ({ x, y, payload }) => {
    return (
      <text
        orientation="bottom"
        type="number"
        x={x}
        y={y}
        stroke="none"
        fill="#F2F2F2"
        className="recharts-text recharts-cartesian-axis-tick-value"
        textAnchor="middle"
      >
        <tspan x={x} dy="1em" dx="0em">
          {payload.value}
        </tspan>
      </text>
    );
  };

  var loadYears = (startFrom) => {
    const currYear = new Date().getFullYear();
    let r = [{ value: currYear, label: currYear }];
    if (typeof startFrom == "number") {
      const diff = currYear - startFrom;
      if (startFrom > currYear) {
        alert("start from year is should be less than current year.");
        return r;
      }
      r = [];
      for (let i = diff; i >= 0; i--) {
        r.push({ value: currYear - i, label: currYear - i });
      }
    }
    return r.reverse();
  };

  return (
    <AccessManager allowedRoles={[1, 2, 3, 4, 5, 6]}>
      <Container>
        <div className="header">
          <span className="title">
            {t("Business Analysis - ")}
            {yearFilter.label}
          </span>
          <div className="d-flex text-secondary align-items-center">
            {/* <div className="me-2">Filter</div> */}
            <CustomSelect
              placeholder={"Month..."}
              defaultValue={yearFilter}
              value={yearFilter}
              onChange={(event) => dispatch(setYearFilter(event))}
              options={yearOptions}
              style={{ minWidth: "100px" }}
            />
          </div>
        </div>
        <div className="chart">
          <ResponsiveContainer width="100%" height={340}>
            <AreaChart
              data={graphData}
              margin={{ top: 60, right: 30, left: 0, bottom: 15 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="30%" stopColor="#FFE402" stopOpacity={0.27} />
                  <stop offset="95%" stopColor="#1C1A1D" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="name"
                tickLine={false}
                axisLine={false}
                fill="#F2F2F2"
                tick={renderCustomAxisXTick}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={renderCustomAxisYTick}
                fill="#F2F2F2"
              />
              <CartesianGrid vertical={false} stroke="#1C1A1D" />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="price"
                stroke="#D9D9D9"
                fillOpacity={2}
                fill="url(#colorUv)"
                dot={{ stroke: "#D9D9D9", strokeWidth: 2 }}
                strokeWidth={2}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className="content">
          <div className="item-group">
            <div className="item">
              <div
                className="icon"
                style={{
                  backgroundColor: "#13335F",
                }}
              >
                <BiDumbbell className="dumbbell" />
              </div>
              <span className="text">{t("Total GYM’s")}</span>
              <span className="number dumbbell">{analytics.branches}</span>
            </div>
            <div className="item">
              <div className="icon" style={{ backgroundColor: "#5E2323" }}>
                <FaUserTie className="trainer" />
              </div>
              <span className="text">{t("Total Trainer")}</span>
              <span className="number trainer">{analytics.trainers}</span>
            </div>
            <div className="item">
              <div className="icon" style={{ backgroundColor: "#104626" }}>
                <IoPerson className="person" />
              </div>
              <span className="text">{t("Total Customer")}</span>
              <span className="number person">{analytics.customers}</span>
            </div>
          </div>
          <div className="table-layout">
            <div className="table-header">
              <span className="title-dashboard">
                {profile?.role?.includes(4) && t("TOP 10 GYMS - ")}
                {profile?.role?.includes(3) && t("TOP 10 BRANCHES - ")}
                {yearFilter.label}
              </span>
              <span className="button">{t("view all")}</span>
            </div>
            <div className="table-content">
              {topGymData &&
                topGymData?.length > 0 &&
                topGymData.map((data, index) => {
                  return <TableItem key={index} {...data} />;
                })}
            </div>
          </div>
        </div>
      </Container>
    </AccessManager>
  );
};

export default Dashboard;
