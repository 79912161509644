import React from "react";

export default function Padding(props) {
  const style = {
    width: "100%",
    padding: props.padding || "15px",
  };
  return (
    <div style={style} {...props}>
      {props.children}
    </div>
  );
}
