import { Firestore, Timestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import {
//   getAllAttendance,
//   getAttendanceForBranch,
//   getAttendanceForGymowner,
// } from "../../../../common/repository/js/attendance.service";
import {
  getAllAttendanceController,
  getAttendanceForBranchController,
  getAttendanceForGymownerController,
} from "../../../../../../controllers/web/attendanceController";
import InputBox from "../../../custom/InputBox/InputBox";
import PageHeader from "../../../custom/PageHeader/PageHeader";
import PageSection, {
  PageSectionBody,
} from "../../../custom/PageSection/PageSection";
import ThemeButton from "../../../custom/ThemeButton/ThemeButton";
import CustomLoading from "../../../items/loadingBar";
import CustomSelect from "../item/CustomSelect";
import { reportingData } from "./reporting.data";
import "./reportingattendance.scss";
import { CSVLink, CSVDownload } from "react-csv";
import { useSelector } from "react-redux";
import ConfirmDialog from "../../../custom/ConfirmDialog/ConfirmDialog";
import { Msgs } from "../../../../../../common/data/msgs";
import Padding from "../../../custom/Padding/Padding";

export default function ReportingAttendance() {
  const { user: userProfile } = useSelector((state) => state.user);
  const [searchText, setSearchText] = useState("");
  const [attendanceLogs, setattendanceLogs] = useState(null);
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [loading, setLoading] = useState(true);
  const monthOptions = [
    { value: null, label: "All" },
    { value: 1, label: "JAN" },
    { value: 2, label: "FEB" },
    { value: 3, label: "MAR" },
    { value: 4, label: "APR" },
    { value: 5, label: "MAY" },
    { value: 6, label: "JUN" },
    { value: 7, label: "JUL" },
    { value: 8, label: "AUG" },
    { value: 9, label: "SEP" },
    { value: 10, label: "OCT" },
    { value: 11, label: "NOV" },
    { value: 12, label: "DEC" },
  ];
  const [monthFilter, setMonthFilter] = useState(
    monthOptions[new Date().getMonth() + 1]
  );

  const [yearOptions, setyearOptions] = useState(null);
  const [yearFilter, setyearFilter] = useState({
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  });

  const [downloadCSV, setdownloadCSV] = useState(false);

  useEffect(async () => {
    setyearOptions(loadYears(2020));
    if (userProfile) {
      if (userProfile.role.includes(4)) {
        await getAllAttendanceController().then((result) => {
          setattendanceLogs(result);
        });
      }
      if (userProfile.role.includes(3)) {
        await getAttendanceForGymownerController(
          userProfile?.company_id
        ).then((result) => {
          setattendanceLogs(result);
        });
      }
      if (userProfile.role.includes(6)) {
        await getAttendanceForBranchController(
          userProfile?.company_id
        ).then((result) => {
          setattendanceLogs(result);
        });
      }
    }
  }, []);

  useEffect(() => {
    loadAttendanceLogs();
  }, [attendanceLogs]);

  useEffect(() => {
    if (downloadCSV) {
      setdownloadCSV(false);
    }
  }, [downloadCSV]);

  useEffect(() => {
    searchData(searchText);
  }, [monthFilter, yearFilter, data, searchText]);

  const loadYears = (startFrom) => {
    const currYear = new Date().getFullYear();
    let r = [{ value: currYear, label: currYear }];
    if (typeof startFrom == "number") {
      const diff = currYear - startFrom;
      if (startFrom > currYear) {
        alert("start from year is should be less than current year.");
        return r;
      }
      r = [];
      for (let i = diff; i >= 0; i--) {
        r.push({ value: currYear - i, label: currYear - i });
      }
    }
    return r.reverse();
  };

  const loadAttendanceLogs = () => {
    setLoading(true);
    if (attendanceLogs?.length > 0) {
      let rec = [];
      attendanceLogs.forEach((e) => {
        rec.push({
          date: new Date(e?.entrance_date).toLocaleString(),
          companyname: e?.mainGymName,
          branch: e?.branch_name,
          sure_name: e?.sure_name,
          Email: e?.username,
          time: new Date(e?.entrance_date).toLocaleTimeString(),
          month: new Date(e?.entrance_date).getMonth() + 1,
          year: new Date(e?.entrance_date).getFullYear(),
          attendanceCost: e?.attendanceCost,
        });
      });
      // console.log("rec : ", rec);
      setLoading(false);
      setData(rec);
      setFilteredData(rec);
    } else {
      setLoading(false);
    }
  };

  const searchData = (txt) => {
    const txt1 = txt.toLowerCase();
    if (data?.length > 0) {
      const fData = data.filter((e) => {
        if (monthFilter !== null && monthFilter?.value !== null) {
          return (
            (e?.month === monthFilter?.value &&
              e?.year === yearFilter?.value &&
              e?.sure_name?.toLowerCase().includes(txt1)) ||
            (e?.month === monthFilter?.value &&
              e?.year === yearFilter?.value &&
              e?.Email?.toLowerCase().includes(txt1)) ||
            (e?.month === monthFilter?.value &&
              e?.year === yearFilter?.value &&
              e?.companyname?.toLowerCase().includes(txt1)) ||
            (e?.month === monthFilter?.value &&
              e?.year === yearFilter?.value &&
              e?.branch?.toLowerCase().includes(txt1))
          );
        } else {
          return (
            (e?.sure_name?.toLowerCase().includes(txt1) &&
              e?.year === yearFilter?.value) ||
            (e?.Email?.toLowerCase().includes(txt1) &&
              e?.year === yearFilter?.value)
          );
        }
      });
      setFilteredData(fData);
    } else {
      setFilteredData([]);
    }
  };


  return (
    <PageSection>
      <CustomLoading isStart={loading} isFull={true} />
      <PageHeader>
        <span className="page-header-title">Reporting</span>
        <div className="page-header-rightside">
          <div className="header-search-bar">
            <InputBox
              placeholder="Search..."
              icon={Icons.Search}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
        </div>
      </PageHeader>
      <PageSectionBody>
        <div className="page-content">
          <div className="grid-container">
            <div className="reporting-action-container">
              <div className="reporting-action-title">
                {monthFilter.label}-{yearFilter.label}
              </div>
              <div>
                <div className="d-flex flex-row">
                  <CustomSelect
                    className="custom-select-report me-1"
                    placeholder="Month.."
                    options={monthOptions}
                    defaultValue={monthFilter}
                    onChange={setMonthFilter}
                  />
                  <CustomSelect
                    className="custom-select-report"
                    placeholder="Year.."
                    options={yearOptions}
                    defaultValue={yearFilter}
                    onChange={setyearFilter}
                  />
                </div>
                {downloadCSV && (
                  <CSVDownload
                    data={filteredData}
                    target="_blank"
                    filename="helll"
                    uFEFF={false}
                  />
                )}
                <ThemeButton
                  onClick={(e) => {
                    if (userProfile?.activated) {
                      setdownloadCSV(true);
                    } else {
                      ConfirmDialog.show({
                        message: Msgs.NotActiveMsg,
                        showFooter: false,
                      });
                    }
                  }}
                >
                  <span>&#8609;</span>
                  CSV
                </ThemeButton>
              </div>
            </div>
            <div className="controls-group"></div>
            <div>
              <div style={{ textAlign: "center" }}></div>
            </div>
            <div className="content-container">
              <div className="content-table">
                <div className="table-header">
                  <span className="">Access Date</span>
                  <span className="">Company Name</span>
                  <span>Branch Name Entered</span>
                  <span className="">Customer Sure name</span>
                  <span className="">Customer User Name</span>
                </div>

                <div className="table-content">
                  {filteredData?.length > 0 &&
                    filteredData.map((e, i) => {
                      return (
                        <div key={i} className="table-row">
                          {Object.values(e).map((item, index) => {
                            if (index < Object.values(e).length - 4)
                              return (
                                <span
                                  key={index}>
                                  {item}
                                </span>
                              );
                          })}

                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageSectionBody>
    </PageSection>
  );
}

export const Icons = {
  Search: (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      className="icon"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15zm-3.847-8.699a2 2 0 1 0 2.646 2.646 4 4 0 1 1-2.646-2.646z"></path>
      </g>
    </svg>
  ),
};
