import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Modal } from "react-bootstrap";
import { BsPlusCircleFill, BsCheckCircleFill } from "react-icons/bs";
import { RiCloseCircleFill } from "react-icons/ri";
import CustomLoading from "../../../items/loadingBar";
import { SketchPicker } from "react-color";
import ConfirmDialog from "../../../custom/ConfirmDialog/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  setDisable,
  setAnnualError,
  setAttendanceError,
  setAttendancePrice,
  setColor,
  setColorPicker,
  setCreateError,
  setCreateSuccess,
  setDescription,
  setIsAddDesc,
  setIsOpen,
  setLoading,
  setMonthError,
  setName,
  setNameError,
  setNewDesc,
  setOneDayPrice,
  setPriceAnnual,
  setPriceMonth,
} from "../../../../redux/slices/AddMembership.slice";
import useManageMembershipController from "../../../componentsController/ManageMembership";

const AddMembership = (props) => {
  const { AddaMemberShip } = useManageMembershipController();
  const dispatch = useDispatch();
  const {
    disable,
    loading,
    isOpen,
    isAddDesc,
    description,
    name,
    priceMonth,
    oneDayPrice,
    attendancePrice,
    priceAnnual,
    displayColorPicker,
    color,
    newDesc,
    nameError,
    monthError,
    annualError,
    attendanceError,
    createError,
    createSuccess,
  } = useSelector((state) => state.addMembership);
  // Utils
  // const [disable, setDisable] = useState(false);
  // const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // const [isOpen, setIsOpen] = useState(false);
  // const [isAddDesc, setIsAddDesc] = useState(false);
  // const [description, setDescription] = useState([]);

  // Form inputs
  // const [name, setName] = useState("");
  // const [priceMonth, setPriceMonth] = useState("");
  // const [oneDayPrice, setOneDayPrice] = useState(0);
  // const [attendancePrice, setAttendancePrice] = useState(0);
  // const [priceAnnual, setPriceAnnual] = useState("");
  // const [displayColorPicker, setColorPicker] = useState(false);
  // const [color, setColor] = useState({ r: "241", g: "112", b: "19", a: "1" });
  // const [newDesc, setNewDesc] = useState("");

  // Error messages
  // const [nameError, setNameError] = useState(false);
  // const [monthError, setMonthError] = useState(false);
  // const [annualError, setAnnualError] = useState(false);
  // const [attendanceError, setAttendanceError] = useState(false);
  // const [createError, setCreateError] = useState(false);
  // const [createSuccess, setCreateSuccess] = useState(false);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props]);

  const resetStates = () => {
    dispatch(setIsAddDesc(false));
    dispatch(setName(""));
    dispatch(setPriceAnnual(""));
    dispatch(setPriceMonth(""));
    dispatch(setNameError(false));
    dispatch(setMonthError(false));
    dispatch(setAnnualError(false));
    dispatch(setDescription([]));
    dispatch(setNewDesc(""));
    dispatch(setDisable(false));
  };
  const hideModal = () => {
    resetStates();
    emptyMessage();
    dispatch(setIsOpen(false));
    props.close();
  };
  const emptyMessage = () => {
    dispatch(setCreateError(false));
    dispatch(setCreateSuccess(false));
  };
  const onAddDescItem = () => {
    dispatch(setIsAddDesc(true));
  };
  const closeAddDesc = () => {
    dispatch(setIsAddDesc(false));
    dispatch(setNewDesc(""));
  };
  const handleClick = () => {
    dispatch(setColorPicker(!displayColorPicker));
  };
  const handleClose = () => {
    dispatch(setColorPicker(false));
  };
  const handleChange = (e) => {
    dispatch(setColor(e.rgb));
  };
  const onSave = async () => {
    if (disable) return;
    dispatch(setLoading(true));
    if (
      name === "" ||
      priceMonth <= 0 ||
      priceAnnual <= 0 ||
      attendancePrice <= 0
    ) {
      dispatch(setNameError(name === ""));
      dispatch(setMonthError(priceMonth <= 0));
      dispatch(setAnnualError(priceAnnual <= 0));
      dispatch(setAttendanceError(attendancePrice <= 0));
      dispatch(setLoading(false));
      return;
    }
    dispatch(setDisable(true));
    const data = await AddaMemberShip();
    if (data.success !== "") {
      dispatch(setCreateSuccess(true));
      dispatch(setDisable(false));
      resetStates();
      props.refresh();
      dispatch(setLoading(false));
      dispatch(setIsOpen(false));
      ConfirmDialog.hide();
    } else {
      dispatch(setCreateError(true));
      dispatch(setLoading(false));
    }
    // addMembership(
    //   name,
    //   description,
    //   priceMonth,
    //   priceAnnual,
    //   attendancePrice,
    //   color
    // ).then((data) => {
    //   if (data.success !== "") {
    //     dispatch(setCreateSuccess(true));
    //     dispatch(setDisable(false));
    //     resetStates();
    //     props.refresh();
    //     dispatch(setLoading(false));
    //     dispatch(setIsOpen(false));
    //     ConfirmDialog.hide();
    //   } else {
    //     dispatch(setCreateError(true));
    //     dispatch(setLoading(false));
    //   }
    // });
  };
  const onAddDescription = () => {
    if (newDesc === "") return;
    let temp = [...description];
    temp.push(newDesc);
    dispatch(setDescription(temp));
    dispatch(setNewDesc(""));
  };
  const onDeleteItem = (index) => {
    let temp = [...description];
    temp.splice(index, 1);
    let newDescription = [];
    temp.forEach((item) => {
      newDescription.push(item);
    });
    dispatch(setDescription(newDescription));
  };

  return (
    <>
      <CustomLoading isStart={loading} />

      <Container>
        {/* <div className="header">{t("Add Membership")}</div> */}
        <div className="content">
          <span className="label">{t("Name")}</span>
          <input
            className={nameError ? "input error" : "input"}
            value={name}
            onChange={(e) => {
              dispatch(setName(e.target.value));
              emptyMessage();
              dispatch(setNameError(false));
            }}
          />

          <div className="price-layout w-100">
            <div>
              <span className="label">{t("Monthly Price")}</span>
              <input
                className={monthError ? "input error" : "input"}
                value={priceMonth}
                type={"number"}
                onChange={(e) => {
                  emptyMessage();
                  dispatch(setMonthError(false));
                  dispatch(setPriceMonth(e.target.value));
                }}
              />
            </div>
            <div>
              <span className="label">{t("Annual Price")}</span>
              <input
                className={annualError ? "input error" : "input"}
                value={priceAnnual}
                type={"number"}
                onChange={(e) => {
                  emptyMessage();
                  dispatch(setAnnualError(false));
                  dispatch(setPriceAnnual(e.target.value));
                }}
              />
            </div>
            <div>
              <span className="label">{t("attendance price")}</span>
              <input
                className={monthError ? "input error" : "input"}
                type={"number"}
                onChange={(e) => {
                  emptyMessage();
                  dispatch(setAttendancePrice(e.target.value));
                }}
              />
            </div>
            {/* <div>
              <span className="label">{t("one day price")}</span>
              <input
                className={annualError ? "input error" : "input"}
                type={"number"}
                onChange={(e) => {
                  emptyMessage();
                  setOneDayPrice(e.target.value);
                }}
              />
            </div> */}
          </div>

          <div className="description-layout">
            <span className="label">{t("Description")}</span>
            <div className="description">
              {description.map((item, index) => (
                <div key={index} className="description-item">
                  <li>{item}</li>
                  <RiCloseCircleFill
                    className="delete"
                    onClick={() => onDeleteItem(index)}
                  />
                </div>
              ))}
            </div>
            <div className={isAddDesc ? "create-description" : "disable"}>
              <input
                className="input"
                value={newDesc}
                onChange={(e) => dispatch(setNewDesc(e.target.value))}
              />
            </div>
            <div className="button-container">
              <span onClick={onAddDescItem}>
                <BsPlusCircleFill className="icon" />
                {t("Add items")}
              </span>
              <div className="create-container">
                <span onClick={closeAddDesc}>{t("Cancel")}</span>
                <span className="add" onClick={onAddDescription}>
                  <BsCheckCircleFill className="icon" />
                  {t("Add")}
                </span>
              </div>
            </div>
          </div>

          <div className="colorcode-layout">
            <span className="label">Choose Color:</span>
            <div className="swatch" onClick={handleClick}>
              <div
                style={{
                  width: "36px",
                  height: "14px",
                  borderRadius: "2px",
                  background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
                }}
              />
            </div>
            {displayColorPicker ? (
              <div className="popover">
                <div className="cover" onClick={handleClose} />
                <SketchPicker
                  color={color}
                  onChange={handleChange}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="message">
          {createError && (
            <span className="error-msg">
              {t("Cannot create a new membership. Try again.")}
            </span>
          )}
          {createSuccess && (
            <span className="success-msg">
              {t("New Membership is created successfully.")}
            </span>
          )}
        </div>
        <div className="button-group">
          <Button
            variant="secondary"
            className="ps-3 pe-3"
            onClick={ConfirmDialog.hide}
          >
            Cancel
          </Button>
          <Button variant={"warning"} className="ps-4 pe-4" onClick={onSave}>
            Save
          </Button>
        </div>
      </Container>
    </>
  );
};

const Container = styled("div")`
  width: 498px;
  background-color: black;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
  .error {
    border-color: red !important;
  }
  .header {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: #f2f2f2;
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }
  .label {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #888888;
    margin-bottom: 8px;
  }
  .input {
    color: #888888;
    background: #1c1a1d;
    border-radius: 8px;
    border: 1px solid #888888;
    height: 42px;
    padding-left: 10px;
    margin-bottom: 15px;
  }
  .button {
    cursor: pointer;
    width: 110px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f2f2f2;
    font-size: 18px;
    line-height: 22px;
    border-radius: 10px;
    border: 1px solid #888888;
    margin-left: 16px;
  }
  .button.active {
    text-transform: uppercase;
    color: #000000;
    background: #feca00;
    border: none;
  }
  .button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 40px;
    gap: 8px 8px;
  }
  .description {
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-height: 0px;
    padding: 0px 0px;

    .description-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    li {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #f2f2f2;
      margin-bottom: 6px;
      list-style: none;
      border-bottom: 1px dashed #88888875;
      width: 100%;
      padding: 5px 0px;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: #888888;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: #ffcc00;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
    }
  }
  .description-layout {
    display: flex;
    flex-direction: column;
    height: max-content;
    margin-bottom: 20px;
    border: 1px solid #88888850;
    border-radius: 5px;
    padding: 15px 20px;
  }
  .create-description {
    display: flex;
    flex-direction: column;
  }
  .disable {
    display: none;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 160%;
    color: #888888;
    margin-top: 10px;
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .create-container {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
    .add {
      color: #feca00;
    }
    .icon {
      margin-right: 8px;
      font-size: 18px;
    }
  }
  .price-layout {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 0px 20px;
    & > div {
      display: flex;
      flex-direction: column;
    }
  }
  .colorcode-layout {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .message {
    display: flex;
    align-items: center;
    margin-top: 10px;
    height: 20px;
  }
  .error-msg {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: red;
  }
  .success-msg {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #f2f2f2;
  }
  .delete {
    color: #f03f3f;
    font-size: 1em;
  }
  .swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: pointer;
  }
  .popover {
    position: absolute;
    bottom: 20px;
    z-index: 2;
  }
  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  @media (max-width: 768px) {
    width: 300px;
    height: unset;
    .header {
      margin-bottom: 20px;
    }

    .button {
      margin-right: 0px;
    }
    .button-group {
      justify-content: space-between;
      margin-top: 30px;
    }
  }
`;

export default AddMembership;
