import { configureStore } from "@reduxjs/toolkit";
import DashboardSlice from "../slices/Dashboard.slice";
import AuthSlice from "../slices/Auth.slice";
import ManageGymSlice from "../slices/ManageGym.slice";
import AddMembershipSlice from "../slices/AddMembership.slice";
import EditMembershipSlice from "../slices/EditMembership.slice";
import ManageMembershipSlice from "../slices/ManageMembership.slice";
import AddUserSlice from "../slices/AddUser.slice";
import LocalStatesSlice from "../slices/LocalStates.slice";
export default configureStore({
  reducer: {
    dashbaord: DashboardSlice,
    user: AuthSlice,
    manageGym: ManageGymSlice,
    addMembership: AddMembershipSlice,
    editMembership: EditMembershipSlice,
    manageMembership: ManageMembershipSlice,
    addUser: AddUserSlice,
    localStates: LocalStatesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
