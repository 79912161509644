const createVerificationCode = () => {
  let code = "";
  for (let i = 0; i < 4; i++) {
    code += Math.floor(Math.random() * 10).toString();
  }
  return code;
};

export const getSMSVerificationCode = async (phoneNumber) => {
  let verificationCode = createVerificationCode();
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        code: verificationCode,
        phoneNumber: phoneNumber,
      }),
    };
    return await fetch(
      `https://us-central1-gym-api-9f285.cloudfunctions.net/verifyPhone`,
      requestOptions
    ).then((res) => {
      res.json();
      if (res.status === 204) {
        return verificationCode;
      } else {
        return false;
      }
    });
  } catch (e) {
    console.log("Error getEmailVerification: ", e.error);
  }
};

export const getEmailVerificationCode = async (emailAddress) => {
  let verificationCode = createVerificationCode();
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ code: verificationCode, sendTo: emailAddress }),
    };
    return await fetch(
      `https://us-central1-gym-api-9f285.cloudfunctions.net/verifyEmail`,
      requestOptions
    ).then((res) => {
      res.json();
      if (res.status === 204) {
        return verificationCode;
      } else {
        return false;
      }
    });
  } catch (e) {
    console.log("Error getEmailVerification: ", e.error);
  }
};
