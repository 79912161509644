import { useDispatch, useSelector } from "react-redux";
import {
  setFilteredContent,
  setGyms,
  setLoading,
  setGymOwners,
} from "../../redux/slices/ManageGym.slice";
import { getGyms } from "../../../../repository/web/js/gym";
import { getGymsController } from "../../../../controllers/web/gymController";
import { getGymOwners } from "../../../../repository/web/js/user";
import { changeGymActivation } from "../../../../repository/web/js/gym";
const useManageGymController = () => {
  const dispatch = useDispatch();
  const { user: loggedinUser } = useSelector((state) => state.user);
  const getAllGyms = async () => {
    dispatch(setLoading(true));
    await getGymsController().then((datas) => {
      let temp_gyms = [];
      datas.forEach((data) => {
        let temp = { ...data.data(), qrCode: data.id };
        temp_gyms.push(temp);
      });
      if (loggedinUser?.role.includes(4)) {
        dispatch(setGyms(temp_gyms));
        dispatch(setFilteredContent(temp_gyms));
      } else if (loggedinUser?.role.includes(3)) {
        let temp = [];
        temp_gyms.forEach((item) => {
          if (item.mainBranchID === loggedinUser?.company_id) {
            temp.push(item);
          }
        });
        dispatch(setGyms(temp));
        dispatch(setFilteredContent(temp));
      } else if (loggedinUser?.role.includes(6)) {
        let temp = [];
        temp_gyms.forEach((item) => {
          if (item.mainBranchID === loggedinUser?.company_id) {
            temp.push(item);
          }
        });
        dispatch(setGyms(temp));
        dispatch(setFilteredContent(temp));

        // let temp_gyms = [];
        // datas.forEach((data) => {
        //   let temp = { ...data.data(), qrCode: data.id };
        //   temp_gyms.push(temp);
        // });
        // console.log("Temp gyms are ", temp_gyms)

        // let temp = [];
        // let branchesA = loggedinUser?.branches?.map((item) => item.value);
        // console.log("Branches are ", datas)
        // temp_gyms.forEach((item) => {
        //   if (branchesA?.includes(item.qrCode)) {
        //     temp.push(item);
        //   }
        // });
        // dispatch(setGyms(temp));
        // dispatch(setFilteredContent(temp));
      }
      dispatch(setLoading(false));
    });
  };

  const getOwnersOfGym = () => {
    getGymOwners().then((response) => {
      dispatch(setGymOwners(response.data));
    });
  };

  const changeActivationOfGym = async (id, state) => {
    changeGymActivation(id, state).then((response) => {
      return response;
    });
  };

  return {
    getAllGyms,
    getOwnersOfGym,
    changeActivationOfGym,
  };
};

export default useManageGymController;
