import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import MapPicker from "react-google-map-picker";
import Geocode from "react-geocode";
// import { saveGym } from "../../../../../common/repository/js/gym";
import { saveGymController } from "../../../../../../../controllers/web/gymController";
import {
  getGymInfo,
  getGymsOption,
} from "../../../../../../../repository/web/js/gym_main";
import {
  getGymInfoController,
  getGymsOptionController,
} from "../../../../../../../controllers/web/gymMainController";
import CustomSelect from "../../item/CustomSelect";
import CustomLoading from "../../../../items/loadingBar";
import { useSelector } from "react-redux";
import ResponsiveDialog from "./trialpage";
import ReactGoogleMap from "../../../../custom/GoogleMap/GoogleMap";
import InputBox from "../../../../custom/InputBox/InputBox";
import ConfirmDialog from "../../../../custom/ConfirmDialog/ConfirmDialog";
import { FacilityTypes } from "../../../../../../../common/data/FacilityTypes";

const DEFAULT_LOCATION = { lat: 25.2048, lng: 55.2708 };
const DEFAULT_ZOOM = 9;

const Addgymbranch = (props) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);

  const GOOGLE_MAP_API_KEY = "AIzaSyDQ4WBaFAr6HqsjFOiAc_8jXxBUgLel-TM";
  Geocode.setApiKey(GOOGLE_MAP_API_KEY);
  Geocode.setLanguage("en");

  //Form attributes
  const [name, setName] = useState("");
  const [location, setLocation] = useState(DEFAULT_LOCATION);
  const [mainBranchID, setMainBranchID] = useState("");
  const [gender, setGender] = useState("");
  const [facilityType, setFacilityType] = useState("");
  const [address, setaddress] = useState("");
  const [addressError, setaddressError] = useState("");

  // Input Error/Success Messages
  const [nameError, setNameError] = useState(false);
  const [facilityTypeError, setFacilityTypeError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [mainBranchIDError, setMainBranchIDError] = useState(false);
  const [createError, setCreateError] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);

  // Utilities
  const [isOpen, setIsOpen] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [disable, setDisable] = useState(false);
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const [loading, setLoading] = useState(false);
  const [gymsOptions, setGymsOptions] = useState();

  const gymOptions = async () => {
    let options = await getGymsOptionController();
    return options;
  };

  useEffect(() => {
    setIsOpen(props.isOpen);
    gymOptions().then((options) => {
      setGymsOptions(options);
    });
  }, [props]);

  const resetStates = () => {
    setIsOpen(false);
    setNameError("");
    setName("");
    setLocation(DEFAULT_LOCATION);
    setCreateError(false);
    setCreateSuccess(false);
    setIsCreated(false);
    setDisable(false);
  };

  const hideModal = () => {
    resetStates();
    if (isCreated) {
      props.refresh();
    }
    props.close();
  };

  const onCreate = async () => {
    let userID = user.id;
    if (disable) return;

    if (name === "" || gender === "" || address === "" || facilityType == "") {
      setNameError(name === "");
      setLoading(false);
      setGenderError(gender === "");
      setaddressError(address === "");
      setFacilityTypeError(facilityType === "");
      if (address === "") {
        return ConfirmDialog.show({
          message: "Please select the address of branch by using the Map.",
          showFooter: false,
        });
      }
      return;
    }

    if (user.role.includes(4)) {
      if (mainBranchID === "") {
        setMainBranchIDError(mainBranchID === "");
        setLoading(false);
        setDisable(false);
        return;
      }
      await getGymInfoController(mainBranchID).then((mainBranch) => {
        userID = mainBranch.owner;
      });
    }

    setDisable(true);
    setLoading(true);
    let _mainBranchId = mainBranchID || user.company_id;

    Geocode.fromLatLng(location.lat, location.lng)
      .then((response) => {
        const address = response.results[0].formatted_address;
        return saveGymController(
          name,
          address,
          location,
          userID,
          _mainBranchId,
          gender,
          facilityType
        );
      })
      .then((data) => {
        if (data.success === "success") {
          setCreateSuccess(true);
          setIsCreated(true);
          setName("");
          setDisable(false);
          props.refresh();
          props.close();
          setCreateError(false);
          setCreateSuccess(false);
          setIsOpen(false);
          setLoading(false);
        } else {
          console.log("Error : ", data);
          setLoading(false);
          setCreateError(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        // setCreateError(error);
        console.log(error);
      });
  };

  const onInputName = (e) => {
    setCreateError(false);
    setCreateSuccess(false);
    setNameError(false);
    setName(e.target.value);
  };

  const handleOnMapChange = (latlng, address, addressList) => {
    setLocation(latlng);
    setaddress(address);
  };
  return (
    <Modal
      show={isOpen}
      onHide={hideModal}
      centered
      dialogClassName="login-modal"
      style={{ minWidth: "400px" }}
    >
      <CustomLoading isStart={loading} />
      <Container>
        <div className="panel">
          {/* <span className="title">{t("Add GYM BRANCH")}</span> */}
          <span className="title">{t("Add Facility")}</span>

          <div className="setting">
            <span className="label">{t("Facility Type")}</span>
            <CustomSelect
              options={FacilityTypes}
              className={
                facilityTypeError ? "input-select error" : "input-select"
              }
              placeholder={"Facility Type"}
              onChange={(e) => {
                setFacilityTypeError(false);
                setFacilityType(e.value);
              }}
            />
            <span className="label">{t("Facility name")}</span>
            <input
              className={nameError ? "input error" : "input"}
              value={name}
              onChange={(e) => onInputName(e)}
            />
            <span className="label">{t("Gender")}</span>
            <CustomSelect
              options={genderOptions}
              className={genderError ? "input-select error" : "input-select"}
              placeholder={"Gender"}
              onChange={(e) => {
                setGenderError(false);
                setGender(e.value);
              }}
            />
            <InputBox
              error={addressError}
              label="Address"
              className="disabled"
              value={address}
              placeholder="To select address move the location pin on map."
            />
            {user.role.includes(4) && (
              <>
                <span className="label">{t("Main Company")}</span>
                <CustomSelect
                  className={
                    mainBranchIDError ? "input-select error" : "input-select"
                  }
                  isSearch={true}
                  options={gymsOptions}
                  placeholder="Select Main Company"
                  onChange={(e) => {
                    setMainBranchIDError(false);
                    setMainBranchID(e.value);
                  }}
                />
              </>
            )}

            <br />
            <div className="message">
              {createError && (
                <span className="error-msg">
                  {t("Cannot create a new gym. Try again.")}
                </span>
              )}
              {createSuccess && (
                <span className="success-msg">
                  {t("New Gym is created successfully.")}
                </span>
              )}
            </div>

            <div className="button-group">
              <span className="button" onClick={hideModal}>
                {t("Cancel")}
              </span>
              <span className="button active" onClick={onCreate}>
                {t("Create")}
              </span>
            </div>
          </div>
        </div>
        <div className="panel" style={{ height: "680px" }}>
          <span className="map-title">{t("Location")}</span>
          <ReactGoogleMap
            onChange={handleOnMapChange}
            APIKey="AIzaSyDQ4WBaFAr6HqsjFOiAc_8jXxBUgLel-TM"
            defaultLocation={location}
          />
          {/* <MapPicker
            defaultLocation={DEFAULT_LOCATION}
            zoom={DEFAULT_ZOOM}
            mapTypeId="roadmap"
            onChangeLocation={handleChangeLocation}
            apiKey={GOOGLE_MAP_API_KEY}
            style={{ height: "100%", width : "100%" }}
          /> */}
        </div>
        <span className="title-mobile">{t("Add GYM")}</span>
      </Container>
    </Modal>
  );
};

const Container = styled("div")`
  width: 828px;
  height: 100%;
  border-radius: 20px;
  background: black;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border: 1px solid #88888840;
  box-shadow: 0px 0px 45px 0px #88888820;

  .modal-backdrop {
    opacity: 0.2 !important;
  }

  .panel {
    width: 50%;
    display: flex;
    position: relative;
    flex-direction: column;
  }

  .error {
    border-color: red !important;
  }

  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #f2f2f2;
    padding: 20px 25px;
  }

  .setting {
    display: flex;
    flex-direction: column;
    padding: 25px 30px;

    .cost-panel {
      display: flex;
      justify-content: space-between;

      .cost-item {
        width: 45%;
        display: flex;
        position: relative;
        flex-direction: column;
      }
    }

    .label {
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: #888888;
      margin-bottom: 8px;
      margin-top: 10px;
    }

    .input {
      color: #888888;
      background: #1c1a1d;
      border-radius: 8px;
      border: 1px solid #888888;
      height: 42px;
      padding-left: 10px;
    }

    .qr-button {
      font-size: 18px;
      line-height: 22px;
      color: #f2f2f2;
      height: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1c1a1d;
      border: 1px solid #888888;
      border-radius: 12px;
      margin-top: 36px;
    }

    .qr-button.disable {
      display: none;
    }

    .qr-generated.disable {
      display: none;
    }

    .qr-generated {
      font-size: 18px;
      line-height: 22px;
      color: #f2f2f2;
      height: 130px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #1c1a1d;
      border: 1px solid #888888;
      border-radius: 12px;
      margin-top: 36px;

      .content-layout {
        display: flex;
        flex-direction: row;
        align-items: center;

        .qr-image {
          width: 30px;
          height: 30px;
          margin-right: 4px;
          color: #888888;
        }

        .text {
          font-size: 18px;
          line-height: 140%;
          letter-spacing: 0.01em;
          color: #888888;
        }
      }

      .button-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: 0.01em;
        color: #f2f2f2;
        margin-top: 30px;

        .download {
          display: flex;
          flex-direction: row;
          margin-right: 25px;

          .icon {
            font-size: 18px;
            color: #f2f2f2;
            margin-right: 6px;
          }
        }

        .delete {
          margin-left: 25px;
          color: #f03f3f;
        }
      }
    }

    .button {
      cursor: pointer;
      width: 110px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f2f2f2;
      font-size: 18px;
      line-height: 22px;
      border-radius: 8px;
      border: 1px solid #888888;
      margin-left: 16px;
    }

    .button.active {
      text-transform: uppercase;
      color: #000000;
      background: #feca00;
      border: none;
    }

    .button-group {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 68px;
    }

    .message {
      display: flex;
      align-items: center;
      margin-top: 10px;
      height: 20px;
    }

    .error-msg {
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: red;
    }

    .success-msg {
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: #f2f2f2;
    }
  }

  .gmnoprint {
    display: none;
  }

  .map-message {
    font-size: 0.9em;
    color: #feca00;
    margin-top: -5px;
    padding-left: 5px;
  }

  .map-title {
    position: absolute;
    left: 19px;
    top: 27px;
    font-size: 0.9em;
    line-height: 22px;
    color: #fff;
    z-index: 10;
    font-weight: 900;
    text-shadow: 0px 0px 3px #000000;
    background-color: #00000090;
    padding: 2px 10px;
    letter-spacing: 0.05em;
    border-radius: 15px;
  }

  .title-mobile {
    display: none;
  }

  @media (max-width: 1180px) {
    width: 600px;
  }
  @media (max-width: 768px) {
    width: 360px;
    border-radius: 0px;
    height: unset;
    flex-direction: column-reverse;
    .panel {
      width: 100%;
      height: auto;
      // overflow:auto;
      .title {
        display: none;
      }

      .button {
        margin-left: 0px;
      }

      .button-group {
        margin-top: 30px;
        justify-content: space-between;
      }
    }

    .title-mobile {
      padding: 18px 20px;
      display: unset;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #f2f2f2;
    }
  }
`;

export default Addgymbranch;
