export const MonthNames = [
    {
      label: "JAN",
      value: "JAN",
    },
    {
      label: "FEB",
      value: "FEB",
    },
    {
      label: "MAR",
      value: "MAR",
    },
    {
      label: "APR",
      value: "APR",
    },
    {
      label: "MAY",
      value: "MAY",
    },
    {
      label: "JUN",
      value: "JUN",
    },
    {
      label: "JUL",
      value: "JUL",
    },
    {
      label: "AUG",
      value: "AUG",
    },
    {
      label: "SEP",
      value: "SEP",
    },
    {
      label: "OCT",
      value: "OCT",
    },
    {
      label: "NOV",
      value: "NOV",
    },
    {
      label: "DEC",
      value: "DEC",
    },
  ];  