   export const Months = [
    {
      name: "Jan",
      price: 10,
    },
    {
      name: "Feb",
      price: 10,
    },
    {
      name: "Mar",
      price: 10,
    },
    {
      name: "Apr",
      price: 10,
    },
    {
      name: "May",
      price: 10,
    },
    {
      name: "Jun",
      price: 10,
    },
    {
      name: "Jul",
      price: 10,
    },
    {
      name: "Aug",
      price: 10,
    },
    {
      name: "Sep",
      price: 10,
    },
    {
      name: "Oct",
      price: 10,
    },
    {
      name: "Nov",
      price: 10,
    },
    {
      name: "Dec",
      price: 10,
    },
  ];
  