import users from "./users";
class Gym {
  constructor(
    companyID = "",
    facilityType = "",
    address = "",
    gender = "",
    name = "",
    owner = new users(),
    location = {},
    activated = false,
    images = [],
    mainBranchID = ""
  ) {
    this.id = "";
    this.companyID = companyID;
    this.facilityType = facilityType;
    this.address = address;
    this.gender = gender;
    this.name = name;
    this.owner = owner;
    this.location = location;
    this.activated = activated;
    this.images = images;
    this.mainBranchID = mainBranchID;
    Object.preventExtensions(this);
  }
  get CompanyID() {
    return this.companyID;
  }
  set CompanyID(value) {
    this.companyID = value;
  }
  get FacilityType() {
    return this.facilityType;
  }
  set FacilityType(value) {
    this.facilityType = value;
  }
  get Address() {
    return this.address;
  }
  set Address(value) {
    this.address = value;
  }
  get Gender() {
    return this.gender;
  }
  set Gender(value) {
    this.gender = value;
  }
  get Name() {
    return this.name;
  }
  set Name(value) {
    this.name = value;
  }
  get Owner() {
    return this.owner;
  }
  set Owner(value) {
    this.owner = value;
  }
  get Location() {
    return this.location;
  }
  set Location(value) {
    this.location = value;
  }
  get Activated() {
    return this.activated;
  }
  set Activated(value) {
    this.activated = value;
  }
  get Images() {
    return this.images;
  }
  set Images(value) {
    this.images = value;
  }

  get MainBranchId() {
    return this.mainBranch;
  }
  set MainBranchId(value) {
    this.mainBranch = value;
  }
}

export default Gym;
