import firebase, { auth, db } from "../main";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  setDoc,
  getDocs,
  getDoc,
  deleteDoc,
  collectionGroup,
  query,
  arrayUnion,
  arrayRemove,
  where,
  limit,
  Timestamp,
} from "firebase/firestore";
import { getActiveBranchesByGymId, getGym } from "./gym";
import {
  getActiveMembershipGymsList,
  getGymInfo,
  getGymInfo02,
  getGymsList,
} from "./gym_main";
import Attendance from "../../../common/models/attendance";
import { MonthNames } from "../../../common/data/MonthNames";
import { getAllAttendanceForYearController } from "../../../controllers/web/attendanceController";
import { getCollectionRef } from "../../../common/Helpers/GetCollectionRef";
const coll_attendance = "attendance";

export const getAllAttendance = () => {
  return new Promise(async (resolve, reject) => {
    let temp = [];
    let docsF = await getDocs(getCollectionRef(coll_attendance));
    if (docsF.size) {
      docsF.forEach((doc) => {
        const AttendanceObj = Object.assign(new Attendance(), {
          ...doc.data(),
          id: doc.id,
        });
        temp.push(AttendanceObj);
      });
    }
    resolve(temp);
  });
};

export const getTopGymsData = async (year) => {
  const attendance = await getAllAttendanceForYearController(year);
  const companys = await getActiveMembershipGymsList();
  let result = [];
  if (companys && companys.length > 0) {
    companys.forEach((company, index) => {
      if (attendance && attendance.length > 0) {
        const _att = attendance.filter(
          (att) => att.mainBranchID === company.id
        );
        result.push({
          name: company.name,
          customers: _att.length || 0,
          price: company.memberShipPlan.attendancePrice || 0,
        });
      }
    });
  }
  result = result.sort(function (a, b) {
    if (a.price > b.price) return 1;
    return 0;
  });
  return result.slice(0, 9);
};

export const getTopBranchesData = async (year, companyId = null) => {
  if (!companyId || companyId === null)
    return [
      {
        name: "-",
        customers: 0,
        price: 0,
      },
    ];
  const branches = await getActiveBranchesByGymId(companyId);
  const attendance = await getAllAttendanceForYearController(year);
  const _company = await getGymInfo(companyId);
  let result = [];
  if (branches && branches.length > 0) {
    branches.forEach((branch, index) => {
      if (attendance && attendance.length > 0) {
        const _att = attendance.filter((att) => att.gymId === branch.id);
        result.push({
          name: branch.name,
          customers: _att.length || 0,
          price: _company?.memberShipPlan?.attendancePrice || 0,
        });
      }
    });
  }
  result = result.sort(function (a, b) {
    if (a.customers > b.customers) return 1;
    return 0;
  });
  return result.slice(0, 9);
};

export const getAllAttendanceForYear = (year) => {
  return new Promise(async (resolve, reject) => {
    let temp = [];
    let docsF = await getDocs(getCollectionRef(coll_attendance));
    if (docsF.size) {
      docsF.forEach((doc) => {
        const data = doc.data();
        const entranceDate = new Timestamp(
          data.entrance_date.seconds,
          data.entrance_date.nanoseconds
        ).toDate();
        const docYear = new Date(entranceDate).getFullYear();
        if (docYear && docYear === year) {
          temp.push({
            ...doc.data(),
            date: entranceDate.toLocaleDateString(),
            id: doc.id,
          });
        }
      });
    }
    resolve(temp);
  });
};

// export const getAllAttendanceForYear = async (year) => {
//   const temp = [];
//   const querySnapshot = await getDocs(collection(db, coll_attendance));
//   querySnapshot.forEach((doc) => {
//     const attendanceObj = Object.assign(new Attendance(), {
//       ...doc.data(),
//       id: doc.id,
//     });
//     const entranceDate = new Timestamp(
//       attendanceObj.entrance_date.seconds,
//       attendanceObj.entrance_date.nanoseconds
//     ).toDate();
//     const docYear = new Date(entranceDate).getFullYear();
//     if (docYear === year) {
//       temp.push({
//         ...attendanceObj,
//         date: entranceDate.toLocaleDateString(),
//       });
//     }
//   });
//   return temp;
// };

// export const getGraphDataByYear = (year, companyId = null) => {
//   return new Promise(async (resolve, reject) => {
//     let temp = [];
//     let counterArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
//     let docsF = await getDocs(collection(db, coll_attendance));
//     if (docsF.size) {
//       docsF.forEach((doc) => {
//         const data = doc.data();
// const entranceDate = new Timestamp(
//   data.entrance_date.seconds,
//   data.entrance_date.nanoseconds
// ).toDate();
//         const docMonth = entranceDate.getMonth();
//         const docYear = entranceDate.getFullYear();
//         if (!companyId) {
//           if (docYear && docYear === year) {
//             counterArray[entranceDate.getMonth()] += 1;
//           }
//         } else {
//           if (docYear && docYear === year && data.mainBranchID === companyId) {
//             counterArray[entranceDate.getMonth()] += 1;
//           }
//         }
//       });
// const M = MonthNames.filter(
//   (month) => month.label === "JAN" || month.label === "FEB"
// ).map((month) => month.label);
//       // const M = [
//       //   "JAN",
//       //   "FEB",
//       //   "MAR",
//       //   "APR",
//       //   "MAY",
//       //   "JUN",
//       //   "JUL",
//       //   "AUG",
//       //   "SEP",
//       //   "OCT",
//       //   "NOV",
//       //   "DEC",
//       // ];

// M.forEach((e, index) => {
//   temp.push({ name: e, price: counterArray[index] });
// });
//     }
//     resolve(temp);
//   });
// };

export const getGraphDataByYear = async (year, companyId = null) => {
  try {
    let temp = [];
    let counterArray = Array(12).fill(0);

    const attendanceDocs = await getDocs(getCollectionRef(coll_attendance));
    attendanceDocs.forEach((doc) => {
      const attendance = Object.assign(new Attendance(), {
        ...doc.data(),
        id: doc.id,
      });
      const entranceDate = new Timestamp(
        attendance.entrance_date.seconds,
        attendance.entrance_date.nanoseconds
      ).toDate();
      if (!companyId) {
        if (entranceDate.getFullYear() === year) {
          counterArray[entranceDate.getMonth()] += 1;
        }
      } else {
        if (
          entranceDate.getFullYear() === year &&
          attendance.mainBranchID === companyId
        ) {
          counterArray[entranceDate.getMonth()] += 1;
        }
      }
    });
    const monthLabels = MonthNames.filter((month) => month.label).map(
      (month) => month.label
    );
    monthLabels.forEach((e, index) => {
      temp.push({ name: e, price: counterArray[index] });
    });

    return temp;
  } catch (error) {
    console.error("Error getting graph data by year:", error);
    return [];
  }
};

// export const getAllAttendanceBetween = (startDate, endDate) => {
//   return new Promise(async (resolve, reject) => {
//     startDate = new Date(startDate - 1);
//     endDate = new Date(new Date(endDate).setDate(endDate.getDate() + 1));
//     if (startDate <= endDate) {
//       let temp = [];
//       let docsF = await getDocs(collection(db, coll_attendance));
//       if (docsF.size) {
//         docsF.forEach((doc) => {
//           const data = doc.data();
//           const entranceDate = new Timestamp(
//             data.entrance_date.seconds,
//             data.entrance_date.nanoseconds
//           ).toDate();
//           const docDate = new Date(entranceDate);
//           if (docDate && docDate > startDate && docDate < endDate) {
//             temp.push({ ...doc.data(), id: doc.id });
//           }
//         });
//       }
//       resolve(temp);
//     } else {
//       resolve({
//         status: false,
//         message: "please enter valid dates.",
//         data: [],
//       });
//     }
//   });
// };

export const getAllAttendanceBetween = (startDate, endDate) => {
  return new Promise(async (resolve, reject) => {
    startDate = new Date(startDate - 1);
    endDate = new Date(new Date(endDate).setDate(endDate.getDate() + 1));
    if (startDate <= endDate) {
      let temp = [];
      const attendanceDocs = await getDocs(getCollectionRef(coll_attendance));
      if (attendanceDocs.size) {
        attendanceDocs.forEach((doc) => {
          const attendance = Object.assign(new Attendance(), {
            ...doc.data(),
            id: doc.id,
          });
          const entranceDate = new Timestamp(
            attendance.entrance_date.seconds,
            attendance.entrance_date.nanoseconds
          ).toDate();
          const docDate = new Date(entranceDate);
          if (docDate && docDate > startDate && docDate < endDate) {
            temp.push(attendance);
          }
        });
      }
      resolve(temp);
    } else {
      resolve({
        status: false,
        message: "please enter valid dates.",
        data: [],
      });
    }
  });
};

export const getAttendanceForGymowner = (gymId) => {
  return new Promise(async (resolve, reject) => {
    let temp = [];
    let docsF = await getDocs(
      query(
        getCollectionRef(coll_attendance),
        where("mainBranchID", "==", gymId)
      )
    );
    if (docsF.size) {
      docsF.forEach((doc) => {
        const attendanceObj = Object.assign(new Attendance(), {
          ...doc.data(),
          id: doc.id,
        });
        temp.push(attendanceObj);
      });
    }
    resolve(temp);
  });
};

export const getAttendanceForBranch = (branchId) => {
  return new Promise(async (resolve, reject) => {
    let temp = [];
    let docsF = await getDocs(
      query(getCollectionRef(coll_attendance), where("gymId", "==", branchId))
    );
    if (docsF.size) {
      docsF.forEach((doc) => {
        const attendanceObj = Object.assign(new Attendance(), {
          ...doc.data(),
          id: doc.id,
        });
        temp.push(attendanceObj);
      });
    }
    resolve(temp);
  });
};
