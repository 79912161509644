import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import StyledButton from "../../items/StyledButton";
import { useMediaQuery } from "react-responsive";
// import { getMemberships } from "../../../common/repository/js/membership";
import { getMembershipsController } from "../../../../../controllers/web/memberShipController";
import { Carousel } from "react-responsive-carousel";
import { Button } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import "./plans.scss";
import { fontWeight } from "@mui/system";

const PlanPage = () => {
  const { t } = useTranslation();
  const [memberships, setMemberships] = useState([]);

  useEffect(async () => {
    await getMembershipsController().then((datas) => {
      let temp_memberships = [];
      datas.data.forEach((data) => {
        temp_memberships.push({ ...data });
      });
      setMemberships(temp_memberships);
    });
  }, []);

  const MobilePlanItem = ({ data, index }) => {
    const topBorderColor = data.color
      ? `rgba(${data.color.r}, ${data.color.g}, ${data.color.b}, ${data.color.a})`
      : "orange";
    return (
      <div className="mobile-view-plan-item mt-sm-5">
        <div className="title_div">
          {/* plan title */}
          <h5 className="plan-name">{data.name}</h5>
        </div>
        <div
          className="plan-item"
          style={{
            borderTopColor: topBorderColor,
          }}
        >
          {/* pla description section */}
          <div className="plan-description">
            <h6 className="title">Facilities</h6>
            <ul className="plan-description-list">
              {data?.description &&
                data?.description?.length > 0 &&
                data.description.map((e, i) => {
                  return <li key={i}>{e}</li>;
                })}
            </ul>
          </div>

          {/* pricing section */}
          <div className="plan-pricing">
            <span className="amount">AED {data.monthly_amount || "00"}</span>
            <span className="unit">Monthly</span>
          </div>
          <div className="plan-pricing">
            <span className="amount">AED {data.annually_amount || "00"}</span>
            <span className="unit">Annually</span>
          </div>

          {/* Action Button */}
          {/*<div className="plan-selection-controls">*/}
          {/*  <Button variant="warning">Choose The Plan</Button>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: "0px",
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "-80px",
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  };
  let counter = 0;
  return (
    <div id="plan" className="home-plan-container">
      <div className="pricing">
        <p>{t("Our pass types")}</p>
      </div>
      <div className="d-block p-3">
        <div className="plan-desktop-container">
          <div className="d-flex flex-wrap gap-5 justify-content-center">
            {memberships &&
              memberships?.length > 0 &&
              memberships.map((item, index) => {
                counter++;
                if (item.approved) {
                  return (
                    <div key={index} className="  c-side">
                      <MobilePlanItem data={item} index={index} />
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlanPage;
