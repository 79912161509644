import { getRelatedRoles } from "./UserTypes";

export const loadRoleOptions = (loggedinUserProfile, userProfileToUpdate) => {
  let roleOptions = [];

  // Gym Owner
  if (loggedinUserProfile?.role?.includes(3)) {
    roleOptions = getRelatedRoles([1, 6]);
  }

  if (loggedinUserProfile?.role?.includes(6)) {
    roleOptions = getRelatedRoles([1, 6]);
  }

  if (loggedinUserProfile?.role?.includes(4)) {
    roleOptions = getRelatedRoles([1, 2, 3, 4, 5, 6]);
  }

  return roleOptions;
};
