import memberships from "./memberships";

class gym_main {
  constructor(
    approved = false,
    attendanceCost = "",
    bankname = "",
    iban = "",
    memberShipPlan = new memberships(),
    membershipCardPath = "",
    name = "",
    owner = "",
    tradeLicenseExpiryDate = "",
    tradeLicenseFilePath = "",
    tradeLicenseNumber = "",
    attachments = []
  ) {
    this.id = "";
    this.approved = approved;
    this.attendanceCost = attendanceCost;
    this.bankname = bankname;
    this.iban = iban;
    this.memberShipPlan = memberShipPlan;
    this.membershipCardPath = membershipCardPath;
    this.name = name;
    this.owner = owner;
    this.tradeLicenseExpiryDate = tradeLicenseExpiryDate;
    this.tradeLicenseFilePath = tradeLicenseFilePath;
    this.tradeLicenseNumber = tradeLicenseNumber;
    this.attachments = attachments;
    Object.preventExtensions(this);
  }

  get Approved() {
    return this.approved;
  }

  set Approved(approved) {
    this.approved = approved;
  }

  get AttendanceCost() {
    return this.attendanceCost;
  }

  set AttendanceCost(attendanceCost) {
    this.attendanceCost = attendanceCost;
  }

  get Bankname() {
    return this.bankname;
  }

  set Bankname(bankname) {
    this.bankname = bankname;
  }

  get Iban() {
    return this.iban;
  }

  set Iban(iban) {
    this.iban = iban;
  }

  get MemberShipPlan() {
    return this.memberShipPlan;
  }

  set MemberShipPlan(memberShipPlan) {
    this.memberShipPlan = memberShipPlan;
  }

  get MembershipCardPath() {
    return this.membershipCardPath;
  }

  set MembershipCardPath(membershipCardPath) {
    this.membershipCardPath = membershipCardPath;
  }

  get Name() {
    return this.name;
  }

  set Name(name) {
    this.name = name;
  }

  get Owner() {
    return this.owner;
  }

  set Owner(owner) {
    this.owner = owner;
  }

  get TradeLicenseExpiryDate() {
    return this.tradeLicenseExpiryDate;
  }

  set TradeLicenseExpiryDate(tradeLicenseExpiryDate) {
    this.tradeLicenseExpiryDate = tradeLicenseExpiryDate;
  }

  get TradeLicenseFilePath() {
    return this.tradeLicenseFilePath;
  }

  set TradeLicenseFilePath(tradeLicenseFilePath) {
    this.tradeLicenseFilePath = tradeLicenseFilePath;
  }

  get TradeLicenseNumber() {
    return this.tradeLicenseNumber;
  }

  set TradeLicenseNumber(tradeLicenseNumber) {
    this.tradeLicenseNumber = tradeLicenseNumber;
  }

  get Attachments() {
    return this.attachments;
  }

  set Attachments(attachments) {
    this.attachments = attachments;
  }
}

export default gym_main;
