import { createTheme, makeStyles } from "@mui/material";
import { red, yellow } from "@mui/material/colors";
import { NonceProvider } from "react-select";

export const outerTheme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          "& .Mui-checked": {
            "& .MuiSwitch-thumb": {
              backgroundColor: "#358f10",
            },
          },
          "& span.MuiSwitch-track": {
            backgroundColor: "grey !important",
            opacity: "0.3",
          },
        },
        thumb: {
          backgroundColor: red[800],
        },
        track: {
          backgroundColor: red[200],
        },
      },
    },
  },
});

export const getDaysBetween = (date1, date2) => {
  let result = 0;
  if (!date1 && !date2) return 0;
  if(!Date.parse(date1)) return 0;
  if(!Date.parse(date2)) return 0;
  let Difference_In_Time = date2.getTime() - date1.getTime();
  let year1 = date1.getFullYear(), year2 = date2.getFullYear();
  let yearsDiffrence = year2 - year1;
  if(yearsDiffrence < 0) return 0;
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  result = Difference_In_Days;
  result = result + 1;
  if(result < 1) return 0;
  if (result.toString().length === 1) result = "0" + result;
  if(result > 9999) return 0;
  return result;
};

export const getHoursBetween = (time1, time2) => {
  let result = {time : 0, unit : "hours"};
  if (!time1 && !time2) return {time : 0, unit : "hours"};
  let _d1 = new Date(Date.now());
  _d1.setHours(time1.split(":")[0], time1.split(":")[1], 0, 0);
  let _d2 = new Date(Date.now());
  _d2.setHours(time2.split(":")[0], time2.split(":")[1], 0, 0);
  let Difference_In_Time = _d2.getTime() - _d1.getTime();
  let Difference_In_Hours = Difference_In_Time / 3600000;
  result = Difference_In_Hours;
  if(result < 0) return {time : 0, unit : "hours"};
  let _subT = Math.round(result.toFixed(2).split(".")[1] * 0.6).toString();
  if(_subT.toString().length === 1) _subT = "0" + _subT;
  let _unit  = "hours";
  let _result = result >= 1 ?  result.toFixed(2).split(".")[0] + "." + _subT : (result * 0.6).toFixed(2) || "0";
  _result < 1 ? _unit = "Minute" : _unit = "Hours";
  let _finalTime = _result < 1 ? _result.split(".")[1] : _result;
  _finalTime.split(".")[1] == 0 ? _finalTime = _finalTime.split(".")[0] : _finalTime = _finalTime;
  if(_finalTime.toString().length === 1) _finalTime = "0" + _finalTime;
  if(_finalTime == "NAN") return {time : 0, unit : "hours"};
  return {time : _finalTime, unit : _unit};
};

export const formatDateHTML5 = (stringDate) => {
  let _da = new Date(stringDate || Date.now());
  let _date = _da.getDate();
  if (_date.toString().length == 1) _date = "0" + _date;

  let _month = _da.getMonth() + 1;
  if (_month.toString().length == 1) _month = "0" + _month;

  let _year = _da.getFullYear();
  if (_year.toString().length == 1) _year = "0" + _year;

  let _res = _year + "-" + _month + "-" + _date;
  return _res;
};
