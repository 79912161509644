import React from "react";
import "./themebutton.scss";

export default function ThemeButton({ secondary = false, ...rest }) {
  return (
    <div {...rest} className={`theme-button-bt ${secondary && "bt-secondary"}`}>
      {rest.children}
    </div>
  );
}
