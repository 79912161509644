import React, { useEffect } from "react";
import "./managemembership.scss";
import $ from "jquery";

export default function DraggableItem(item, index) {
  let color = `rgba(${item?.color?.r},${item?.color?.g},${item?.color?.b},${item?.color?.a})`;
  return (
    <li draggable keys={index}>
      <span
        className="draggable-item-icon"
        style={{
          backgroundColor: color,
        }}
      ></span>
      <span>{item.name}</span>
      <span className="priority-index">{index}</span>
    </li>
  );
}
