import {
  updateMembership,
  addMembership,
  changeMembership,
  changeMembershipPlanActivation,
  deleteMembership,
  getMembership,
  getMembershipDataList,
  getMembershipNames,
  getMemberships,
} from "../../repository/web/js/membership";

export const updateMembershipController = async (content, id) => {
  try {
    const response = await updateMembership(content, id);
    return response;
  } catch (error) {
    return { success: false };
  }
};

export const getMembershipsController = async () => {
  return await getMemberships().then((data) => {
    return data;
  });
};

export const getMembershipController = async (id) => {
  const membershipData = await getMembership(id);
  return membershipData;
};

export const getMembershipNamesController = async () => {
  try {
    const { success, data } = await getMembershipNames();
    return { success, data };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const getMembershipDataListController = async () => {
  return await getMembershipDataList().then((result) => {
    return result;
  });
};

export const changeMembershipPlanActivationController = async (id, state) => {
  const result = await changeMembershipPlanActivation(id, state);
  return result;
};

export const deleteMembershipController = async (id) => {
  try {
    const result = await deleteMembership(id);
    return result;
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};

export const addMembershipController = async (
  name,
  description,
  monthly_amount,
  annually_amount,
  attendancePrice,
  color
) => {
  try {
    const result = await addMembership(
      name,
      description,
      monthly_amount,
      annually_amount,
      attendancePrice,
      color
    );
    return result;
  } catch (error) {
    // Handle error here
    console.log(error);
    return { success: "", error: error.message };
  }
};

export const changeMembershipController = async (
  id,
  name,
  description,
  monthly_amount,
  annually_amount,
  attendancePrice,
  color
) => {
  await changeMembership(
    id,
    name,
    description,
    monthly_amount,
    annually_amount,
    attendancePrice,
    color
  )
    .then(() => {
      return { success: "success", error: "" };
    })
    .catch((error) => {
      return { success: "", error: error.message };
    });
};
