import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { exportComponentAsPNG } from "react-component-export-image";
import QRCode from "react-qr-code";
import "./QRModal.css";

export default function QRModal({
  showModal = false,
  qrValue = null,
  data = null,
  callback = () => {},
}) {
  let qrRef = useRef();
  const [show, setShow] = useState(showModal);
  const handleClose = () => {
    setShow(false);
    callback(false);
  };
  const handleShow = () => setShow(true);

  const handleDownload = () => {
    exportComponentAsPNG(qrRef, { fileName: "QR_CODE" });
    handleClose();
  };

  useEffect(() => {
    if (showModal) {
      handleShow();
    } else {
      handleClose();
      callback(false);
    }
  }, [showModal]);

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        backdrop="static"
        keyboard={false}
        fullscreen={true}
      >
        <Modal.Header className="bg-dark">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="warning" onClick={handleDownload}>
            Download
          </Button>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center bg-secondary">
          <div className="qrtoppad" ref={qrRef}>
            <div className="qrpanel">
              <div className="qrmodalbox">
                <div className={"qrboxcontainer"}>
                  <h2>www.vigory.app</h2>
                  <div className="qrbox">
                    <QRCode
                      value={qrValue || ""}
                      size={500}
                      bgColor="#F9F9F9"
                      fgColor="#1C1A1D"
                    />
                  </div>
                  <h1 className="text-primary">{data.name ? data.name : ""}</h1>
                  <h4 className="text-center p-3">
                    {data.address ? data.address : ""}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
}
