import {
  getBranches,
  addTrainer,
  changeGymActivation,
  getActiveBranchesByGymId,
  getAllClassesForGym,
  getBranchesByGymId,
  getBranchesListByGymId,
  getGym,
  getGyms,
  getTrainers,
  removeGymImageByIndex,
  saveClassesForGym,
  saveGym,
  updateClass,
  updateGym,
  updateGym02,
  updateGymImage,
  updateTrainer,
} from "../../repository/web/js/gym";

export const getGymsController = async () => {
  const gyms = await getGyms();
  return gyms;
};

export const getBranchesController = async () => {
  try {
    const branches = await getBranches();
    return branches;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getBranchesByGymIdController = async (mainGymId) => {
  const branches = await getBranchesByGymId(mainGymId);
  return branches;
};

export const getActiveBranchesByGymIdCotroller = async (mainGymId) => {
  try {
    const activeBranches = await getActiveBranchesByGymId(mainGymId);
    return activeBranches;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getBranchesListByGymIdController = async (mainGymId) => {
  const branchesList = await getBranchesListByGymId(mainGymId);
  return branchesList;
};

export const getGymController = async (id) => {
  const gym = await getGym(id);
  return gym;
};

export const saveGymController = async (
  name,
  address,
  location,
  userID,
  mainBranchID,
  gender,
  facilityType
) => {
  const result = await saveGym(
    name,
    address,
    location,
    userID,
    mainBranchID,
    gender,
    facilityType
  );
  return result;
};

export const updateGym02Controller = async (id, name, gender, facility_type) => {
  const result = await updateGym02(id, name, gender, facility_type);
  return result;
};

export const updateGymController = async (id, name, visitCost, attendCost) => {
  const response = await updateGym(id, name, visitCost, attendCost);
  return response;
};

export const updateGymImageController = async (id, image) => {
  const response = await updateGymImage(id, image);
  return response;
};

export const changeGymActivationController = async (id, state) => {
  const response = await changeGymActivation(id, state);
  return response;
};

export const removeGymImageByIndexController = async (id, index) => {
  try {
    const result = await removeGymImageByIndex(id, index);
    return result;
  } catch (error) {
    console.log(error);
    return { success: "", error: error.message };
  }
};

export const getAllClassesForGymController = async (gymId) => {
  const result = await getAllClassesForGym(gymId);
  return result;
};

export const saveClassesForGymController = async (gymId, info) => {
  const id = await saveClassesForGym(gymId, info);
  return id;
};

export const updateClassController = async (gymId, docId, content) => {
  const result = await updateClass(gymId, docId, content);
  return result;
};

export const getTrainersController = async (gymId) => {
  const result = await getTrainers(gymId);
  return result;
};

export const addTrainerController = async (gymId, data) => {
  const result = await addTrainer(gymId, data);
  return result;
};

export const saveTrainer = async (gymId, data) => {
  const result = await updateTrainer(gymId, data);
  return result;
};

export const updateTrainerController = async (gymId, trainerData) => {
  const result = await updateTrainer(gymId, trainerData);
  return result;
};
