import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import CustomSelect from "../item/CustomSelect";
import UserTypes from "../../../../../../common/data/UserTypes";
import { emailValidator } from "../../../../../../common/validations/emailValidator";
import CustomLoading from "../../../items/loadingBar";
// import { signUpWithEmailAndPassword } from "../../../../common/repository/js/firebaseAuth";
import PhoneInput from "react-phone-input-2";
import InputBox from "../../../custom/InputBox/InputBox";
import { useSelector, useDispatch } from "react-redux";
// import { getActiveCompanyList } from "../../../../common/repository/js/gym_main";
import { getActiveCompanyListController } from "../../../../../../controllers/web/gymMainController";
// import { getBranchesListByGymId } from "../../../../repository/web/js/gym";
import { getBranchesListByGymIdController } from "../../../../../../controllers/web/gymController";
import { BranchUserTypes } from "../../../../../../common/data/BranchUserTypes";
import { GymManagerUserTypes } from "../../../../../../common/data/GymManagerUserTypes";
import {
  setIsOpen,
  setBranchError,
  setBranchList,
  setCompanyError,
  setCompanyList,
  setCreateError,
  setCreateSuccess,
  setDisable,
  setEmail,
  setEmailError,
  setErrorMessage,
  setLoading,
  setMembership,
  setMembershipError,
  setMobile,
  setMobileError,
  setName,
  setNameError,
  setSelectedBranch,
  setSelectedCompany,
  setType,
  setTypeError,
} from "../../../../redux/slices/AddUser.slice";
import useManageUserController from "../../../componentsController/ManageUserController";
const AddUser = (props) => {
  const { SignupWithEmailAndPassword } = useManageUserController();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user: loggedinUser } = useSelector((state) => state.user);
  const {
    isOpen,
    name,
    email,
    mobile,
    type,
    memberShipPlan,
    nameError,
    emailError,
    mobileError,
    typeError,
    membershipError,
    companyList,
    selectedCompany,
    companyError,
    branchList,
    selectedBranch,
    branchError,
    createSuccess,
    createError,
    errorMessage,
    disable,
    loading,
  } = useSelector((state) => state.addUser);
  // const [isOpen, setIsOpen] = useState(false);
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [mobile, setMobile] = useState("");
  // const [type, setType] = useState([]);
  // const [membership, setMembership] = useState("");

  // const [nameError, setNameError] = useState(false);
  // const [emailError, setEmailError] = useState(false);
  // const [mobileError, setMobileError] = useState(false);
  // const [typeError, setTypeError] = useState(false);
  // const [membershipError, setMembershipError] = useState(false);

  // const [companyList, setCompanyList] = useState([]);
  // const [selectedCompany, setSelectedCompany] = useState("");
  // const [companyError, setCompanyError] = useState(false);

  // const [branchList, setBranchList] = useState([]);
  // const [selectedBranch, setSelectedBranch] = useState("");
  // const [branchError, setBranchError] = useState(false);

  // const [createSuccess, setCreateSuccess] = useState(false);
  // const [createError, setCreateError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  // const [disable, setDisable] = useState(false);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadActiveCompanies();
    dispatch(setIsOpen(props.isOpen));
  }, [props]);

  const resetStates = () => {
    dispatch(setName(""));
    dispatch(setEmail(""));
    dispatch(setMobile(""));
    dispatch(setType([]));
    dispatch(setMembership(""));
    dispatch(setNameError(false));
    dispatch(setEmailError(false));
    dispatch(setMobileError(false));
    dispatch(setTypeError(false));
    dispatch(setMembershipError(false));
    dispatch(setDisable(false));
    dispatch(setCompanyError(false));
    dispatch(setSelectedCompany(""));
    dispatch(setBranchError(false));
    dispatch(setSelectedBranch(""));
    dispatch(setBranchList([]));
  };

  const hideModal = () => {
    resetStates();
    dispatch(setIsOpen(false));
    emptyMessage();
    props.close();
  };

  const emptyMessage = () => {
    dispatch(setCreateSuccess(false));
    dispatch(setCreateError(false));
  };

  const onSelectType = (data) => {

    dispatch(setTypeError(false));
    emptyMessage();
    // let temp = [];
    // data.forEach((element) => {
    //   temp.push(element.value);
    // });
    // console.log("roles :", temp);
    dispatch(setType(data));
  };

  const onSelectMembership = (data) => {

    dispatch(setMembershipError(false));
    emptyMessage();
    if (!props.role.includes(3) && props.role.includes(4)) {
      dispatch(setMembership(data.value));
    }
  };

  const onSave = async () => {
    if (disable) return;
    if (name === "" || mobile === "" || emailValidator(email) || type === "") {
      dispatch(setNameError(name === ""));
      dispatch(setEmailError(emailValidator(email)));
      dispatch(setMobileError(mobile === ""));
      dispatch(setTypeError(type.length === 0));
      dispatch(setMembershipError(memberShipPlan === ""));
      dispatch(setLoading(false));
      return;
    }
    if ((type.value == 1 || type.value == 6) && loggedinUser.role.includes(4)) {
      dispatch(setCompanyError(selectedCompany == ""));
      dispatch(setBranchError(selectedBranch == ""));
      if (selectedCompany == "") {
        return;
      }
      if (selectedBranch == "") {
        return;
      }
    }
    if (type.value == 5) {
      if (memberShipPlan === "") {
        dispatch(setMembershipError(memberShipPlan === ""));
        return;
      }
    }

    dispatch(setLoading(true));
    dispatch(setDisable(true));
    let user = {
      sure_name: name,
      username: email,
      phone: mobile,
      role: [type.value],
    };
    if (props.gymID !== undefined) {
      user = { ...user, company_id: props.gymID };
    }
    // is user is gym manager
    if (loggedinUser.role.includes(6)) {
      const currentBranch = JSON.parse(localStorage.getItem("branchDoc"));
      user = {
        ...user,
        company_id: loggedinUser.company_id,
        company_name: loggedinUser.company_name,
        // facility_id: selectedBranch.value,
        // facility_name: selectedBranch.label
        // branches: [{ value: currentBranch.qrCode, label: currentBranch.name }],
      };
    }

    if ((type.value == 1 || type.value == 6) && loggedinUser.role.includes(4)) {
      const currentBranch = JSON.parse(localStorage.getItem("branchDoc"));

      if (selectedCompany !== "" && selectedBranch !== "") {
        // console.log("Branch selected is here", selectedBranch)
        user = {
          ...user,
          company_id: loggedinUser.company_id,
          company_name: loggedinUser.company_name,
          facility_id: selectedBranch.value,
          facility_name: selectedBranch.label
          // branches: [selectedBranch],
         
        };
      }
    }
    if(loggedinUser){
      const currentBranch = JSON.parse(localStorage.getItem("branchDoc"));

      user = {
        ...user,
        company_id: loggedinUser.company_id,
        company_name: loggedinUser.company_name,
        // facility_id: currentBranch.name,
        // facility_name: currentBranch.qrCode
        
      };
    }

    const data = await SignupWithEmailAndPassword(user, "123456");
    // await signUpWithEmailAndPassword(user, "123456")
    //   .then((data) => {
    if (data.error) {
      dispatch(setCreateError(true));
      dispatch(setDisable(false));
      dispatch(setErrorMessage(data.error));
    } else {
      dispatch(setCreateSuccess(true));
      dispatch(setDisable(false));
      resetStates();
      props.refresh();
      dispatch(setIsOpen(false));
      emptyMessage();
      props.close();
    }
    dispatch(setLoading(false));
  };
  // )
  // .catch((e) => {
  //   dispatch(setCreateError(true));
  // });
  // };

  const loadActiveCompanies = async () => {
    const dataList = await getActiveCompanyListController();
    // console.log("Data list is here", dataList)
    if (dataList) {
      dispatch(setCompanyList(dataList));
    } else {
      dispatch(setCompanyList([]));
    }
  };

  const onChangeCompany = (company) => {
    dispatch(setSelectedCompany(company));
    loadActiveBranch(company.value);
  };

  const loadActiveBranch = async (companyId) => {
    dispatch(setBranchList([]));
    const branchData = await getBranchesListByGymIdController(companyId);
    if (branchData) {
      dispatch(setBranchList(branchData));
    } else {
      dispatch(setBranchList([]));
    }
  };

  
  

  return (
    <Modal
      show={isOpen}
      onHide={hideModal}
      centered
      dialogClassName="login-modal"
    >
      <CustomLoading isStart={loading} />

      <Container>
        <div className="header">{t("Add user")}</div>
        <div className="content">
          <span className="label">{t("Name")}</span>
          <input
            className={nameError ? "input error" : "input"}
            value={name}
            onChange={(e) => {
              emptyMessage();
              dispatch(setNameError(false));
              dispatch(setName(e.target.value));
            }}
          />
          <span className="label">{t("Email")}</span>
          <input
            className={emailError ? "input error" : "input"}
            type="email"
            value={email}
            onChange={(e) => {
              dispatch(setEmailError(false));
              emptyMessage();
              dispatch(setEmail(e.target.value));
            }}
          />
          <InputBox
            className={mobileError ? "inputbox-error" : ""}
            label={t("Mobile")}
            required
            type="phone"
            value={mobile}
            onChange={(phone) => {
              dispatch(setMobile(phone));
              dispatch(setMobileError(false));
            }}
          />
          <span className="label">{t("Type")}</span>
          <CustomSelect
            options={
              loggedinUser.role.includes(4)
                ? UserTypes
                : loggedinUser.role.includes(6)
                ? GymManagerUserTypes
                : BranchUserTypes
            }
            placeholder={""}
            className={typeError ? "input-select error" : "input-select"}
            isMulti={false}
            value={type}
            onChange={onSelectType}
          />

          {type.value == 5 && (
            <React.Fragment>
              <span className="label">{t("Membership")}</span>
              <CustomSelect
                options={props.memberships}
                placeholder={""}
                className={
                  membershipError ? "input-select error" : "input-select"
                }
                onChange={onSelectMembership}
              />
            </React.Fragment>
          )}

          {(type.value == 1 || type.value == 6) &&
            loggedinUser.role.includes(4) && (
              <React.Fragment>
                <span className="label">{t("Company")}</span>
                <CustomSelect
                  options={companyList}
                  placeholder={""}
                  className={
                    companyError === true
                      ? "input-select error"
                      : "input-select"
                  }
                  onChange={onChangeCompany}
                />
                <span className="label">{t("Faciity")}</span>
                <CustomSelect
                  options={branchList}
                  placeholder={""}
                  className={
                    branchError === true ? "input-select error" : "input-select"
                  }
                  onChange={(e) => dispatch(setSelectedBranch(e))}
                />
              </React.Fragment>
            )}
        </div>
        <div className="message">
          {createError && (
            <span className="error-msg">
              {t("Cannot create a new user")}, {errorMessage}
            </span>
          )}
          {createSuccess && (
            <span className="success-msg">
              {t("New User is created successfully.")}
            </span>
          )}
        </div>
        <div className="button-group">
          <span className="button" onClick={hideModal}>
            {t("Cancel")}
          </span>
          <span className="button active" onClick={onSave}>
            {t("Save")}
          </span>
        </div>
      </Container>
    </Modal>
  );
};

const Container = styled("div")`
  width: 498px;
  height: max-content;
  background-color: black;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  border: 1px solid #88888840;
  box-shadow: 0px 0px 45px 0px #88888820;
  .header {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: #f2f2f2;
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }
  .label {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #888888;
    margin-bottom: 8px;
  }
  .input {
    color: #888888;
    background: #1c1a1d;
    border-radius: 8px;
    border: 1px solid #888888;
    height: 38px;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  .button {
    cursor: pointer;
    width: 110px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f2f2f2;
    font-size: 18px;
    line-height: 22px;
    border-radius: 8px;
    border: 1px solid #888888;
    margin-left: 16px;
  }
  .button.active {
    text-transform: uppercase;
    color: #000000;
    background: #feca00;
    border: none;
  }
  .button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
  }
  .input-select {
    margin-bottom: 20px;
  }
  .error {
    border-color: red !important;
  }
  .message {
    display: flex;
    align-items: center;
    margin-top: 10px;
    height: 20px;
  }
  .error-msg {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: red;
  }
  .success-msg {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #f2f2f2;
  }
  @media (max-width: 768px) {
    width: 300px;
    height: unset;
    .header {
      margin-bottom: 20px;
    }

    .button {
      margin-right: 0px;
    }
    .button-group {
      justify-content: space-between;
      margin-top: 50px;
    }
  }
`;

export default AddUser;
