import React from "react";
import "./pagesection.scss";

export default function PageSection(props) {
  return (
    <div className={`page-section-container ${props.className || ""}`}>
      {props.children}
    </div>
  );
}

export const PageSectionBody = (props) => {
  return <div className="page-section-body">{props.children}</div>;
};
