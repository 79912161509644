import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdArrowRight, MdCheck, MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
// import {
//   getGym,
//   removeGymImageByIndex,
//   updateGym,
//   updateGym02,
//   updateGymImage,
// } from "../../../../../common/repository/js/gym";
import {
  getGymController,
  removeGymImageByIndexController,
  updateGymController,
  updateGym02Controller,
  updateGymImageController,
} from "../../../../../../../controllers/web/gymController";
// import { getGymInfo } from "../../../../../repository/web/js/gym_main";
import { getGymInfoController } from "../../../../../../../controllers/web/gymMainController";
import AccessManager from "../../../../custom/AccessManager/AccessManager";
import ConfirmDialog from "../../../../custom/ConfirmDialog/ConfirmDialog";
import Divider from "../../../../custom/Divider/Divider";
import FileUploader from "../../../../custom/FileUploader/FileUploader";
import InputBox from "../../../../custom/InputBox/InputBox";
import Padding from "../../../../custom/Padding/Padding";
import PageHeader from "../../../../custom/PageHeader/PageHeader";
import PageSection, {
  PageSectionBody,
} from "../../../../custom/PageSection/PageSection";
import Panel from "../../../../custom/Panel/Panel";
import QRModal from "../../../../custom/QRModal";
import Toster from "../../../../custom/Toaster/Toster";
import CustomLoading from "../../../../items/loadingBar";
import CustomQRCode from "../../item/CustomQRCode";
import CustomSelect from "../../item/CustomSelect";
import "./BranchDetailPage.scss";
import { genderOption } from "../../../../../../../common/data/GenderOptions";
import { activatedOption } from "../../../../../../../common/data/ActivatedOptions";
import { FacilityTypes } from "../../../../../../../common/data/FacilityTypes";
import { useDispatch } from "react-redux";

export default function BranchDetailPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const qrRef = useRef();
  const { user: userInfo } = useSelector((state) => state.user);
  const [branchData, setBranchData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const branch =
    location?.state?.gym || JSON.parse(localStorage.getItem("branchDoc"));
  const owners = location?.state?.owners || "";
  const [formData, setFormData] = useState({
    name: branch?.name || "",
    nameError: false,
    gender: { value: branch?.gender, label: branch?.gender } || "",
    genderError: false,
    company: "-",
    branchId: branch.mainBranchID,
    activated: {
      value: branch?.activated,
      label: branch?.activated ? "true" : "false",
    },
    activatedError: false,
    // owner: owners[0].label,
    address: branch?.address || "",
    images: [],
    facilityType:
      { value: branch?.facilityType, label: branch?.facilityType } || "",
    facilityError: false,
  });
  if (userInfo.role.includes(3) || userInfo.role.includes(4)) {
    localStorage.setItem("branchDoc", JSON.stringify(branch));
  }

  useEffect(() => {
    setLoading(true);
    loadCompanyDetails().then((res) => {
      if (res) {
        loadBranchDetails();
      }
    });
  }, []);

  const refreshData = () => {
    setLoading(true);
    loadCompanyDetails().then((res) => {
      if (res) {
        loadBranchDetails(res);
      }
    });
  };

  const loadCompanyDetails = async () => {
    let result = null;
    setLoading(true);
    const companyData = await getGymInfoController(
      branch?.mainBranchID || "unknown"
    );
    if (companyData) {
      setFormData({ ...formData, company: companyData.name });
      setLoading(false);
      result = companyData;
      return result;
    } else {
      setLoading(false);
      return result;
    }
  };

  const loadBranchDetails = async (company) => {
    setLoading(true);
    const _branchData = await getGymController(branch?.qrCode || "unknown");
    if (_branchData) {
      setBranchData(_branchData);
      setFormData({
        ...formData,
        name: _branchData.name,
        gender: { value: _branchData.gender, label: _branchData.gender },
        company: company && company.name,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const AddImageModal = (props) => {
    const [image, setImage] = useState([]);
    const onImageChange = (imageList) => {
      setImage(imageList);
    };
    const uploadFile = async () => {
      ConfirmDialog.hide();
      const imageFile = image[0];
      const storage = getStorage();
      const userRef = ref(
        storage,
        `/branch/${props.branch.qrCode}/branchimage_${new Date().getTime()}_${
          imageFile.name
        }`
      );
      const uploadTask = uploadBytesResumable(userRef, imageFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setLoading(true);
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              //console.log("Upload is paused");
              break;
            case "running":
              //console.log("Upload is running");
              break;
          }
        },
        (error) => {},
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then(
            async (downloadURL) => {
              let result = await updateGymImageController(
                props.branch.qrCode,
                downloadURL
              );
              if (result.success) {
                ConfirmDialog.hide();
                Toster.show("Image added.");
                props.refreshData();
              } else {
                //remove the added document
                ConfirmDialog.hide();
                props.refreshData();
              }
            }
          );
        }
      );
    };
    return (
      <div className="image-modal-container">
        <FileUploader onChange={onImageChange} acceptTypes={["png", "jpg"]}>
          {({ onSelect, onRemove, error }) => {
            return (
              <div className="attachment-file-uploader">
                <img
                  className="img-fluid"
                  alt=""
                  src={image[0] ? URL.createObjectURL(image[0]) : null}
                />
                {error && <div className="error-msg">{error}</div>}
                {!error && (
                  <div className="file-name">
                    {image.length > 0 ? image[0].name : "No file selected"}
                  </div>
                )}
                {image.length === 0 && (
                  <Button size="sm" variant="success" onClick={onSelect}>
                    Select File
                  </Button>
                )}
                {image.length > 0 && (
                  <>
                    <div className="d-inline-flex w-100">
                      <Button size="sm" variant="danger" onClick={onRemove}>
                        <MdClose /> Change
                      </Button>
                    </div>
                    <br />
                    <div className="d-inline-flex w-100">
                      <Button size="" variant="warning" onClick={uploadFile}>
                        <MdCheck /> UPLOAD
                      </Button>
                    </div>
                  </>
                )}
              </div>
            );
          }}
        </FileUploader>
      </div>
    );
  };

  const handleAddImageClick = () => {
    ConfirmDialog.show({
      title: "Add Image",
      message: <AddImageModal branch={branch} refreshData={refreshData} />,
      showFooter: false,
    });
  };

  const handleUpdateBranch = () => {
    if (formData.name === "" || formData.gender === "") {
      setFormData({
        ...formData,
        nameError: formData.name === "",
        genderError: formData.gender === "",
        activatedError: formData.activated === "",
        facilityError: formData.facilityType === "",
      });
      return;
    }
    setLoading(true);
    updateGym02Controller(
      branch.qrCode,
      formData.name.trim(),
      formData.gender.value,
      formData.facilityType.value
    ).then((data) => {
      if (data.success === "success") {
        refreshData();
      }
    });
  };

  const QRSection = (
    <div className="qr-container">
      <QRModal
        showModal={showQrModal}
        qrValue={branch?.qrCode || ""}
        callback={setShowQrModal}
        data={branch}
      />
      <CustomQRCode
        containerClass="qr-image"
        size={170}
        ref={qrRef}
        qrCode={branch.qrCode ? branch.qrCode : ""}
      />
      <Button variant="light" onClick={(e) => setShowQrModal(true)}>
        Download
      </Button>
    </div>
  );

  const FormSection = (
    <div className="form-container">
      <div className="form-group">
        <InputBox
          error={formData.nameError}
          label="Name"
          required
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        <InputBox
          label="Branch ID"
          className="disabled"
          required
          value={formData.branchId}
          onChange={(e) =>
            setFormData({ ...formData, branchId: e.target.value })
          }
        />
      </div>
      <div className="form-group">
        <div className="form-row">
          <span className="form-label">Gender *</span>
          <CustomSelect
            className={formData.genderError ? "select-error" : ""}
            options={genderOption}
            value={formData.gender}
            onChange={(e) => setFormData({ ...formData, gender: e })}
          />
        </div>
        <div className="form-row">
          <InputBox
            className="disabled"
            label="Company"
            value={formData.company}
          />
        </div>
      </div>
      <div className="form-group">
        <div className="form-row">
          <span className="form-label">Facility Type</span>
          <CustomSelect
            className={formData.facilityError ? "select-error" : ""}
            options={FacilityTypes}
            value={formData?.facilityType}
            onChange={(e) => setFormData({ ...formData, facilityType: e })}
          />
        </div>
        <div className="form-row">
          <InputBox className="disabled" label="Owner" value={formData.owner} />
        </div>
      </div>
      <div className="form-row">
        <span className="form-label">Activated</span>
        <CustomSelect
          className={formData.activatedError ? "select-error" : ""}
          options={activatedOption}
          value={formData?.activated}
          onChange={(e) => setFormData({ ...formData, activated: e })}
        />
      </div>
      <InputBox className="disabled" label="Address" value={formData.address} />
      {(userInfo.role.includes(3) || userInfo.role.includes(4)) && (
        <Button
          variant="warning"
          className="ms-5 me-5 mt-3"
          onClick={handleUpdateBranch}
        >
          UPDATE
        </Button>
      )}
    </div>
  );

  const ImageItem = (props) => {
    const handleImageDelete = (index) => {
      ConfirmDialog.show({
        message: "Are you sure to remove?",
        okCallback: async () => {
          onRemoveImage(index).then((res) => {
            if (res) {
              ConfirmDialog.hide();
              props.refreshData();
            }
          });
        },
      });
    };

    const handleImageClick = (e) => {
      if (e.target.src) {
        window.open(e.target.src, "blank");
      }
    };

    const onRemoveImage = async (index) => {
      return await removeGymImageByIndexController(
        props.branch.qrCode,
        index
      ).then((data) => {
        if (data) {
          return true;
        } else {
          return false;
        }
      });
    };
    return (
      <div key={props.index} className="image-item-container">
        <img onClick={handleImageClick} alt="" src={props.data || null} />
        <span
          className="image-delete-button"
          onClick={(e) => handleImageDelete(props.index)}
        ></span>
      </div>
    );
  };

  const GymImagesSection = (
    <div className="w-100">
      <Divider marginTop={"10px"}>
        <span>GYM IMAGES</span>
        <Button variant="warning" size="sm" onClick={handleAddImageClick}>
          Add Image
        </Button>
      </Divider>
      <div className="gym-images-container">
        {branchData?.images &&
          branchData?.images?.length > 0 &&
          branchData.images.map((e, i) => {
            return (
              <ImageItem
                refreshData={refreshData}
                branch={branchData}
                key={i}
                data={e}
                index={i}
              />
            );
          })}
      </div>
    </div>
  );

  return (
    <AccessManager allowedRoles={[4, 3, 6]}>
      <CustomLoading isStart={loading} />
      <PageSection>
        <PageHeader>
          <span>
            <div className="page-header-title">
              {branch.name || "Branch Details"}
            </div>
          </span>
          <span className="d-flex"></span>
        </PageHeader>
        <PageSectionBody>
          <Row className="gx-0 gy-0 w-100">
            <Col lg={8}>
              <Panel>{FormSection}</Panel>
            </Col>
            <Col lg={4}>
              <Panel>{QRSection}</Panel>
            </Col>
            {(userInfo.role.includes(3) || userInfo.role.includes(4)) && (
              <Col lg={12}>
                <Panel>{GymImagesSection}</Panel>
              </Col>
            )}
          </Row>
        </PageSectionBody>
      </PageSection>
    </AccessManager>
  );
}
