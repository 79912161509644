import { createSlice } from "@reduxjs/toolkit";

const LocalStates = createSlice({
  name: "LocalStates",
  initialState: {
    username: "",
    userEmail: "",
    authToken:"",
    fromGoogleLogin: false,
  },
  reducers: {
    setUsernameFromGoogle: (state, action) => {
      state.username = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setFromGoogle: (state, action) => {
      state.fromGoogleLogin = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
  },
});

export const { setAuthToken ,setFromGoogle, setUserEmail, setUsernameFromGoogle } =
  LocalStates.actions;
export default LocalStates.reducer;
