export const BranchUserTypes = [
  {
    value: 1,
    label: "Trainer",
  },
  {
    value: 6,
    label: "Gym Manager",
  },
];
