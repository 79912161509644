import { t } from "i18next";
import React, { useEffect } from "react";
import styled from "styled-components";
import StyledButton from "../../items/StyledButton";
import { FaReddit } from "react-icons/fa";
import { Button, Spinner } from "react-bootstrap";
import {
  IoMdPaperPlane,
  IoLogoYoutube,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTwitter,
} from "react-icons/io";
import InputBox from "../../custom/InputBox/InputBox";
import { useState } from "react";
import ConfirmDialog from "../../custom/ConfirmDialog/ConfirmDialog";
import { emailValidator } from "../../../../../common/validations/emailValidator";
import { getEmailVerificationCode } from "../../../../../common/validations/verification";
import Toster from "../../custom/Toaster/Toster";
import {
  addSubscrier,
  isExists,
} from "../../../../../repository/web/js/subscriber.service";

const ConfirmationModal = ({ email, callback }) => {
  const [otp, setOTP] = useState("");
  const [sendedOTP, setSendedOTP] = useState("");
  const [error, setError] = useState("");
  const [isCheckingEmail, setIsCheckingEmail] = useState(true);
  const [isEmailAlreadyExists, setIsEmailAlreadyExists] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);

  useEffect(async () => {
    setTimeout(async () => {
      const _exists = await isExists(email);
      if (_exists) {
        setIsEmailAlreadyExists(true);
        setIsCheckingEmail(false);
      } else {
        setIsCheckingEmail(false);
        setIsEmailAlreadyExists(false);
      }
    }, 2000);
  });

  const handleOTPSend = () => {
    try {
      getEmailVerificationCode(email).then((code) => {
        if (code) {
          setSendedOTP(code);
          setIsOTPSent(true);
        } else {
          setError(t("Can not get verification code."));
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleVerifyOTP = () => {
    if (otp === sendedOTP) {
      addSubscrier({ email: email })
        .then((res) => {
          callback();
          Toster.show("Thanks to subscribe newsletters.");
          ConfirmDialog.hide();
        })
        .catch((error) => {
          Toster.show("Subscription is failed.", true);
        });
    } else {
      Toster.show("OTP is invalid or expired.", true);
    }
  };
  return (
    <div style={{ minWidth: "300px" }} className="w-100 d-flex flex-column">
      <div className="d-flex flex-column w-100">
        {isCheckingEmail && (
          <span className="p-3 text-light d-flex flex-column align-items-center">
            <Spinner
              color="warning"
              size="sm"
              animation="border"
              className="mb-3 text-warning"
            />
            Please wait while checking your email..
          </span>
        )}
        {!isCheckingEmail && !isEmailAlreadyExists && (
          <>
            <span className="text-secondary mb-3">
              You have to verify the email address. We will sent the OTP to your
              email <span className="text-warning">{email}</span>
              {". "} Please verify your email to subscribe newsletter.
            </span>
            {!isOTPSent && (
              <Button onClick={handleOTPSend} variant="warning">
                Send OTP
              </Button>
            )}
            {isOTPSent && (
              <>
                <InputBox
                  maxLength="4"
                  type="search"
                  label="Enter OTP"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                />
                <Button onClick={handleVerifyOTP} variant="warning">
                  Verify
                </Button>
              </>
            )}
          </>
        )}
        {!isCheckingEmail && isEmailAlreadyExists && (
          <span className="p-3 text-light">
            You have <span className="text-warning">already</span> subscribed
            newsletter with email <span className="text-warning">{email}</span>.
            Thanks to showing your interest in our services.
          </span>
        )}
        <span className="p-2 w-100 d-flex text-danger">{error}</span>
      </div>
    </div>
  );
};

const SubscribePage = () => {
  const [email, setEmail] = useState("");

  const handleSubscribeClick = () => {
    const _emailValidation = emailValidator(email);
    if (_emailValidation !== "") {
      return ConfirmDialog.show({
        message: _emailValidation,
        showFooter: false,
      });
    }
    ConfirmDialog.show({
      title: "Verify your email",
      message: (
        <ConfirmationModal email={email} callback={() => setEmail("")} />
      ),
      showFooter: false,
    });
  };

  return (
    <Container id="subscribe">
      <div className="containerLayout">
        <span className="title">{t("Subscribe newsletter")}</span>
        <div className="d-lg-flex justify-content-between align-items-end w-100">
          <div className="newsletter">
            <InputBox
              className="mb-0"
              placeholder={t("Enter your email address")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              className="ms-2 me-2 mb-1 p-2 ps-4 pe-4"
              style={{ textTransform: "uppercase" }}
              variant="warning"
              onClick={handleSubscribeClick}
            >
              {t("subscribe")}
            </Button>
          </div>
          <div className="social-layout">
            <p className="label">{t("Visit our social media ")}</p>
            <div className="social">
              {/*<IoLogoYoutube className="item" />*/}
              <IoLogoFacebook className="item" />
              <IoLogoInstagram className="item" />
              {/*<FaReddit className="item" />*/}
              {/*<IoLogoTwitter className="item" />*/}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled("div")`
  padding: 3.75em 8em 0px;
  margin-top: 5em;
  .containerLayout {
    background: black;
    border-radius: 1.3em 1.3em 0px 0px;
    padding: 1.8em;
    display: flex;
    flex-direction: column;
  }
  .title {
    font-weight: bold;
    font-size: 1.5em;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #feca00;
    margin: 1em;
    margin-left: 0;
    text-transform: uppercase;
  }
  .label {
    font-size: 1.2em;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #ffffff;
  }

  .newsletter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .social {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .item {
      color: #feca00;
      font-size: 1.6em;
      margin-right: 1.9em;
      &:hover {
        color: #f2f2f2;
      }
    }
  }
  .button {
    margin-left: 1.3em;
  }
  .plane {
    margin-left: 0.6em;
    font-size: 1.5em;
  }
  .social-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  @media (max-width: 1280px) {
    padding: 13px 80px;
    .containerLayout {
      flex-direction: column;
    }
    .social-layout {
      align-items: center;
      margin-top: 20px;
    }
    .title {
      text-align: left;
      font-size: 24px;
      line-height: 130%;
    }
  }

  @media (max-width: 800px) {
    padding: 13px 60px;
  }
  @media (max-width: 768px) {
    padding: 0px;
    .containerLayout {
      border-radius: 0px;
    }
    .newsletter {
      flex-direction: column;
      align-items: center;
    }
    .title {
      text-align: center;
      margin-bottom: 16px;
    }
    .input {
      margin-bottom: 16px;
      width: 280px;
    }
  }
`;

export default SubscribePage;
