import { createSlice } from "@reduxjs/toolkit";
import { Months } from "../../../../common/data/Months";

export const dashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState: {
    graphData: Months,
    topGymData: [],
    yearOptions: null,
    yearFilter: {
      value: new Date().getFullYear(),
      label: new Date().getFullYear(),
    },
    analytics: {
      maingym: 0,
      branches: 0,
      customers: 0,
      membershipplans: 0,
      attendance: 0,
      classes: 0,
      trainers: 0,
    },
  },
  reducers: {
    setGraphData: (state, action) => {
      state.graphData = action.payload;
    },
    setTopGymData: (state, action) => {
      state.topGymData = action.payload;
    },
    setYearOptions: (state, action) => {
      state.yearOptions = action.payload;
    },
    setYearFilter: (state, action) => {
      state.yearFilter = action.payload;
    },
    setAnalytics: (state, action) => {
      state.analytics = action.payload;
    },
  },
});

export const {
  setAnalytics,
  setGraphData,
  setTopGymData,
  setYearFilter,
  setYearOptions,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
