// import { getAnalyticsData } from "../../common/repository/js/analytics.service";
import { getAnalyticsDataController } from "../../../../controllers/web/analyticsController";
import {
  setAnalytics,
  setGraphData,
  setTopGymData,
} from "../../redux/slices/Dashboard.slice";
import { useDispatch, useSelector } from "react-redux";
import {
  getGraphDataByYear,
  getTopBranchesData,
  getTopGymsData,
} from "../../../../repository/web/js/attendance.service";
import {
  getGraphDataByYearController,
  getTopBranchesDataController,
  getTopGymsDataController,
} from "../../../../controllers/web/attendanceController";

const useDashboardController = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { yearFilter } = useSelector((state) => state.dashbaord);

  const loadAnalyticsData = () => {
    if (user) {
      if (user?.role?.includes(4)) {
        getAnalyticsDataController(4, null).then((result) => {
          dispatch(setAnalytics(result));
        });
      } else if (user?.role?.includes(3)) {
        getAnalyticsDataController(user?.role[0], user?.company?.value).then(
          (result) => {
            dispatch(setAnalytics(result));
          }
        );
      }
    }
  };

  const loadGraphAndTopGymsData = async () => {
    let data = [];
    let topGymData = [];
    if (user.role.includes(4)) {
      data = await getGraphDataByYearController(yearFilter.label);
      topGymData = await getTopGymsDataController(yearFilter.label);
      dispatch(setTopGymData(topGymData));
      dispatch(setGraphData(data));
    }
    if (user.role.includes(3)) {
      data = await getGraphDataByYearController(
        yearFilter.label,
        user?.company_id || "company info not available"
      );
      topGymData = await getTopBranchesDataController(
        yearFilter.label,
        user?.company_id
      );
      dispatch(setTopGymData(topGymData));
      dispatch(setGraphData(data));
    }
  };

  return {
    loadAnalyticsData,
    loadGraphAndTopGymsData,
  };
};

export default useDashboardController;
