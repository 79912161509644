import gym_main from "../../../common/models/gym_main.js";
import firebase, { auth, db } from "../main";
import { getUsers } from "./user";

import {
  collection,
  doc,
  addDoc,
  updateDoc,
  setDoc,
  getDocs,
  getDoc,
  deleteDoc,
  query,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import { data } from "jquery";
import { forEach } from "react-bootstrap/ElementChildren";
import { getAllGyms } from "./gym_main";
import { getAllGymsController } from "../../../controllers/web/gymMainController";
import Memberships from "../../../common/models/memberships";
import { preSaveOrUpdateAClass } from "../../../common/validations/PreSave";
import { getCollectionRef } from "../../../common/Helpers/GetCollectionRef";

const collection_membership = "memberships";

export const updateMembership = (content, id) => {
  return new Promise(async (resolve, reject) => {
    updateDoc(getCollectionRef(collection_membership, id), content, {
      merge: true,
    })
      .then((data) => {
        resolve({ success: true });
      })
      .catch((error) => {
        resolve({ success: false });
      });
  });
};

export const getMemberships = async () => {
  const q = query(getCollectionRef(collection_membership), orderBy("index"));
  return getDocs(q).then((datas) => {
    if (datas.empty) {
      return { success: false, data: undefined};
    }
    let memberships = [];
    datas.forEach((data, index) => {
      let membership = Object.assign(new Memberships(), {
        ...data.data(),
        id: data.data().index,
        _id: data.id,
      });
      memberships.push(membership);
    });
    return { success: true, data: memberships };
  });
};

export const getMembership = async (id) => {
  const docRef = getCollectionRef(collection_membership, id);
  return getDoc(docRef)
    .then((data) => {
      let membership;
      if (data.exists()) {
        membership = Object.assign(new Memberships(), {
          ...data.data(),
        });
      } else {
        throw Error("Membership not found");
      }
      return {
        success: true,
        data: membership,
      };
    })
    .catch((e) => console.log(e));
};

export const getMembershipNames = async () => {
  return getDocs(getCollectionRef(collection_membership)).then((datas) => {
    let memberships = [];
    if (datas.empty) {
      throw Error("Membership names not found");
    } else {
      datas.forEach((data) => {
        let membership = Object.assign(new Memberships(), {
          ...data.data(),
          id: data.id,
        });
        memberships.push({
          label: membership.name,
          value: membership.id,
          id: membership.id,
          description: membership.description,
          index: membership.index,
        });
      });
      return { success: true, data: memberships };
    }
  });
};

export const getMembershipDataList = async () => {
  return getDocs(getCollectionRef(collection_membership)).then((datas) => {
    let memberships = [];
    datas.forEach((data) => {
      if (data.exists()) {
        let membership = Object.assign(new Memberships(), {
          ...data.data(),
          id: data.id,
        });
        memberships.push({
          label: membership.name,
          value: membership.id,
          annually_amount: membership.annually_amount,
          attendancePrice: membership.attendancePrice,
          monthly_amount: membership.monthly_amount,
          id: membership.id,
          index: membership.index,
        });
      } else {
        throw Error("Membership List not found");
      }
    });
    return { success: true, data: memberships };
  });
};

export const changeMembershipPlanActivation = async (id, state) => {
  return updateDoc(getCollectionRef(collection_membership, id), {
    approved: state,
  })
    .then(() => {
      return { success: "success", error: "" };
    })
    .catch((error) => {
      return { success: "", error: error.message };
    });
};

export const deleteMembership = async (id) => {
  // First get users who have same membrship
  let hasError = false;
  const users = await getUsers();
  let errorObject = { users: [], companies: [] };
  let usersWithMem = [];
  users.data.forEach((user) => {
    if (user?.memberShipPlan?.id === id) {
      usersWithMem.push(user.username);
      hasError = true;
    }
  });
  errorObject.users = usersWithMem;
  // Second get companies who have same membrship
  const companies = await getAllGymsController();
  let companiesWithMem = [];
  companies.forEach((company) => {
    if (company?.memberShipPlan?.id === id) {
      companiesWithMem.push(company.name);
      hasError = true;
    }
  });
  errorObject.companies = companiesWithMem;

  let result = "";
  if (hasError) {
    result = { success: false, errorData: errorObject };
  } else {
    try {
      await deleteDoc(getCollectionRef(collection_membership, id)).then(
        (data) => {
          result = { success: true };
        }
      );
    } catch (error) {
      result = { success: false };
    }
  }
  return result;
};

export const getMembershipOption = async () => {
  const gymOptions = [];
  return getDocs(query(getCollectionRef(collection_membership))).then((data) => {
    data.forEach((data) => {
      const membership = Object.assign(new Memberships(), {
        ...data.data(),
        id: data.id,
      });
      gymOptions.push({
        label: membership.name,
        value: membership.index,
        id: membership.id,
      });
    });
    return gymOptions;
  });
};


export const addMembership = async (
  name,
  description,
  monthly_amount,
  annually_amount,
  attendancePrice,
  color
) => {
  return new Promise(async (resolve, reject) => {
    let docsM = await getDocs(getCollectionRef(collection_membership));
    let index = 0;
    if (docsM.size > 0) {
      let temp = [];
      docsM.forEach((doc) => {
        temp.push(doc.data().index);
      });
      index = Math.max(...temp) + 1;
    }
    const data = {
      name,
      description,
      monthly_amount,
      annually_amount,
      attendancePrice,
      color,
      index: index,
    };
    const membership = Object.assign(new Memberships(), { ...data });
    addDoc(
      getCollectionRef(collection_membership),
      preSaveOrUpdateAClass(membership)
    )
      .then((data) => {
        resolve({ success: data.id, error: "" });
      })
      .catch((error) => {
        resolve({ success: "", error: error.message });
      });
  });
};

export const changeMembership = async (
  id,
  name,
  description,
  monthly_amount,
  annually_amount,
  attendancePrice,
  color
) => {
  const docRef = getCollectionRef(collection_membership, id);
  const membership = Object.assign(new Memberships(), {
    name: name,
    description: description,
    annually_amount: annually_amount,
    monthly_amount: monthly_amount,
    attendancePrice: attendancePrice,
    color: color,
  });
  return updateDoc(docRef, {
    name: membership.name,
    description: membership.description,
    annually_amount: membership.annually_amount,
    monthly_amount: membership.monthly_amount,
    attendancePrice: membership.attendancePrice,
    color: membership.color,
  })
    .then(() => {
      return { success: "success", error: "" };
    })
    .catch((error) => {
      return { success: "", error: error.message };
    });
};
