import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BsPlusCircleFill } from "react-icons/bs";
import { IoMapSharp } from "react-icons/io5";
import { RiPencilFill, RiSearchEyeLine } from "react-icons/ri";
import Addgymbranch from "./modal/addgymbranch";
// import { changeGymActivation } from "../../../../common/repository/js/gym";
import { changeGymActivationController } from "../../../../../../controllers/web/gymController";
import { useLocation, useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import CustomLoading from "../../../items/loadingBar";
import { useSelector, useDispatch } from "react-redux";
import ConfirmDialog from "../../../custom/ConfirmDialog/ConfirmDialog";
import { Msgs } from "../../../../../../common/data/msgs";
import AccessManager from "../../../custom/AccessManager/AccessManager";
import { outerTheme } from "../../../global";
import { Switch, ThemeProvider } from "@mui/material";
import InputBox from "../../../custom/InputBox/InputBox";
import useManageGymController from "../../../componentsController/ManageGymController";
import {
  setFilteredContent,
  setIsAdd,
  setsearchText,
} from "../../../../redux/slices/ManageGym.slice";
import { BiSearchAlt } from "react-icons/bi";

const TableRow = ({ gym, openEdit, refresh }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { changeActivationOfGym } = useManageGymController();
  const tests = "";
  const { user: loggedinUser } = useSelector((state) => state.user);
  const [activate, setActivate] = useState(true);
  useEffect(() => {
    setActivate(gym.activated);
  }, [gym]);

  // console.log(gym.images[0]);

  return (
    <React.Fragment>
      {/*<div className="table-row">*/}
      {/*  <div className="gymImage">*/}
      {/*    <img*/}
      {/*      src={*/}
      {/*        gym.images && gym.images.length > 0 ? gym.images[0] : undefined*/}
      {/*      }*/}
      {/*      className="image-gym"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className="qrcode">*/}
      {/*    <QRCode*/}
      {/*      value={gym.qrCode}*/}
      {/*      size={50}*/}
      {/*      bgColor="#D6D6D6"*/}
      {/*      fgColor="#1C1A1D"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <span className="gymname">{gym.name}</span>*/}
      {/*  <div className="location">*/}
      {/*    <span className="map-icon">*/}
      {/*      <IoMapSharp />*/}
      {/*    </span>*/}
      {/*    {gym?.address && gym?.address?.length > 40*/}
      {/*      ? gym?.address.slice(0, 39).trim() + "..."*/}
      {/*      : gym?.address || ""}*/}
      {/*  </div>*/}

      {/*  {(loggedinUser.role.includes(3) || loggedinUser.role.includes(4)) && (*/}
      {/*    <div className="controls">*/}
      {/*      <span*/}
      {/*        className="edit"*/}
      {/*        onClick={() => {*/}
      {/*          openEdit(gym);*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <RiPencilFill />*/}
      {/*      </span>*/}
      {/*      {loggedinUser.role.includes(4) && (*/}
      {/*        <div className="d-flex align-items-center">*/}
      {/*          <ThemeProvider theme={outerTheme}>*/}
      {/*            <Switch*/}
      {/*              checked={activate}*/}
      {/*              onChange={(e, c) => {*/}
      {/*                console.log(c + "1");*/}
      {/*                ConfirmDialog.show({*/}
      {/*                  title: "Confirmation!",*/}
      {/*                  message: `Are Sure to change branch status?`,*/}
      {/*                  okCallback: async () => {*/}
      {/*                    if (loggedinUser?.activated) {*/}
      {/*                      changeGymActivationController(gym.qrCode, c).then(*/}
      {/*                        (res) => {*/}
      {/*                          if (res.success === "success") {*/}
      {/*                            refresh();*/}
      {/*                            ConfirmDialog.hide();*/}
      {/*                          }*/}
      {/*                        }*/}
      {/*                      );*/}
      {/*                    } else {*/}
      {/*                      ConfirmDialog.show({*/}
      {/*                        message: Msgs.NotActiveMsg,*/}
      {/*                        showFooter: false,*/}
      {/*                      });*/}
      {/*                    }*/}
      {/*                  },*/}
      {/*                });*/}
      {/*              }}*/}
      {/*              name="checkedA"*/}
      {/*            />*/}
      {/*            <div*/}
      {/*              className={`${activate ? "text-light" : "text-secondary"}`}*/}
      {/*            >*/}
      {/*              Active*/}
      {/*            </div>*/}
      {/*          </ThemeProvider>*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  )}*/}

      {/*  {loggedinUser.role.includes(6) && (*/}
      {/*    <div className="controls">*/}
      {/*      <span*/}
      {/*        className="edit"*/}
      {/*        onClick={() => {*/}
      {/*          localStorage.setItem("branchDoc", JSON.stringify(gym));*/}
      {/*          navigate("/gymeditclass");*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <RiPencilFill />*/}
      {/*      </span>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}
      <div className="table-row-mobile">
        <div className="info">
          <div>
            <img
              src={
                gym.images && gym.images.length > 0 ? gym.images[0] : undefined
              }
              className="image-gym"
            />
          </div>
          <div>
            <QRCode
              value={gym.qrCode}
              size={50}
              bgColor="#D6D6D6"
              fgColor="#1C1A1D"
            />
          </div>

          <div className="text">
            <span className="gymname">{gym.name}</span>
            <span className="location">{gym.address}</span>
          </div>
        </div>
        <div className="button-group">
          <span className="map-view">
            <IoMapSharp className="icon" />
            {t("View on map")}
          </span>
          {(loggedinUser.role.includes(3) || loggedinUser.role.includes(4)) && (
            <div className="controls">
              <span
                className="edit"
                onClick={() => {
                  openEdit(gym);
                }}
              >
                <RiPencilFill />
              </span>
              {loggedinUser.role.includes(4) && (
                <div className="d-flex align-items-center">
                  <ThemeProvider theme={outerTheme}>
                    <Switch
                      checked={activate}
                      onChange={(e, c) => {
                        console.log(c + " 2");
                        ConfirmDialog.show({
                          title: "Confirmation!",
                          message: `Are Sure to change branch status?`,
                          okCallback: async () => {
                            if (loggedinUser?.activated) {
                              ConfirmDialog.hide();
                              await changeGymActivationController(gym.qrCode, c).then((res) => {
                                if (res.success === "success") {
                                  refresh();
                                }
                              });
                            } else {
                              ConfirmDialog.show({
                                message: Msgs.NotActiveMsg,
                                showFooter: false,
                              });
                            }
                          },
                        });
                      }}
                      name="checkedA"
                    />
                    <div
                      className={`${
                        activate ? "text-light" : "text-secondary"
                      }`}
                    >
                      Active
                    </div>
                  </ThemeProvider>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const ManageGymBranch = () => {
  const { getAllGyms, getOwnersOfGym } = useManageGymController();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { path } = useLocation();
  const { user: loggedinUser } = useSelector((state) => state.user);
  const { isAdd, filteredContent, searchText, gymOwners, loading, gyms } =
    useSelector((state) => state.manageGym);
  const closeAddModal = () => {
    dispatch(setIsAdd(false));
  };
  const openBranchDetailPage = (content) => {
    if (loggedinUser?.activated) {
      navigate("/gymBranchDetails", {
        state: { gym: content, owners: gymOwners },
      });
    } else {
      ConfirmDialog.show({ message: Msgs.NotActiveMsg, showFooter: false });
    }
  };
  const openAddModal = () => {
    if (loggedinUser?.activated) {
      dispatch(setIsAdd(true));
    } else {
      ConfirmDialog.show({ message: Msgs.NotActiveMsg, showFooter: false });
    }
  };

  const onSearch = (e) => {
    const val = e.target.value;
    dispatch(setsearchText(val));
    const _filteredData = gyms.filter((data) =>
      data.name.toLowerCase().includes(val)
    );
    dispatch(setFilteredContent(_filteredData));
  };

  useEffect(() => {
    localStorage.removeItem("branchDoc");
    getAllGyms();
    getOwnersOfGym();
  }, [path]);

  return (
    <AccessManager allowedRoles={[4, 3, 6]}>
      <Container>
        <div className="header">
          <div className="header-title">
            <span className="title">
              {loggedinUser.role.includes(1)
                ? t("Company Facilities")
                : t("Facilities list")}
            </span>
            {(loggedinUser.role.includes(3) ||
              loggedinUser.role.includes(4)) && (
              <span
                className="button"
                onClick={openAddModal}
                style={{ cursor: "pointer" }}
              >
                <BsPlusCircleFill className="icon" />
                {t("Add Facility")}
              </span>
            )}
          </div>
          <div className="d-flex ms-auto mt-3">
            <InputBox
              icon={<RiSearchEyeLine />}
              placeholder="Search..."
              value={searchText}
              onChange={onSearch}
            />
          </div>
        </div>

        <CustomLoading isStart={loading} isFull={true} />

        <div className="content">
          <div className="table-header">
            <span className="gymImage">{t("Image")}</span>
            <span className="qrcode">{t("QR code")}</span>
            <span className="gymname">{t("Branch name")}</span>
            <span className="location">{t("Location")}</span>
            <span className="controls">{""}</span>
          </div>
          <div className="table-content">
            {filteredContent.map((item, index) => (
              <TableRow
                key={index}
                gym={item}
                openEdit={openBranchDetailPage}
                refresh={getAllGyms}
              />
            ))}
          </div>
        </div>
        <Addgymbranch
          isOpen={isAdd}
          close={closeAddModal}
          refresh={getAllGyms}
        />
      </Container>
    </AccessManager>
  );
};

const Container = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #1c1a1d;
  @media (max-width: 1180px) {
    margin-top: 86px;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    height: 30px;
    background-color: black;
    z-index: 100;
    .title {
      font-size: 24px;
      line-height: 120%;
      text-transform: uppercase;
      font-weight: bold;
      color: #f2f2f2;
      margin-right: 25px;
    }
    .button {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #feca00;
      .icon {
        font-size: 16px;
        margin-right: 14px;
      }
    }
    .header-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      // min-width: 300px;
    }
    .header-search {
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // // min-width: 300px;

      width: 260px;
      height: 50px;
      background: #1c1a1d;
      border: 1px solid #888888;
      box-sizing: border-box;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 20px;
      font-size: 18px;
      line-height: 100%;
      color: #3e3e3e;

      .icon {
        font-size: 22px;
        margin-right: 12px;
      }

      background: #1c1a1d;
      border: none;
      color: #888888;

      &:focus-visible {
        outline: none;
      }
    }
  }

  @media (max-width: 768px) {
    .header-search {
      max-width: 223px;
      margin-left: auto;
      padding: 15px 20px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 18px 40px;
    .table-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.01em;
      color: #888888;
      padding-bottom: 10px;
      border-bottom: 1px solid #4f4f4f;
    }
    .gymImage {
      width: 100%;
      max-width: 180px;
      .image-gym {
        width: 50px;
        height: 50px;
        border-radius: 6px;
      }
    }
    .qrcode {
      width: 100%;
      max-width: 180px;
      .image {
        width: 50px;
        height: 50px;
      }
    }
    .gymname {
      width: 100%;
      max-width: 300px;
    }
    .location {
      width: 100%;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.01em;
      color: #888888;
      .map-icon {
        width: 30px;
        min-width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #feca00;
        margin-right: 10px;
        background: #333333;
        &:hover,
        &:active {
          background: #333333;
          color: #fff;
        }
      }
    }
    .controls {
      width: max-content;
      max-width: 150px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 20px;
      align-items: center;

      .edit {
        cursor: pointer;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
        border-radius: 50%;
        background-color: #333333;
        color: #159cff;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;

        &:active,
        &:hover {
          cursor: pointer;
          color: #fff;
        }
      }
      .activate {
        cursor: pointer;
        width: 93px;
        height: 42px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 160%;
        background: #1e382a;
        color: #27ae60;
      }
      .activate.disable {
        cursor: pointer;
        background: #462224;
        color: #f03f3f;
      }
    }
    .table-content {
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 200px);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: #888888;
        border-radius: 100px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-color: #ffcc00;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
      }
    }
    .table-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 0px;
      border-bottom: 1px solid #4f4f4f;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.01em;
      color: #bfbfbf;
      .location {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .table-row-mobile {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      height: unset;
      padding: 15px 20px;
      background: #1c1a1d;

      .header-title {
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        justify-content: space-between;
        // width: 100%;

        & span:first-child {
          display: flex;
          justify-content: center;
          width: 100%;
        }
        // & span:last-child {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: center;
        //  width: 100%;
        // margin-top: 10px;
        // margin-bottom: 10px;
      }
    }
    .header-search {
      // display: flex;
      // align-items: center;
      width: 100%;
      margin-top: 15px;
    }
  }
  .content {
    padding: 10px;
    .table-header {
      display: none;
    }
    .table-row {
      display: none;
    }
    .table-content {
      max-height: unset;
      overflow: none;
    }
    .table-row-mobile {
      display: flex;
      border: 1px solid #4f4f4f;
      border-radius: 15px;
      margin-bottom: 10px;
      padding: 18px;
      flex-direction: column;

      .image-gym {
        width: 50px;
        height: 50px;
        border-radius: 15px;
        margin-right: 10px;
      }
      .image {
        width: 70px;
        height: 70px;
      }
      .info {
        display: flex;
        flex-direction: row;
        align-items: center;

        .text {
          display: flex;
          flex-direction: column;
          margin-left: 24px;
          .gymname {
            font-size: 18px;
            line-height: 22px;
            color: #d6d6d6;
          }
          .location {
            font-size: 14px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #888888;
          }
        }
      }
      .button-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .map-view {
          font-size: 14px;
          line-height: 160%;
          letter-spacing: 0.01em;
          color: #f6f6f6;
          .icon {
            font-size: 20px;
            margin-right: 6px;
          }
        }
        .controls {
          display: flex;
          flex-direction: row;
          .edit {
            width: 74px;
            height: 34px;
            background-color: #333333;
            color: #2f80ed;
            border-radius: 10px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            .icon {
              margin-right: 5px;
              font-size: 18px;
            }
            &:active,
            &:hover {
              background-color: #1f2f46;
            }
          }
          .activate {
            width: 83px;
            height: 34px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 160%;
            background: #1e382a;
            color: #27ae60;
          }
          .activate.disable {
            background: #462224;
            color: #f03f3f;
          }
        }
      }
    }
  }
`;

export default ManageGymBranch;
