export const FacilityTypes = [
  {
    value: "Gym",
    label: "Gym",
  },
  {
    value: "Swimming Pool",
    label: "Swimming Pool",
  },
  {
    value: "Sona",
    label: "Sona",
  },
  {
    value: "Jacozi",
    label: "Jacozi",
  },
  {
    value: "Tinnes Table",
    label: "Tinnes Table",
  },
  {
    value: "Padle",
    label: "Padle",
  },
];
