import firebase, { auth, db } from "../main";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  setDoc,
  getDocs,
  getDoc,
  deleteDoc,
  collectionGroup,
  query,
  arrayUnion,
  arrayRemove,
  where,
  limit,
  Timestamp,
} from "firebase/firestore";
import { getCollectionRef } from "../../../common/Helpers/GetCollectionRef";

const coll_subscribers = "subscribers";

export const addSubscrier = async (data) => {
  return new Promise(async (resolve, reject) => {
    const _exists = await isExists(data.email);
    if (!_exists) {
      await addDoc(getCollectionRef(coll_subscribers), data)
        .then((data) => {
          resolve(data.id);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    } else {
      resolve(true);
    }
  });
};

export const getAllSubscribers = () => {
  return new Promise(async (resolve, reject) => {
    let temp = [];
    let docsF = await getDocs(query(getCollectionRef(coll_subscribers)));
    if (docsF.size) {
      docsF.forEach((doc) => {
        temp.push({ ...doc.data(), id: doc.id });
      });
    }
    resolve(temp);
  });
};

export const isExists = (email) => {
  return new Promise(async (resolve, reject) => {
    let temp = false;
    let docsF = await getDocs(
      query(getCollectionRef(coll_subscribers), where("email", "==", email))
    );
    if (docsF.size > 0) {
      temp = true;
    }
    resolve(temp);
  });
};
