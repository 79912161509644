import React from "react";
import { Button } from "react-bootstrap";
import { MdInfo } from "react-icons/md";
import PageHeader from "../PageHeader/PageHeader";
import PageSection, { PageSectionBody } from "../PageSection/PageSection";
import { useParams, useNavigate } from "react-router-dom";

export default function UnauthorizeAccess(props) {
  const params = useParams();
  const navigate = useNavigate();
  return (
    <PageSection>
      <PageHeader></PageHeader>
      <PageSectionBody>
        <div
          style={{ minHeight: "550px" }}
          className="d-flex flex-column w-100 h-100 justify-content-center align-items-center text-light"
        >
          <MdInfo className="h1 text-warning" />
          <h5>401 Unauthorize Access</h5>
          <div style={{ fontSize: "1.1em" }} className="text-secondary">
            You are unauthorized to access this page.
          </div>
          <Button
            onClick={(e) => {
              if (Object.keys(params).length > 0) {
                if (Object.keys(params)[0].toString() === "*") {
                  if (props.privateFallback) {
                    navigate("/" + props.privateFallback);
                  } else {
                    navigate("/");
                  }
                } else {
                  navigate("/" + params.fallback);
                }
              } else {
                navigate("/");
              }
            }}
            className="mt-5"
            variant="warning"
          >
            Go Back
          </Button>
        </div>
      </PageSectionBody>
    </PageSection>
  );
}
