import Memberships from "./memberships";
class users {
  constructor(
    complete_profile = false,
    deviceId = "",
    id = "",
    gender = "",
    isSocial = false,
    activated = false,
    lang = "",
    phone = "",
    photo = "",
    role = [],
    sure_name = "",
    username = "",
    memberShipPlan = [],
    company_id = "",
    company_name = "",
    facility_id = "",
    attachments = [],
    facility_name = "",
  ) {
    this.id = id;
    this.complete_profile = complete_profile;
    this.deviceId = deviceId;
    this.gender = gender;
    this.isSocial = isSocial;
    this.activated = activated;
    this.lang = lang;
    this.phone = phone;
    this.photo = photo;
    this.role = role;
    this.sure_name = sure_name;
    this.username = username;
    this.memberShipPlan = memberShipPlan;
    this.company_id = company_id;
    this.company_name = company_name;
    this.facility_id = facility_id;
    this.attachments = attachments;
    this.facility_name = facility_name;
    Object.preventExtensions(this);
  }

  get Id() {
    return this._id;
  }

  set Id(value) {
    this._id = value;
  }
  get Complete_profile() {
    return this.complete_profile;
  }

  set Complete_profile(value) {
    this.complete_profile = value;
  }

  get DeviceId() {
    return this._deviceId;
  }

  set DeviceId(value) {
    this.deviceId = value;
  }

  get Gender() {
    return this.gender;
  }

  set Gender(value) {
    this.gender = value;
  }

  get IsSocial() {
    return this.isSocial;
  }

  set IsSocial(value) {
    this.isSocial = value;
  }

  get Activated() {
    return this.activated;
  }

  set Activated(value) {
    this.activated = value;
  }

  get Lang() {
    return this.lang;
  }

  set Lang(value) {
    this.lang = value;
  }

  get Phone() {
    return this.phone;
  }

  set Phone(value) {
    this.phone = value;
  }

  get Photo() {
    return this.photo;
  }

  set Photo(value) {
    this.photo = value;
  }

  get Role() {
    return this.role;
  }

  set Role(value) {
    this.role = value;
  }
  get Sure_name() {
    return this.sure_name;
  }

  set Sure_name(value) {
    this.sure_name = value;
  }

  get Username() {
    return this.username;
  }

  set Username(value) {
    this.username = value;
  }

  get MemberShipPlan() {
    return this.memberShipPlan;
  }

  set MemberShipPlan(value) {
    this.memberShipPlan = value;
  }

  get Company_Id() {
    return this.company_id;
  }

  set Company_Id(value) {
    this.company_id = value;
  }

  get Company_Name() {
    return this.company_name;
  }

  set Company_Name(value) {
    this.company_name = value;
  }

  get Facility_Id() {
    return this.facility_id;
  }

  set Facility_Id(value) {
    this.facility_id = value;
  }

  get Attachments() {
    return this.attachments;
  }

  set Attachments(value) {
    this.attachments = value;
  }

  get Facility_Name() {
    return this.facility_name;
  }

  set Facility_Name(value) {
    this.facility_name = value;
  }

}

export default users;
