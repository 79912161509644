import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Modal } from "react-bootstrap";
import { BsPlusCircleFill, BsCheckCircleFill } from "react-icons/bs";
import { RiCloseCircleFill } from "react-icons/ri";
import CustomLoading from "../../../items/loadingBar";
import { SketchPicker } from "react-color";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBox from "../../../custom/InputBox/InputBox";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsOpen,
  setAnnualError,
  setAttendanceError,
  setColor,
  setColorPicker,
  setCreateError,
  setCreateSuccess,
  setDescription,
  setDisable,
  setFormData,
  setIsAddDesc,
  setIsId,
  setLoading,
  setMonthError,
  setNameError,
  setNewDesc,
  setPriorityError,
} from "../../../../redux/slices/EditMembership.slice";
import useManageMembershipController from "../../../componentsController/ManageMembership";
toast.configure();

const EditMembership = (props) => {
  const { EditaMembership } = useManageMembershipController();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isOpen,
    isId,
    isAddDesc,
    displayColorPicker,
    nameError,
    monthError,
    annualError,
    newDesc,
    disable,
    loading,
    formData,
  } = useSelector((state) => state.editMembership);
  // const [isOpen, setIsOpen] = useState(false);
  // const [isId, setIsId] = useState("");
  // const [isAddDesc, setIsAddDesc] = useState(false);
  // const [description, setDescription] = useState([]);
  // const [displayColorPicker, setColorPicker] = useState(false);
  // const [color, setColor] = useState({});

  // const [nameError, setNameError] = useState(false);
  // const [monthError, setMonthError] = useState(false);
  // const [attendanceError, setAttendanceError] = useState(false);
  // const [annualError, setAnnualError] = useState(false);
  // const [priorityError, setPriorityError] = useState(false);
  // const [createError, setCreateError] = useState(false);
  // const [createSuccess, setCreateSuccess] = useState(false);
  // const [newDesc, setNewDesc] = useState("");
  // const [disable, setDisable] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const defaultData = {
  //   name: "",
  //   annually_amount: "0",
  //   monthly_amount: "0",
  //   attendancePrice: "0",
  //   color: { b: 194, a: 1, g: 227, r: 80 },
  //   description: [],
  //   docId: "",
  //   id: 0,
  //   index: 0,
  // };
  // const [formData, setFormData] = useState(defaultData);

  useEffect(() => {
    dispatch(setFormData({ ...props.contentToEdit }));
    dispatch(setIsOpen(props.isOpen));
    dispatch(setIsId(props.id));
  }, [props]);

  const resetStates = () => {
    dispatch(setIsAddDesc(false));
    dispatch(setNameError(false));
    dispatch(setMonthError(false));
    dispatch(setAttendanceError(false));
    dispatch(setAnnualError(false));
    dispatch(setDescription([]));
    dispatch(setPriorityError(false));
    dispatch(setNewDesc(""));
    dispatch(setDisable(false));
    dispatch(setColor({ b: 194, a: 1, g: 227, r: 80 }));
  };

  const hideModal = () => {
    resetStates();
    dispatch(setIsOpen(false));
    props.refresh();
    props.close();
  };

  const onAddDescItem = () => {
    dispatch(setIsAddDesc(true));
  };

  const closeAddDesc = () => {
    dispatch(setIsAddDesc(false));
    dispatch(setNewDesc(""));
  };

  const handleClick = () => {
    dispatch(setColorPicker(!displayColorPicker));
  };

  const handleClose = () => {
    dispatch(setColorPicker(false));
  };

  const handleChange = (e) => {
    dispatch(setFormData({ ...formData, color: e.rgb }));
  };

  const onSave = async () => {
    if (disable) return;
    dispatch(setLoading(true));
    if (
      formData.name === "" ||
      formData.monthly_amount === "" ||
      isNaN(formData.monthly_amount) ||
      formData.annually_amount === "" ||
      isNaN(formData.annually_amount) ||
      formData.attendancePrice === "" ||
      isNaN(formData.attendancePrice)
    ) {
      dispatch(setNameError(formData.name === ""));
      dispatch(
        setMonthError(
          formData.monthly_amount === "" || isNaN(formData.monthly_amount)
        )
      );
      dispatch(
        setAttendanceError(
          formData.attendancePrice === "" || isNaN(formData.attendancePrice)
        )
      );
      dispatch(
        setAnnualError(
          formData.annually_amount === "" || isNaN(formData.annually_amount)
        )
      );
      dispatch(setLoading(false));
      return;
    }
    dispatch(setDisable(true));
    const data = await EditaMembership();
    if (data.success !== "") {
      dispatch(setCreateSuccess(true));
      dispatch(setDisable(false));
      resetStates();
      props.refresh();
      dispatch(setLoading(false));
      toast.success("MemberShip Update", "Infomation", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      hideModal();
    } else {
      dispatch(setCreateError(true));
      dispatch(setLoading(false));
    }
  };

  const onAddDescription = () => {
    if (newDesc === "") return;
    let temp = [...formData.description];
    temp.push(newDesc);
    dispatch(setFormData({ ...formData, description: temp }));
    dispatch(setNewDesc(""));
  };

  const onDeleteItem = (index) => {
    let temp = [...formData.description];
    temp.splice(parseInt(index), 1);
    let newDescription = [];
    temp.forEach((item) => {
      newDescription.push(item);
    });
    dispatch(setFormData({ ...formData, description: newDescription }));
  };

  return (
    <Modal
      show={isOpen}
      onHide={hideModal}
      centered
      dialogClassName="login-modal"
    >
      <CustomLoading isStart={loading} />

      <Container>
        <div className="header">{t("Edit Membership")}</div>
        <div className="content">
          <InputBox
            label="Name"
            error={nameError}
            value={formData.name}
            onChange={(e) =>
              dispatch(setFormData({ ...formData, name: e.target.value }))
            }
          />
          {/* Annual and Monthly price */}
          <div className="form-row">
            <div className="field-1">
              <InputBox
                error={annualError}
                label="Annual Price"
                value={formData.annually_amount}
                onChange={(e) =>
                  dispatch(
                    setFormData({
                      ...formData,
                      annually_amount: e.target.value,
                    })
                  )
                }
              />
            </div>
            <div className="field-1">
              <InputBox
                error={monthError}
                label="Monthly Price"
                value={formData.monthly_amount}
                onChange={(e) =>
                  dispatch(
                    setFormData({ ...formData, monthly_amount: e.target.value })
                  )
                }
              />
            </div>
          </div>
          {/* attendance cost */}
          <div className="form-row">
            <div className="field-1">
              <InputBox
                error={formData.attendancePrice <= 0}
                label="Attendance Cost"
                value={formData.attendancePrice}
                onChange={(e) =>
                  dispatch(
                    setFormData({
                      ...formData,
                      attendancePrice: e.target.value,
                    })
                  )
                }
              />
            </div>
          </div>
          {/* description box */}
          <div className="description-layout">
            <span className="label">{t("Description")}</span>
            <div className="description">
              {formData?.description?.map((item, index) => (
                <div key={index} className="description-item">
                  <li>{item}</li>
                  <RiCloseCircleFill
                    className="delete"
                    onClick={() => onDeleteItem(index)}
                  />
                </div>
              ))}
            </div>
            <div className={isAddDesc ? "create-description" : "disable"}>
              <input
                className="input"
                value={newDesc}
                onChange={(e) => dispatch(setNewDesc(e.target.value))}
              />
            </div>
            <div className="button-container">
              <span onClick={onAddDescItem}>
                <BsPlusCircleFill className="icon" />
                {t("Add items")}
              </span>
              <div className="create-container">
                <span onClick={closeAddDesc}>{t("Cancel")}</span>
                <span className="add" onClick={onAddDescription}>
                  <BsCheckCircleFill className="icon" />
                  {t("Add")}
                </span>
              </div>
            </div>
          </div>
          {/* Color selector */}
          <div className="colorcode-layout mt-2">
            <span className="label">Choose Color:</span>
            <div className="swatch" onClick={() => handleClick()}>
              <div
                style={{
                  width: "36px",
                  height: "14px",
                  borderRadius: "2px",
                  background: `rgba(${formData.color?.r}, ${formData.color?.g}, ${formData.color?.b}, ${formData.color?.a})`,
                }}
              />
            </div>
            {displayColorPicker ? (
              <div className="popover">
                <div className="cover" onClick={handleClose} />
                <SketchPicker color={formData.color} onChange={handleChange} />
              </div>
            ) : null}
          </div>
        </div>

        <div className="button-group">
          <Button
            variant="secondary"
            className="ps-3 pe-3 me-2"
            onClick={hideModal}
          >
            {t("Cancel")}
          </Button>
          <Button variant={"warning"} className="ps-4 pe-4" onClick={onSave}>
            {t("Save")}
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

const Container = styled("div")`
  width: 498px;
  background-color: black;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  display: flex;
  overflow: hidden;
  border: 1px solid #88888840;
  box-shadow: 0px 0px 45px 0px #88888820;
  .error {
    border-color: red !important;
  }
  .header {
    font-weight: bold;
    font-size: 24px;
    line-height: 0px;
    padding-top: 15px;
    text-transform: uppercase;
    color: #f2f2f2;
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-equal;
    width: 100%;
    gap: 10px 8px;
  }

  .field-1 {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .label {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #888888;
    margin-bottom: 8px;
  }
  .input {
    color: #888888;
    background: #1c1a1d;
    border-radius: 12px;
    border: 1px solid #888888;
    height: 50px;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  .button {
    cursor: pointer;
    width: 110px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f2f2f2;
    font-size: 18px;
    line-height: 22px;
    border-radius: 10px;
    border: 1px solid #888888;
    margin-left: 16px;
  }
  .button.active {
    text-transform: uppercase;
    color: #000000;
    background: #feca00;
    border: none;
  }
  .button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 40px;
  }
  .description {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 140px;
    padding: 10px 0px;

    .description-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    li {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #f2f2f2;
      margin-bottom: 6px;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: #888888;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: #ffcc00;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
    }
  }
  .description-layout {
    display: flex;
    flex-direction: column;
    height: max-content;
    margin-bottom: 20px;
    border: 1px solid #88888850;
    border-radius: 5px;
    padding: 15px 20px;
    margin-top: 15px;
  }
  .create-description {
    display: flex;
    flex-direction: column;
  }
  .disable {
    display: none;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 160%;
    color: #888888;
    margin-top: 10px;
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .create-container {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
    .add {
      color: #feca00;
    }
    .icon {
      margin-right: 8px;
      font-size: 18px;
    }
  }
  .price-layout {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 0px 20px;
    & > div {
      display: flex;
      flex-direction: column;
    }
  }
  .colorcode-layout {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .message {
    display: flex;
    align-items: center;
    margin-top: 10px;
    height: 20px;
  }
  .error-msg {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: red;
  }
  .success-msg {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #f2f2f2;
  }
  .delete {
    color: #f03f3f;
    font-size: 1em;
  }
  .swatch {
    padding: 5px;
    background: #fff;
    borderradius: 1px;
    boxshadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: pointer;
  }
  .popover {
    position: absolute;
    zindex: 2;
  }
  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  @media (max-width: 768px) {
    width: 300px;
    height: unset;
    .header {
      margin-bottom: 20px;
    }

    .button {
      margin-right: 0px;
    }
    .button-group {
      justify-content: space-between;
      margin-top: 30px;
    }
  }
`;

export default EditMembership;
