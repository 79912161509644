import {
  getAllAttendance,
  getAllAttendanceBetween,
  getAllAttendanceForYear,
  getAttendanceForBranch,
  getAttendanceForGymowner,
  getGraphDataByYear,
  getTopBranchesData,
  getTopGymsData,
} from "../../repository/web/js/attendance.service";

export const getAllAttendanceController = async () => {
  try {
    const attendance = await getAllAttendance();
    return attendance;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getTopGymsDataController = async (year) => {
  const topGymsData = await getTopGymsData(year);
  return topGymsData;
};

export const getTopBranchesDataController = async (year, companyId = null) => {
  return await getTopBranchesData(year, companyId);
};

export const getAllAttendanceForYearController = async (year) => {
  const attendance = await getAllAttendanceForYear(year);
  return attendance;
};

export const getGraphDataByYearController = async (year, companyId = null) => {
  const data = await getGraphDataByYear(year, companyId);
  return data;
};

export const getAllAttendanceBetweenController = async (startDate, endDate) => {
  try {
    const attendanceData = await getAllAttendanceBetween(startDate, endDate);
    return attendanceData;
  } catch (error) {
    console.error("Error fetching attendance data:", error);
    return [];
  }
};

export const getAttendanceForGymownerController = async (gymId) => {
  const attendance = await getAttendanceForGymowner(gymId);
  return attendance;
};

export const getAttendanceForBranchController = async (branchId) => {
  try {
    const attendance = await getAttendanceForBranch(branchId);
    return attendance;
  } catch (error) {
    console.error("Error fetching attendance for branch:", error);
    return [];
  }
};
