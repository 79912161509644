class Attendance {
  constructor(
    attendanceCost = "",
    branch_name = "",
    gymId = "",
    mainBranchID = "",
    mainGymName = "",
    membershipName = "",
    sure_name = "",
    user_id = "",
    username = "",
    usersContact = "",
    entrance_date = null,
    companyID = ""
  ) {
    this.id = "";
    this.attendanceCost = attendanceCost;
    this.branch_name = branch_name;
    this.gymId = gymId;
    this.mainBranchID = mainBranchID;
    this.mainGymName = mainGymName;
    this.membershipName = membershipName;
    this.sure_name = sure_name;
    this.user_id = user_id;
    this.username = username;
    this.usersContact = usersContact;
    this.entrance_date = entrance_date;
    this.companyID = companyID;
    Object.preventExtensions(this);
  }

  getAttendanceCost() {
    return this.attendanceCost;
  }
  setAttendanceCost(attendanceCost) {
    this.attendanceCost = attendanceCost;
  }
  getBranchName() {
    return this.branch_name;
  }
  setBranchName(branch_name) {
    this.branch_name = branch_name;
  }
  getGymId() {
    return this.gymId;
  }
  setGymId(gymId) {
    this.gymId = gymId;
  }
  getMainBranchID() {
    return this.mainBranchID;
  }
  setMainBranchID(mainBranchID) {
    this.mainBranchID = mainBranchID;
  }
  getMainGymName() {
    return this.mainGymName;
  }
  setMainGymName(mainGymName) {
    this.mainGymName = mainGymName;
  }
  getMembershipName() {
    return this.membershipName;
  }
  setMembershipName(membershipName) {
    this.membershipName = membershipName;
  }
  getSureName() {
    return this.sure_name;
  }
  setSureName(sure_name) {
    this.sure_name = sure_name;
  }
  getUserId() {
    return this.user_id;
  }
  setUserId(user_id) {
    this.user_id = user_id;
  }
  getUsername() {
    return this.username;
  }
  setUsername(username) {
    this.username = username;
  }
  getUsersContact() {
    return this.usersContact;
  }
  setUsersContact(usersContact) {
    this.usersContact = usersContact;
  }
  getEntranceDate() {
    return this.entrance_date;
  }
  setEntranceDate(entrance_date) {
    this.entrance_date = entrance_date;
  }
  getCompanyID() {
    return this.companyID;
  }
  setCompanyID(companyID) {
    this.companyID = companyID;
  }
}

export default Attendance;
