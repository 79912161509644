import React, { forwardRef, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import "./InputBox.scss";

export default function InputBox(props) {
  const placeholder = props.placeholder
    ? props.placeholder
    : props.label
    ? props.label.toLowerCase() + "..."
    : "";
  const onChangeHandler = (e) => {
    console.log(iconRef.current.offsetWidth);
  };
  const iconRef = useRef();
  return (
    <div
      className="inputbox-group"
      style={{
        marginTop: props.topSpace ? "10px" : "0",
        marginBottom: props.topSpace ? "5px" : "8px",
      }}
    >
      <label>
        {props.label || ""}
        {props.required ? " *" : ""}
      </label>
      {props.icon ? (
        <span ref={iconRef} className="inputbox-icon">
          {props.icon}
        </span>
      ) : (
        ""
      )}
      {props.type && props.type === "phone" ? (
        <PhoneInput
          country={"ae"}
          inputClass="phone-input"
          buttonClass="phone-button"
          dropdownClass="phone-dropdown"
          placeholder={placeholder}
          value={props.value || ""}
          ref={props.ref || null}
          readOnly={props.readOnly || false}
          required={props.required || false}
          onChange={props.onChange || onChangeHandler}
          autoComplete={props.autoComplete || "false"}
          className={`${props.className || ""} ${
            props.error ? "inputbox-error" : ""
          }`}
          {...props}
        />
      ) : props.type && props.type === "textarea" ? (
        <textarea
          style={{ paddingLeft: props.icon ? "35px" : "8px" }}
          type={props.type || "text"}
          placeholder={placeholder}
          value={props.value || ""}
          ref={props.ref || null}
          onChange={props.onChange || onChangeHandler}
          autoComplete={props.autoComplete || "false"}
          readOnly={props.readOnly || false}
          required={props.required || false}
          minLength={props.minLength || 0}
          maxLength={props.maxLength || 2000}
          className={`${props.className || ""} ${
            props.error ? "inputbox-error" : ""
          }`}
          {...props}
        />
      ) : (
        <input
          style={{ paddingLeft: props.icon ? "35px" : "8px" }}
          type={props.type || "text"}
          placeholder={placeholder}
          value={props.value || ""}
          ref={props.ref || null}
          onChange={props.onChange || onChangeHandler}
          autoComplete={props.autoComplete || "false"}
          readOnly={props.readOnly || false}
          required={props.required || false}
          minLength={props.minLength || 0}
          maxLength={props.maxLength || 2000}
          className={`${props.className || ""} ${
            props.error ? "inputbox-error" : ""
          }`}
          {...props}
        />
      )}
    </div>
  );
}
