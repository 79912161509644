import React, { createContext, useContext, useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { BiPencil } from "react-icons/bi";
import { BsFillPencilFill, BsSave, BsSave2 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
// import {
//   addUserAttachment,
//   getUser,
//   removeUserAttachment,
//   updateUserImage,
//   updateUserNew,
// } from "../../../../common/repository/js/user";
import {
  addUserAttachmentController,
  getUserController,
  removeUserAttachmentController,
  updateUserImageController,
  updateUserNewController,
} from "../../../../../../controllers/web/userController";
import { getMembershipOption } from "../../../../../../repository/web/js/membership.js";
import InputBox from "../../../custom/InputBox/InputBox";
import PageHeader from "../../../custom/PageHeader/PageHeader";
import PageSection, {
  PageSectionBody,
} from "../../../custom/PageSection/PageSection";
import Panel from "../../../custom/Panel/Panel";
import CustomSelect from "../item/CustomSelect";
import ReactImageUploading from "react-images-uploading";
import "./userdetailpage.scss";
import { MdAddCircle, MdClose, MdDelete } from "react-icons/md";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import CustomLoading from "../../../items/loadingBar";
// import {actionUserLoggedin} from "../../../../redux/states/user/user.action";
import { setUserLoggedIn } from "../../../../redux/slices/Auth.slice";
// import {
//   addAttachment,
//   getGymInfo,
//   getGymsList,
//   getMainGymByOwnerID,
// } from "../../../../repository/web/js/gym_main";
import {
  getGymsListController,
  getGymInfoController,
  getMainGymByOwnerIDController,
  addAttachmentController,
  getGymsOptionController,
} from "../../../../../../controllers/web/gymMainController";
// import {
//   getBranchesByGymId,
//   getBranchesListByGymId,
// } from "../../../../repository/web/js/gym";
import { getBranchesListByGymIdController } from "../../../../../../controllers/web/gymController";
// import { autoLogin } from "../../../../repository/web/js/firebaseAuth";
import { autoLoginController } from "../../../../../../controllers/web/firebaseAuthController";
import ConfirmDialog from "../../../custom/ConfirmDialog/ConfirmDialog";
import FileUploader from "../../../custom/FileUploader/FileUploader";
import Toster from "../../../custom/Toaster/Toster";
import Section from "../../../custom/Section/Section";
// import { getMembershipNames } from "../../../../repository/web/js/membership";
import { getMembershipNamesController } from "../../../../../../controllers/web/memberShipController";
// import { resetDeviceID } from "../../../../repository/web/js/user";
import { resetDeviceIDController } from "../../../../../../controllers/web/userController";
import { genderOption } from "../../../../../../common/data/GenderOptions";
import { loadRoleOptions } from "../../../../../../common/data/roleOptionsUtils";
import { getRelatedRoles } from "../../../../../../common/data/UserTypes";

var MyContext = createContext();
var globalTimer = "";

export default function UserDetailPageNew() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user: loggedinUserProfile } = useSelector((state) => state.user);
  const [userProfileToUpdate, setuserProfileToUpdate] = useState({});
  const [updaterProfileLoaded, setupdaterProfileLoaded] = useState(false);
  const [dataReceivedFrom, setdataReceivedFrom] = useState(
    location?.state?.dataReceivedFrom || "redux"
  );
  const [loading, setLoading] = useState(false);
  const [editModeEnabled, seteditModeEnabled] = useState(false);
  const [showInititalForm, setshowInititalForm] = useState(true);
  const [showAdminForm, setshowAdminForm] = useState(false);
  const [showGymOwnerForm, setshowGymOwnerForm] = useState(false);
  const [showCustomerForm, setshowCustomerForm] = useState(false);
  const [showManagerForm, setshowManagerForm] = useState(false);
  const [showTrainerForm, setshowTrainerForm] = useState(false);
  const [selectedCompany, setselectedCompany] = useState({
    value: userProfileToUpdate?.company_id || "",
    label: userProfileToUpdate?.company_name || "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    loadProfileForUpdate();
  }, []);

  useEffect(async () => {
    if (updaterProfileLoaded) {
      //** */ if profile for Trainer or Gym manager
      if (
        userProfileToUpdate.role.includes(1) ||
        userProfileToUpdate.role.includes(6)
      ) {
        await getGymInfoController(userProfileToUpdate.company_id).then((res) => {
          if (res) {
            setuserProfileToUpdate({
              ...userProfileToUpdate,
              maingyminfo: res,
            });
            setupdaterProfileLoaded(false);
          }
        });
      }
      //** */ if profile for customer
      if (userProfileToUpdate.role.includes(5)) {
        // code will be go here
      }

      //** */ if profile for Gym Owner
      if (userProfileToUpdate.role.includes(3)) {
        await getMainGymByOwnerIDController(userProfileToUpdate.id).then(
          (res) => {
            if (res) {
              setuserProfileToUpdate({
                ...userProfileToUpdate,
                maingyminfo: { ...res, id: res.id },
              });
            }
            setupdaterProfileLoaded(false);
          }
        );
      }

      //** */ if profile for freelance trainer
      if (userProfileToUpdate.role.includes(2)) {
      }
    }
  }, [updaterProfileLoaded]);

  const loadProfileForUpdate = async () => {
    let r = false;
    setLoading(true);
    try {
      if (dataReceivedFrom == "custom") {
        // console.log(location);

        await getUserController(location?.state.userid).then((result) => {
          if (!result.error) {
            setuserProfileToUpdate(result);
            setupdaterProfileLoaded(true);
            setLoading(false);
            r = true;
          }
        });
      } else {
        setuserProfileToUpdate(loggedinUserProfile);
        setupdaterProfileLoaded(true);
        setLoading(false);
        r = true;
      }
    } catch (error) {
      console.log(error);
    }
    return r;
  };

  const convertUserRoles = (roles) => {
    let result = "";
    if (roles?.length > 0) {
      roles.forEach((role, index) => {
        if (role === 1) {
          index === 0 ? (result += "Trainer") : (result += " | Trainer");
        }
        if (role === 2) {
          index === 0
            ? (result += "Freelance Trainer")
            : (result += " | Freelance Trainer");
        }
        if (role === 3) {
          index === 0 ? (result += "Gym Owner") : (result += " | Gym Owner");
        }
        if (role === 4) {
          index === 0 ? (result += "Admin") : (result += " | Admin");
        }
        if (role === 5) {
          index === 0 ? (result += "Customer") : (result += " | Customer");
        }
        if (role === 6) {
          index === 0
            ? (result += "Gym Manager")
            : (result += " | Gym Manager");
        }
      });
    }
    return result;
  };

  // const convertBrnaches = (branches) => {
  //   let result = "";
  //   if (branches?.length > 0) {
  //     branches.forEach((branch, index) => {
  //       index === 0
  //         ? (result += branch.label)
  //         : (result += " | " + branch.label);
  //     });
  //   }
  //   return result;
  // };

  const handleCancelClick = () => {
    setshowAdminForm(false);
    setshowGymOwnerForm(false);
    setshowCustomerForm(false);
    setshowManagerForm(false);
    setshowTrainerForm(false);
    setshowInititalForm(true);
    seteditModeEnabled(false);
  };

  const handleCancel = () => {
    navigate("/user");
  };

  const handleEditClick = () => {
    //console.log(dataReceivedFrom, location?.state);
    if (editModeEnabled) {
      setshowAdminForm(false);
      setshowGymOwnerForm(false);
      setshowCustomerForm(false);
      setshowManagerForm(false);
      setshowTrainerForm(false);
      setshowInititalForm(true);
      seteditModeEnabled(false);
    } else {
      seteditModeEnabled(true);
      setshowAdminForm(true);
      setshowInititalForm(false);
    }
  };

  const handleUpdateClick = async () => {
    //console.log(userProfileToUpdate?.sure_name?.trim().length);
    console.log("Uset to update is", userProfileToUpdate)
    if (
      userProfileToUpdate?.sure_name?.trim().length === 0 ||
      userProfileToUpdate?.phone?.trim().length === 0 ||
      userProfileToUpdate?.role?.length === 0 ||
      userProfileToUpdate?.gender?.trim().length === 0
    ) {
      return ConfirmDialog.show({
        message: "Please fill all required fields.",
        showFooter: false,
      });
    }
    let dataToUpdate = {
      sure_name: userProfileToUpdate.sure_name,
      phone: userProfileToUpdate.phone,
      role: userProfileToUpdate.role,
      gender: userProfileToUpdate.gender || "male",
      company_name: userProfileToUpdate?.company_name || "",
      company_id: userProfileToUpdate?.company_id || "",
      facility_id: userProfileToUpdate.facility_id,
      facility_name: userProfileToUpdate.facility_name,

    };
    // trainer & gym manager
    if (
      (userProfileToUpdate.role.includes(6) ||
        userProfileToUpdate.role.includes(1)) &&
      userProfileToUpdate.branches
    ) {
      dataToUpdate = {
        ...dataToUpdate,
        // branches: userProfileToUpdate.branches,
        facility_id: userProfileToUpdate.facility_id,
        facility_name: userProfileToUpdate.facility_name,

      };
    }

    // is customer
    if (
      userProfileToUpdate.role.includes(5) &&
      userProfileToUpdate.memberShipPlan
    ) {
      dataToUpdate = {
        ...dataToUpdate,
        memberShipPlan: userProfileToUpdate.memberShipPlan,
      };
    }

    // gym owner
    // if (userProfileToUpdate.role.includes(3) && userProfileToUpdate.company) {
    //   dataToUpdate = { ...dataToUpdate, company: userProfileToUpdate.company };
    // }

    // If any user will change his profile. Then particular user's profile status will changed to "InActive"
    // Admin will check the changes and active particular profile.

    // if (
    //   !loggedinUserProfile?.role?.includes(4) &&
    //   dataReceivedFrom === "redux"
    // ) {
    //   dataToUpdate = { ...dataToUpdate, activated: false };
    // }

    updateUserNewController(userProfileToUpdate.id, dataToUpdate).then(
      async (res) => {
        if (res.success) {
          if (
            userProfileToUpdate.id === loggedinUserProfile.id &&
            loggedinUserProfile.role.includes(4)
          ) {
            dispatch(setUserLoggedIn(userProfileToUpdate));
            toast.success("User profile updated", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
            });
            handleCancelClick();
          } else if (dataReceivedFrom === "redux") {
            await autoLoginController().then((r) => {
              if (r) {
                dispatch(setUserLoggedIn(r));
                toast.success("User profile updated", {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: true,
                });
                handleCancelClick();
              } else {
                localStorage.clear();
                toast.success("User profile updated", {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: true,
                });
              }
            });
          } else {
            toast.success("User profile updated", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
            });
            handleCancelClick();
          }
        } else {
          toast.error("Failed to update", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      }
    );
  };

  const handleResetDeviceID = async () => {
    setLoading(true);
    await resetDeviceIDController(userProfileToUpdate.id)
      .then((res) => {
        if (res.success === true) {
          Toster.show("Device ID reset successfully");
        } else {
          Toster.show("Device ID reset failed");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div className="user-detail-page-container">
      <MyContext.Provider
        value={[
          loggedinUserProfile,
          userProfileToUpdate,
          // convertBrnaches,
          // selectedCompany,
          // setselectedCompany,
          convertUserRoles,
          editModeEnabled,
          setuserProfileToUpdate,
          loadProfileForUpdate,
          dataReceivedFrom,
        ]}
      >
        <CustomLoading isStart={loading} isFull={true} />
        <PageSection className={dataReceivedFrom === "redux" && "autoHeight"}>
          <PageHeader>
            <div className="page-header-title">
              {dataReceivedFrom === "redux" ? "Settings" : "User Profile"}
            </div>
            <div className="header-right-side-container text-warning d-flex justify-content-center align-items-center">
              {editModeEnabled && (
                <>
                  <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                  </Button>
                  <Button
                    className="ms-1"
                    variant="warning"
                    onClick={handleUpdateClick}
                  >
                    <BsSave />{" "}
                    {dataReceivedFrom === "redux"
                      ? " Update Profile"
                      : " Update"}
                  </Button>
                </>
              )}
              {!editModeEnabled && (
                <>
                  {dataReceivedFrom !== "redux" && (
                    <>
                      <Button
                        className="btn btn-danger me-1"
                        onClick={handleResetDeviceID}
                      >
                        Reset Device
                      </Button>
                      <Button variant="secondary" onClick={handleCancel}>
                        Cancel
                      </Button>
                    </>
                  )}
                  <Button
                    className="ms-1"
                    variant="light"
                    onClick={handleEditClick}
                  >
                    <BsFillPencilFill />{" "}
                    {dataReceivedFrom === "redux" ? " Edit Profile" : " Edit"}
                  </Button>
                </>
              )}
            </div>
          </PageHeader>
          <PageSectionBody>
            <div className="d-flex w-100 flex-column">
              <Panel style={{ minHeight: "auto" }}>
                <div className="p-4 container">
                  <Row className="w-100 gx-0 gy-0">
                    <Col lg="3">
                      <ImageSection />
                    </Col>
                    <Col lg="9">
                      <div className="user-form-container">
                        <div className="user-form-sizer">
                          {showInititalForm && <InitialForm />}
                          {showAdminForm && <AdminEditForm />}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Panel>
              {((loggedinUserProfile?.role?.includes(4) &&
                userProfileToUpdate?.role?.includes(2)) ||
                loggedinUserProfile?.role?.includes(2)) && (
                  <Panel minheight="maxt-content">
                    <div className="d-flex position-relative w-100 p-2">
                      <AttachmentsSection />
                    </div>
                  </Panel>
                )}
            </div>
          </PageSectionBody>
        </PageSection>
      </MyContext.Provider>
    </div>
  );
}

const InitialForm = () => {
  const [
    loggedinUserProfile,
    userProfileToUpdate,
    // convertBrnaches,
    convertUserRoles,
    ,
    ,
    loadProfileForUpdate,
  ] = useContext(MyContext);

  // useEffect(() => {
  //   loadProfileForUpdate();
  // }, []);
  return (
    <>
      <div className="user-data-item">
        <span className="label">Status</span>
        <span
          className={`data-fi ${!userProfileToUpdate.activated ? "text-danger" : "text-success"
            }`}
        >
          {userProfileToUpdate.activated ? "Active" : "Deactivated"}
        </span>
      </div>
      <div className="user-data-item">
        <span className="label">Name</span>
        <span className="data-fi">{userProfileToUpdate.sure_name}</span>
      </div>
      <div className="user-data-item">
        <span className="label">Email</span>
        <span className="data-fi">{userProfileToUpdate.username}</span>
      </div>
      <div className="user-data-item">
        <span className="label">Phone</span>
        <span className="data-fi">{userProfileToUpdate.phone}</span>
      </div>
      <div className="user-data-item">
        <span className="label">Gender</span>
        <span className="data-fi">{userProfileToUpdate.gender || "-"}</span>
      </div>
      <div className="user-data-item">
        <span className="label">Role</span>
        <span className="data-fi">
          {convertUserRoles(userProfileToUpdate.role) || "-"}
        </span>
      </div>
      {userProfileToUpdate?.role?.includes(5) && (
        <div className="user-data-item">
          <span className="label">Membership</span>
          <span className="data-fi">
            {userProfileToUpdate.memberShipPlan?.label || "-"}
          </span>
        </div>
      )}
      {(userProfileToUpdate?.role?.includes(3) ||
        userProfileToUpdate?.role?.includes(1) ||
        userProfileToUpdate?.role?.includes(6)) && (
          <div className="user-data-item">
            <span className="label">Company</span>
            <span className="data-fi">
              {userProfileToUpdate.company_name ||
                userProfileToUpdate.company_name ||
                "-"}
            </span>
          </div>
        )}
      {(userProfileToUpdate?.role?.includes(1) ||
        userProfileToUpdate?.role?.includes(6)) && (
          <div className="user-data-item">
            <span className="label">Facility Name</span>
            <span className="data-fi">
              {userProfileToUpdate.facility_name || "-"}
            </span>
          </div>
        )}
    </>
  );
};

const AddAttachmentModal = (props) => {
  const [formData, setFormData] = useState({
    title: "",
    titleError: false,
    images: [],
  });

  const onChange = (imageList) => {
    setFormData({ ...formData, images: imageList });
  };

  const handleAddFile = () => {
    setFormData({
      ...formData,
      titleError: formData.title.trim().length === 0,
    });
    if (formData.images.length <= 0 || formData.title.trim().length === 0) {
      formData.images.length <= 0 && alert("Please select a file to add.");
      return;
    }
    props.okCallback(formData);
  };
  return (
    <div className="add-attachment-container">
      <InputBox
        label="Title"
        error={formData.titleError}
        value={formData.title}
        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
      />
      <FileUploader onChange={onChange} acceptTypes={["png", "jpg", "pdf"]}>
        {({ onSelect, onRemove, error }) => {
          return (
            <div className="attachment-file-uploader">
              {error && <div className="error-msg">{error}</div>}
              {!error && (
                <div className="file-name">
                  {formData.images.length > 0
                    ? formData.images[0].name
                    : "No file selected"}
                </div>
              )}
              {formData.images.length === 0 && (
                <Button size="sm" variant="success" onClick={onSelect}>
                  Select File
                </Button>
              )}
              {formData.images.length > 0 && (
                <div className="d-inline-flex w-100">
                  <Button size="sm" variant="danger" onClick={onRemove}>
                    <MdClose /> Change
                  </Button>
                </div>
              )}
            </div>
          );
        }}
      </FileUploader>
      <div
        className="d-flex justify-content-center w-100 mt-3 mb-3 border-top pt-3"
        style={{ gap: "10px" }}
      >
        <Button
          className="w-100"
          variant="secondary"
          onClick={(e) => ConfirmDialog.hide()}
        >
          Cancel
        </Button>
        <Button className="w-100" variant="warning" onClick={handleAddFile}>
          Add File
        </Button>
      </div>
    </div>
  );
};

const AttachmentsSection = () => {
  const [
    loggedinUserProfile,
    userProfileToUpdate,
    // convertBrnaches,
    convertUserRoles,
    ,
    ,
    loadProfileForUpdate,
  ] = useContext(MyContext);

  // const userProfile = useSelector((state) => state.user);
  // const [reloadAll, setreloadAll, gymProfileData] = useContext(PageContext);
  const [attachmentData, setattachmentData] = useState([]);
  const [loading, setloading] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    loadAttachments();
    return () => {
      if (globalTimer !== "") {
        clearTimeout(globalTimer);
      }
    };
  }, []);

  useEffect(() => {
    loadAttachments();
  }, [userProfileToUpdate]);

  const uploadFile = async (data) => {
    // console.log("In uploadFile");
    const imageFile = data.images[0];
    const storage = getStorage();
    const userRef = ref(
      storage,
      `/user_attachments/${userProfileToUpdate.id}/${data.title}_${imageFile.name}`
    );
    const uploadTask = uploadBytesResumable(userRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setloading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            setloading(false);
            break;
        }
      },
      (error) => {
        console.log("Error uploading", error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then(
          async (downloadURL) => {
            let result = await addUserAttachmentController(
              userProfileToUpdate.id,
              {
                title: data.title,
                url: downloadURL,
                filename:
                  "user_attachments/" +
                  userProfileToUpdate.id +
                  "/" +
                  data.title +
                  "_" +
                  imageFile.name,
              }
            );
            Toster.show("Attachment added.");
            setloading(false);
          }
        );
      }
    );
  };

  const AddNewFile = (data) => {
    console.log("In ADD New File");
    if (attachmentData.length < 5) {
      if (attachmentData.map((e) => e.title).includes(data.title)) {
        ConfirmDialog.show({
          message: "Title is already exists. Pelase try with another.",
          showFooter: false,
        });
      } else {
        let temp = [...attachmentData];
        temp.push({ title: data.title, link: "", actionLink: "" });
        setattachmentData(temp);
        uploadFile(data);
        ConfirmDialog.hide();
      }
    } else {
      ConfirmDialog.show({
        message: "You have reached the max attachment limit.",
        showFooter: false,
      });
    }
  };

  const handleRemoveItem = (index, url) => {
    console.log("IN handle remove ", url);
    ConfirmDialog.show({
      message: "Sure to remove the attachment?",
      okCallback: async () => {
        const storage = getStorage();
        const desertRef = ref(storage, url);
        ConfirmDialog.hide();
        setloading(true);
        return deleteObject(desertRef)
          .then(async () => {
            return await removeUserAttachmentController(
              userProfileToUpdate.id,
              {
                filename: url,
                title: attachmentData[index].title,
                url: attachmentData[index].link,
              }
            ).then(async (result) => {
              if (result.success) {
                Toster.show("Attachment removed.");
                let temp = [...attachmentData];
                temp.splice(index, 1);
                setattachmentData(temp);
                await autoLoginController().then((r) => {
                  if (r) {
                    dispatch(setUserLoggedIn(r));
                  }
                });
                setloading(false);

                return true;
              } else {
                throw new Error("file not found");
              }
            });
          })
          .catch(async (error) => {
            await removeUserAttachmentController(userProfileToUpdate.id, {
              filename: url,
              title: attachmentData[index].title,
              url: attachmentData[index].link,
            }).then((result) => {
              if (result.success) {
                Toster.show("Attachment removed.");
                let temp = [...attachmentData];
                temp.splice(index, 1);
                setattachmentData(temp);
                autoLoginController().then((r) => {
                  if (r) {
                    dispatch(setUserLoggedIn(r));
                    loadAttachments();
                  }
                });
                return true;
              } else {
                throw new Error("file not found");
              }
            });
            ConfirmDialog.show({
              message:
                "An error accoured to remove this file. May be you added duplicate attachment.",
              showFooter: false,
            });
          });
      },
    });
  };

  const loadAttachments = () => {
    setloading(true);
    console.log("In Load Attachment");
    try {
      setattachmentData(null);
      if (userProfileToUpdate) {
        let temp = [];
        userProfileToUpdate?.attachments?.forEach((e, index) => {
          temp.push({ title: e.title, link: e.url, filename: e.filename });
        });
        setloading(false);
        setattachmentData(temp);
      }
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  return (
    <Section>
      {loading && (
        <div className="loadSpinner black">
          <Spinner size="sm" animation="border" variant="warning" />
        </div>
      )}
      <div className="attachment-section-header">
        <h5>
          Other Attachments{" "}
          <span style={{ fontSize: "0.65em" }} className="text-warning">
            (Max 05 files)
          </span>
        </h5>
        <Button
          onClick={(e) => {
            if (!loading && userProfileToUpdate) {
              ConfirmDialog.show({
                message: <AddAttachmentModal okCallback={AddNewFile} />,
                title: "Add Attachment",
                okText: "Add File",
                cancelText: "Cancel",
                showFooter: false,
              });
            }
          }}
        >
          <MdAddCircle /> Add Attachment
        </Button>
      </div>
      {!loading && (
        <div className="gym-attachment-table">
          <div className="table-headings">
            <span>SN</span>
            <span>Document Title</span>
            <span>Link</span>
            <span>Action</span>
          </div>
          <div className="table-body">
            {attachmentData &&
              attachmentData.length > 0 &&
              attachmentData.map((element, index) => {
                return (
                  <div className="table-row" key={index + 1}>
                    <span>{index + 1}</span>
                    <span>{element.title}</span>
                    <span>
                      <a
                        onClick={(e) => {
                          var a = document.createElement("a");
                          a.href = element.link;
                          a.target = "_blank";
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                        }}
                      >
                        Download
                      </a>
                    </span>
                    <span>
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={(e) =>
                          handleRemoveItem(index, element.filename)
                        }
                      >
                        <MdClose />
                      </Button>
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </Section>
  );
};

const AdminEditForm = () => {
  const [
    loggedinUserProfile,
    userProfileToUpdate,
    // convertBrnaches,
    // convertUserRoles,
    // editModeEnabled,
    setselectedCompany,
    selectedCompany,
    setuserProfileToUpdate,
    loadProfileForUpdate,
    dataReceivedFrom,
    
  ] = useContext(MyContext);
  const [selectedGender, setselectedGender] = useState([
    {
      value: userProfileToUpdate?.gender || "male",
      label: userProfileToUpdate?.gender || "male",
    },
  ]);
  const [roleOptions, setroleOptions] = useState([]);
  const [selectedRole, setselectedRole] = useState(
    getDefaultRoles(userProfileToUpdate.role)
  );
  const [membershipList, setmembershipList] = useState([]);
  const [selectedMembership, setselectedMembership] = useState([]);
  const [branchList, setbranchList] = useState([]);
  const [selectedBranch, setselectedBranch] = useState();
  const [companyList, setcompanyList] = useState([]);
  
  const [gymsOptions, setGymsOptions] = useState();
  const [mainBranchIDError, setMainBranchIDError] = useState(false);
  const [mainBranchID, setMainBranchID] = useState("");

  const gymOptions = async () => {
    let options = await getGymsOptionController();
    return options;
  };

  // useEffect(() => {
  //
  // }, []);

  useEffect(() => {
    loadOptionsRole();
  }, [loggedinUserProfile]);

  useEffect(() => {
    gymOptions().then((options) => {
      setGymsOptions(options);
    });
    if (!userProfileToUpdate.gender) {
      setuserProfileToUpdate({
        ...userProfileToUpdate,
        gender: "male",
      });
    }
    loadOtherDataAccordingRole();
  }, []);

  useEffect(() => {
    // console.log(roleOptions)
  }, [roleOptions]);

  const loadCompanyList = async () => {
    await getGymsListController().then((res) => {
      if (res?.length) {
        setcompanyList(res);
      
      }
    });
  };
  const loadMembershipPlanList = async () => {
    let membershipOptions = await getMembershipOption();
    setmembershipList(membershipOptions);
  };

  const getUserSubscribedMembershipIndex = async () => {
    const subscribedMemberships = await getMembershipNamesController();

    let maxIndex = -1;
    for (const membership of subscribedMemberships.data) {
      const index = membership.index || 0;
      if (index > maxIndex) {
        maxIndex = index;
      }
    }

    return maxIndex;
  };

  const loadOtherDataAccordingRole = () => {
    if (userProfileToUpdate) {
      if (
        userProfileToUpdate.role.includes(6) ||
        userProfileToUpdate.role.includes(1)
      ) {
        loadBranchList(userProfileToUpdate?.company_id);
        console.log("Needs to be updated");
      }
      if (userProfileToUpdate.role.includes(3)) {
        loadCompanyList();
      }

      loadMembershipPlanList();

    }
  };

  const loadOptionsRole = () => {
    const roleOptions = loadRoleOptions(
      loggedinUserProfile,
      userProfileToUpdate
    );
    setroleOptions(roleOptions);
  };

  function getDefaultRoles(roles) {
    let result = [];
    if (roles?.length > 0) {
      result = getRelatedRoles(roles);
    }
    return result;
  }

  const loadBranchList = async (mainGymId) => {
    try {
      const res = await getBranchesListByGymIdController(mainGymId);
      if (res?.length) {
        console.log("Branch ..",res);
        setbranchList(res.map((branch) => ({
            // console.log() 
            label: branch.label, 
             value: branch.value })));
      }
    } catch (error) {
      console.error("Error loading branch list:", error);
    }
    console.log("Branch list is", branchList);
  };


  

  return (
    <div>
      <InputBox
        label="Name"
        required
        value={userProfileToUpdate.sure_name}
        onChange={(e) => {
          setuserProfileToUpdate({
            ...userProfileToUpdate,
            sure_name: e.target.value,
          });
        }}
      />
      <InputBox
        className="disabled"
        label="Email-Id"
        required
        value={userProfileToUpdate.username}
      />
      <InputBox
        label="Phone"
        required
        type="phone"
        value={userProfileToUpdate.phone}
        onChange={(e) => {
          setuserProfileToUpdate({
            ...userProfileToUpdate,
            phone: e,
          });
        }}
      />
      <div className="mb-3">
        <label className="custom-select-label">Gender *</label>
        <CustomSelect
          options={genderOption}
          value={selectedGender}
          isSearchable={true}
          onChange={(e) => {
            setselectedGender(e);
            setuserProfileToUpdate({
              ...userProfileToUpdate,
              gender: e.label,
            });
          }}
        />
      </div>
      {(loggedinUserProfile.role.includes(4) ||
        loggedinUserProfile.role.includes(3)) &&
        dataReceivedFrom !== "redux" && (
          <div className="mb-3">
            <label className="custom-select-label">Roles *</label>
            <CustomSelect
              options={roleOptions}
              isSearchable={true}
              value={selectedRole}
              onChange={(e) => {
                let temp = [];
                e.reduce((pv, cv) => {
                  pv = cv;
                  temp.push(Number.parseInt(pv.value));
                  return pv;
                }, []);
                setuserProfileToUpdate({ ...userProfileToUpdate, role: temp });
                setselectedRole(e);
              }}
              isMulti
            />
          </div>
        )}
      {userProfileToUpdate.role.includes(5) &&
        loggedinUserProfile.role.includes(4) &&
        dataReceivedFrom !== "redux" && (
          <div className="mb-3">
            <label className="custom-select-label">Membership *</label>
            <CustomSelect
              options={membershipList}
              value={userProfileToUpdate.memberShipPlan || ""}
              onChange={(e) => {
                let updateDetails = {};
                if (e.label !== "None") {
                  const endDate = new Date().setDate(new Date().getDate() + 30);
                  updateDetails = {
                    end_date: new Date(endDate),
                    purchased_date: new Date(),
                    paymentId: "By Admin",
                    index: e["value"],
                  };
                }
                delete e["value"];
                setuserProfileToUpdate({
                  ...userProfileToUpdate,
                  memberShipPlan: {
                    ...e,
                    ...updateDetails,
                  },
                });
              }}
            />
          </div>
        )}

      {(userProfileToUpdate.role.includes(3) ||
        userProfileToUpdate.role.includes(1) ||
        userProfileToUpdate.role.includes(6)) &&
        loggedinUserProfile.role.includes(4) && (
          <>
            <label className="custom-select-label">Company *</label>
            <CustomSelect
              className={
                mainBranchIDError ? "input-select error" : "input-select"
              }
              value={selectedCompany}
              isSearch={true}
              options={gymsOptions}
              placeholder="Select Main Company"
              onChange={(e) => {
                console.log("Data to u", e)
                setselectedCompany(e);
                loadBranchList(e.value);
                setuserProfileToUpdate({ ...userProfileToUpdate, company_name: e.label, company_id: e.value });
              }}
            />
          </>
        )}

      {(userProfileToUpdate.role.includes(1) ||
        userProfileToUpdate.role.includes(6)) &&
        (loggedinUserProfile.role.includes(4) ||
          loggedinUserProfile.role.includes(3)) &&
        dataReceivedFrom !== "redux" && (
          <div className="mb-3">
            <label className="custom-select-label">Facility Name *</label>
            <CustomSelect
              options={branchList || []}
              value={selectedBranch}
              onChange={(e) => {
                setselectedBranch(e);
                console.log(userProfileToUpdate)
                setuserProfileToUpdate((prevState) => ({
                  ...prevState,
                  facility_id: e.value,
                  facility_name: e.label
                }));
                console.log(userProfileToUpdate)

                // setuserProfileToUpdate({ ...userProfileToUpdate, facility_id: e.value, facility_name:e.label });
              }}
            />
          </div>
        )}
    </div>
  );
};

const ImageSection = () => {
  const [
    loggedinUserProfile,
    userProfileToUpdate,
    convertBrnaches,
    convertUserRoles,
    editModeEnabled,
    setuserProfileToUpdate,
    loadProfileForUpdate,
    dataReceivedFrom,
  ] = useContext(MyContext);
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [savedImage, setsavedImage] = useState(userProfileToUpdate.photo || "");

  useEffect(() => {
    setsavedImage(userProfileToUpdate.photo);
  }, [userProfileToUpdate]);

  useEffect(() => {
    if (!editModeEnabled) {
      setImages([]);
    }
  }, [editModeEnabled]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const handleImageUpdate = () => {
    const imageFile = images[0].file;
    const storage = getStorage();
    const userRef = ref(
      storage,
      `/users/${loggedinUserProfile.id}/${imageFile.name}`
    );
    const uploadTask = uploadBytesResumable(userRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setUploading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => { },
      () => {
        toast.success("User image updated", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          progress: undefined,
        });
        setUploading(false);
        setImages([]);
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          updateUserImageController(userProfileToUpdate.id, downloadURL);
          setsavedImage(downloadURL);
          autoLoginController().then((r) => {
            if (r) {
              dispatch(setUserLoggedIn(r));
            }
          });
        });
      }
    );
  };
  return (
    <div className="d-flex flex-column align-items-center p-4">
      <div className="user-image-container mb-3">
        <img
          className="img-fluid user-image"
          style={{ width: "100%" }}
          alt=""
          src={images[0]?.data_url || savedImage}
        />
      </div>
      <ReactImageUploading
        value={images}
        onChange={onChange}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <>
            {images?.length <= 0 ? (
              <>
                {editModeEnabled == true && (
                  <Button
                    variant="warning"
                    className="mb-4"
                    onClick={onImageUpload}
                  >
                    SELECT
                  </Button>
                )}
              </>
            ) : (
              <>
                {editModeEnabled == true && (
                  <div className="d-inline-flex ">
                    <Button
                      variant="warning"
                      className="mb-4"
                      onClick={handleImageUpdate}
                    >
                      <BsSave /> {" Update"}
                    </Button>
                    <Button
                      variant="danger"
                      className="delete-btn mb-4"
                      onClick={() => onImageRemove(0)}
                    >
                      <MdClose />
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </ReactImageUploading>
    </div>
  );
};