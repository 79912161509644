import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../../assets/images/Logo.png";
import { HiHome, HiMenuAlt3 } from "react-icons/hi";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import {
  MdSpaceDashboard,
  MdHotelClass,
  MdGrade,
  MdWebStories,
  MdDomain,
  MdInsertDriveFile,
} from "react-icons/md";
import { FaUsers, FaDumbbell } from "react-icons/fa";
import { RiUserFill } from "react-icons/ri";
import { TiWarning } from "react-icons/ti";
import { GrDomain } from "react-icons/gr";
import { IoSettings, IoLanguage } from "react-icons/io5";
import { AiOutlineExport, AiFillDashboard } from "react-icons/ai";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../../../locales/locale";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
// import { actionUserLoggedout } from "../../../redux/states/user/user.action";
import { logoutUser } from "../../../redux/slices/Auth.slice";
import { style } from "@mui/system";

const { SingleValue, Option, DropdownIndicator } = components;

const Menu = ({ icon, text, link, active, onClick }) => {
  return (
    <Link to={link} className="link" onClick={onClick ? onClick : () => {}}>
      <div className={active ? "menu active" : "menu"}>
        {icon}
        {text}
      </div>
    </Link>
  );
};
const langStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  control: () => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  group: () => ({
    backgroundColor: "red",
    padding: "30px",
    borderRadius: "15px",
  }),
  indicatorSeparator: () => ({
    color: "transparent",
  }),
  dropdownIndicator: () => ({
    color: "#888888",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#FECA00" : "#888888",
    backgroundColor: "#1C1A1D",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: "30px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#888888",
    cursor: "default",
  }),
  menu: (provided) => ({
    ...provided,
    margin: 0,
    backgroundColor: "#1C1A1D",
    borderRadius: "15px",
    padding: "5px 72px 30px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#888888",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  }),
  input: (provided) => ({
    ...provided,
    textAlign: "left",
    color: "white",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px",
  }),
};

const IconSingleValue = (props) => (
  <SingleValue {...props} className="lang-content">
    <IoLanguage
      className={props.selectProps.menuIsOpen ? "icon active" : "icon"}
    />
    {/* <img src={props.data.image} style={{ borderRadius: '50%', marginRight: '10px' }}/> */}
    <span className="lang-menu-text" onClick={props.data.onClick}>
      {props.data.label}
    </span>
  </SingleValue>
);

const IconOption = (props) => (
  <Option {...props}>
    {/* <img src={props.data.image} style={{ borderRadius: '50%', marginRight: '10px' }}/> */}
    <span className="lang-menu-text" onClick={props.data.onClick}>
      {props.data.label}
    </span>
  </Option>
);
const IconDropdownIndicator = (props) => {
  return (
    DropdownIndicator && (
      <DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <BsChevronDown className="menu-indicator active" />
        ) : (
          <BsChevronUp className="menu-indicator" />
        )}
      </DropdownIndicator>
    )
  );
};
export default function Sidebar() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [user, setUser] = useState({});
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  var { user: userInfo } = useSelector((state) => state.user);

  const GeneralMenus = [
    {
      icon: <MdSpaceDashboard className="icon" />,
      text: t("Dashboard"),
      link: "/dashboard",
    },
    {
      icon: <MdDomain className="icon" />,
      // text: t("Manage Gyms"),
      text: t("Companies"),
      link: "/gyms",
    },
    {
      icon: <FaDumbbell className="icon" />,
      // text: t("Manage Branches"),
      text: t("Facilities"),
      link: "/branches",
    },
    {
      icon: <RiUserFill className="icon" />,
      text: t("Manage User"),
      link: "/user",
    },
    {
      icon: <FaUsers className="icon" />,
      text: t("Manage Membership"),
      link: "/membership",
    },
    {
      icon: <MdHotelClass className="icon" />,
      text: t("Freelance Classes"),
      link: "/freelanceclasses",
    },
    {
      icon: <MdInsertDriveFile className="icon" />,
      text: t("Reporting"),
      link: "/reporting",
    },
  ];

  const TrainersMenu = [
    {
      icon: <MdHotelClass className="icon" />,
      text: t("Manage Classes"),
      link: "/gymeditclass",
    },
  ];

  const GymManagersMenu = [
    {
      icon: <FaDumbbell className="icon" />,
      text: t("Manage Branch"),
      link: "/branches",
    },
  ];
  const GymManagersEditMenu = [
    {
      icon: <RiArrowGoBackLine className="icon" />,
      text: t("Back to Branches"),
      link: "/branches",
    },
    {
      icon: <FaDumbbell className="icon" />,
      text: t("Manage Branch"),
      link: "/gymBranchDetails",
    },
    {
      icon: <MdHotelClass className="icon" />,
      text: t("Manage Classes"),
      link: "/gymeditclass",
    },
    {
      icon: <RiUserFill className="icon" />,
      text: t("Manage Users"),
      link: "/user",
    },
  ];

  const GymEditMenu = [
    {
      icon: <RiArrowGoBackLine className="icon" />,
      text: t("Back to Branches"),
      link: "/branches",
    },
    {
      icon: <MdHotelClass className="icon" />,
      text: t("Manage Class"),
      link: "/gymeditclass",
    },
  ];
  const Settings = [
    {
      icon: <IoSettings className="icon" />,
      text: t("Settings"),
      link: "/settings",
    },
    {
      icon: <AiOutlineExport className="icon" />,
      text: t("Log Out"),
      link: "/",
    },
  ];
  const changeLanguage = (lng) => {
    setUser({ ...user, lang: lng });
    i18n.changeLanguage(lng).then(() => {});
  };
  const onLangEn = (e) => {
    changeLanguage("en");
    e.preventDefault();
  };
  const onLangAr = (e) => {
    changeLanguage("ar");
    e.preventDefault();
  };

  const options = [
    {
      value: "english",
      label: t("English"),
      onClick: onLangEn,
      // image: EnglishFlag
    },
    {
      value: "arabic",
      label: t("Arabic"),
      onClick: onLangAr,
      // image: UAE FLag
    },
  ];
  const showMenu = () => {
    setVisible(!visible);
  };

  const [menus, setMenus] = useState([]);
  useEffect(() => {
    if (userInfo?.activated) {
    } else {
      setMenus([]);
    }
    // function handleResize() {
    //   console.log("resizing" + window.innerWidth);
    //   if (window.innerWidth >= 1182) {
    //     console.log("in");
    //     setVisible(true);
    //   } else {
    //     console.log("out");
    //     if (!visible) {
    //       setVisible(false);
    //     }
    //   }
    // }
    // window.addEventListener("resize", handleResize);
    if (
      pathname.startsWith("/gymBranchDetails") ||
      pathname.startsWith("/gymeditclass")
    ) {
      if (userInfo.role.includes(4) || userInfo.role.includes(3)) {
        setMenus(GymEditMenu);
      } else if (userInfo.role.includes(2)) {
        setMenus(TrainersMenu);
      } else if (userInfo.role.includes(6)) {
        setMenus(GymManagersEditMenu);
      }
    } else if (pathname.startsWith("/user") && userInfo.role.includes(6)) {
      setMenus(GymManagersEditMenu);
    } else {
      if (userInfo.role) {
        if (userInfo.role.includes(4)) {
          setMenus(GeneralMenus);
        } else if (userInfo.role.includes(3)) {
          setMenus([
            GeneralMenus[0],
            GeneralMenus[2],
            GeneralMenus[3],
            GeneralMenus[6],
          ]);
        } else if (userInfo.role.includes(2)) {
          setMenus(TrainersMenu);
        } else if (userInfo.role.includes(6)) {
          setMenus(GymManagersMenu);
        }
      }
    }
    // return () => window.removeEventListener("resize", handleResize);
  }, [pathname]);

  const navigate = useNavigate();
  return (
    <Container>
      <div className="header">
        <div
          className="logo"
          onClick={(e) => {
            navigate("/");
          }}
        >
          <img src={Logo} className="logo" />
        </div>
        <HiMenuAlt3 className="toggle-menu-btn" onClick={showMenu} />
      </div>
      <div className={visible ? "content" : "content active"}>
        <div className="menu-group">
          {menus.map((item, index) => (
            <Menu
              onClick={() => {
                if (window.innerWidth <= 1180) {
                  setVisible(false);
                }
              }}
              icon={item.icon}
              text={item.text}
              link={item.link}
              key={index}
              active={pathname === item.link}
            />
          ))}
        </div>
        <div className="setting-group">
          <div className="menu-group">
            {Settings.map((item, index) => {
              if (item.link === "/") {
                return (
                  <Menu
                    onClick={() => {
                      if (window.innerWidth <= 1180) {
                        setVisible(false);
                      }
                      localStorage.clear();
                      dispatch(logoutUser());
                    }}
                    icon={item.icon}
                    text={item.text}
                    link={item.link}
                    key={index}
                    active={pathname === item.link}
                  />
                );
              }
              return (
                <Menu
                  onClick={() => {
                    if (window.innerWidth <= 1172) {
                      setVisible(false);
                    }
                  }}
                  icon={item.icon}
                  text={item.text}
                  link={item.link}
                  key={index}
                  active={pathname === item.link}
                />
              );
            })}
          </div>
          <div className="lang-select">
            <Select
              styles={langStyles}
              components={{
                SingleValue: IconSingleValue,
                Option: IconOption,
                DropdownIndicator: IconDropdownIndicator,
              }}
              options={options}
              isSearchable={false}
              menuPlacement="top"
              placeholder={
                <div className="lang-content">
                  <IoLanguage className="icon" />
                  <span className="lang-menu-text">{t("English")}</span>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

const Container = styled("div")`
  display: flex;
  min-height: auto;
  flex-direction: column;
  align-items: center;
  width: 310px;
  padding: 0px;
  background-color: black;
  position: relative;

  .header {
    width: 100%;
    height: 34px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 32px;
    border-bottom: 0px solid #1c1a1d;
    .logo {
      width: 210px;
      height: 32px;
      cursor: pointer;
    }
  }
  .link {
    text-decoration: none;
  }

  .hidden {
    display: none;
  }
  @media screen and (max-width: 1182px) {
    .content {
      min-height: calc(100vh - 70px);
      max-height: calc(100vh - 70px);
      overflow: auto;
      width: 100%;
      align-items: center;
      .menu-group,
      .setting-group {
        width: 330px;
        padding: 30px;
        .menu-group {
          padding: 0;
        }
      }
    }
    .lang-content {
      padding-left: 12px !important;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 100%;
    min-height: calc(100vh - 70px);

    .menu-group {
      display: flex;
      flex-direction: column;
      padding: 30px;
    }
    .setting-group {
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;
    }
    .menu {
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 15px 30px;
      font-size: 18px;
      line-height: 22px;
      color: #888888;
      width: 210px;
      margin-bottom: 5px;

      &:active,
      &:hover {
        color: #fbd806;
        background: #211c00;
      }
    }
    .menu.active {
      color: #fbd806;
      background: #211c00;
    }
    .icon {
      margin-right: 26.5px;
      font-size: 24px;
    }
    .icon.active {
      color: #feca00;
    }
    .lang-select {
      border-top: 1px solid #1c1a1d;
      padding: 0px 20px 16px 20px;
      font-size: 18px;
      line-height: 22px;
      color: #888888;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .lang-content {
    padding: 16px 0px 0px 28px;
    display: flex;
    align-items: center;
  }
  .lang-menu-text {
    width: 100%;
  }
  .menu-indicator {
    padding-top: 16px;
  }
  .menu-indicator.active {
    color: #feca00;
  }
  #react-select-2-listbox {
    border-radius: 15px;
  }
  .toggle-menu-btn {
    display: none;
  }

  @media (max-width: 1180px) {
    width: unset;
    min-height: unset;
    padding: 8px 25px;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 10000;
    .header {
      justify-content: space-between;
      .logo {
        width: 155px;
        height: 24px;
      }
    }
    .toggle-menu-btn {
      display: unset;
      color: #f2f2f2;
      font-size: 30px;
    }
    .content.active {
      display: none;
    }
  }
`;
