import { db } from "../main";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  setDoc,
  getDocs,
  getDoc,
  deleteDoc,
  query,
  limit,
  where,
  arrayRemove,
  arrayUnion,
  deleteField,
} from "firebase/firestore";
import { preSaveOrUpdateAClass } from "../../../common/validations/PreSave";
import { UserTypeArray } from "../../../common/data/UserTypes";
import { getMainGymIDByUser } from "./gym_main";
import users from "../../../common/models/users";
import { getCollectionRef } from "../../../common/Helpers/GetCollectionRef";
const collection_user = "users";
/*
Gets all users from DB Then filter them if they have gym owner. This is bad function quality. change it with where clause
 */


export const getGymOwners = async () => {
  try {
    const getGymOwnersQuery = query(
      getCollectionRef(collection_user),
      where("role", "array-contains", 3) // Assuming 3 represents the gym owner role
    );

    const querySnapshot = await getDocs(getGymOwnersQuery);

    const gymOwners = querySnapshot.docs.map((doc) => {
      const userData = doc.data();
      return { label: userData.sure_name, value: doc.id };
    });

    return { success: true, data: gymOwners };
  } catch (error) {
    console.error("Error fetching gym owners:", error);
    throw new Error("Failed to fetch gym owners!"); // Throw an error in case of failure
  }
};

// export const getTrainers = async (gymId) => {
//   const docs = await getUsers();
//   if (!docs.success) {
//     throw Error("Gym owners not found !");
//   } else {
//     let gym_owners = [];
//     docs.data.forEach((data) => {
//       if (data.role.includes(1) && data.gym_id === gymId) {
//         gym_owners.push({ label: data.sure_name, value: data.id });
//       }
//     });
//     return { success: true, data: gym_owners };
//   }
// };
export const getTrainers = async (company_id) => {
  const getTrainersQuery = query(
    getCollectionRef(collection_user),
    where("role", "array-contains", 1),
    where("company_id", "==", company_id)
  );
  const querySnapshot = await getDocs(getTrainersQuery);
  const trainers = querySnapshot.docs.map((doc) => {
    const user = Object.assign(new users(), { ...doc.data(), id: doc.id });
    return { label: user.sure_name, value: user.id };
  });
  return { success: true, data: trainers };
};

// export const getAllFreelanceTrainer = async () => {
//   const docs = await getUsers();
//   if (!docs.success) {
//     throw Error("Freelance trainers not found !");
//   } else {
//     let freelance_trainer = [];
//     docs.data.forEach((data) => {
//       if (data.role.includes(2)) {
//         freelance_trainer.push({ label: data.sure_name, value: data.id });
//       }
//     });
//     return { success: true, data: freelance_trainer };
//   }
// };
export const getAllFreelanceTrainer = async (userProfile) => {
  const getAllFreelanceTrainerQuery = query(
    getCollectionRef(collection_user),
    where("role", "array-contains", 2)
  );
  const querySnapshot = await getDocs(getAllFreelanceTrainerQuery);
  const trainers = querySnapshot.docs.map((doc) => {
    const user = Object.assign(new users(), { ...doc.data(), id: doc.id });
    return { label: user.sure_name, value: user.id };
  });
  return { success: true, data: trainers };
};

// export const getTrainersforBranch = (branchId) => {
//   return getUsers().then((datas) => {
//     if (!datas.success) {
//       throw Error("Freelance trainers not found !");
//     }
//     let get_alltrainer = [];
//     datas.data.forEach((data) => {
//       if (data.role.includes(1) && data.activated === true) {
//         get_alltrainer.push(data);
//       }
//     });
//     let trainers = [];
//     if (get_alltrainer && get_alltrainer.length > 0) {
//       get_alltrainer.forEach((user) => {
//         if (user.branches && user.branches.length > 0) {
//           user.branches.forEach((branch) => {
//             if (branch.value === branchId) {
//               trainers.push({ label: user.sure_name, value: user.id });
//             }
//           });
//         }
//       });
//     }
//     return { success: true, data: trainers };
//   });
// };

export const getTrainersforBranch = async (branchId) => {
  
  const getUsersQuery = query(
    getCollectionRef(collection_user),
    where("role", "array-contains", 1), // Assuming 1 represents the trainer role
    // where("activated", "==", false),
    // where("company_id", "==", branchId),
  );

  try {
    const querySnapshot = await getDocs(getUsersQuery);
    const trainers = [];

    querySnapshot.forEach((doc) => {
      const user = { ...doc.data(), id: doc.id }; // Assuming each doc represents a user
      
      const trainer = {
        label: user.sure_name, // Assuming sure_name contains the username
        value: user.id
      };
      trainers.push(trainer);
    });

    if (trainers.length > 0) {
      return { success: true, data: trainers };
    } else {
      throw new Error("Trainers not found for this branch!");
    }
  } catch (error) {
    console.error("Error fetching trainers for branch:", error);
    throw new Error("Failed to fetch trainers for this branch!"); // Throw an error in case of failure
  }
};

/*
This function returns all employees of a gym. bad quality, it is not using where clause
 */

export const getUsers = async () => {
  try {
    const querySnapshot = await getDocs(getCollectionRef(collection_user));
    const users = [];

    querySnapshot.forEach((doc) => {
      users.push({ id: doc.id, ...doc.data() });
    });

    if (users.length === 0) {
      throw new Error("Users not found!");
    }

    return { success: true, data: users };
  } catch (error) {
    console.error("Error fetching users:", error);
    return { success: false, error: error.message };
  }
};


export const getUsersByRole = async (userInfo) => {
  let getUsersByRoleQuery = getCollectionRef(collection_user);
  const company_id = userInfo.company_id;
  if (userInfo?.role.includes(3) && !userInfo?.role.includes(4)) {
    getUsersByRoleQuery = query(
      getUsersByRoleQuery,
      where("company_id", "==", company_id)
    );
  } else if (userInfo?.role.includes(6)) {
    const selectedBranch = JSON.parse(localStorage.getItem("branchDoc"));
    getUsersByRoleQuery = query(
      getUsersByRoleQuery,
      where("company_id", "==", company_id),
    );
  } else if (userInfo?.role.includes(4)){
    getUsersByRoleQuery = query(
      getUsersByRoleQuery
    );
  }

  try {
    const querySnapshot = await getDocs(getUsersByRoleQuery);

    if (querySnapshot.empty) {
      throw new Error("Users not found for this role!");
    }
    const users = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id }; // No need for Object.assign here
    });

    return { success: true, data: users }; // Use consistent variable naming

  } catch (error) {
    console.error("Error fetching users:", error); // Add a descriptive log message
    return { success: false, error: error.message };
  }

};



export const getAllUsersNew = async () => {
  let userId = JSON.parse(localStorage.getItem("accesstoken"));
  let gymID;
  getMainGymIDByUser(userId).then((id) => {
    gymID = id;
  });
  return await getUsers().then((datas) => {
    if (!datas.success) {
      throw Error("users not found !");
    }
    let Users = [];
    datas.data.forEach((data) => {
      if (data.id) {
        Users.push({
          id: data.id,
          name: data.sure_name,
          userid: data.username,
          phone: data.phone,
          action: data.activated,
        });
      }
    });
    return { success: true, data: Users };
  });
};

/*
 Normal update document
 */
export const updateUser = async (id, updatedUser) => {
  try {
    let user = { ...updatedUser }; // Copy the updatedUser object

    await updateDoc(
      doc(getCollectionRef(collection_user), id),
      user
    );
  } catch (error) {
    console.error("Error updating user:", error);
    throw error; // Re-throw the error for the caller to handle
  }
};

export const updateUserNew = async (id, updatedUser) => {

  // Check if the "company" field exists in the updatedUser object
  if ('company' in updatedUser) {
    // Destructure the updatedUser object, excluding the "company" field if present
    const { branches, company, ...rest } = updatedUser;
  } else {
    // If the "company" field does not exist, destructure the updatedUser object as is
    const { branches, ...rest } = updatedUser;
  }
  let outputObject = {};

  // Check if the "branches" array exists and has elements
  if (updatedUser.branches && updatedUser.branches.length > 0) {
    // Extract facility information from branches array
    const facility = updatedUser.branches[0];

    // Create the output object with necessary fields
    outputObject = {
      ...updatedUser,
      facility_id: facility.value,
      facility_name: facility.label
    };
  } else {
    // If branches array is empty or does not exist, use the updatedUser directly
    outputObject = updatedUser;
  }

  // Update the document in the collection
  return updateDoc(
    getCollectionRef(collection_user, id),
    preSaveOrUpdateAClass(outputObject)
  )
    .then(() => {
      return { success: true, error: "" };
    })
    .catch((error) => {
      return { success: false, error: error.message };
    });
};

/*
 This updates activated field
 */
export const changeUserActivateState = async (id, state) => {
  return updateDoc(getCollectionRef(collection_user, id), {
    activated: state,
  })
    .then(() => {
      return { success: "success", error: "" };
    })
    .catch((error) => {
      return { success: "", error: error.message };
    });
};

export const updateUserImage = async (uid, url) => {
  await updateDoc(getCollectionRef(collection_user, uid), {
    photo: url,
  });
};

export const updateUserBranches = async (id, branches) => {
  return updateDoc(getCollectionRef(collection_user, id), {
    branches: branches,
  })
    .then(() => {
      return { success: "success", error: "" };
    })
    .catch((error) => {
      return { success: "", error: error.message };
    });
};

export const getUser = async (id) => {
  return getDoc(getCollectionRef(collection_user, id))
    .then((res) => {
      const userObj = Object.assign(new users(), { ...res.data(), id: res.id });
      return userObj;
    })
    .catch((error) => {
      return { success: "", error: error.message };
    });
};

export const addUserAttachment = async (id, data) => {
  try {
    return updateDoc(getCollectionRef(collection_user, id), {
      attachments: arrayUnion(data),
    })
      .then(async () => {
        return { success: true, error: "" };
      })
      .catch((error) => {
        return { error: error.message, success: false };
      });
  } catch (err) {
    return { error: err, success: false };
  }
};

export const removeUserAttachment = async (id, data) => {
  // const userId = JSON.parse(localStorage.getItem("accesstoken"));
  try {
    return updateDoc(getCollectionRef(collection_user, id), {
      attachments: arrayRemove(data),
    })
      .then(async () => {
        return { success: true, error: "" };
      })
      .catch((error) => {
        return { error: error.message, success: false };
      });
  } catch (err) {
    return { error: err, success: false };
  }
};

export const resetDeviceID = async (id) => {
  try {
    return updateDoc(getCollectionRef(collection_user, id), {
      deviceId: deleteField(),
    })
      .then(async () => {
        return { success: true, error: "" };
      })
      .catch((error) => {
        return { error: error.message, success: false };
      });
  } catch (err) {
    return { error: err, success: false };
  }
};

export const saveUser = async (uid, user) => {
  try {
    let userObj = { ...user };
    let tempUser = { ...user, activated: false };

    if (userObj?.role?.includes(5) && userObj?.role?.length === 1) {
      tempUser = { ...userObj, activated: true };
    }

    await addDoc(
      getCollectionRef(collection_user),
      preSaveOrUpdateAClass({ ...tempUser, complete_profile: true })
    );
  } catch (error) {
    console.error("Error saving user:", error);
    throw error; // Re-throw the error for the caller to handle
  }
};


export const createNewUser = async (user) => {
  let userObj = Object.assign(new users(), {
    ...user,
    activated: true,
    complete_profile: true,
  });
  await addDoc(
    getCollectionRef(collection_user),
    preSaveOrUpdateAClass(userObj)
  );
};
