class Memberships {
  constructor(
    annually_amount = "",
    approved = true,
    attendancePrice = "",
    color = {},
    description = [],
    index = 0,
    monthly_amount = 0,
    name = ""
  ) {
    this.id = "";
    this._id = "";
    this.annually_amount = annually_amount;
    this.approved = approved;
    this.attendancePrice = attendancePrice;
    this.color = color;
    this.description = description;
    this.index = index;
    this.monthly_amount = monthly_amount;
    this.name = name;
    Object.preventExtensions(this);
  }

  get Id() {
    return this._id;
  }

  set Id(value) {
    this._id = value;
  }
  // get DocId() {
  //   return this.docId;
  // }

  // set DocId(value) {
  //   this.docId = value;
  // }
  get Annually_amount() {
    return this.annually_amount;
  }

  set Annually_amount(annually_amount) {
    this.annually_amount = annually_amount;
  }
  get Approved() {
    return this.approved;
  }

  set Approved(approved) {
    this.approved = approved;
  }
  get AttendancePrice() {
    return this.attendancePrice;
  }

  set AttendancePrice(attendancePrice) {
    this.attendancePrice = attendancePrice;
  }
  get Color() {
    return this.color;
  }

  set Color(color) {
    this.color = color;
  }
  get Description() {
    return this.description;
  }

  set Description(description) {
    this.description = description;
  }
  get Index() {
    return this.index;
  }

  set Index(index) {
    this.index = index;
  }

  get Monthly_amount() {
    return this.monthly_amount;
  }

  set Monthly_amount(monthly_amount) {
    this.monthly_amount = monthly_amount;
  }

  get Name() {
    return this.name;
  }

  set Name(name) {
    this.name = name;
  }
}

export default Memberships;
