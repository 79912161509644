import { createSlice } from "@reduxjs/toolkit";

const EditMembershipSlice = createSlice({
  name: "EditMembership",
  initialState: {
    isOpen: false,
    isId: "",
    isAddDesc: false,
    description: [],
    displayColorPicker: false,
    color: {},
    nameError: false,
    monthError: false,
    attendanceError: false,
    annualError: false,
    priorityError: false,
    createError: false,
    createSuccess: false,
    newDesc: "",
    disable: false,
    loading: false,
    formData: {
      name: "",
      annually_amount: "0",
      monthly_amount: "0",
      attendancePrice: "0",
      color: { b: 194, a: 1, g: 227, r: 80 },
      description: [],
      docId: "",
      id: 0,
      index: 0,
    },
  },
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setIsId: (state, action) => {
      state.isId = action.payload;
    },
    setIsAddDesc: (state, action) => {
      state.isAddDesc = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setColorPicker: (state, action) => {
      state.displayColorPicker = action.payload;
    },
    setColor: (state, action) => {
      state.color = action.payload;
    },
    setNameError: (state, action) => {
      state.nameError = action.payload;
    },
    setMonthError: (state, action) => {
      state.monthError = action.payload;
    },
    setAttendanceError: (state, action) => {
      state.attendanceError = action.payload;
    },
    setAnnualError: (state, action) => {
      state.annualError = action.payload;
    },
    setPriorityError: (state, action) => {
      state.priorityError = action.payload;
    },
    setCreateError: (state, action) => {
      state.createError = action.payload;
    },
    setCreateSuccess: (state, action) => {
      state.createSuccess = action.payload;
    },
    setNewDesc: (state, action) => {
      state.newDesc = action.payload;
    },
    setDisable: (state, action) => {
      state.disable = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
  },
});

export const {
  setAnnualError,
  setAttendanceError,
  setColor,
  setColorPicker,
  setCreateError,
  setCreateSuccess,
  setDescription,
  setDisable,
  setFormData,
  setIsAddDesc,
  setIsId,
  setIsOpen,
  setLoading,
  setMonthError,
  setNameError,
  setNewDesc,
  setPriorityError,
} = EditMembershipSlice.actions;
export default EditMembershipSlice.reducer;
