import firebase, { auth, db } from "../main";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  setDoc,
  getDocs,
  getDoc,
  deleteDoc,
  collectionGroup,
  query,
  arrayUnion,
  arrayRemove,
  where,
  limit,
} from "firebase/firestore";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { getCollectionRef } from "../../../common/Helpers/GetCollectionRef";
import { FaSleigh } from "react-icons/fa";
import Gym from "../../../common/models/gym";
import Classes from "../../../common/models/classes";

const collection_gym = "gyms";
const gymCollectionRef = collection(db, collection_gym);

export const getGyms = () => {
  return getDocs(getCollectionRef(collection_gym));
};

export const getBranches = async () => {
  return getDocs(getCollectionRef(collection_gym)).then((data) => {
    let gyms = [data];
    data.forEach((gym) => {
      const gymObj = Object.assign(new Gym(), { ...gym.data(), id: gym.id });
      gyms.push({ ...gymObj, id: gymObj.id });
    });
    return gyms;
  });
};

export const getBranchesByGymId = async (mainGymId) => {
  let getBranchesByGymIdQuery = query(
    getCollectionRef(collection_gym),
    where("mainBranchID", "==", mainGymId)
  );
  return getDocs(getBranchesByGymIdQuery).then((data) => {
    let gyms = [];
    data.forEach((gym) => {
      const gymObj = Object.assign(new Gym(), { ...gym.data(), id: gym.id });
      gyms.push({ ...gymObj, id: gymObj.id });
    });
    return gyms;
  });
};

// export const getActiveBranchesByGymId = (mainGymId) => {
//   let q = query(gymCollectionRef, where("mainBranchID", "==", mainGymId));
//   return getDocs(q).then((data) => {
//     let gyms = [];
//     data.forEach((gym) => {
//       const gymObj = Object.assign(new Gym(), { ...gym.data(), id: gym.id });
//       console.log(gymObj);
//       if (gymObj.activated === true || gymObj.activated === false) {
//         gyms.push({ ...gymObj, id: gymObj.id });
//       }
//     });
//     return gyms;
//   });
// };
export const getActiveBranchesByGymId = async (mainGymId) => {
  let q = query(
    getCollectionRef(collection_gym),
    where("mainBranchID", "==", mainGymId),
    where("activated", "!=", undefined)
  );
  return getDocs(q).then((data) => {
    let gyms = [];
    data.forEach((gym) => {
      const gymObj = Object.assign(new Gym(), { ...gym.data(), id: gym.id });
      gyms.push({ ...gymObj, id: gymObj.id });
    });
    return gyms;
  });
};

export const getBranchesListByGymId = async (mainGymId) => {
  let q = query(
    getCollectionRef(collection_gym),
    where("mainBranchID", "==", mainGymId)
  );
  return getDocs(q).then((data) => {
    let gyms = [];
    data.forEach((gym) => {
      const gymObj = Object.assign(new Gym(), { ...gym.data(), id: gym.id });
      gyms.push({ label: gymObj.name, value: gymObj.id });
    });
    return gyms;
  });
};

export const getGym = (id) => {
  if (id && id !== "" && id !== undefined)
    return getDoc(getCollectionRef(collection_gym, id)).then((data) => {
      const gymObj = Object.assign(new Gym(), { ...data.data(), id: data.id });
      return { ...gymObj, qrCode: gymObj.id };
    });
};

export const saveGym = async (
  name,
  address,
  location,
  userID,
  mainBranchID,
  gender = "male",
  facilityType
) => {
  const gymObj = Object.assign(new Gym(), {
    name: name,
    address: address,
    location: location,
    owner: userID,
    mainBranchID: mainBranchID,
    gender: gender,
    facilityType: facilityType,
  });
  return await addDoc(getCollectionRef(collection_gym), {
    name: gymObj.name,
    address: gymObj.address,
    location: gymObj.location,
    owner: gymObj.owner,
    mainBranchID: gymObj.mainBranchID,
    gender: gymObj.gender,
    activated: false,
    facilityType: gymObj.FacilityType,
  })
    .then((data) => {
      return { success: "success" };
    })
    .catch((error) => {
      return { error: error.message, success: "" };
    });
};

export const updateGym02 = async (id, name, gender, facility_type) => {
  const gymObj = new Gym();
  gymObj.name = name;
  gymObj.gender = gender;
  gymObj.facilityType = facility_type;
  return updateDoc(getCollectionRef(collection_gym, id), {
    name: gymObj.name,
    gender: gymObj.gender,
    facilityType : gymObj.facilityType,
  })
    .then(() => {
      return { success: "success", error: "" };
    })
    .catch((error) => {
      return { success: "", error: error.message };
    });
};

export const updateGym = async (id, name, visitCost, attendCost) => {
  return updateDoc(getCollectionRef(collection_gym, id), {
    name: name,
    visitCost: visitCost,
    attendanceCost: attendCost,
  })
    .then(() => {
      return { success: "success", error: "" };
    })
    .catch((error) => {
      return { success: "", error: error.message };
    });
};

export const updateGymImage = async (id, image) => {
  return getDoc(getCollectionRef(collection_gym, id))
    .then((data) => {
      return data.data().images;
    })
    .then((images) => {
      let temp = images ? images : [];
      temp.push(image);
      return updateDoc(getCollectionRef(collection_gym, id), {
        images: temp,
      });
    })
    .then(() => {
      return { success: "success", error: "" };
    })
    .catch((error) => {
      return { success: "", error: error.message };
    });
};

export const changeGymActivation = async (id, state) => {
  return updateDoc(getCollectionRef(collection_gym, id), {
    activated: state,
  })
    .then(() => {
      return { success: "success", error: "" };
    })
    .catch((error) => {
      return { success: "", error: error.message };
    });
};

export const removeGymImageByIndex = async (id, index) => {
  return getDoc(getCollectionRef(collection_gym, id))
    .then((data) => {
      return data.data().images;
    })
    .then((images) => {
      if (images) {
        const removedUrl = images.splice(index, 1).toString();
        if (removedUrl) {
          const storage = getStorage();
          const desertRef = ref(storage, removedUrl);
          return deleteObject(desertRef)
            .then(async () => {
              return updateDoc(getCollectionRef(collection_gym, id), {
                images: images,
              });
            })
            .catch((error) => {
              return false;
            });
        }
      }
      throw "exception";
    })
    .then(() => {
      return { success: "success", error: "" };
    })
    .catch((error) => {
      return { success: "", error: error.message };
    });
};

// export const getAllClassesForGym = (gymId) => {
//   let classes = [];
//   const classGroup = query(collectionGroup(db, "classes"));
//   return getDocs(classGroup)
//     .then((querySnapshot) => {
//       querySnapshot.forEach((doc) => {
//         const classObj = Object.assign(new Classes(), {
//           ...doc.data(),
//           id: doc.id,
//         });
//         if (classObj.gym === gymId) {
//           classes.push(classObj);
//         }
//       });
//       return classes;
//     })
//     .catch(() => {
//       return [];
//     });
// };
export const getAllClassesForGym = async (gymId) => {
  const classGroup = query(
    getCollectionRef("classes"),
    where("gym", "==", gymId)
  );
  let classes = [];
  return getDocs(classGroup)
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const classObj = Object.assign(new Classes(), {
          ...doc.data(),
          id: doc.id,
        });
        classes.push(classObj);
      });
      return classes;
    })
    .catch(() => {
      return [];
    });
};

export const saveClassesForGym = async (gymId, info) => {
  const ClassesObj = Object.assign(new Classes(), { ...info });
  return addDoc(collection(db, "gyms", gymId, "classes"), ClassesObj).then(
    (data) => {
      return data.id;
    }
  );
};

export const updateClass = async (gymId, docId, content) => {
  const ClassesObj = Object.assign(new Classes(), { ...content });
  return updateDoc(doc(db, "gyms/" + gymId + "/classes", docId), ClassesObj)
    .then(() => {
      return { success: "success", error: "" };
    })
    .catch((e) => {
      return { success: "", error: "error" };
    });
};

export const getTrainers = async (gymId) => {
  return getDoc(getCollectionRef(collection_gym, gymId))
    .then((data) => {
      const gymObj = Object.assign(new Gym(), { ...data.data(), id: data.id });
      return { success: gymObj.trainers, error: "" };
    })
    .catch((error) => {
      return { success: "", error: error.message };
    });
};

export const addTrainer = async (gymId, data) => {
  return updateDoc(getCollectionRef(collection_gym, gymId), {
    trainers: arrayUnion(data),
  })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const updateTrainer = async (gymId, data) => {
  return updateDoc(getCollectionRef(collection_gym, gymId), {
    trainers: data,
  })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};
