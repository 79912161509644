import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Header from "../item/Header";
import { RiPencilFill, RiDeleteBin6Line } from "react-icons/ri";
import AddMembership from "./addMembership";
import EditMembership from "./editMembership";
// import {
//   changeMembershipPlanActivation,
//   deleteMembership,
//   getMemberships,
//   updateMembership,
// } from "../../../../common/repository/js/membership";
import {
  changeMembershipPlanActivationController,
  deleteMembershipController,
  updateMembershipController,
  getMembershipsController,
} from "../../../../../../controllers/web/memberShipController";
import { useLocation, useNavigate } from "react-router-dom";
import CustomLoading from "../../../items/loadingBar";
import ConfirmDialog from "../../../custom/ConfirmDialog/ConfirmDialog";
import RLDD from "react-list-drag-and-drop/lib/RLDD";

import "./managemembership.scss";
import DraggableItem from "./draggableItem";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import AccessManager from "../../../custom/AccessManager/AccessManager";
import ErrorDialog from "../../../custom/ErrorDialog/ErrorDialog";
import { Switch, ThemeProvider } from "@mui/material";
import { outerTheme } from "../../../global";
// import {
//   getAllUsersNew,
//   getUsers,
//   updateUser,
// } from "../../../../repository/web/js/user";
import {
  getAllUsersNewController,
  getUsersController,
  updateUserController,
} from "../../../../../../controllers/web/userController";
// import {
//   getAllGyms,
//   updateCompany,
// } from "../../../../repository/web/js/gym_main";
import {
  getAllGymsController,
  updateCompanyController,
} from "../../../../../../controllers/web/gymMainController";
import {
  setIsEdit,
  setContent,
  setErrorObject,
  setIsId,
  setLoading,
  setSelectedContentToEdit,
  setdraggedContent,
} from "../../../../redux/slices/ManageMembership.slice";

const MembershipItem = ({ content, onEdit, onDelete, refresh, loading }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(true);
  useEffect(() => {
    setActivate(content.approved);
  }, [content]);
  return (
    <div className="item">
      <div className="d-flex align-items-center mb-3">
        <ThemeProvider theme={outerTheme}>
          <Switch
            checked={activate}
            onChange={(e, c) => {
              ConfirmDialog.show({
                title: "Confirmation !",
                message: `Are Sure to change plan status?`,
                okCallback: async () => {
                  ConfirmDialog.hide();
                  loading.setLoading(true);
                  await changeMembershipPlanActivationController(content._id, c).then(
                      (res) => {
                        if (res?.success === "success") {
                          refresh();
                        }
                      }
                  );
                },
              });
            }}
            name="checkedA"
          />
          <div className={`${activate ? "text-light" : "text-secondary"}`}>
            Active
          </div>
        </ThemeProvider>
      </div>
      <div className="title">
        <p className="name">{content.name}</p>
        <div
          className="circle"
          style={{
            background: `rgba(${content.color.r}, ${content.color.g}, ${content.color.b}, ${content.color.a})`,
          }}
        />
      </div>
      <div className="item-content">
        <span className="label">{t("Description")}</span>
        <div className="description">
          {content.description.map((item, index) => (
            <li key={index}>{item.text ? item.text : item}</li>
          ))}
        </div>
        <div className="price-layout">
          <div className="price">
            <span className="label">{t("Monthly Price")}</span>
            <span>AED {content.monthly_amount}</span>
          </div>
          <div className="price">
            <span className="label">{t("Annual Price")}</span>
            <span>AED {content.annually_amount}</span>
          </div>
          <div className="price">
            <span className="label">{t("attendance price")}</span>
            <span>AED {content.attendancePrice}</span>
          </div>
          {/* <div className="price">
            <span className="label">{t("one day price")}</span>
            <span>AED {content.oneDayPrice}</span>
          </div> */}
        </div>
      </div>
      <div className="button-layout">
        <span
          className="item-btn edit"
          onClick={() => onEdit(content._id, content)}
        >
          <RiPencilFill className="button-icon" />
          {t("Edit")}
        </span>
        <span className="item-btn delete" onClick={() => onDelete(content._id)}>
          <RiDeleteBin6Line className="button-icon" />
          {t("Delete")}
        </span>
        <span
          className="item-btn-mobile edit"
          onClick={() => onEdit(content._id, content)}
        >
          <RiPencilFill className="button-icon" />
        </span>
        <span
          className="item-btn-mobile delete"
          onClick={() => onDelete(content._id)}
        >
          <RiDeleteBin6Line className="button-icon" />
        </span>
      </div>
    </div>
  );
};

const ManageMembership = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [isEdit, setIsEdit] = useState(false);
  // const [content, setContent] = useState([]);
  // const [selectedContentToEdit, setSelectedContentToEdit] = useState([]);
  // const [draggedContent, setdraggedContent] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [isId, setIsId] = useState("");
  const { path } = useLocation();
  // const [errorObject, setErrorObject] = useState({});
  const { user: loggedinUser } = useSelector((state) => state.user);
  const {
    isEdit,
    content,
    selectedContentToEdit,
    draggedContent,
    loading,
    isId,
    errorObject,
  } = useSelector((state) => state.manageMembership);
  useEffect(() => {
    if (!loggedinUser?.role?.includes(4)) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    getAllData();

    if (errorObject?.hasError) {
      ErrorDialog.show({
        message:
          "There are users or companies still member of this plan. \n      Users :" +
          errorObject.users +
          ". \n  " +
          " companies : " +
          errorObject.companies,
        title: "Error",
        okCallback: async () => {
          dispatch(setErrorObject({ hasError: true }));
          return true;
        },
      });
    }
  }, [path, errorObject]);

  const closeEditModal = () => {
    dispatch(setIsEdit(false));
  };
  const openEditModal = (id, content) => {
    dispatch(setIsEdit(true));
    dispatch(setIsId(id));
    dispatch(setSelectedContentToEdit(content));
  };
  const onDelete = async (id) => {
    ConfirmDialog.show({
      message: "Are you sure to remove this membership plan?",
      title: "Sure to remove?",
      okCallback: async () => {
        dispatch(setLoading(true));
        ConfirmDialog.hide();
        return await deleteMembershipController(id).then((res) => {
          if (res.success === false) {
            dispatch(setLoading(false));
            dispatch(setErrorObject({ ...res.errorData, hasError: true }));
          } else {
            handlePrioritySet();
          }
          return true;
        });
      },
    });
  };

  const getAllData = () => {
    dispatch(setLoading(true));
    getMembershipsController().then((data) => {
      console.log("Content ", data.data);
      dispatch(setContent(data.data));
      dispatch(setdraggedContent(data.data));
      dispatch(setLoading(false));
    });
  };

  function handleRLDDChange(reorderedItems) {
    dispatch(setContent(reorderedItems));
  }

  const handlePrioritySet = async () => {
    // dispatch(setLoading(true));

    const users = await getUsersController();
    const companies = await getAllGymsController();
    if (content?.length > 0) {
      if (draggedContent?.length > 0) {
        for (let i = 0; i < content.length; i++) {
          let rr = new Promise(async (resolve, reject) => {
            var kl = await updateMembershipController(
              { index: i },
              content[i]._id
            );
            if (kl?.success) {
              resolve(kl);
              let usersToUpdate = [];
              let companiesToUpdate = [];

              users.data.forEach((user) => {
                if (user?.memberShipPlan?.id === content[i]._id) {
                  let u = user;
                  u.memberShipPlan.index = i;
                  usersToUpdate.push(u);
                }
              });
              companies.forEach((company) => {
                if (company?.memberShipPlan?.id === content[i]._id) {
                  let u = company;
                  u.memberShipPlan.index = i;
                  companiesToUpdate.push(u);
                }
              });

              usersToUpdate.forEach((user) => {
                updateUserController(user.id, user);
              });

              companiesToUpdate.forEach((company) => {
                updateCompanyController(company.id, company);
              });
            } else {
              resolve(kl);
            }
          });

          rr.then((e) => {
            if (i === content?.length - 1) {
              dispatch(setContent(null));
              dispatch(setdraggedContent(null));
              getAllData();
            }
          });
        }
      }
    }
  };
  return (
    <AccessManager allowedRoles={[4]}>
      <Container className="position-relative">
        <Header
          title={t("Membership")}
          button={t("Add Membership")}
          openModal={() => {
            ConfirmDialog.show({
              message: <AddMembership isOpen="true" refresh={getAllData} />,
              title: "Add Membership",
              showFooter: false,
            });
          }}
        />
        <CustomLoading isStart={loading} isFull={true} />
        <div className="content-body-container">
          <div className="content-plan-container">
            {/* LEFT SIDE  membership plan card list */}
            { content && content.length > 0    ? (
                content.map((item, index) => (
                    <MembershipItem
                        key={index}
                        content={item}
                        onEdit={openEditModal}
                        onDelete={onDelete}
                        refresh={getAllData}
                        loading={{ setLoading }}
                    />
                ))
            ) : (
                <div>No membership plans available.</div>
            )}
          </div>
          <div className="content-planlist-container">
            {/* RIGHT SIDE membership plan drag drop list */}
            <div className="planlist-header">
              <h5>Priority</h5>
              <Button variant="warning" onClick={handlePrioritySet} size="sm">
                Save
              </Button>
            </div>
            <RLDD
              cssClasses="plan-list-draggable-container"
              items={content || []}
              itemRenderer={DraggableItem}
              onChange={handleRLDDChange}
            />
          </div>
        </div>
        <EditMembership
          isOpen={isEdit}
          close={closeEditModal}
          refresh={getAllData}
          id={isId}
          contentToEdit={selectedContentToEdit}
        />
      </Container>
    </AccessManager>
  );
};

const Container = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #1c1a1d;
  @media (max-width: 1180px) {
    margin-top: 86px;
  }
  .content {
    display: flex;
    padding: 40px;
    flex-flow: row wrap;
    justify-content: flex-start;
    background-color: #554421;

    &::-webkit-scrollbar {
      width: 3px;
      position: absolute;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      background: #88888850;
      padding: 5px;
    }

    /* Handle */

    &::-webkit-scrollbar-thumb {
      background: #e7b80c;
    }

    /* Handle on hover */

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      cursor: pointer;
    }
  }

  .item {
    width: 210px;
    min-width: 210px;
    border: 1px solid #88888840;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    background-color: #00000050;
    position: relative;

    opacity: 1;

    .name {
      font-weight: bold;
      font-size: 24px;
      line-height: 120%;
      overflow-wrap: break-word;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #f2f2f2;
      max-width: 80%;
      margin-bottom: 15px;
    }

    .title {
      // width: 200px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .circle {
      border-radius: 50%;
      width: 20px;
      height: 20px;
    }

    .label {
      font-size: 14px;
      line-height: 17px;
      color: #888888;
      margin-bottom: 8px;
    }

    .description {
      display: flex;
      flex-direction: column;
      overflow: auto;
      height: 140px;

      li {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #f2f2f2;
        margin-bottom: 6px;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: #888888;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-color: #ffcc00;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
      }
    }

    .price-layout {
      display: flex;
      flex-flow: column wrap;
      gap: 0px 20px;
      justify-content: flex-start;
    }

    .price {
      display: flex;
      flex-direction: row;
      font-size: 18px;
      line-height: 22px;
      color: #f2f2f2;
      margin-bottom: 10px;
      justify-content: space-between;
    }
  }

  .button-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }

  .item-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 36px;
    border-radius: 6px;
  }

  .edit {
    cursor: pointer;
    background: #333333;
    color: #2f80ed;
  }

  .delete {
    cursor: pointer;
    background: #462224;
    color: #f03f3f;
  }

  .button-icon {
    font-size: 20px;
    margin-right: 6px;
  }

  .item-content {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #3e3e3e;
  }

  .item-btn-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    .content {
      padding: 20px 14px;
    }

    .item {
      width: 80%;
      padding: 20px 10px;
      margin: 20px 5px;
    }

    .item-btn {
      display: none;
    }

    .button-layout {
      justify-content: space-around;
    }

    .item-btn-mobile {
      display: flex;
      width: 44px;
      height: 44px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    .button-icon {
      margin: 0px;
    }
  }
`;

export default ManageMembership;
