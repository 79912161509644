import React from "react";
import { Col, Row } from "react-bootstrap";
import Divider from "../../../../../custom/Divider/Divider";
import "./viewClass.scss";

export default function ViewClassModal({ data }) {
  return (
    <div className="view-class-container">
      <div className="d-flex flex-column w-100">
        {/* Data Starts here */}
        <div className="cRow">
          <div className="colHeader">Status</div>
          <div
            className={`colData ${
              data.status === "open" ? "text-success" : "text-danger"
            }`}
          >
            {data.status || "Expired"}
          </div>
        </div>
        <div className="cRow">
          <div className="colHeader">Title</div>
          <div className="colData">{data.name || ""}</div>
        </div>
        <div className="cRow">
          <div className="colHeader">Capacity</div>
          <div className="colData">
            {data.capacity || "-"} {" Candidates"}
          </div>
        </div>
        <Divider marginTop="10px">Duration of Class</Divider>
        <div className="cRow">
          <div className="colHeader">Start Date</div>
          <div className="colData">{data.starts_date || "-"}</div>
        </div>
        <div className="cRow">
          <div className="colHeader">End Date</div>
          <div className="colData">{data.ends_date || "-"}</div>
        </div>
        <div className="cRow">
          <div className="colHeader">Duration</div>
          <div className="colData">
            {data.duration || "-"} {" Days"}
          </div>
        </div>
        <Divider marginTop="10px">Daily Time Schedule</Divider>
        <div className="cRow">
          <div className="colHeader">Start Time</div>
          <div className="colData">{data.start_time || "-"}</div>
        </div>
        <div className="cRow">
          <div className="colHeader">End Time</div>
          <div className="colData">{data.end_time || "-"}</div>
        </div>
        <div className="cRow">
          <div className="colHeader">Daily Scheduled</div>
          <div className="colData">
            {data.daily_time || "-"} {data.time_unit || " Hours"}
          </div>
        </div>
        <Divider marginTop="10px">More Details</Divider>
        <div className="cRow">
          <div className="colHeader">Description</div>
          <div className="colData">{data.description || "-"}</div>
        </div>
        <div className="cRow">
          <div className="colHeader">Address</div>
          <div className="colData">{data.address || "-"}</div>
        </div>
        <div className="cRow">
          <div className="colHeader">Trainer</div>
          <div className="colData trainers">
            {data?.trainer?.label  }
          </div>
        </div>
        {/* Data ends here */}
      </div>
    </div>
  );
}
