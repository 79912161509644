import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import { useEffect } from "react";

const GMC = withScriptjs(
  withGoogleMap((props) => {
    var _gak = props.ak ? props.ak : "";
    Geocode.setApiKey(_gak);
    Geocode.setLanguage("en");
    const _uc = void 0,
      _hde = (e, cb) => {
        const _ltlng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        Geocode.fromLatLng(_ltlng.lat, _ltlng.lng).then(
          (response) => {
            const _adL = response.results;
            const _ad = _adL[0].formatted_address;
            cb(_ltlng, _ad, _adL);
          },
          (error) => {
            console.error(error);
          }
        );
      };
    return (
      <GoogleMap defaultZoom={12} defaultCenter={props.dl}>
        {props.isMS && (
          <Marker
            onDragEnd={(e) => _hde(e, props.uc || _uc)}
            draggable
            position={props.dl}
          />
        )}
      </GoogleMap>
    );
  })
);

const ReactGoogleMap = (props) => {
  const _dlIh = { lat: 22.6954772, lng: 75.823649 },
    _gmu = (_akk) => {
      const _u1 = "https://maps.googleapis.com/maps/api/js?key=",
        _u2 = _akk,
        _u3 = "&v=3.exp&libraries=geometry,drawing,places";
      return _u1 + _u2 + _u3;
    };
  var _oc = props.onChange ? props.onChange : () => {},
    _dl = props.defaultLocation ? props.defaultLocation : _dlIh,
    _ak = props.APIKey ? props.APIKey : "";
  const gmu = _gmu(_ak);
  const _ud = (_ll, _a, _al) => {
    _oc(_ll, _a, _al);
  };
  return (
    <GMC
      uc={_ud}
      dl={_dl}
      ak={_ak}
      isMS
      googleMapURL={gmu}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
};

export default ReactGoogleMap;

//===================================================================================================
// *** USAGE
// *** Copy paste following code to place map in  your compoent
// *** Change the API Key
// *** Now you can drag the location pin on map to get address and location from map
//===================================================================================================
{
  //===================================================================================================
  {
    /* Copy followng function to hadle map changes */
  }
  //===================================================================================================
  //   const handleOnMapChange = (latlng, address, addressList) => {
  //     console.log(latLng);
  //     console.log(address);
  //     console.log(addressList);
  //   };
  //
  //===================================================================================================
  {
    /* Copy followng code and paste in your compoent */
  }
  //===================================================================================================
  //   <ReactGoogleMap
  //     onChange={handleOnMapChange}
  //     APIKey="AIzaSyDQ4WBaFAr6HqsjFOiAc_8jXxBUgLel-TM"
  //     defaultLocation={{ lat: 22.6954772, lng: 75.823649 }}
  //   />;
}
