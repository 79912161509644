import { createSlice } from "@reduxjs/toolkit";

export const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState: {
    user: null,
  },
  reducers: {
    setUserLoggedIn: (state, action) => {
      state.user = action.payload;
    },
    logoutUser: (state, action) => {
      state.user = {};
    },
  },
});

export const { logoutUser, setUserLoggedIn } = AuthSlice.actions;
export default AuthSlice.reducer;
