export const UserTypeArray = [
  "",
  "Trainer",
  "Freelance Trainer",
  "Gym Owner",
  "Admin",
  "Customer",
  "Gym Manager",
];

const UserTypes = [
  {
    value: 1,
    label: "Trainer",
  },
  {
    value: 2,
    label: "Freelance Trainer",
  },
  {
    value: 3,
    label: "Company Owner",
  },
  {
    value: 4,
    label: "Admin",
  },
  {
    value: 5,
    label: "Customer",
  },
  {
    value: 6,
    label: "Facility Manager",
  },
];

export const getRelatedRoles = (values) => {
  return UserTypes.filter((x) => values.includes(x.value));
};

export default UserTypes;
