import React, { useEffect, useState } from "react";
import UserContext from "./context/UserContext";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./components/home";
import { PolicyComponent } from "./components/home";
import DashboardLayout from "./components/dashboard/layouts/dashboard.layout";
import Dashboard from "./components/dashboard/pages/Dashboard/Dashboard";
import ManageGymBranch from "./components/dashboard/pages/ManageGymBranch/ManageGymBranch";
import ManageUser from "./components/dashboard/pages/ManageUsers/ManageUser";
import ManageGymMain from "./components/dashboard/pages/ManageGymMain/ManageGymMain";
import GymDetailComponent from "./components/dashboard/pages/ManageGymMain/GymDetailComponent";
import ManageMembership from "./components/dashboard/pages/ManageMembership/ManageMembership";
import ManageTrainer from "./components/dashboard/pages/ManageGymBranch/GymBranch/manageTrainer";
import ManageClass from "./components/dashboard/pages/ManageGymBranch/GymBranch/manageClass";
import Setting from "./components/dashboard/pages/SettingsPage/Setting";
import AttendanceReport from "./components/dashboard/pages/Reporting/ReportingAttendance";
import Dragtrial from "./components/custom/Dragtrial/Dragtrial";
import ConfirmDialog from "./components/custom/ConfirmDialog/ConfirmDialog";
import { autoLogin } from "../../repository/web/js/firebaseAuth";
import { SpinnerCircular } from "spinners-react";
import { useDispatch } from "react-redux";
// import { actionUserLoggedin } from "./redux/states/user/user.action";
import { setUserLoggedIn } from "./redux/slices/Auth.slice";
import UserDetailPageNew from "./components/dashboard/pages/UserDetailPage/UserDetailPageNew";
import Toster from "./components/custom/Toaster/Toster";
import UnauthorizeAccess from "./components/custom/AccessManager/UnauthorizeAccess";
import GymownerDetailView from "./components/dashboard/pages/ManageGymMain/detailGymOwner/gymowner.detailview";
import ManageFreelanceClasses from "./components/dashboard/pages/ManageGymBranch/GymBranch/manageFreelanceClasses";
import BranchDetailPage from "./components/dashboard/pages/ManageGymBranch/GymBranch/BranchDetailPage";
import GymDetailAdminView from "./components/dashboard/pages/ManageGymMain/detailGymAdmin/adminGymDetailView";
import { Provider } from "react-redux";
import store from "./redux/sliceStore/store";
const App = () => {
  const [user, setUser] = useState({});
  const [wait, setWait] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    autoLogin().then((r) => {
      if (r) {
        dispatch(setUserLoggedIn(r));
      } else {
        localStorage.clear();
      }
      setWait(false);
    });
    var globalTimer = setInterval(() => {
      autoLogin().then((r) => {
        if (r) {
          dispatch(setUserLoggedIn(r));
        } else {
          localStorage.clear();
        }
        setWait(false);
      });
    }, 900000);
    return () => {
      clearInterval(globalTimer);
    };
  }, []);
  const PrivateRoute = ({ Component }) => {
    // const isAuthenticated = localStorage.getItem("isAuthenticated");
    const isAuthenticated = localStorage.getItem("accesstoken");
    return isAuthenticated ? <Component /> : <Navigate to="/" />;
  };
  return (
    <UserContext.Provider value={[user, setUser]}>
      <Provider store={store}>
        {wait && (
          <div className="init-loading">
            <SpinnerCircular color="yellow" />
            <h6 className="mt-3 text-light">Please wait..</h6>
          </div>
        )}
        <Toster />
        <ConfirmDialog />
        <div>
          <HashRouter>
            {!wait && (
              <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="policy" element={<PolicyComponent />} />
                <Route
                  path="/"
                  element={<PrivateRoute Component={DashboardLayout} />}
                >
                  <Route
                    path="dashboard"
                    element={<PrivateRoute Component={Dashboard} />}
                  />
                  <Route
                    exact
                    path="unauthorize"
                    element={<PrivateRoute Component={UnauthorizeAccess} />}
                  />
                  <Route
                    path="unauthorize/:fallback"
                    element={<PrivateRoute Component={UnauthorizeAccess} />}
                  />
                  <Route
                    path="branches"
                    element={<PrivateRoute Component={ManageGymBranch} />}
                  />
                  <Route
                    path="user"
                    element={<PrivateRoute Component={ManageUser} />}
                  />
                  <Route
                    path="membership"
                    element={<PrivateRoute Component={ManageMembership} />}
                  />
                  <Route
                    path="settings"
                    element={<PrivateRoute Component={Setting} />}
                  />
                  <Route
                    path="logout"
                    element={<PrivateRoute Component={Dashboard} />}
                  />
                  <Route
                    path="gyms"
                    element={<PrivateRoute Component={ManageGymMain} />}
                  />
                  <Route
                    path="maingymdetail"
                    element={<PrivateRoute Component={GymDetailAdminView} />}
                  />
                  <Route
                    path="gymBranchDetails"
                    element={<PrivateRoute Component={BranchDetailPage} />}
                  />
                  <Route
                    path="edituser"
                    element={<PrivateRoute Component={UserDetailPageNew} />}
                  />
                  <Route
                    path="gymedittrainer"
                    element={<PrivateRoute Component={ManageTrainer} />}
                  />
                  <Route
                    path="gymeditclass"
                    element={<PrivateRoute Component={ManageClass} />}
                  />
                  <Route
                    path="reporting"
                    element={<PrivateRoute Component={AttendanceReport} />}
                  />
                  <Route
                    path="gymprofile"
                    element={<PrivateRoute Component={GymownerDetailView} />}
                  />
                  <Route
                    path="freelanceclasses"
                    element={
                      <PrivateRoute Component={ManageFreelanceClasses} />
                    }
                  />
                  <Route path="dragging" element={<Dragtrial />} />
                  <Route
                    path="*"
                    element={<UnauthorizeAccess privateFallback="dashboard" />}
                  />
                </Route>
              </Routes>
            )}
          </HashRouter>
        </div>
      </Provider>
    </UserContext.Provider>
  );
};

export default App;
