import { createSlice } from "@reduxjs/toolkit";

const ManageMembershipSlice = createSlice({
  name: "ManageMembership",
  initialState: {
    disable: false,
    loading: false,
    isOpen: false,
    isAddDesc: false,
    description: [],
    name: "",
    priceMonth: "",
    oneDayPrice: 0,
    attendancePrice: 0,
    priceAnnual: "",
    displayColorPicker: false,
    color: { r: "241", g: "112", b: "19", a: "1" },
    newDesc: "",
    nameError: false,
    monthError: false,
    annualError: false,
    attendanceError: false,
    createError: false,
    createSuccess: false,
  },
  reducers: {
    setDisable: (state, action) => {
      state.disable = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setIsAddDesc: (state, action) => {
      state.isAddDesc = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setPriceMonth: (state, action) => {
      state.priceMonth = action.payload;
    },
    setOneDayPrice: (state, action) => {
      state.oneDayPrice = action.payload;
    },
    setAttendancePrice: (state, action) => {
      state.attendancePrice = action.payload;
    },
    setPriceAnnual: (state, action) => {
      state.priceAnnual = action.payload;
    },
    setColorPicker: (state, action) => {
      state.displayColorPicker = action.payload;
    },
    setColor: (state, action) => {
      state.color = action.payload;
    },
    setNewDesc: (state, action) => {
      state.newDesc = action.payload;
    },
    setNameError: (state, action) => {
      state.nameError = action.payload;
    },
    setMonthError: (state, action) => {
      state.monthError = action.payload;
    },
    setAnnualError: (state, action) => {
      state.annualError = action.payload;
    },
    setAttendanceError: (state, action) => {
      state.attendanceError = action.payload;
    },
    setCreateError: (state, action) => {
      state.createError = action.payload;
    },
    setCreateSuccess: (state, action) => {
      state.createSuccess = action.payload;
    },
  },
});

export const {
  setAnnualError,
  setAttendanceError,
  setAttendancePrice,
  setColor,
  setColorPicker,
  setCreateError,
  setCreateSuccess,
  setDescription,
  setDisable,
  setIsAddDesc,
  setIsOpen,
  setLoading,
  setMonthError,
  setName,
  setNameError,
  setNewDesc,
  setOneDayPrice,
  setPriceAnnual,
  setPriceMonth,
} = ManageMembershipSlice.actions;

export default ManageMembershipSlice.reducer;
