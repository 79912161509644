import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import "./ErrorDialog.scss";

export default class ErrorDialog extends Component {
  constructor(props) {
    super(props);
    window.confirmDialog = this;
    this.state = {
      show: false,
      title: "",
      message: "", // can be jsx
      okCallback: async () => {
        return true;
      },
      cancelCallback: () => {},
      okText: "Close",
      cancelText: "Close",
      okVariant: "warning",
      showFooter: true,
    };
  }

  handleClose = () => {
    this.state.cancelCallback();
    this.setState({ show: false });
  };

  handleClose = () => {
    this.state.okCallback().then((result) => {
      if (result) {
        this.setState({ show: false });
      }
    });
  };

  static hide() {
    window.confirmDialog.setState({
      okCallback: () => {},
      cancelCallback: () => {},
      okVariant: "warinig",
      okText: "Close",
      cancelText: "No",
      title: "Hey!",
    });
    window.confirmDialog.setState({
      show: false,
    });
  }

  static show({
    message = "",
    title = "Hey!",
    okCallback = () => {},
    cancelCallback = () => {},
    okText = "Close",
    cancelText = "No",
    okVariant = "warning",
    showFooter = false,
  }) {
    window.confirmDialog.setState({
      show: true,
      title: title,
      message: message,
      okCallback: okCallback,
      cancelCallback: cancelCallback,
      okText: okText,
      cancelText: cancelText,
      okVariant: okVariant,
      showFooter: showFooter,
    });
  }

  render() {
    return (
      <Modal
        className="confirmDialog-modal"
        show={this.state.show}
        onHide={this.handleClose}
        backdrop={"static"}
        keyboard={true}
        centered
      >
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
          <button onClick={this.handleClose}>&#10005;</button>
        </Modal.Header>
        <Modal.Body
          className={`${!this.state.showFooter && "body-content-fullborder"}`}
        >
          {this.state.message}
        </Modal.Body>
        {this.state.showFooter && (
          <Modal.Footer>
            <Button
              variant="secondary"
              size="sm"
              className="ps-3 pe-3"
              onClick={this.handleClose}
            >
              {this.state.cancelText}
            </Button>
            <Button
              variant={this.state.okVariant}
              size="sm"
              className=""
              onClick={this.handleClose}
            >
              {this.state.okText}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}
