import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../main";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
  getAuth,
} from "firebase/auth";
import { saveUser } from "./user";

const collection_user = "users";

export const autoLogin = async () => {
  let result = null;
  try {
    const key = localStorage.getItem("accesstoken");
    if (key) {
      const userData = await getUserById(JSON.parse(key));
      if (userData.exists()) {
        result = { ...userData.data(), id: userData.id };
      }
    }
  } catch (error) {
    console.log(error);
  }
  return result;
};

export const getUserById = (id) => {
  return getDoc(doc(db, collection_user, id));
};

export const getUsersByEmail = async (email) => {
  let profile = "";
  let docsF = await getDocs(
    query(
      collection(db, collection_user),
      where("username", "==", email),
      limit(1)
    )
  );
  if (docsF.docs.size !== 0) {
    docsF.docs.forEach((doc) => {
      profile = { ...doc.data(), id: doc.id };
    });
  }

  return profile;
};

/*
This function saves a user not ( Not specific )
 */
// const saveUser = async (uid, user) => {
//   let temp = { ...user, activated: false };
//   if (user?.role?.includes(5) && user?.role?.length === 1) {
//     temp = { ...user, activated: true };
//   }
//   await setDoc(doc(db, collection_user, uid), {
//     ...temp,
//   });
// };

/*
This function creates a customer in database with out phone number
 */
export const addUserWithEmailAndName = (username, displayname) => {
  return addDoc(collection(db, collection_user), {
    username: username,
    sure_name: displayname,
    role: [5],
    activated: true,
  });
};

export const logInWithEmailAndPassword = async (email, password) => {
  let token = "";
  let userId = 0;
  let username = "";

  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      userId = userCredential.user.uid;
      username = userCredential.user.email;

      return userCredential.user.getIdToken();
    })
    .then((idtoken) => {
      token = idtoken;
      //return getUserById(userId);
      return getUsersByEmail(username);
    })
    .then((data) => {
      //   data = { ...data.data(), id: data.id };
      return { profile: data, error: "", token: token };
    })
    .catch((error) => {
      return { profile: "", error: error.code, token: "" };
    });
};

export const signUpWithEmailAndPassword = async (user, password) => {
  let token = 0,
    userId;
  let error = "";
  return createUserWithEmailAndPassword(auth, user.username, password)
    .then((data) => {
      userId = data.user.uid;
      return data.user.getIdToken();
    })
    .then((idtoken) => {
      token = idtoken;
      saveUser(userId, user);
      return { token: token, error: "" };
    })
    .catch((err) => {
      return { profile: "", error: err.code, token: token };
    });
};

const provider = new GoogleAuthProvider();
export const loginwithGoogleAccount = async () => {
  let email = "";
  let token = 0;
  let name = "";
  return signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const accesstoken = credential.accessToken;
      const user = result.user;
      token = credential.idToken;
      email = user.email;
      name = user.displayName;
      const q = query(
        collection(db, collection_user),
        where("username", "==", user.email),
        limit(1)
      );
      return getDocs(q);
    })
    .then((docs) => {
      if (docs.size != 0) {
        let profile;
        docs.forEach((doc) => {
          profile = { ...doc.data(), id: doc.id };
        });
        return { profile: profile, error: "", token: token };
      } else {
        // return addUserWithEmailAndName(email, name).then((data) => {
        return {
          profile: {
            username: email,
            sure_name: name,
            //  id: data.id
          },
          error: "",
          token: token,
          verified: false,
        };
        // });
      }
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log(error);
      return { profile: "", error: error.message, token: "" };
    });
};

const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("name");

export const loginWithApple = async () => {
  let email = "";
  let token = 0;
  let name = "";
  return signInWithPopup(auth, appleProvider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const accesstoken = credential.accessToken;
      const user = result.user;
      token = credential.idToken;
      email = user.email;
      name = user.displayName;
      const q = query(
          collection(db, collection_user),
          where("username", "==", user.email),
          limit(1)
      );
      return getDocs(q);
    })
      .then((docs) => {
        if (docs.size != 0) {
          let profile;
          docs.forEach((doc) => {
            profile = { ...doc.data(), id: doc.id };
          });
          return { profile: profile, error: "", token: token };
        } else {
          // return addUserWithEmailAndName(email, name).then((data) => {
          return {
            profile: {
              username: email,
              sure_name: name,
              //  id: data.id
            },
            error: "",
            token: token,
            verified: false,
          };
          // });
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(error);
        return { profile: "", error: error.message, token: "" };
      });
};

export const resetPassword = async (email) => {
  const auth = getAuth();
  return sendPasswordResetEmail(auth, email)
    .then(() => {
      return "Success";
    })
    .catch((error) => {
      // const errorCode = error.code;
      const errorMessage = error.message;
      return errorMessage;
    });
};

export const emailVerification = async (email) => {
  const auth = getAuth();
  return sendEmailVerification(auth.currentUser)
    .then(() => {
      return auth.currentUser;
    })
    .catch((error) => {
      return false;
    });
};

export const logOut = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      console.log(error);
    });
};
