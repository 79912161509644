import React, { createContext, useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Toster from "../Toaster/Toster";
export const AccessContext = createContext();
export default function AccessManager({
  allowedRoles = null,
  fallback = null,
  activateRequired = false,
  ...rt
}) {
  const navigate = useNavigate();
  const { user: userLoggedin } = useSelector((state) => state.user);
  const [allowedRolesList, setallowedRolesList] = useState(allowedRoles || []);
  const [isValidRole, setisValidRole] = useState(false);
  const accesstoken = JSON.parse(localStorage.getItem("accesstoken"));

  const isUserValid = () => {
    let result = false;
    if (userLoggedin && userLoggedin !== null) {
      if (userLoggedin.id === accesstoken) {
        result = true;
      }
    }
    return result;
  };

  const checkRoles = () => {
    let isHaveValidRole = false;
    let lRoles = userLoggedin?.role;
    if (allowedRolesList && allowedRoles?.length > 0) {
      lRoles.forEach((role) => {
        if (allowedRolesList?.includes(role)) {
          isHaveValidRole = true;
          setisValidRole(true);
          return;
        }
      });
    }
    return isHaveValidRole;
  };

  useEffect(() => {
    setallowedRolesList(allowedRoles);
    let validUser = isUserValid();
    if (!validUser) return navigate("/");
    let havevalidRole = checkRoles();
    if (!havevalidRole)
      Toster.show(
        "Unauthorized access: You are not allowed to access this page.",
        true
      );
    if (!havevalidRole)
      return navigate(fallback ? "/unauthorize/" + fallback : "/unauthorize");
  }, []);

  return <>{rt.children}</>;
}
